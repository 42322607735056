
import InfoItem from '@/components/InfoItem'

export default {
  name: 'InfoList',
  components: {
    InfoItem,
  },
  props: {
    items: {
      type: Array,
      default: () => {},
    },
  },
};
