import { render, staticRenderFns } from "./ControlPanel.vue?vue&type=template&id=038845f1"
import script from "./ControlPanel.vue?vue&type=script&lang=js"
export * from "./ControlPanel.vue?vue&type=script&lang=js"
import style0 from "./ControlPanel.vue?vue&type=style&index=0&id=038845f1&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SubscribeLabel: require('/builds/web-cloud/webfront/components/SubscribeLabel.vue').default,StateBadge: require('/builds/web-cloud/webfront/components/StateBadge.vue').default,ControlPanelIndicator: require('/builds/web-cloud/webfront/components/control-panel/ControlPanelIndicator.vue').default,ControlPanelLottie: require('/builds/web-cloud/webfront/components/control-panel/ControlPanelLottie.vue').default,ControlPanelCircular: require('/builds/web-cloud/webfront/components/control-panel/ControlPanelCircular.vue').default,ControlPanelLinear: require('/builds/web-cloud/webfront/components/control-panel/ControlPanelLinear.vue').default,ControlPanelIcon: require('/builds/web-cloud/webfront/components/control-panel/ControlPanelIcon.vue').default,ControlPanelCard: require('/builds/web-cloud/webfront/components/control-panel/ControlPanelCard.vue').default,ControlPanelTimer: require('/builds/web-cloud/webfront/components/control-panel/ControlPanelTimer.vue').default,ControlPanelSchedule: require('/builds/web-cloud/webfront/components/control-panel/ControlPanelSchedule.vue').default,ClimateOnlineLabel: require('/builds/web-cloud/webfront/components/ClimateOnlineLabel.vue').default,SubscribeBadge: require('/builds/web-cloud/webfront/components/SubscribeBadge.vue').default})
