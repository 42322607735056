export const state = () => ({
  optionInfoData: null,
});

export const getters = {
  getOptionInfoData: ({ optionInfoData }) => optionInfoData,
};

export const mutations = {
  SET_MODAL_OPTION_INFO_DATA(state, optionInfoData) {
    state.optionInfoData = optionInfoData;
  },
};

export const actions = {
  setModalData({ commit }, data) {
    commit('SET_MODAL_OPTION_INFO_DATA', data);
  },
};
