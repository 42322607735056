
import ArrowIcon from '../../assets/icons/Arrows/arrow-right.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'CreatePresetButtonPreview',
  components: { ArrowIcon },
  props: {
    place: {
      type: Object,
      required: true,
      validator: (value) =>
        // проверка на наличи заголовка
        value.title
      ,
    },
    presetData: {
      type: Object,
      required: false,
    },
  },
  emits: ['openPanel'],
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
    stateInfoText() {
      if (this.presetData?.state?.info?.text) {
        return this.presetData.state.info.text.replace(' ℃', '°C');
      }
      if (this.presetData?.deviceState?.info?.text) {
        return this.presetData.deviceState.info.text.replace(' ℃', '°C');
      }
      return '';
    },
  },
  methods: {
    hiddenIcons(icons) {
      return icons ? icons.slice(4).length : 0;
    },
  },
};
