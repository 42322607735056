
import { mapActions, mapGetters } from "vuex";
import AppButton from "../Buttons/AppButton.vue";
import CheckboxObject from "../checks/checkbox-object.vue";
import modalEsc from "../../mixins/modalEsc";
import CloseIcon from "../../assets/icons/x.svg";
import SearchInput from "../Inputs/SearchInput.vue";
import AddCommandPanel from "../Menus/AddCommandPanel.vue";
import PlusIcon from "../../assets/icons/plus.svg";
import CreatePresetButtonPreview from "../Buttons/CreatePresetButtonPreview.vue";

export default {
  name: "AddCommandModal",
  components: {
    CreatePresetButtonPreview,
    AddCommandPanel,
    AppButton,
    CheckboxObject,
    CloseIcon,
    SearchInput,
    PlusIcon,
  },
  mixins: [modalEsc],
  data: () => ({
    modalName: "addCommandModal",
    step: 1,
    searchValue: "",
    preloading: false,
    listOfPlaces: [],
    countChecked: 0,
    SHOW_BY: 21,
    countShowPlaces: 21,
    showPanel: false,
    deviceInPanel: null,
    preloadingDeviceInfo: false,
    deviceTitle: "",
    pulfForConfiguration: null,
    deviceId: null,
  }),
  methods: {
    ...mapActions({
      toggleModal: "system/setShowModal",
      fetchBuildingSelectPlaces: "buildingDashboard/fetchBuildingSelectPlaces",
      fetchPultForConfiguration: "addPreset/fetchPultForConfiguration",
      fetchPultConfigurate: "addPreset/fetchPultConfigurate",
      fetchDevicesPresets: "addPreset/fetchDevicesPresets",
      fetchConfigurationForPreset: "addPreset/fetchConfigurationForPreset",
    }),
    async pultHandler(data) {
      const configuration = { ...data, pult: this.pulfForConfiguration.pult };
      this.pulfForConfiguration = await this.fetchPultConfigurate({
        id: this.deviceId,
        configuration,
      });
      this.listOfPlaces = this.listOfPlaces.map((item) => {
        if (item.id === this.deviceId) {
          item.pult = this.pulfForConfiguration.pult;
          item.presetData = {
            type: "custom",
            deviceState: this.pulfForConfiguration.state,
          };
        }
        return item;
      });
    },
    choicePreset(preset) {
      this.listOfPlaces = this.listOfPlaces.map((item) => {
        if (item.id === preset.placeId) {
          item.presetData = preset;
        }
        return item;
      });
    },
    async buttonHandler() {
      if (this.step === 1) {
        this.step = 2;
      } else if (this.step === 2) {
        if (this.typeOfModal === "edit") {
          this.$store.commit(
            "addPreset/setDataToSend",
            this.listOfPlaces.filter((item) => item.checked)
          );
          await this.$store.dispatch("addPreset/changeCommand");
          this.$modal.hide(this.modalName);
        } else {
          this.$store.commit(
            "addPreset/setDataToSend",
            this.listOfPlaces.filter((item) => item.checked)
          );
          this.$modal.show("fastCommandModal");
        }
      }
    },
    async openPanel(place) {
      this.showPanel = true;
      if (place.id !== this.deviceId) {
        this.preloadingDeviceInfo = true;
        this.pulfForConfiguration = await this.fetchPultForConfiguration(
          place.id
        );
        this.deviceInPanel = await this.fetchConfigurationForPreset({
          id: place.id,
          type: "group_preset",
        });
        this.deviceId = place.id;
        this.deviceTitle = place.title;
        // проверить создание и редактирование клики
        if (!place.presetData) {
          this.choicePreset({
            type: "current",
            placeId: this.deviceId,
            state: this.deviceInPanel[0].options[2].deviceOption.state,
            functions: this.deviceInPanel[0].options[2].deviceOption.functions,
          });
        }
        this.preloadingDeviceInfo = false;
      }
    },
    async modalOpen() {
      this.preloading = true;
      await this.fetchBuildingSelectPlaces(this.buildingId);
      this.listOfPlaces.push(...this.allPlaces);
      this.allGroups.forEach((item) => {
        item && this.listOfPlaces.push(...item.places);
      });
      this.toggleModal(true);
      document.addEventListener("keydown", this.clickHandler);
      if (this.typeOfModal === "edit") {
        this.step = 2;
        if (!this.editData.isGroup) {
          this.listOfPlaces = this.listOfPlaces.map((item) => {
            if (item.id === this.editData.placeId) {
              this.countChecked++;
              item.checked = true;
              if (this.editData.deviceState.isOn === false) {
                item.presetData = {
                  deviceState: this.editData.deviceState,
                  type: "off",
                };
              } else {
                item.presetData = {
                  deviceState: this.editData.deviceState,
                  type: "current",
                };
              }
            }
            return item;
          });
        }
        if (this.editData.isGroup) {
          await this.$store.dispatch(
            "addPreset/fetchEditGroupPresetData",
            this.editData.id
          );
          this.editGroupData.contents.forEach((place) => {
            this.listOfPlaces = this.listOfPlaces.map((item) => {
              if (item.id === place.placeId) {
                item.presetData = {
                  deviceState: place.deviceState,
                  type: place.deviceState.isOn ? "current" : "off",
                  functions: place.functions,
                };
                this.countChecked++;
                item.checked = true;
              }
              return item;
            });
          });
        }
      }
      this.preloading = false;
      window.addEventListener("keydown", this.enterClick);
    },
    async enterClick(e) {
      const { keyCode } = e;
      if (keyCode === 13 && !this.buttonDisabled) {
        await this.buttonHandler();
      }
    },
    closeHandler() {
      this.$modal.hide(this.modalName);
      this.$emit("prevStep");
    },
    closePanelOnBg(e) {
      const path = e.path || e.composedPath();
      if (
        path &&
        path[0] &&
        path[0].classList &&
        path[0].classList.contains("add-command__inner")
      ) {
        this.showPanel = false;
      }
    },
    beforeClosed(e) {
      if (this.modalShowCount > 1) {
        e.cancel();
      }
    },
    modalClosed() {
      this.toggleModal(false);
      document.removeEventListener("keydown", this.clickHandler);
      this.step = 1;
      this.listOfPlaces = [];
      this.countChecked = 0;
      this.countShowPlaces = this.SHOW_BY;
      this.showPanel = false;
      this.deviceId = null;
      this.deviceInPanel = null;
      this.searchValue = "";
      this.$store.commit("addPreset/setCurrentPresetToChange", null);
      this.$store.commit("addPreset/setTypeOfCommand", null);
      window.removeEventListener("keydown", this.enterClick);
    },
    searchHandler() {
      this.countShowPlaces = this.SHOW_BY;
    },
    checkItem(item) {
      for (let i = 0; i < this.listOfPlaces.length; i++) {
        if (this.listOfPlaces[i].id === item.id) {
          this.listOfPlaces[i].checked = !this.listOfPlaces[i].checked;
          this.listOfPlaces[i].checked
            ? this.countChecked++
            : this.countChecked--;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      dashboardData: "buildingDashboard/getBuildingSelectDashboard",
      buildingId: "buildings/getBuildingSelectId",
      typeOfModal: "addPreset/getTypeOfModal",
      typeOfCommand: "addPreset/getTypeOfCommand",
      editData: "addPreset/getCurrentPresetToChange",
      editGroupData: "addPreset/getDataEditGroupPreset",
      getTranslations: 'localization/getTranslations',
    }),
    currentTypeOfSettings() {
      const device = this.listOfPlaces.find(
        (item) => item.id === this.deviceId
      );
      if (device && device.presetData) {
        return device.presetData.type;
      }
      return "current";
    },
    mainTitle() {
      return this.typeOfModal === "create"
        ? this.getTranslations['Выберите устройства'] || 'Выберите устройства'
        : this.getTranslations['Редактировать быструю команду'] || 'Редактировать быструю команду';
    },
    firstDescription() {
      return this.typeOfModal === "create"
        ? this.getTranslations['Выберите устройство или устройства, для которых вы хотите создать быструю команду'] || 'Выберите устройство или устройства, для которых вы хотите создать быструю команду'
        : this.getTranslations['Выберите устройство или устройства, которые хотите добавить в быструю команду'] || 'Выберите устройство или устройства, которые хотите добавить в быструю команду';
    },
    secondTitle() {
      return this.typeOfModal === "create"
        ? this.getTranslations['Добавление быстрой команды'] || 'Добавление быстрой команды'
        : this.getTranslations['Редактирование быстрой команды'] || 'Редактирование быстрой команды';
    },
    secondDescription() {
      return this.typeOfModal === "create"
        ? this.getTranslations['Определите настройки для режима работы устройств в рамках создаваемой быстрой команды'] || 'Определите настройки для режима работы устройств в рамках создаваемой быстрой команды'
        : this.getTranslations['Определите настройки для режима работы устройств в рамках быстрой команды'] || 'Определите настройки для режима работы устройств в рамках быстрой команды';
    },
    buttonDisabled() {
      if (this.step === 1) {
        return this.countChecked < 1;
      }
      if (this.step === 2) {
        return (
          this.listOfPlaces.filter((item) => item.checked && item.presetData)
            .length !== this.countChecked
        );
      }
    },
    searchPlacesResult() {
      return this.listOfPlaces.filter((place) =>
        place.title.toLowerCase().includes(this.searchValue.toLowerCase())
      );
    },
    searchPlacesToShow() {
      return this.searchPlacesResult.slice(0, this.countShowPlaces);
    },
    showMorePlacesAmount() {
      let amount = 0;
      const leftToShow = this.searchPlacesResult.length - this.countShowPlaces;
      if (leftToShow >= this.SHOW_BY) {
        amount = this.SHOW_BY;
      } else if (leftToShow > 0) {
        amount = leftToShow;
      }
      return amount;
    },
    allPlaces() {
      return this.dashboardData ? this.dashboardData.places : [];
    },
    allGroups() {
      return this.dashboardData ? this.dashboardData.groups : [];
    },
  },
};
