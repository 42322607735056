
import { mapGetters } from 'vuex';

export default {
  name: 'AuthFooter',
  data: () => ({}),
  computed: {
    ...mapGetters({
      versions: 'system/getVersions',
      getTranslations: 'localization/getTranslations',
    }),
  },
  mounted() {
    // eslint-disable-next-line nuxt/no-env-in-hooks
    if (process.client && !(this.versions.front || this.versions.back)) {
      this.$store.dispatch('system/setAppVersion');
    }
  },
};
