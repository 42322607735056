
import MarkIcon from '../../assets/icons/mark.svg';

export default {
  name: 'CheckMark',
  components: { MarkIcon },
  props: {
    id: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    inputHandler() {
      this.$emit('input');
    },
  },
};
