
import lottie from 'vue-lottie/src/lottie.vue';
import * as animationData from 'static/DC-spinner.json';

export default {
  name: 'LoadingSpinner',
  components: {
    lottie,
  },
  props: {
    playing: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    anim: null,
    lottieOptions: {
      animationData: animationData.default,
      autoplay: false,
    },
  }),
  watch: {
    playing() {
      if (this.playing) {
        this.anim.play();
      } else {
        this.anim.stop();
      }
    },
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
    },
  },
};

