
import { mapGetters } from "vuex";

export default {
  name: "ClimateOnlinePaymentSmall",
  computed: {
    ...mapGetters({
      getTranslations: "localization/getTranslations",
    }),
  },
};
