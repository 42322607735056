
import { mapGetters } from 'vuex';
import Timeline from './TimeLine.vue';
import DropDown from '../../assets/icons/24x24/dropDown.svg';
import DayButton from '../Buttons/DayButton.vue';
import AddPeriodButton from '../Buttons/AddPeriodButton.vue';
import DeleteButton from '../Buttons/DeleteButton.vue';
import PasteButton from '../Buttons/PasteButton.vue';
import CopyButton from '../Buttons/CopyButton.vue';

export default {
  name: 'SchedulePlaceSetting',
  components: {
    CopyButton,
    PasteButton,
    DeleteButton,
    DayButton,
    Timeline,
    DropDown,
    AddPeriodButton,
  },
  props: {
    place: {
      type: Object,
      required: true,
    },
  },
  emits: ['newCols', 'toggleDayWeek', 'newWidth', 'newInterval', 'paste-schedule', 'show-panel', 'copy-schedule'],
  data: () => ({
    // days: [this.getTranslations['Пн'], this.getTranslations['Вт'], this.getTranslations['Ср'], this.getTranslations['Чт'], this.getTranslations['Пт'], this.getTranslations['Сб'], 'Вс'],
    opened: null,
  }),
  computed: {
    ...mapGetters({
      copyRequestStore: 'schedules/getCopyRequestStore',
      getTranslations: 'localization/getTranslations',
    }),
    days() {
      return [this.getTranslations['Пн'], this.getTranslations['Вт'], this.getTranslations['Ср'], this.getTranslations['Чт'], this.getTranslations['Пт'], this.getTranslations['Сб'], 'Вс'];
    },
    disableDelete() {
      return this.place.periods.length === 1;
    },
    disableAddPeriod() {
      let countDays = 0;
      this.place.periods.forEach((period) => {
        countDays += period.weekDays.length;
      });
      return countDays === 7;
    },
  },
  beforeMount() {
    if (!this.closed) {
      this.opened = [];
      this.place.periods.forEach(() => {
        this.opened.push(true);
      });
    }
  },
  methods: {
    addFirstTime(e) {
      this.$store.commit('schedules/addFirstTime', {
        ...e,
        placeId: this.place.id,
      });
    },
    filterTimes(info) {
      this.$store.commit('schedules/filterSmallTimes', info);
    },
    isAllConfigured(periodIndex) {
      let isAllConfigured = true;
      this.place.periods[periodIndex].times.forEach((time) => {
        if ((!time.action.deviceState.isOn && !time.action.deviceState.functions && !time.action.presetId)
          || (!time.action.presetId && !time.action.deviceState)
        ) {
          isAllConfigured = false;
        }
      });
      return isAllConfigured;
    },
    isFreeDay(day) {
      const dayNumber = this.days.indexOf(day);
      const usedDays = [];
      this.place.periods.forEach((period) => {
        usedDays.push(...period.weekDays);
      });
      return usedDays.indexOf(dayNumber + 1) === -1;
    },
    deleteHandler(periodIndex) {
      this.$store.commit(
        'schedules/deletePeriod',
        { periodIndex, placeId: this.place.placeId },
      );
    },
    createPeriod() {
      this.$store.commit('schedules/setNewPeriod', this.place.placeId);
      this.$nextTick(() => {
        this.opened = [];
        this.place.periods.forEach(() => {
          this.opened.push(true);
        });
      });
    },
    openTime({ indexTime, periodIndex }) {
      this.$store.commit('schedules/setActivePeriodIndex', periodIndex);
      this.$store.commit('schedules/setActiveTimeIndex', indexTime);
      this.$store.commit('schedules/setPanelStep', 2);
      this.$emit('show-panel');
    },
    pasteHandler(index) {
      this.$emit('paste-schedule');
      this.$store.dispatch(
        'schedules/pasteSchedule',
        { targetDeviceIds: [this.place.placeId], pastePeriodIndex: index },
      );
    },
    copyClick(periodIndex) {
      this.$emit('copy-schedule');
      this.$store.commit('schedules/setCopyData', {
        periods: this.place.periods[periodIndex],
        sourceDeviceId: this.place.placeId,
        periodIndex,
      });
    },
    newInterval(info) {
      this.$emit('newInterval', info);
    },
    newWidth(info) {
      this.$emit('newWidth', info);
    },
    toggleDay(info) {
      this.$emit('toggleDayWeek', info);
    },
    toggleTimeLine(index) {
      this.opened = this.opened.map((item, openIndex) => {
        if (index === openIndex) {
          item = !item;
        }
        return item;
      });
    },
  },
};
