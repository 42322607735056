import * as scheme1 from './scheme-1.json';
import * as scheme2 from './scheme-2.json';
import * as scheme3 from './scheme-3.json';

export default [
  {
    title: 'SCHEME_1',
    data: scheme1.default,
    text: 'хорошее качество воздуха',
    background: 'linear-gradient(90deg, #51CDBC, #FFCBAD)',
  },
  {
    title: 'SCHEME_2',
    data: scheme2.default,
    text: 'среднее загрязнение',
    background: 'linear-gradient(90deg, #859BFF, #FFBAAD)',
  },
  {
    title: 'SCHEME_3',
    data: scheme3.default,
    text: 'сильное загрязнение',
    background: 'linear-gradient(90deg, #FF694D, #FFDCD6)',
  },
];
