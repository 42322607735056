import { render, staticRenderFns } from "./ButtonArrow.vue?vue&type=template&id=2df55d19&scoped=true"
import script from "./ButtonArrow.vue?vue&type=script&lang=js"
export * from "./ButtonArrow.vue?vue&type=script&lang=js"
import style0 from "./ButtonArrow.vue?vue&type=style&index=0&id=2df55d19&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2df55d19",
  null
  
)

export default component.exports