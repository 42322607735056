import Vue from 'vue';
import VModal from 'vue-js-modal/dist/ssr.nocss';

// немного переписал css у плагина
import '../assets/css/modal-plugin.css';

Vue.use(VModal);

export default (context, inject) => {
  inject('modal', Vue.prototype.$modal);
};
