export const MIN_LENGTH_PASSWORD = 6;
export const MIN_LENGTH_CODE = 6;
export const MAX_LENGTH_COMMENT = 255;
export const RESCUE_TIME_CODE = 60; // время таймера, если не было получено из запроса
export const PHONE_REG = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{10}$/;
export const EMAIL_REG = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// role - роль пользователя
// group - название для группы в получаемых данных
export const userAccessRoles = [
  {
    role: 'owner',
    group: 'owners',
    title: 'Владелец',
  },
  {
    role: 'master',
    group: 'masters',
    title: 'Полный доступ к объекту',
  },
  {
    role: 'guest',
    group: 'guests',
    title: 'Гость',
  },
];
export const userAccessGroups = [
  {
    role: 'all',
    group: 'all',
    title: 'Все',
  },
  ...userAccessRoles,
];

export const getUserInitials = (username) => {
  // Инициалы пользователя для аватара. Первые буквы имени/и фамилии
  const words = username.toUpperCase().split(' ');
  if (words.length >= 2) {
    return words[0].slice(0, 1) + words[1].slice(0, 1);
  }
  return words[0].slice(0, 1);
};

export const clearUsername = (username) => username.replace(/ +/g, ' ').trim();

export const getExpiredDays = (seconds) => {
  const minutes = seconds / 60;
  const hours = minutes / 60;
  return Math.ceil(hours / 24);
};
