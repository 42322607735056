export const { CLIENT_ID } = process.env;
export const { GMAP_API_KEY } = process.env;
export const clearAllHeaders = (data, headers) => {
  // установленные вручную в конфиге
  delete headers.Authorization;
  delete headers.appVersion;
  delete headers.platform;

  // установленные по умолчанию
  delete headers.common;
  return data;
};

export function statusHandler(status) {
  if (status >= 500) {
    this.$modal.show("serverError");
  }
}

// Вывод модалок с ошибками подключения (500)
export function errorServiceHandler(error) {
  if (!navigator.onLine) {
    this.$modal.show("noConnection");
  } else if (error.response?.status >= 500 || error.request?.status === 0) {
    this.$modal.show("serverError");
  } else {
    console.error(error);
    this.$modal.show("problems");
  }
}

// Вывод модалок с ошибками сервера (422) ^
export function errorHandler(error) {
  console.log("error", error.response);
  if (error.response?.status === 422) {
    // 422 - ошибка, обработанная сервером
    this.dispatch("modalDefaultMessage/setModalData", {
      title: this.getters["localization/getTranslations"]["Ошибка"],
      text: error.response.data.message,
    });
    this.$modal.show("DefaultMessageModal");
  } else {
    errorServiceHandler.call(this, error);
  }
}

// Вывод модалок с ошибками сервера (422) ^
export function errorHandlerWithAction(error, action) {
  // console.log('####errorHandlerWithAction');
  if (!navigator.onLine) {
    this.$modal.show("noConnection");
  } else if (error.response?.status >= 500 || error.request?.status === 0) {
    this.$modal.show("serverError");
  } else {
    this.dispatch("modalDefaultMessage/setModalData", {
      title: this.getters["localization/getTranslations"]["Ошибка"],
      text: error.response.data.message,
      status: error.response.status,
      action,
    });
    this.$modal.show("DefaultMessageModal");
  }

  // this.dispatch("modalDefaultMessage/setModalData", {
  //   title: "Ошибка",
  //   text: error.response.data.message,
  //   status: error.response.status,
  //   action,
  // });
  // this.$modal.show("DefaultMessageModal");
}

// Вывод модалок с ошибками конфликтов (409) и ошибок сервера (422) ^^
export function errorConflictHandler(error, payload) {
  if (error.response?.status === 409) {
    // 409 - конфликт при управлении сущностями
    this.dispatch("modalConflictResolve/setModalData", {
      mainData: error.response.data,
      config: error.response.config,
      forceLoadingCardId: payload?.forceLoadingCardId,
    });
    this.$modal.show("ConflictResolveModal");
  } else {
    errorHandler.call(this, error);
  }
}

// Вывод модалок с ошибками конфликтов (409) и ошибок сервера (422) ^^
export function errorConflictPaymentHandler(error, cb, action) {
  if (error.response?.status === 409) {
    // 409 - конфликт при управлении сущностями
    cb();
    this.dispatch("modalConflictResolve/setModalData", {
      mainData: error.response.data,
      config: error.response.config,
    });
    this.$modal.show("ConflictResolveModal");
  } else {
    errorHandlerWithAction.call(this, error, action);
  }
}

// Вывод модалок с ошибками конфликтов (409) и ошибок сервера (422) ^^
export function errorConflictDeviceHandler(conflict, cb) {
  cb();
  this.$store.dispatch("modalConflictResolve/setModalData", {
    mainData: conflict,
    config: null,
  });
  this.$modal.show("ConflictResolveModal");
}
