import { render, staticRenderFns } from "./AddFastCommandControlPanel.vue?vue&type=template&id=4f99173e&scoped=true"
import script from "./AddFastCommandControlPanel.vue?vue&type=script&lang=js"
export * from "./AddFastCommandControlPanel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f99173e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControlPanelCircular: require('/builds/web-cloud/webfront/components/control-panel/ControlPanelCircular.vue').default,ControlPanelLinear: require('/builds/web-cloud/webfront/components/control-panel/ControlPanelLinear.vue').default,ControlPanelIcon: require('/builds/web-cloud/webfront/components/control-panel/ControlPanelIcon.vue').default,ControlPanelCard: require('/builds/web-cloud/webfront/components/control-panel/ControlPanelCard.vue').default})
