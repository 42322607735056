
import { mapGetters } from "vuex";
import CloseIcon from "@/assets/icons/24x24/close.svg";
import AppButton from "@/components/Buttons/AppButton";
import Icon from '@/assets/images/icon-default.svg';

export default {
  name: 'RecommendationModal',
  components: {
    CloseIcon,
    AppButton,
    Icon,
  },
  data: () => ({
    modalName: 'deviceErrorModal',
    isImageFail: false,
  }),
  computed: {
    ...mapGetters({
      getTranslations: "localization/getTranslations",
      currentError: "deviceFunctions/getCurrentError",
    }),
  },
  mounted() {
    // this.$modal.show(this.modalName);
  },
  methods: {
    onImageFail(e) {
      this.isImageFail = true;
    },
    beforeClose() {
      this.isImageFail = false;
    },
  },
};
