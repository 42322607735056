
import { mapActions, mapGetters } from 'vuex';
import modalEsc from '@/mixins/modalEsc';
import AppButton from '@/components/Buttons/AppButton';
import TimerInput from '@/components/Inputs/TimerInput';
import AppInput from '@/components/Inputs/AppInput';
import { errorConflictHandler, errorServiceHandler } from '@/utils/api';
import { getTextStyle } from '@/utils/helpers';

export default {
  name: 'StartTimerModal',
  components: {
    AppInput,
    TimerInput,
    AppButton,
  },
  mixins: [modalEsc],
  data: () => ({
    stateDetails: null,
    groupDevices: null,
    modalName: 'StartTimerModal',
    maskTimeLength: 5,
    timerStartValue: '00:00',
    timerStartValueInitial: '00:00', // иначе при вводе в маску курсор устанавливается в конец строки
    timerEndValue: '00:30',
    timerEndValueInitial: '00:30',
    loading: false,
    countShowIcons: 5,
  }),
  computed: {
    ...mapGetters({
      objectInfo: 'modalStartTimer/objectInfo',
      getTranslations: 'localization/getTranslations',
    }),
    modelType() {
      if (this.objectInfo?.isDevice || (this.objectInfo?.state && this.objectInfo?.status)) {
        return 'device';
      }
      if (this.objectInfo?.deviceState || this.objectInfo?.placeId) {
        return 'singlePreset';
      }
      if (this.objectInfo?.contents) {
        return 'groupPreset';
      }
      return false;
    },
    slicedStateDetails() {
      return this.sliceElements(this.stateDetails);
    },
    slicedGroupsDevices() {
      return this.sliceElements(this.groupDevices);
    },
    disableStart() {
      return this.modelType === 'device' && this.objectInfo.state?.isOn;
    },
    timeStartValid() {
      if (this.disableStart) {
        return true;
      }
      return this.timerEndValue.length === this.maskTimeLength;
    },
    timeEndValid() {
      return this.timerEndValue.length === this.maskTimeLength;
    },
    submitDisabled() {
      return !(this.timeStartValid && this.timeEndValid);
    },
  },
  methods: {
    ...mapActions({
      toggleModal: 'system/setShowModal',
      findCurrentDevice: 'deviceFunctions/findCurrentDevice',
      changePreset: 'buildingDashboard/changePreset',
      changePresetGroup: 'buildingDashboard/changePresetGroup',
    }),
    modalBeforeOpen() {
      this.prepareStateDetail();
    },
    modalOpened() {
      document.addEventListener('keydown', this.clickHandler);
      this.toggleModal(true);
    },
    modalClosed() {
      document.removeEventListener('keydown', this.clickHandler);
      this.toggleModal(false);
      this.timerStartValue = '00:00';
      this.timerEndValue = '00:30';
      this.stateDetails = null;
      this.groupDevices = null;
    },

    prepareStateDetail() {
      if (this.modelType === 'device') {
        this.getStateDetails(`/devices/${this.objectInfo.id}/state-details`);
      } else if (this.modelType === 'singlePreset') {
        this.getStateDetails(`/presets/${this.objectInfo.id}`);
      } else if (this.modelType === 'groupPreset') {
        this.getStateDetails(`/groupPresets/${this.objectInfo.id}`);
      }
    },
    getStateDetails(url) {
      this.$axios.get(
        url,
        {
          headers: {
            Authorization: `Bearer ${this.$auth.user && this.$auth.user.token}`,
          },
        },
      )
        .then((response) => {
          const responseData = response.data.data;
          if (this.modelType === 'device') {
            this.stateDetails = responseData;
          } else if (this.modelType === 'singlePreset') {
            this.stateDetails = responseData.deviceStateDetails;
          } else if (this.modelType === 'groupPreset') {
            this.groupDevices = responseData.contents;
          }
        })
        .catch((error) => {
          errorServiceHandler.call(this, error);
        });
    },

    sliceElements(elArray) {
      if (!elArray) {
        return [];
      }

      if (elArray.length <= 4) {
        return [elArray];
      }

      const firstPartLength = Math.ceil(elArray.length / 2);
      const firstPart = elArray.slice(0, firstPartLength);
      const secondPart = elArray.slice(firstPartLength, elArray.length);

      return [firstPart, secondPart];
    },

    changeStartValue(value) {
      this.timerStartValue = value;
    },
    changeEndValue(value) {
      this.timerEndValue = value;
    },

    getTextStyleBus(attributedText) {
      return getTextStyle(attributedText);
    },

    showIcons(icons) {
      return icons.slice(0, this.countShowIcons);
    },

    hideIconsCount(icons) {
      return icons.slice(this.countShowIcons).length;
    },

    submit() {
      if (!this.submitDisabled) {
        this.createTimer();
      }
    },

    createTimer() {
      const timeEndSplit = this.timerEndValue.split(':');
      let timeStartMinutes = null;
      const timeEndMinutes = Number(timeEndSplit[0]) * 60 + Number(timeEndSplit[1]);

      if (!this.disableStart) {
        const timeStartSplit = this.timerStartValue.split(':');
        timeStartMinutes = Number(timeStartSplit[0]) * 60 + Number(timeStartSplit[1]);
      }

      const requestBody = {
        delay: timeStartMinutes,
        duration: timeEndMinutes,
        conflictResolveData: null,
      };

      if (this.modelType === 'device') {
        this.createDeviceTimer(requestBody);
      } else if (this.modelType === 'singlePreset') {
        this.createSingleFastCommandTimer(requestBody);
      } else if (this.modelType === 'groupPreset') {
        this.createGroupFastCommandTimer(requestBody);
      }
    },

    createDeviceTimer(requestBody) {
      this.sendRequest(`/devices/${this.objectInfo.id}/timer?ignoreConflicts=false`, requestBody);
    },

    createSingleFastCommandTimer(requestBody) {
      this.sendRequest(`/presets/${this.objectInfo.id}/timer?ignoreConflicts=false`, requestBody);
    },

    createGroupFastCommandTimer(requestBody) {
      this.sendRequest(`/groupPresets/${this.objectInfo.id}/timer?ignoreConflicts=false`, requestBody);
    },

    sendRequest(url, requestBody) {
      this.loading = true;
      return this.$axios.post(url, requestBody, {
        Authorization: `Bearer ${this.$auth.user.token}`,
      })
        .then((response) => {
          const systemStateUpdate = response.data.data;
          this.findCurrentDevice(systemStateUpdate);

          const updatedDevices = systemStateUpdate.devices;
          for (const updated in updatedDevices) {
            this.$store.dispatch('buildingDashboard/setPlaceData', updatedDevices[updated]);
          }

          const updatedPresets = systemStateUpdate.presets;
          const updatedGroupPresets = systemStateUpdate.groupPresets;
          this.$store.dispatch('buildingDashboard/updatePresets', updatedPresets);
          this.$store.dispatch('buildingDashboard/updateGroupPresets', updatedGroupPresets);
          for (const updated in updatedPresets) {
            this.$store.commit('fastCommandsPage/EDIT_PLACE_PRESET', updatedPresets[updated]);
          }
          for (const updated in updatedGroupPresets) {
            this.$store.commit('fastCommandsPage/EDIT_GROUP_PRESET', updatedGroupPresets[updated]);
          }
        })
        .catch((error) => {
          errorConflictHandler.call(this.$store, error);
        })
        .finally(() => {
          this.loading = false;
          this.$modal.hide(this.modalName);
        });
    },
  },
};
