import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    localLoading: false,
    localLoadingTimeout: null,
  }),
  computed: {
    ...mapGetters({
      comfortSleepData: 'deviceFunctions/getComfortSleepData',
    }),
    controllable() {
      // controllable - доступна ли функция для управления.
      // Если значение false, то UI-элемент должен быть некликабелен
      return this.functionItem.state.controllable && this.deviceConnected;
    },
    isOn() {
      // isOn - включена или выключена функция (белый или серый цвет отображения UI-элемента функции)
      return this.functionItem.state.isOn && this.deviceConnected;
    },
    blocked() {
      // blocked - заблокирована ли функция или нет.
      // Важно - этот параметр отвечает только за opacity отображения UI-элемента функции. При этом он может быть кликабелен.
      // Показывает, что применение данной функции может отменить действие других функций.
      // Если данное значение = true, то в интерфейсе UI-элемент управления функцией будет иметь прозрачность 50%.
      return this.functionItem.state.blocked || !this.deviceConnected;
    },
    hasSpecialBg() {
      return this.functionItem.uiInfo.displaySpecialBackground && this.deviceConnected;
    },
  },
  watch: {
    globalLoading: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue && !newValue && !this.localLoadingTimeout && this.localLoading) {
          this.localLoading = false;
        }
      },
    },
  },
  methods: {
    ...mapActions({
      setModalType: 'system/setGoToAppModalType',
      getDeviceFunctionDetails: 'deviceFunctions/getDeviceFunctionDetails',
    }),
    toggleFunctionState() {
      if (!this.controllable || this.globalLoading || this.localLoading) return;
      // applyable = true - значит при управлении в запросе всегда необходимо посылать значение isOn = true (выключить ее нельзя, можно только применить)

      const requestValue = {
        functionId: this.functionItem.id,
        parameters: null, // дополнительные параметры для функции
      };

      if (this.functionItem.metaData.tag === 'COMFORT_SLEEP') {
        if (this.functionItem.state.isOn) { // можно отправить запрос на управление функцией (выключение)
          requestValue.isOn = false;
        } else {
          this.localLoading = true;

          this.getDeviceFunctionDetails({
            functionId: requestValue.functionId,
            deviceId: null,
            onClose: () => {
              this.localLoading = false;
            },
          })
            .then(() => {
              this.$modal.show('comfortSleepModal');
            })
            .finally(() => {
              this.localLoading = false;
            });
          // setTimeout(() => {
          //   this.localLoading = false;
          // }, 1000);

          // this.setModalType('comfortSleep');
          // this.$modal.show('goToApp');
          return;
        }
      } else if (this.functionItem.metaData.tag === 'VERTICAL_FLOW_DETAILED') {
        requestValue.parameters = this.functionItem.metaData.parameters; // FunctionVerticalFlowDetailedParameters
        if (this.functionItem.metaData.applyable) {
          requestValue.isOn = true;
        } else {
          requestValue.isOn = !this.functionItem.state.isOn;
        }
      } else if (this.functionItem.metaData.applyable) {
        requestValue.isOn = true;
      } else {
        requestValue.isOn = !this.functionItem.state.isOn;
      }

      this.localLoading = true;
      this.localLoadingTimeout = setTimeout(() => {
        // Искусственная минимальная задержка для анимации
        this.localLoadingTimeout = null;
        if (!this.globalLoading && this.localLoading) {
          this.localLoading = false;
        }
      }, 1000);
      this.$emit('controlStateChanged', requestValue);
    },
  },
};
