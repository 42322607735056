import {
  SET_MODAL_COMMON_MESSAGE_CONFIRM,
  SET_MODAL_COMMON_MESSAGE_TEXT,
  SET_MODAL_COMMON_MESSAGE_TITLE,
} from '@/utils/types';

export const state = () => ({
  title: 'Ошибка',
  text: 'Ошибка выполнения действия',
  confirmed: false,
});

export const getters = {
  getTitle: ({ title }) => title,
  getText: ({ text }) => text,
  getConfirmed: ({ confirmed }) => confirmed,
};

export const mutations = {
  [SET_MODAL_COMMON_MESSAGE_TITLE](state, title) {
    state.title = title;
  },
  [SET_MODAL_COMMON_MESSAGE_TEXT](state, text) {
    state.text = text;
  },
  [SET_MODAL_COMMON_MESSAGE_CONFIRM](state, value) {
    state.confirmed = value;
  },
};

export const actions = {
  setModalData({ commit }, data) {
    data.title && commit(SET_MODAL_COMMON_MESSAGE_TITLE, data.title);
    data.text && commit(SET_MODAL_COMMON_MESSAGE_TEXT, data.text);
    commit(SET_MODAL_COMMON_MESSAGE_CONFIRM, data.confirmed);
  },
};
