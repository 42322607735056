
import { mapGetters } from "vuex";
import WalletIcon from "../assets/images/wallet.svg";

export default {
  name: "SubscribeLabel",
  components: {
    WalletIcon,
  },
  props: {
    deviceInfo: {
      type: Object,
      default: {},
    },
    onLabelClick: {
      type: Function,
      default: () => 1,
    },
  },
  computed: {
    ...mapGetters({
      getTranslations: "localization/getTranslations",
    }),
    tarificationInfo() {
      return this.deviceInfo.tarificationInfo;
    },
    subscription() {
      return this.deviceInfo.subscription;
    },
    dotColor() {
      if (this.subscription) {
        return this.subscription.statusColor;
      }

      if (
        this.tarificationInfo.subscriptionInfo &&
        this.tarificationInfo.subscriptionInfo.isUnlimited
      ) {
        return null;
      }

      if (
        this.tarificationInfo.summaryPacketsData &&
        !this.tarificationInfo.summaryPacketsData.daysLeft &&
        !this.tarificationInfo.summaryPacketsData.hoursLeft
      ) {
        return "#F25248";
      }

      return null;
    },
    isAlertText() {
      return (
        !this.tarificationInfo.summaryPacketsData.daysLeft &&
        !this.tarificationInfo.summaryPacketsData.hoursLeft
      );
    },
    // daysPhrase() {
    //   return this.$numWord(this.totalDays, [
    //     this.getTranslations['{0} день'],
    //     this.getTranslations['{0} дня'],
    //     this.getTranslations['{0} дней'],
    //   ])
    // }
  },
  methods: {
    getDaysPhrase(days) {
      return this.$numWord(days, [
        this.getTranslations["{0} день"],
        this.getTranslations["{0} дня"],
        this.getTranslations["{0} дней"],
      ]);
    },
    getHoursPhrase(hours) {
      return this.$numWord(hours, [
        this.getTranslations["{0} час"],
        this.getTranslations["{0} часа"],
        this.getTranslations["{0} часов"],
      ]);
    },
  },
};
