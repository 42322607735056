
import { mapActions, mapGetters } from 'vuex';
import modalEsc from '@/mixins/modalEsc';
import AppInput from '../Inputs/AppInput.vue';
import AppButton from '../Buttons/AppButton.vue';

const NAME_MIN_LENGTH = 2;
const NAME_MAX_LENGTH = 120;

export default {
  name: 'NameScheduleModal',
  components: { AppButton, AppInput },
  mixins: [modalEsc],
  data: () => ({
    modalName: 'nameSchedule',
    inputName: '',
    loading: false,
    isPin: true,
  }),
  computed: {
    ...mapGetters({
      buildingSelect: 'buildings/getBuildingSelect',
      getTranslations: 'localization/getTranslations',
    }),
    buildingId() {
      return this.buildingSelect && this.buildingSelect.id;
    },
    isValueChanged() {
      return this.inputName !== this.inputValue;
    },
    isValueValidMin() {
      return this.inputName.trim().length >= NAME_MIN_LENGTH;
    },
    isValueValidMax() {
      return this.inputName.trim().length <= NAME_MAX_LENGTH;
    },
    isValueValid() {
      return this.isValueValidMin && this.isValueValidMax;
    },
    nameErrorMessage() {
      if (this.isValueValidMin && !this.isValueValidMax) {
        // return `Максимальная длина имени ${NAME_MAX_LENGTH} символов`;
        return `${this.getTranslations['Максимальная длина имени {0} символов'][0]}${NAME_MAX_LENGTH}${this.getTranslations['Максимальная длина имени {0} символов'][1]}`;
      }
      return '';
    },
    submitDisabled() {
      return !this.isValueChanged || !this.isValueValid;
    },
  },
  methods: {
    ...mapActions({
      toggleModal: 'system/setShowModal',
      createSchedule: 'schedules/createSchedule',
    }),
    changeInput(value) {
      this.inputName = value;
    },
    async modalOpened() {
      document.addEventListener('keydown', this.clickHandler);
      this.toggleModal(true);
    },
    async modalClosed() {
      document.removeEventListener('keydown', this.clickHandler);
      this.inputName = '';
      this.toggleModal(false);
    },
    submit() {
      if (!this.submitDisabled) {
        this.saveSchedule();
      }
    },
    async saveSchedule() {
      this.loading = true;
      const isResponseSuccess = await this.createSchedule({
        title: this.inputName,
        isPin: true,
        buildingId: this.buildingId,
      });
      this.loading = false;

      if (isResponseSuccess) {
        this.$modal.hide(this.modalName);
        this.$nextTick(() => {
          setTimeout(() => {
            this.$modal.hide('createEditSchedule');
          }, 400);
        });
      }
    },
  },
};
