export const SET_CURRENT_GROUP_ID = 'SET_CURRENT_GROUP_ID';

export const state = () => ({
  currentGroupId: null,

});

export const getters = {
  getCurrentGroupId: ({ currentGroupId }) => currentGroupId,
};

export const mutations = {
  [SET_CURRENT_GROUP_ID](state, data) {
    state.currentGroupId = data;
  },
};

export const actions = {
  setCurrentGroupId({ commit }, data) {
    commit(SET_CURRENT_GROUP_ID, data);
  },
};
