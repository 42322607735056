
import VueContext from 'vue-context';
import { mapActions, mapGetters } from 'vuex';
import { errorConflictHandler } from '@/utils/api';
import { getRandomIntInclusive } from '@/utils/helpers';
import contextMenuMixin from './contextMenuMixin';

export default {
  components: { VueContext },
  mixins: [contextMenuMixin],
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
  },
  methods: {
    ...mapActions({
      setModalData: 'modalInput/setModalData',
      setPanelType: 'system/toggleFastCommandsPanelType',
      showPanel: 'system/toggleFastCommandsPanel',
      unpinCard: 'buildingDashboard/unpinCardFromDashboard',
      setModalDeleteData: 'modalDelete/setModalData',
      pinCard: 'buildingDashboard/pinCard',
      setModalStartTimer: 'modalStartTimer/setModalData',
      cancelTimer: 'deviceFunctions/cancelTimer',
    }),
    async unpinFromDashboard(place) {
      await this.unpinCard({
        type: 'preset',
        group: place,
      });
      this.$store.commit('deviceFunctions/pinPreset', place);

      this.closeMenu();
    },

    async pinOnDashboard(place) {
      await this.pinCard({
        type: 'preset',
        group: place,
      });
      this.$store.commit('buildingDashboard/PIN_DASHBOARD_PRESET', place);
      this.$store.commit('deviceFunctions/pinPreset', place);
      this.$store.commit('fastCommandsPage/PIN_PRESET', place.id);

      this.closeMenu();
    },

    turnOffDevice(data) {
      this.$axios.post(
        `/presets/${data.id}/ctrl?ignoreConflicts=false`,
        {
          isOn: false,
          cmdId: getRandomIntInclusive(0, 99999999),
          conflictResolveData: null,
        },
        {
          Authorization: `Bearer ${this.$auth.user.token}`,
        },
      )
        .then((response) => {
          this.$store.commit('system/FORCE_LOCAL_LOADING_CARD_PLACE_ID', data.id);

          const systemStateUpdate = response.data.data;
          const updatedDevices = systemStateUpdate.devices;

          this.$store.dispatch('deviceFunctions/findCurrentDevice', systemStateUpdate);
          for (const updated in updatedDevices) {
            this.$store.dispatch('buildingDashboard/setPlaceData', updatedDevices[updated]);
          }

          const updatedPresets = systemStateUpdate.presets;
          const currentPreset = updatedPresets.find((preset) => preset.id === data.id);
          this.$store.commit('buildingDashboard/UPDATE_PRESET_DATA', currentPreset);
          if (currentPreset) {
            this.$store.commit('deviceFunctions/setPresetData', currentPreset);
          }
        })
        .catch((error) => {
          const payload = {
            forceLoadingCardId: data.id,
          };
          errorConflictHandler.call(this.$store, error, payload);
        })
        .finally(() => {
          this.closeMenu();
        });
    },
    cancelTimerAction(place) {
      if (place.timer.id) {
        this.cancelTimer(place.timer.id);
      } else {
        console.error('Отсутствует timer ID');
      }

      this.closeMenu();
    },
    startTimerAction(data) {
      this.setModalStartTimer(data);
      this.$modal.show('StartTimerModal');

      this.closeMenu();
    },
    edit(data) {
      this.$store.commit('addPreset/setTypeOfCommand', 'placePreset');
      this.$store.commit('addPreset/setTypeOfModal', 'edit');
      this.$store.commit('addPreset/setCurrentPresetToChange', data);
      this.$modal.show('addCommandModal');
    },
    clickRename(data) {
      this.setModalData({
        renameType: 'preset',
        title: this.getTranslations['Переименовать'] || 'Переименовать',
        object: data,
        inputValue: data.title,
      });
      this.$modal.show('inputModal');

      this.closeMenu();
    },
    removeCommand(data) {
      this.setModalDeleteData({
        title: this.getTranslations['Вы уверены, что хотите удалить быструю команду?'] || 'Вы уверены, что хотите удалить быструю команду?',
        text: `${this.getTranslations['Быстрая команда']} ${this.getTranslations['{0} будет удалена из вашего аккаунта'][0]}«${data.title}»${this.getTranslations['{0} будет удалена из вашего аккаунта'][1]}.`,
        type: 'preset',
        object: data,
      });
      this.$modal.show('deleteModal');

      this.closeMenu();
    },

    closeMenu() {
      this.$refs.menu.close();
    },
  },
};
