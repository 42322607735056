
import ArrowLeftIcon from '../../assets/icons/Arrows/arrowLeft.svg';
import AddFastCommandControlPanel from './AddFastCommandControlPanel.vue';
import SettingButton from '../Buttons/SettingButton.vue';
import CloseIcon from '../../assets/icons/x.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'AddCommandPanel',
  components: {
    SettingButton, AddFastCommandControlPanel, ArrowLeftIcon, CloseIcon,
  },
  props: {
    deviceInfo: {
      type: Array,
      required: true,
    },
    deviceId: {
      type: Number,
      required: true,
    },
    typeOfModal: {
      type: String,
      validator: (value) => value === 'edit' || value === 'create',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    pulfForConfiguration: {
      type: Object,
      required: true,
    },
    typeOfSetting: {
      type: String,
    },
  },
  data: () => ({
    step: 1,
    selectedPresetId: null,
  }),
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
  },
  watch: {
    deviceId: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue && newValue) {
          this.step = 1;
        }
      },
    },
  },
  methods: {
    hiddenIcons(icons, number) {
      return icons ? icons.slice(number).length : 0;
    },
    offClick() {
      this.$emit('choiceHandler', {
        type: 'off',
        placeId: this.deviceId,
        isOn: false,
      });
    },
    closePanel() {
      this.$emit('close');
    },
    currentClick() {
      this.selectedPresetId = null;
      this.$emit('choiceHandler', {
        type: 'current',
        placeId: this.deviceId,
        state: this.deviceInfo[0].options[2].deviceOption.state,
        functions: this.deviceInfo[0].options[2].deviceOption.functions,
      });
    },
    customClick() {
      this.selectedPresetId = null;
      this.step = 2;
    },
    choicePreset(preset) {
      this.selectedPresetId = preset.presetOption.id;
      this.$emit('choiceHandler', {
        presetId: preset.presetOption.id,
        placeId: this.deviceId,
        deviceState: preset.presetOption.deviceState,
        type: 'preset',
      });
    },
    changeDeviceInfo(data) {
      this.$emit('changeDeviceInfo', data);
    },
  },
};
