
import { mapGetters } from "vuex";

export default {
  name: "IndicatorModal",
  data: () => ({
    modalName: "indicatorModal"
  }),
  computed: {
    ...mapGetters({
      indicator: "deviceFunctions/getCurrentIndicator",
    }),
  },
};
