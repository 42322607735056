
import { mapGetters, mapActions } from "vuex";
import CloseIcon from "../../assets/icons/24x24/close.svg";
import AppInput from "../Inputs/AppInput.vue";
import AppButton from "../Buttons/AppButton.vue";

export default {
  name: "PromocodeModal",
  components: {
    CloseIcon,
    AppInput,
    AppButton
  },
  props: {
    onPromocodeSubmit: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    modalName: "promocodeModal",
    promocodeValue: '',
  }),
  computed: {
    ...mapGetters({
      getTranslations: "localization/getTranslations",
    }),
  },
  methods: {
    onSubmit() {
      this.onPromocodeSubmit(this.promocodeValue);
    },
    onInput(val) {
      this.promocodeValue = val;
    },
    onClose() {
      this.promocodeValue = '';
    }
  },
};
