
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ClimateOnlineStatistics",
  props: {
    statistics: {
      type: Array,
      default: () => {},
    },
    devices: {
      type: Array,
      default: () => [],
    },
    onDeviceClick: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      getTranslations: "localization/getTranslations",
      // panelDeviceId: "system/getPanelDeviceId",
    }),
  },
  methods: {
    onClick(e, id) {
      this.onDeviceClick(id);
    },
  },
};
