import Vue from 'vue';

export default () => {
  Vue.prototype.$textStyle = (item) => {
    const style = {};
    if (item.textStyle) {
      if (item.textStyle === "REGULAR") style.fontWeight = 400;
      if (item.textStyle === "MEDIUM") style.fontWeight = 500;
      if (item.textStyle === "BOLD") style.fontWeight = 600;
    }

    if (item.textColor) {
      style.color = item.textColor;
    }

    return style;
  };

  Vue.prototype.$backgroundStyle = (item) => {
    const style = {};

    if (!item.background) return style;

    if (item.background.backgroundColor) style.backgroundColor = item.background.backgroundColor;
    if (item.background.backgroundImage) style.backgroundImage = `url(${item.background.backgroundImage})`;

    return style;
  };

  Vue.prototype.$labelStyle = function (item) {
    const style = {};
    if (item.titleColor) style.color = item.titleColor;

    return { ...style, ...this.$backgroundStyle(item) };
  };
};
