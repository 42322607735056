
import controlPanelToggle from '@/mixins/controlPanelToggle';
import LoadingSpinnerFunction from '@/components/loaders/LoadingSpinnerFunction.vue';

export default {
  name: 'ControlPanelIcon',
  components: { LoadingSpinnerFunction },
  mixins: [controlPanelToggle],
  props: {
    deviceConnected: {
      type: Boolean,
      required: true,
    },
    functionBlockId: {
      type: Number,
      required: true,
    },
    functionItem: {
      type: Object,
      required: true,
    },
    globalLoading: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  computed: {},
  methods: {},
};
