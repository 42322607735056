
import { mapGetters } from 'vuex';

export default {
  name: 'SearchInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    clearEsc: {
      type: Boolean,
      default: true,
    },
    isDropdown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    showClearButton() {
      return this.value && this.value.length;
    },
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
  },
  methods: {
    inputHandler(event) {
      this.$emit('input', event.target.value);
    },
    clearInput(e) {
      e.preventDefault();
      this.$emit('input', '');
    },
    escCloseHandler(e) {
      if (e.keyCode === 27) {
        this.$emit('input', '');
      }
    },
    focusHandler() {
      if (this.clearEsc) {
        document.addEventListener('keydown', this.escCloseHandler);
      }
    },
    focusOutHandler() {
      document.removeEventListener('keydown', this.escCloseHandler);
    },
  },
};
