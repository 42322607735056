
import LoadingSpinnerLaunch from '@/components/loaders/LoadingSpinnerLaunch.vue';
import PlayIcon from '@/assets/icons/24x24/play.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'ButtonLaunch',
  components: {
    PlayIcon,
    LoadingSpinnerLaunch,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    })
  }
};
