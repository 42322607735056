export const storageLangName = "dcWebUserLanguage";

export const LANGS = ["ru", "en", "fr", "tr"];

export const LongNames = {
  ru: "Русский",
  en: "English",
  fr: "Français",
  tr: "Türkçe",
};
