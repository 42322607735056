
import MinusIcon from '../../assets/icons/count/Minus.svg';
import PlusIcon from '../../assets/icons/count/PLUS.svg';

export default {
  name: 'ButtonCount',
  components: { MinusIcon, PlusIcon },
  props: {
    type: {
      type: String,
      default: 'plus',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
