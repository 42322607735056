
import { mapActions, mapGetters } from 'vuex';
import { errorConflictHandler } from '@/utils/api';
import AppButton from '../Buttons/AppButton';

export default {
  name: 'DeleteModal',
  components: { AppButton },
  data: () => ({
    modalName: 'deleteModal',
    loading: false,
  }),
  computed: {
    ...mapGetters({
      title: 'modalDelete/getTitle',
      text: 'modalDelete/getText',
      typeOfDelete: 'modalDelete/getTypeOfDelete',
      modalData: 'modalDelete/getModalData',
      getTranslations: 'localization/getTranslations',
    }),
  },
  methods: {
    ...mapActions({
      setShowModal: 'system/setShowModal',
      setModalData: 'modalDelete/setModalData',
      togglePanelActive: 'system/togglePanelActive',
    }),
    async confirmDelete() {
      try {
        this.loading = true;
        if (this.typeOfDelete === 'group') {
          const { data } = await this.$axios.delete(`groups/${this.modalData.id}`);
          this.$store.commit('buildingDashboard/SET_NEW_PLACES_GROUPS', data.data);
        } else if (this.typeOfDelete === 'device') {
          const { data } = await this.$axios.delete(`devices/${this.modalData.id}`);
          this.$store.commit('buildingDashboard/SET_NEW_PLACES_GROUPS', data.data);
        } else if (this.typeOfDelete === 'schedule') {
          const { data } = await this.$axios.delete(`schedules/${this.modalData.id}`);
          this.$store.commit('buildingDashboard/REMOVE_SCHEDULE', this.modalData.id);
        } else if (this.typeOfDelete === 'preset') {
          const { data } = await this.$axios.delete(`presets/${this.modalData.id}?ignoreConflicts=false`);
          this.$store.commit('buildingDashboard/REMOVE_PRESET', this.modalData.id);
          this.$store.commit('deviceFunctions/removePreset', this.modalData.id);
          this.$store.dispatch('fastCommandsPage/deletePlacePreset', this.modalData.id);
        } else if (this.typeOfDelete === 'presetGroup') {
          const { data } = await this.$axios.delete(`groupPresets/${this.modalData.id}?ignoreConflicts=false`);
          this.$store.commit('buildingDashboard/REMOVE_PRESET_GROUP', this.modalData.id);
          this.$store.commit('deviceFunctions/removePresetGroup', this.modalData.id);
          this.$store.dispatch('fastCommandsPage/deleteGroupPreset', this.modalData.id);
        }
        this.setModalData({ confirmed: true });
      } catch (error) {
        errorConflictHandler.call(this.$store, error);
      } finally {
        this.loading = false;
        this.$modal.hide(this.modalName);
        if (this.$store.state.deviceFunctions.deviceInfo
          && this.$store.state.deviceFunctions.deviceInfo.id === this.modalData.id) {
          this.togglePanelActive(false);
        }
        if (this.typeOfDelete === 'group') {
          this.$store.commit('system/TOGGLE_OBJECTS_PANEL', false);
        }
      }
    },
  },
};
