export const state = () => ({
  paymentInfoId: null,
});

export const getters = {
  getPaymentInfoId: ({ paymentInfoId }) => paymentInfoId,
};

export const mutations = {
  SET_MODAL_PAYMENT_INFO_DATA(state, paymentInfoId) {
    state.paymentInfoId = paymentInfoId;
  },
};

export const actions = {
  setModalData({ commit }, data) {
    commit('SET_MODAL_PAYMENT_INFO_DATA', data);
  },
};
