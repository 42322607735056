import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a5a937a2 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _560a8caa = () => interopDefault(import('../pages/access-notification.vue' /* webpackChunkName: "pages/access-notification" */))
const _f7338ad2 = () => interopDefault(import('../pages/access-rights.vue' /* webpackChunkName: "pages/access-rights" */))
const _2de575c4 = () => interopDefault(import('../pages/add-object.vue' /* webpackChunkName: "pages/add-object" */))
const _641ce84f = () => interopDefault(import('../pages/all-pages.vue' /* webpackChunkName: "pages/all-pages" */))
const _3a2bb584 = () => interopDefault(import('../pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _b68b176a = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _22088838 = () => interopDefault(import('../pages/Errors.vue' /* webpackChunkName: "pages/Errors" */))
const _1cc49490 = () => interopDefault(import('../pages/fast-commands.vue' /* webpackChunkName: "pages/fast-commands" */))
const _15c25bbf = () => interopDefault(import('../pages/not-found.vue' /* webpackChunkName: "pages/not-found" */))
const _90711b2a = () => interopDefault(import('../pages/Objects.vue' /* webpackChunkName: "pages/Objects" */))
const _43a26e7d = () => interopDefault(import('../pages/payment.vue' /* webpackChunkName: "pages/payment" */))
const _d7c269ba = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _15750333 = () => interopDefault(import('../pages/schedule/index.vue' /* webpackChunkName: "pages/schedule/index" */))
const _65171820 = () => interopDefault(import('../pages/settings-object.vue' /* webpackChunkName: "pages/settings-object" */))
const _09e9c46c = () => interopDefault(import('../pages/auth/EmailFail.vue' /* webpackChunkName: "pages/auth/EmailFail" */))
const _77ba5ba5 = () => interopDefault(import('../pages/auth/EmailSuccess.vue' /* webpackChunkName: "pages/auth/EmailSuccess" */))
const _78fbde19 = () => interopDefault(import('../pages/auth/passowordSuccess.vue' /* webpackChunkName: "pages/auth/passowordSuccess" */))
const _51d89cc3 = () => interopDefault(import('../pages/auth/PasswordRestoreFail.vue' /* webpackChunkName: "pages/auth/PasswordRestoreFail" */))
const _ef660e06 = () => interopDefault(import('../pages/auth/restoreAccount.vue' /* webpackChunkName: "pages/auth/restoreAccount" */))
const _2c2b5372 = () => interopDefault(import('../pages/auth/serviceEngeneer.vue' /* webpackChunkName: "pages/auth/serviceEngeneer" */))
const _03fe1746 = () => interopDefault(import('../pages/auth/waitconfirm.vue' /* webpackChunkName: "pages/auth/waitconfirm" */))
const _3633dfac = () => interopDefault(import('../pages/payments/finish.vue' /* webpackChunkName: "pages/payments/finish" */))
const _e245a8d6 = () => interopDefault(import('../pages/payments/select.vue' /* webpackChunkName: "pages/payments/select" */))
const _3ffd2655 = () => interopDefault(import('../pages/profile/initial.vue' /* webpackChunkName: "pages/profile/initial" */))
const _b34b868c = () => interopDefault(import('../pages/payments/orders/canceled.vue' /* webpackChunkName: "pages/payments/orders/canceled" */))
const _cfe2c352 = () => interopDefault(import('../pages/payments/orders/created.vue' /* webpackChunkName: "pages/payments/orders/created" */))
const _69a959f2 = () => interopDefault(import('../pages/payments/orders/success.vue' /* webpackChunkName: "pages/payments/orders/success" */))
const _3398cb09 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _410eaa0e = () => interopDefault(import('../pages/auth/password-create/_token.vue' /* webpackChunkName: "pages/auth/password-create/_token" */))
const _de0e0482 = () => interopDefault(import('../pages/auth/password-restore/_token.vue' /* webpackChunkName: "pages/auth/password-restore/_token" */))
const _507a74a0 = () => interopDefault(import('../pages/payments/orders/_order.vue' /* webpackChunkName: "pages/payments/orders/_order" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _a5a937a2,
    name: "404"
  }, {
    path: "/access-notification",
    component: _560a8caa,
    name: "access-notification"
  }, {
    path: "/access-rights",
    component: _f7338ad2,
    name: "access-rights"
  }, {
    path: "/add-object",
    component: _2de575c4,
    name: "add-object"
  }, {
    path: "/all-pages",
    component: _641ce84f,
    name: "all-pages"
  }, {
    path: "/auth",
    component: _3a2bb584,
    name: "auth"
  }, {
    path: "/dashboard",
    component: _b68b176a,
    name: "dashboard"
  }, {
    path: "/Errors",
    component: _22088838,
    name: "Errors"
  }, {
    path: "/fast-commands",
    component: _1cc49490,
    name: "fast-commands"
  }, {
    path: "/not-found",
    component: _15c25bbf,
    name: "not-found"
  }, {
    path: "/Objects",
    component: _90711b2a,
    name: "Objects"
  }, {
    path: "/payment",
    component: _43a26e7d,
    name: "payment"
  }, {
    path: "/profile",
    component: _d7c269ba,
    name: "profile"
  }, {
    path: "/schedule",
    component: _15750333,
    name: "schedule"
  }, {
    path: "/settings-object",
    component: _65171820,
    name: "settings-object"
  }, {
    path: "/auth/EmailFail",
    component: _09e9c46c,
    name: "auth-EmailFail"
  }, {
    path: "/auth/EmailSuccess",
    component: _77ba5ba5,
    name: "auth-EmailSuccess"
  }, {
    path: "/auth/passowordSuccess",
    component: _78fbde19,
    name: "auth-passowordSuccess"
  }, {
    path: "/auth/PasswordRestoreFail",
    component: _51d89cc3,
    name: "auth-PasswordRestoreFail"
  }, {
    path: "/auth/restoreAccount",
    component: _ef660e06,
    name: "auth-restoreAccount"
  }, {
    path: "/auth/serviceEngeneer",
    component: _2c2b5372,
    name: "auth-serviceEngeneer"
  }, {
    path: "/auth/waitconfirm",
    component: _03fe1746,
    name: "auth-waitconfirm"
  }, {
    path: "/payments/finish",
    component: _3633dfac,
    name: "payments-finish"
  }, {
    path: "/payments/select",
    component: _e245a8d6,
    name: "payments-select"
  }, {
    path: "/profile/initial",
    component: _3ffd2655,
    name: "profile-initial"
  }, {
    path: "/payments/orders/canceled",
    component: _b34b868c,
    name: "payments-orders-canceled"
  }, {
    path: "/payments/orders/created",
    component: _cfe2c352,
    name: "payments-orders-created"
  }, {
    path: "/payments/orders/success",
    component: _69a959f2,
    name: "payments-orders-success"
  }, {
    path: "/",
    component: _3398cb09,
    name: "index"
  }, {
    path: "/auth/password-create/:token?",
    component: _410eaa0e,
    name: "auth-password-create-token"
  }, {
    path: "/auth/password-restore/:token?",
    component: _de0e0482,
    name: "auth-password-restore-token"
  }, {
    path: "/payments/orders/:order?",
    component: _507a74a0,
    name: "payments-orders-order"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
