
import AppButton from './Buttons/AppButton';
import WelcomeIcon from '../assets/icons/welcome.svg';
import UserAvatarBus from './profile/UserAvatarBus';
import PeopleIcon from '../assets/icons/people.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'AuthSuccess',
  components: {
    UserAvatarBus, AppButton, WelcomeIcon, PeopleIcon,
  },
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
  },
  middleware: 'isAuth',
};
