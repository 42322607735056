// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/SuisseIntlBold.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/SuisseIntl-Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/SuisseIntl-SemiBold.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../fonts/SuisseIntl-SemiBold.woff2");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../fonts/SuisseIntl-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../fonts/SuisseIntl-Medium.woff2");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../fonts/SuisseIntl-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../fonts/SuisseIntl-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_8___ = require("../fonts/SuisseIntl-Light.woff2");
var ___CSS_LOADER_URL_IMPORT_9___ = require("../fonts/SuisseIntl-Light.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*,:after,:before{box-sizing:border-box;font-family:\"Suisselntl\";margin:0;padding:0}*{scrollbar-color:#e6e6ef #fff;scrollbar-width:thin}::-webkit-scrollbar{width:7px}::-webkit-scrollbar-track{background:#fff}::-webkit-scrollbar-thumb{background-color:#e6e6ef;border-radius:20px;-webkit-transition:background-color .2s;transition:background-color .2s}::-webkit-scrollbar-thumb:hover{background-color:#d1d8e1}ul{list-style:none}@font-face{font-family:\"Suisselntl\";font-style:normal;font-weight:700;src:local(\"Suisselntl\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"ttf\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\")}@font-face{font-family:\"Suisselntl\";font-style:normal;font-weight:600;src:local(\"Suisselntl\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"ttf\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff2\")}@font-face{font-family:\"Suisselntl\";font-style:normal;font-weight:500;src:local(\"Suisselntl\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"ttf\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff2\")}@font-face{font-family:\"Suisselntl\";font-style:normal;font-weight:400;src:local(\"Suisselntl\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"ttf\")}@font-face{font-family:\"Suisselntl\";font-style:normal;font-weight:300;src:local(\"Suisselntl\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format(\"ttf\")}.page-enter-active,.page-leave-active{transition:opacity .5s}.page-enter,.page-leave-to{opacity:0}.hidden-icons-count{color:#1a1a1a;font-size:10px;font-style:normal;font-weight:600;line-height:13px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
