
import { mapGetters } from "vuex";
import ClimateOnlinePromoSmall from "./ClimateOnlinePromoSmall.vue";
import ClimateOnlinePromo from "./ClimateOnlinePromo.vue";
import ButtonArrow from "../Buttons/ButtonArrow.vue";

export default {
  name: "ClimateOnlineConnect",
  components: {
    ClimateOnlinePromoSmall,
    ClimateOnlinePromo,
    ButtonArrow,
  },
  props: {
    buildingClimateOnline: {
      type: Object,
      default: () => [],
    },
    onDeviceClick: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      getTranslations: "localization/getTranslations",
    }),
  },
  methods: {
    onButtonClick(id) {
      return () => this.onDeviceClick(id);
    },
  },
};
