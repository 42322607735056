import Vue from "vue";
import { getRandomIntInclusive } from "@/utils/helpers";
import { errorConflictHandler, errorHandlerWithAction } from "@/utils/api";

export const SET_BUILDING_SELECT_DASHBOARD = "SET_BUILDING_SELECT_DASHBOARD";
export const SET_BUILDING_SELECT_DASHBOARD_LOAD =
  "SET_BUILDING_SELECT_DASHBOARD_LOAD";
export const SET_SCHEDULE_STATUS = "SET_SCHEDULE_STATUS";
export const SET_NEW_PLACES_GROUPS = "SET_NEW_PLACES_GROUPS";
export const UNPIN_DASHBOARD_GROUP = "UNPIN_DASHBOARD_GROUP";
export const UNPIN_DASHBOARD_PLACE = "UNPIN_DASHBOARD_PLACE";
export const PIN_DASHBOARD_PLACE = "PIN_DASHBOARD_PLACE";
export const UNPIN_DASHBOARD_SCHEDULE = "UNPIN_DASHBOARD_SCHEDULE";
export const PIN_DASHBOARD_SCHEDULE = "PIN_DASHBOARD_SCHEDULE";
export const PIN_DASHBOARD_GROUP = "PIN_DASHBOARD_GROUP";
export const CHANGE_SCHEDULE = "CHANGE_SCHEDULE";
export const CHANGE_SCHEDULE_PLACES = "CHANGE_SCHEDULE_PLACES";
export const REMOVE_SCHEDULE = "REMOVE_SCHEDULE";
export const UNPIN_DASHBOARD_PRESET = "UNPIN_DASHBOARD_PRESET";
export const UNPIN_DASHBOARD_PRESET_GROUP = "UNPIN_DASHBOARD_PRESET_GROUP";
export const REMOVE_PRESET = "REMOVE_PRESET";
export const REMOVE_PRESET_GROUP = "REMOVE_PRESET_GROUP";
export const UPDATE_PRESET_DATA = "UPDATE_PRESET_DATA";
export const UPDATE_PRESET_GROUP_DATA = "UPDATE_PRESET_GROUP_DATA";
export const PIN_DASHBOARD_PRESET = "PIN_DASHBOARD_PRESET";
export const PIN_DASHBOARD_PRESET_GROUP = "PIN_DASHBOARD_PRESET_GROUP";
export const ADD_PRESET = "ADD_PRESET";
export const SET_BUILDING_SELECT_DASHBOARD_PLACES =
  "SET_BUILDING_SELECT_DASHBOARD_PLACES";
export const CHANGE_COUNTER_COMMANDS = "CHANGE_COUNTER_COMMANDS";
export const SET_BUILDING_SELECT_DASHBOARD_SCHEDULES =
  "SET_BUILDING_SELECT_DASHBOARD_SCHEDULES";
export const ADD_CREATED_PRESET = "ADD_CREATED_PRESET";
export const SET_DASHBOARD_CLIMATE_ONLINE = "SET_DASHBOARD_CLIMATE_ONLINE";
export const SET_DASHBOARD_CLIMATE_ONLINE_STATUS = "SET_DASHBOARD_CLIMATE_ONLINE_STATUS";

export const state = () => ({
  buildingSelectDashboardLoad: true,
  buildingSelectDashboard: {
    places: [],
    groups: [],
    schedules: [],
    allPlacesCount: 0,
    allPresetsCount: 0,
    allSchedulesCount: 0,
  },
  buildingClimateOnline: null,
  climateOnlineSummaryModalOpened: false,
});

export const getters = {
  getBuildingSelectDashboard: (e) => e.buildingSelectDashboard,
  getBuildingsSelectedDashboardPlaces: (e) => e.buildingSelectDashboard.places,
  getBuildingsSelectedDashboardGroups: (e) => e.buildingSelectDashboard.groups,
  getBuildingSelectDashboardLoad: (e) => e.buildingSelectDashboardLoad,
  getBuildingClimateOnline: (e) => e.buildingClimateOnline,
  getBuildingClimateOnlineStatus: (e) => e.climateOnlineSummaryModalOpened,
};

export const mutations = {
  [ADD_CREATED_PRESET](state, preset) {
    state.buildingSelectDashboard.schedules = [
      preset,
      ...state.buildingSelectDashboard.schedules,
    ];
  },
  [SET_BUILDING_SELECT_DASHBOARD](state, value) {
    const data = value;
    data.places = data.places.map((place) => {
      !place.currentSchedule ? (place.currentSchedule = "") : "";
      return place;
    });
    state.buildingSelectDashboard = data;
  },
  [SET_BUILDING_SELECT_DASHBOARD_PLACES](state, value) {
    state.buildingSelectDashboard.places = value.places;
    state.buildingSelectDashboard.groups = value.groups;
  },
  [SET_BUILDING_SELECT_DASHBOARD_SCHEDULES](state, schedules) {
    state.buildingSelectDashboard.schedules = [
      ...schedules.filter((schedule) => schedule.isOn),
      ...schedules.filter((schedule) => !schedule.isOn),
    ];
  },
  [SET_BUILDING_SELECT_DASHBOARD_LOAD](state, value) {
    state.buildingSelectDashboardLoad = value;
  },
  [SET_SCHEDULE_STATUS](state, id) {
    state.buildingSelectDashboard.schedules.map((item) => {
      if (id === item.id) {
        item.isOn = !item.isOn;
      }
    });
  },
  [CHANGE_COUNTER_COMMANDS](state, payload) {
    if (state.buildingSelectDashboard.allPresetsCount) {
      state.buildingSelectDashboard.allPresetsCount =
        state.buildingSelectDashboard.allPresetsCount + payload;
    }
  },
  [SET_DASHBOARD_CLIMATE_ONLINE](state, payload) {
    state.buildingClimateOnline = payload;
  },
  [SET_DASHBOARD_CLIMATE_ONLINE_STATUS](state, payload) {
    state.climateOnlineSummaryModalOpened = payload;
  },
  ADD_TIMER(state, payload) {
    // фикс реактивности свойства timer
    if (
      state.buildingSelectDashboard.groupPresets &&
      state.buildingSelectDashboard.groupPresets.length
    ) {
      state.buildingSelectDashboard.groupPresets.map((preset) => {
        if (preset.id === payload.id) {
          if (!Object.prototype.hasOwnProperty.call(preset, "timer")) {
            Vue.set(preset, "timer", null);
          }
        }
        return preset;
      });
    }
  },
  ADD_SCHEDULE_PROP(state) {
    // фикс реактивности свойства currentSchedule
    if (
      state.buildingSelectDashboard.places &&
      state.buildingSelectDashboard.places.length
    ) {
      state.buildingSelectDashboard.places.map((place) => {
        if (!Object.prototype.hasOwnProperty.call(place, "currentSchedule")) {
          Vue.set(place, "currentSchedule", null);
        }
        return place;
      });
    }

    if (
      state.buildingSelectDashboard.groups &&
      state.buildingSelectDashboard.groups.length
    ) {
      state.buildingSelectDashboard.groups.map((group) => {
        group.places.map((place) => {
          //console.log("place", place);
          if (!Object.prototype.hasOwnProperty.call(place, "currentSchedule")) {
            Vue.set(place, "currentSchedule", null);
          }
          return place;
        });
        return group;
      });
    }
  },
  [UPDATE_PRESET_DATA](state, currentPreset) {
    if (
      state.buildingSelectDashboard.placePresets &&
      state.buildingSelectDashboard.placePresets.length
    ) {
      state.buildingSelectDashboard.placePresets.map((preset) => {
        if (preset.id === currentPreset.id) {
          return Object.assign(preset, currentPreset);
        }
        return preset;
      });
    }
  },
  [UPDATE_PRESET_GROUP_DATA](state, currentGroupPreset) {
    if (
      state.buildingSelectDashboard.groupPresets &&
      state.buildingSelectDashboard.groupPresets.length
    ) {
      state.buildingSelectDashboard.groupPresets.map((preset) => {
        if (preset.id === currentGroupPreset.id) {
          return Object.assign(preset, currentGroupPreset);
        }
        return preset;
      });
    }
  },
  [SET_NEW_PLACES_GROUPS](state, data) {
    if (state.buildingSelectDashboard) {
      state.buildingSelectDashboard.places = data.places;
      state.buildingSelectDashboard.groups = data.groups;
      state.buildingSelectDashboard.allPlacesCount =
        data.places.length + data.groups.length;
    }
  },
  [UNPIN_DASHBOARD_PLACE](state, id) {
    if (state.buildingSelectDashboard && state.buildingSelectDashboard.places) {
      state.buildingSelectDashboard.places = state.buildingSelectDashboard.places.map(
        (item) => {
          if (item.id === id) {
            item.pinned = false;
            return item;
          }
          return item;
        }
      );
    }
  },
  [PIN_DASHBOARD_PRESET](state, preset) {
    state.buildingSelectDashboard &&
      state.buildingSelectDashboard.placePresets &&
      state.buildingSelectDashboard.placePresets.unshift(preset);
  },
  [PIN_DASHBOARD_PRESET_GROUP](state, preset) {
    state.buildingSelectDashboard.groupPresets &&
      state.buildingSelectDashboard.groupPresets.unshift(preset);
  },
  [UNPIN_DASHBOARD_GROUP](state, id) {
    if (state.buildingSelectDashboard && state.buildingSelectDashboard.groups) {
      state.buildingSelectDashboard.groups = state.buildingSelectDashboard.groups.map(
        (item) => {
          if (item.id === id) {
            item.pinned = false;
          }
          return item;
        }
      );
    }
  },
  [PIN_DASHBOARD_GROUP](state, group) {
    if (state.buildingSelectDashboard && state.buildingSelectDashboard.groups) {
      state.buildingSelectDashboard.groups = state.buildingSelectDashboard.groups.map(
        (item) => {
          if (item.id === group.id) {
            item.pinned = true;
          }
          return item;
        }
      );
    }
  },
  [PIN_DASHBOARD_PLACE](state, place) {
    if (state.buildingSelectDashboard && state.buildingSelectDashboard.places) {
      state.buildingSelectDashboard.places = state.buildingSelectDashboard.places.map(
        (item) => {
          if (item.id == place.id) {
            item.pinned = true;
          }
          return item;
        }
      );
    }
  },
  [UNPIN_DASHBOARD_SCHEDULE](state, id) {
    if (
      state.buildingSelectDashboard &&
      state.buildingSelectDashboard.schedules
    ) {
      state.buildingSelectDashboard.schedules = state.buildingSelectDashboard.schedules.map(
        (schedule) => {
          if (schedule.id === id) {
            schedule.pinned = false;
          }
          return schedule;
        }
      );
    }
  },
  [PIN_DASHBOARD_SCHEDULE](state, id) {
    if (
      state.buildingSelectDashboard &&
      state.buildingSelectDashboard.schedules
    ) {
      state.buildingSelectDashboard.schedules = state.buildingSelectDashboard.schedules.map(
        (schedule) => {
          if (schedule.id === id) {
            schedule.pinned = true;
          }
          return schedule;
        }
      );
    }
  },
  [UNPIN_DASHBOARD_PRESET](state, id) {
    if (
      state.buildingSelectDashboard &&
      state.buildingSelectDashboard.placePresets
    ) {
      state.buildingSelectDashboard.placePresets = state.buildingSelectDashboard.placePresets.filter(
        (group) => group.id !== id
      );
    }
  },
  [UNPIN_DASHBOARD_PRESET_GROUP](state, id) {
    if (
      state.buildingSelectDashboard &&
      state.buildingSelectDashboard.groupPresets
    ) {
      state.buildingSelectDashboard.groupPresets = state.buildingSelectDashboard.groupPresets.filter(
        (group) => group.id !== id
      );
    }
  },
  [REMOVE_SCHEDULE](state, id) {
    if (
      state.buildingSelectDashboard &&
      state.buildingSelectDashboard.schedules
    ) {
      state.buildingSelectDashboard.schedules = state.buildingSelectDashboard.schedules.filter(
        (item) => item.id !== id
      );
      state.buildingSelectDashboard.allSchedulesCount -= 1;
      state.buildingSelectDashboard.places = state.buildingSelectDashboard.places.map(
        (place) => {
          if (
            place.currentSchedule &&
            place.currentSchedule.scheduleId === id
          ) {
            place.currentSchedule = null;
          }
          return place;
        }
      );
    }
  },
  [REMOVE_PRESET](state, id) {
    if (
      state.buildingSelectDashboard &&
      state.buildingSelectDashboard.placePresets
    ) {
      state.buildingSelectDashboard.placePresets = state.buildingSelectDashboard.placePresets.filter(
        (item) => item.id !== id
      );
    }
    if (state.buildingSelectDashboard.allPresetsCount) {
      state.buildingSelectDashboard.allPresetsCount--;
    }
  },
  [REMOVE_PRESET_GROUP](state, id) {
    if (
      state.buildingSelectDashboard &&
      state.buildingSelectDashboard.groupPresets
    ) {
      state.buildingSelectDashboard.groupPresets = state.buildingSelectDashboard.groupPresets.filter(
        (item) => item.id !== id
      );
    }
    if (state.buildingSelectDashboard.allPresetsCount) {
      state.buildingSelectDashboard.allPresetsCount--;
    }
  },
  [ADD_PRESET](state, payload) {
    state.buildingSelectDashboard.placePresets.push(payload);
  },
  [CHANGE_SCHEDULE](state, currentSchedule) {
    if (!state.buildingSelectDashboard.schedules) return;
    state.buildingSelectDashboard.schedules = state.buildingSelectDashboard.schedules.map(
      (schedule) => {
        if (schedule.id === currentSchedule.id) {
          return Object.assign(schedule, currentSchedule);
        }
        return schedule;
      }
    );
  },
  [CHANGE_SCHEDULE_PLACES](state, placeSchedule) {
    state.buildingSelectDashboard.schedules = state.buildingSelectDashboard.schedules.map(
      (schedule) => {
        if (schedule.id === placeSchedule.scheduleId) {
          schedule.places.map((place) => {
            if (place.id === placeSchedule.id) {
              return Object.assign(place, placeSchedule);
            }
            return place;
          });
        }
        return schedule;
      }
    );
  },
};

export const actions = {
  fetchBuildingClimateOnline({ commit, dispatch }, buildingId) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`/buildings/${buildingId}/climate-online/summary`, {
          headers: {
            Authorization: `Bearer ${this.$auth.user.token}`,
          },
        })
        .then((res) => {
          commit(SET_DASHBOARD_CLIMATE_ONLINE, res.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          errorHandlerWithAction.call(this, err, {
            onRepeat: () => dispatch("fetchBuildingClimateOnline", buildingId),
            onClose: null,
          });
          reject(err);
        });
    });
  },

  setPlaceData({ commit, getters }, placeData) {
    if (!getters.getBuildingSelectDashboard) return;
    const setPlaces = (currentPlaces) =>
      currentPlaces.map((place) => {
        if (place.id === placeData.id) {
          const newPlace = { ...place };

          Object.keys(placeData).forEach((key) => {
            if (Object.prototype.hasOwnProperty.call(newPlace, key)) {
              newPlace[key] = placeData[key];
            }
          });
          return newPlace;
        }
        return place;
      });

    const places = setPlaces(getters.getBuildingSelectDashboard.places);

    const groups = getters.getBuildingSelectDashboard.groups.map((group) => {
      const newGroup = { ...group };
      newGroup.places = setPlaces(group.places);
      return newGroup;
    });

    commit(SET_BUILDING_SELECT_DASHBOARD_PLACES, { places, groups });
  },

  setPlaceTitle({ commit, getters }, placeData) {
    if (!getters.getBuildingSelectDashboard) return;

    const setPlacesTitle = (currentPlaces) =>
      currentPlaces.map((place) => {
        if (place.id === placeData.id) {
          const newPlace = { ...place };
          newPlace.title = placeData.title;
          return newPlace;
        }
        return place;
      });

    const places = setPlacesTitle(getters.getBuildingSelectDashboard.places);

    const groups = getters.getBuildingSelectDashboard.groups.map((group) => {
      const newGroup = { ...group };
      newGroup.places = setPlacesTitle(group.places);
      return newGroup;
    });

    commit(SET_BUILDING_SELECT_DASHBOARD_PLACES, { places, groups });
  },

  async unpinCardFromDashboard({ commit, dispatch }, info) {
    try {
      if (info.type === "group") {
        const { data } = await this.$axios.post(
          `groups/${info.group.id}/unpin`
        );
        commit(UNPIN_DASHBOARD_GROUP, info.group.id);
      } else if (info.type === "device") {
        const { data } = await this.$axios.post(
          `devices/${info.group.id}/unpin`
        );
        commit(UNPIN_DASHBOARD_PLACE, info.group.id);
        commit("deviceFunctions/pinCurrentPreset", false, { root: true });
      } else if (info.type === "schedule") {
        const { data } = await this.$axios.post(
          `schedules/${info.group.id}/unpin`
        );
        commit(UNPIN_DASHBOARD_SCHEDULE, info.group.id);
      } else if (info.type === "preset") {
        const { data } = await this.$axios.post(
          `presets/${info.group.id}/unpin`
        );
        commit(UNPIN_DASHBOARD_PRESET, info.group.id);
        commit("fastCommandsPage/UNPIN_PRESET", info.group.id, { root: true });
      } else if (info.type === "presetGroup") {
        const { data } = await this.$axios.post(
          `groupPresets/${info.group.id}/unpin`
        );
        commit(UNPIN_DASHBOARD_PRESET_GROUP, info.group.id);
        commit("fastCommandsPage/UNPIN_GROUP_PRESET", info.group.id, {
          root: true,
        });
      }
    } catch (error) {
      errorConflictHandler.call(this, error);
    }
  },
  async pinCard({ commit, dispatch }, info) {
    try {
      if (info.type === "group") {
        const { data } = await this.$axios.post(`groups/${info.group.id}/pin`);
        commit(PIN_DASHBOARD_GROUP, info.group);
      } else if (info.type === "device") {
        const { data } = await this.$axios.post(`devices/${info.group.id}/pin`);
        commit(PIN_DASHBOARD_PLACE, info.group);
        commit("deviceFunctions/pinCurrentPreset", true, { root: true });
      } else if (info.type === "schedule") {
        const { data } = await this.$axios.post(
          `schedules/${info.group.id}/pin`
        );
        commit(PIN_DASHBOARD_SCHEDULE, info.group.id);
      } else if (info.type === "preset") {
        const { data } = await this.$axios.post(`presets/${info.group.id}/pin`);
      } else if (info.type === "presetGroup") {
        const { data } = await this.$axios.post(
          `groupPresets/${info.group.id}/pin`
        );
      }
    } catch (error) {
      errorConflictHandler.call(this, error);
    }
  },
  async setDevicePowerStatus({ commit, dispatch }, place) {
    const { data } = await this.$axios
      .post(`devices/${place.id}/ctrl/power?ignoreConflicts=false`, {
        cmdId: getRandomIntInclusive(0, 99999999),
        isOn: !place.state.isOn,
        conflictResolveData: null,
      })
      .catch((error) => {
        errorConflictHandler.call(this, error);
      });
    dispatch("setPlaceData", data.data.devices[0]);
    dispatch("deviceFunctions/findCurrentDevice", data.data, { root: true });
  },
  async createGroup({ commit, dispatch }, info) {
    try {
      const { data } = await this.$axios.post("groups", {
        title: info.title,
        places: info.places,
        buildingId: info.buildingId,
      });
      commit(SET_NEW_PLACES_GROUPS, data.data);
    } catch (error) {
      errorConflictHandler.call(this, error);
    }
  },

  async changePreset({ commit, dispatch }, info) {
    try {
      const { data } = await this.$axios.put(
        `presets/${info.id}?ignoreConflicts=false`,
        {
          deviceSate: info.deviceState,
          title: info.title,
          conflictResolveData: null,
        }
      );
      const responseData = data.data.result;
      commit(UPDATE_PRESET_DATA, responseData);
      commit("deviceFunctions/setPresetData", responseData, { root: true });
      dispatch("fastCommandsPage/renamePreset", responseData, { root: true });
    } catch (error) {
      errorConflictHandler.call(this, error);
    } finally {
      this.loading = false;
    }
  },

  async changePresetGroup({ commit, dispatch }, info) {
    try {
      const { data } = await this.$axios.put(`groupPresets/${info.id}`, {
        content: info.deviceContent,
        title: info.title,
      });
      const responseData = data.data.result;
      commit(UPDATE_PRESET_GROUP_DATA, responseData);
      commit("deviceFunctions/setGroupPresetData", responseData, {
        root: true,
      });
      dispatch("fastCommandsPage/renameGroupPreset", responseData, {
        root: true,
      });
    } catch (error) {
      errorConflictHandler.call(this, error);
    } finally {
      this.loading = false;
    }
  },

  updatePresets({ commit }, presets) {
    //console.log("###updatePresets", presets);
    for (const updated in presets) {
      commit(UPDATE_PRESET_DATA, presets[updated]);
      commit("deviceFunctions/setPresetData", presets[updated], { root: true });
    }
  },
  updateGroupPresets({ commit }, groupPresets) {
    //console.log("###updateGroupPresets", groupPresets);
    for (const updated in groupPresets) {
      commit(UPDATE_PRESET_GROUP_DATA, groupPresets[updated]);
      commit("deviceFunctions/setGroupPresetData", groupPresets[updated], {
        root: true,
      });
    }
  },
  updateSchedules({ commit }, schedules) {
    //console.log("###updateSchedules", schedules);
    for (const schedule in schedules) {
      commit(CHANGE_SCHEDULE, schedule);
    }
    // schedules.forEach((item) => {
    //   commit(CHANGE_SCHEDULE, item);
    // });
  },
  updateSchedulePlaces({ commit }, schedule) {
    if (schedule && schedule.scheduleId) {
      commit(CHANGE_SCHEDULE_PLACES, schedule);
    }
  },

  async changeSchedule({ commit }, info) {
    try {
      const { data } = await this.$axios.put(`schedules/${info.id}`, {
        title: info.title,
        buildingId: info.buildingId,
        places: info.places.map((place) => {
          const PlaceScheduleCreation = {};
          PlaceScheduleCreation.placeId = place.id;
          PlaceScheduleCreation.serial = place.serial;
          PlaceScheduleCreation.periods = place.periods;
          return PlaceScheduleCreation;
        }),
      });
      commit(CHANGE_SCHEDULE, data.data.result);
    } catch (error) {
      errorConflictHandler.call(this, error);
    } finally {
      this.loading = false;
    }
  },
  async renameSchedule({ commit, dispatch }, info) {
    try {
      const { data } = await this.$axios.put(`schedules/${info.id}`, {
        title: info.title,
      });
      commit(CHANGE_SCHEDULE, data.data.result);
    } catch (error) {
      errorConflictHandler.call(this, error);
    } finally {
      this.loading = false;
    }
  },
  async changeGroup({ commit, dispatch }, info) {
    try {
      const { data } = await this.$axios.put(`groups/${info.groupId}`, {
        title: info.title,
        places: info.places,
        groupId: info.groupId,
      });
      commit(SET_NEW_PLACES_GROUPS, data.data);
    } catch (error) {
      errorConflictHandler.call(this, error);
    }
  },
  async setScheduleStatus({ commit, dispatch }, info) {
    await this.$axios
      .post(`schedules/${info.id}/ctrl?ignoreConflicts=false`, {
        isOn: !info.isOn,
        conflictResolveData: null,
      })
      .then((response) => {
        //console.log(response.data.data.devices);
        if (response.data.data.schedules && response.data.data.schedules.length) {
          response.data.data.schedules.forEach((schedule) => {
            commit(CHANGE_SCHEDULE, schedule);
          });
        }

        if (response.data.data.devices && response.data.data.devices.length) {
          response.data.data.devices.forEach((place) => {
            //console.log(place);
            dispatch("setPlaceData", place);
          });
        }
      })
      .catch((error) => {
        errorConflictHandler.call(this, error);
      });
  },
  setPresetStatus({ commit, rootState, dispatch }, info) {
    return this.$axios
      .post(`presets/${info.id}/ctrl?ignoreConflicts=false`, {
        cmdId: getRandomIntInclusive(0, 99999999),
        isOn: !info.isOn,
        conflictResolveData: null,
      })
      .then((response) => {
        commit("system/FORCE_LOCAL_LOADING_CARD_PLACE_ID", info.id, {
          root: true,
        });

        const systemStateUpdate = response.data.data;
        dispatch("deviceFunctions/findCurrentDevice", systemStateUpdate, {
          root: true,
        });

        const updatedDevices = systemStateUpdate.devices;
        for (const updated in updatedDevices) {
          dispatch("setPlaceData", updatedDevices[updated]);
        }

        const updatedPresets = systemStateUpdate.presets;
        for (const updated in updatedPresets) {
          commit("UPDATE_PRESET_DATA", updatedPresets[updated]);
          commit("deviceFunctions/setPresetData", updatedPresets[updated], {
            root: true,
          });
        }
      })
      .catch((error) => {
        const payload = {
          forceLoadingCardId: info.id,
        };
        errorConflictHandler.call(this, error, payload);
      });
  },
  setGroupPresetStatus({ commit, rootState, dispatch }, info) {
    return this.$axios
      .post(`groupPresets/${info.id}/ctrl?ignoreConflicts=false`, {
        cmdId: getRandomIntInclusive(0, 99999999),
        isOn: !info.isOn,
        conflictResolveData: null,
      })
      .then((response) => {
        commit("system/FORCE_LOCAL_LOADING_CARD_PLACE_ID", info.id, {
          root: true,
        });

        const systemStateUpdate = response.data.data;
        dispatch("deviceFunctions/findCurrentDevice", systemStateUpdate, {
          root: true,
        });

        const updatedDevices = systemStateUpdate.devices;
        for (const updated in updatedDevices) {
          dispatch("setPlaceData", updatedDevices[updated]);
        }

        const updatedGroupPresets = systemStateUpdate.groupPresets;
        for (const updated in updatedGroupPresets) {
          commit("UPDATE_PRESET_GROUP_DATA", updatedGroupPresets[updated]);
          commit(
            "deviceFunctions/setGroupPresetData",
            updatedGroupPresets[updated],
            { root: true }
          );
        }
      })
      .catch((error) => {
        const payload = {
          forceLoadingCardId: info.id,
        };
        errorConflictHandler.call(this, error, payload);
      });
  },
  async fetchBuildingSelectDashboard({ commit }, id) {
    try {
      if (id) {
        commit(SET_BUILDING_SELECT_DASHBOARD_LOAD, true);
        const { data } = await this.$axios.get(`buildings/${id}/dashboard`);

        // const pl = data.data.places.find(p => p.id === 197612);
        // pl.additionalState = {
        //   value: 25,
        //   units: 'ug/m3',
        //   description: 'хорошее качество',
        //   descriptionColor: '#6CC478',
        // };
        // pl.deviceIcon = '/cleaner-icon.png';
        // pl.deviceType = 'AIR_CLEANER';

        // console.log('###data', pl);

        commit(SET_BUILDING_SELECT_DASHBOARD, data.data);
        commit("fastCommandsPage/SET_GROUP_PRESETS", data.data.groupPresets, {
          root: true,
        });
        commit("fastCommandsPage/SET_PLACE_PRESETS", data.data.placePresets, {
          root: true,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      commit(SET_BUILDING_SELECT_DASHBOARD_LOAD, false);
    }
  },
  async fetchBuildingSelectPlaces({ commit }, id) {
    try {
      if (id) {
        commit(SET_BUILDING_SELECT_DASHBOARD_LOAD, true);
        const { data } = await this.$axios.get(`buildings/${id}/places`);

      //   const pl = data.data.places.find(p => p.id === 197612);
      //   pl.additionalState = {
      //     value: 25,
      //     units: 'ug/m3',
      //     description: 'хорошее качество',
      //     descriptionColor: '#6CC478',
      //   };
      //   pl.deviceIcon = '/cleaner-icon.png';
      //   pl.deviceType = 'AIR_CLEANER';

      //   pl.state = {
      //     "isOn": false,
      //     "info": {
      //         "text": "26°C",
      //         "icons": [
      //             "https:\/\/dev-split.daichicloud.ru\/storage\/fu-icons\/url_active\/x4\/14\/5.png?h=072e6de951e5a8095e8b2548aff9fe18",
      //             "https:\/\/dev-split.daichicloud.ru\/storage\/fu-icons\/url_active\/x4\/14\/17.png?h=8b296b3c9fa4ec0edcd960c481c07a52",
      //             "https:\/\/dev-split.daichicloud.ru\/storage\/fu-icons\/url_active\/x4\/14\/29.png?h=0d850d2aa23ca9d90b7c24af5f812c0a"
      //         ],
      //         "iconsSvg": [
      //             "https:\/\/dev-split.daichicloud.ru\/storage\/fu-icons\/url_svg\/original\/5.svg?h=072e6de951e5a8095e8b2548aff9fe18",
      //             "https:\/\/dev-split.daichicloud.ru\/storage\/fu-icons\/url_svg\/original\/17.svg?h=8b296b3c9fa4ec0edcd960c481c07a52",
      //             "https:\/\/dev-split.daichicloud.ru\/storage\/fu-icons\/url_svg\/original\/29.svg?h=0d850d2aa23ca9d90b7c24af5f812c0a"
      //         ],
      //         "iconNames": [
      //             "modeAuto_active",
      //             "fanSpeedAuto_active",
      //             "extLed_active"
      //         ]
      //     }
      // };

        // console.log('###data', pl);

        commit(SET_BUILDING_SELECT_DASHBOARD_PLACES, data.data);
        commit("ADD_SCHEDULE_PROP");
      }
    } catch (error) {
      console.error(error);
    } finally {
      commit(SET_BUILDING_SELECT_DASHBOARD_LOAD, false);
    }
  },
  async fetchSchedules({ commit }, id) {
    try {
      const { data } = await this.$axios.get(`buildings/${id}/schedules`);
      commit(SET_BUILDING_SELECT_DASHBOARD_SCHEDULES, data.data);
    } catch (e) {
      console.log(e);
    }
  },
};
