import {
  SET_MODAL_OBJECT,
} from '@/utils/types';

export const state = () => ({
  objectInfo: null,
});

export const getters = {
  objectInfo: ({ objectInfo }) => objectInfo,
};

export const mutations = {
  [SET_MODAL_OBJECT](state, data) {
    state.objectInfo = data;
  },
};

export const actions = {
  setModalData({ commit }, data) {
    commit(SET_MODAL_OBJECT, data);
  },
};
