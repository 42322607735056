let codeTimerIntervalId;

export const state = () => ({
  inputLoginValue: '', // для сохранения введенного Identifier в StartAuth
  emailLoginValue: '',
  responseIdentifier: '', // для хранения значения Identifier возвращаемого из запроса
  tmpPhoneIdentifier: '', // для записи оставшегося времени до повторной отправки кода на текущий телефон
  tmpPhoneCodeTimer: 0,
  selectedCountry: null,
});

export const getters = {
  getInputLoginValue: ({ inputLoginValue }) => inputLoginValue,
  getEmailLoginValue: ({ emailLoginValue }) => emailLoginValue,
  getResponseIdentifier: ({ responseIdentifier }) => responseIdentifier,
  getTmpPhoneIdentifier: ({ tmpPhoneIdentifier }) => tmpPhoneIdentifier,
  getTmpPhoneCodeTimer: ({ tmpPhoneCodeTimer }) => tmpPhoneCodeTimer,
  getSelectedCountry: ({ selectedCountry }) => selectedCountry,
};

export const mutations = {
  updateInputLoginValue(state, value) {
    state.inputLoginValue = value;
  },
  updateEmailLoginValue(state, value) {
    state.emailLoginValue = value;
  },
  updateResponseIdentifier(state, value) {
    state.responseIdentifier = value;
  },
  updateTmpPhoneIdentifier(state, value) {
    state.tmpPhoneIdentifier = value;
  },
  updateTmpPhoneCodeTimer(state, value) {
    state.tmpPhoneCodeTimer = value;
  },
  setSelectedCountry(state, value) {
    state.selectedCountry = value;
  },
};

export const actions = {
  setInputLoginValue({ commit }, value) {
    commit('updateInputLoginValue', value);
  },
  setEmailLoginValue({ commit }, value) {
    commit('updateEmailLoginValue', value);
  },
  setResponseIdentifier({ commit }, value) {
    commit('updateResponseIdentifier', value);
  },
  setTmpPhoneIdentifier({ commit }, value) {
    commit('updateTmpPhoneIdentifier', value);
  },
  setTmpPhoneCodeTimer({ commit }, time) {
    clearInterval(codeTimerIntervalId);
    commit('updateTmpPhoneCodeTimer', time);

    codeTimerIntervalId = setInterval(() => {
      time--;
      if (time === 0) {
        clearInterval(codeTimerIntervalId);
        commit('updateTmpPhoneIdentifier', '');
      }
      commit('updateTmpPhoneCodeTimer', time);
    }, 1000);
  },
};
