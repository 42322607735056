
import { mapActions, mapGetters } from 'vuex';
import { clearUsername } from '@/utils/user';
import { errorConflictHandler } from '@/utils/api';
import AppInput from '../Inputs/AppInput.vue';
import AppButton from '../Buttons/AppButton.vue';
import modalEsc from '../../mixins/modalEsc';

const NAME_MIN_LENGTH = 2;
const NAME_MAX_LENGTH = 120;

export default {
  name: 'InputModal',
  components: { AppButton, AppInput },
  mixins: [modalEsc],
  data: () => ({
    modalName: 'inputModal',
    inputName: '',
    loading: false,
  }),
  computed: {
    ...mapGetters({
      deviceInfo: 'deviceFunctions/getDeviceInfo',
      renameType: 'modalInput/getRenameType',
      title: 'modalInput/getTitle',
      inputValue: 'modalInput/getInputValue',
      modalDataObject: 'modalInput/getModalData',
      getTranslations: 'localization/getTranslations',
    }),
    isValueChanged() {
      return this.inputName !== this.inputValue;
    },
    isValueValidMin() {
      return this.inputName.trim().length >= NAME_MIN_LENGTH;
    },
    isValueValidMax() {
      return this.inputName.trim().length <= NAME_MAX_LENGTH;
    },
    isValueValid() {
      return this.isValueValidMin && this.isValueValidMax;
    },
    nameErrorMessage() {
      if (this.isValueValidMin && !this.isValueValidMax) {
        // return `Максимальная длина имени ${NAME_MAX_LENGTH} символов`;
        return `${this.getTranslations['Максимальная длина имени {0} символов'][0]}${NAME_MAX_LENGTH}${this.getTranslations['Максимальная длина имени {0} символов'][1]}`;
      }
      return '';
    },
    submitDisabled() {
      return !this.isValueChanged || !this.isValueValid;
    },
  },
  watch: {
    inputValue() {
      this.inputName = this.inputValue;
    },
  },
  methods: {
    ...mapActions({
      setShowModal: 'system/setShowModal',
      updateDeviceInfo: 'deviceFunctions/updateDeviceInfo',
      changeGroup: 'buildingDashboard/changeGroup',
      renameSchedule: 'buildingDashboard/renameSchedule',
      changePreset: 'buildingDashboard/changePreset',
      changePresetGroup: 'buildingDashboard/changePresetGroup',
      setPlaceTitle: 'buildingDashboard/setPlaceTitle',
    }),

    modalOpened() {
      this.setShowModal(true);
      document.addEventListener('keydown', this.clickHandler);
    },

    modalClosed() {
      this.setShowModal(false);
      document.removeEventListener('keydown', this.clickHandler);
      this.inputName = this.inputValue;
    },

    changeInput(value) {
      this.inputName = value;
    },

    async submit() {
      if (this.submitDisabled) return;

      this.inputName = clearUsername(this.inputName);

      if (this.renameType === 'device') {
        await this.renameDeviceAction();
      } else if (this.renameType === 'group') {
        await this.renameGroupAction();
      } else if (this.renameType === 'schedule') {
        await this.renameScheduleAction();
      } else if (this.renameType === 'preset') {
        await this.renamePresetAction();
      } else if (this.renameType === 'presetGroup') {
        await this.renamePresetGroupAction();
      }
    },
    renameDeviceAction() {
      this.loading = true;
      this.$axios.put(
        `devices/${this.modalDataObject.id}`,
        {
          title: this.inputName,
        },
      )
        .then((response) => {
          const deviceData = response.data.data;
          this.updateDeviceInfo(deviceData);
          this.setPlaceTitle(deviceData);

          this.$store.dispatch('buildingDashboard/updatePresets', deviceData.presets);
          this.$store.dispatch('buildingDashboard/updateGroupPresets', deviceData.groupPresets);
          this.$store.dispatch('buildingDashboard/updateSchedulePlaces', deviceData.currentSchedule);

          this.$modal.hide(this.modalName);
        })
        .catch((error) => {
          errorConflictHandler.call(this.$store, error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async renameGroupAction() {
      this.loading = true;
      const places = this.modalDataObject.places.map((item) => item.id);

      await this.changeGroup({
        title: this.inputName,
        places,
        groupId: this.modalDataObject.id,
      });
      this.$modal.hide(this.modalName);
      this.loading = false;
    },

    async renamePresetAction() {
      this.loading = true;
      await this.changePreset({
        title: this.inputName,
        content: this.modalDataObject.content,
        id: this.modalDataObject.id,
      });
      this.loading = false;
      this.$modal.hide(this.modalName);
    },

    async renamePresetGroupAction() {
      this.loading = true;
      await this.changePresetGroup({
        title: this.inputName,
        deviceState: this.modalDataObject.deviceState,
        id: this.modalDataObject.id,
      });
      this.loading = false;
      this.$modal.hide(this.modalName);
    },

    async renameScheduleAction() {
      this.loading = true;
      await this.renameSchedule({
        id: this.modalDataObject.id,
        title: this.inputName,
        // places: this.modalDataObject.places,
        // buildingId: this.modalDataObject.buildingId,
      });
      this.loading = false;
      this.$modal.hide(this.modalName);
    },
  },
};
