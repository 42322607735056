
export default {
  name: 'TimeLineSmall',
  props: {
    times: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    timesText: ['00:00', '06:00', '12:00', '18:00', '24:00'],
  }),
  methods: {
    calcPeriodWidth(period, nextPeriod) {
      const nextPeriodTime = nextPeriod ? nextPeriod.start : 1440;
      const periodInMinutes = nextPeriodTime - period.start;
      return (periodInMinutes * 0.0694).toFixed(2);
    },
    showBorder(period, nextPeriod) {
      return nextPeriod && nextPeriod.action.deviceState.isOn && period.action.deviceState.isOn;
    },
  },
};
