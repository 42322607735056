import {
  errorConflictPaymentHandler,
  errorHandlerWithAction,
} from "@/utils/api";
import { APP_VERSION } from "../versions/app-version";
// import ax from '../utils/axios';
import {
  conflict,
  pages1 as pages,
  basket as basketMock,
  dcOrder,
} from "../mock/tarification/payment-session";

export const SET_DEVICE_ID = "SET_DEVICE_ID";
export const SET_USER_TOKEN_STORAGE = "SET_USER_TOKEN_STORAGE";
export const SET_SESSION = "SET_SESSION";
export const SET_BASKET = "SET_BASKET";
export const SET_SESSION_LOAD = "SET_SESSION_LOAD";
export const SET_PAYMENT_TOKEN = "SET_PAYMENT_TOKEN";
export const ADD_SERVICE_ITEM = "ADD_SERVICE_ITEM";
export const REMOVE_SERVICE_ITEM = "REMOVE_SERVICE_ITEM";
export const REMOVE_SERVICE_ITEMS = "REMOVE_SERVICE_ITEMS";
export const CHANGE_SERVICE_QUANTITY = "CHANGE_SERVICE_QUANTITY";
export const RESET_STATE = "RESET_STATE";
export const SET_PAYMENT_SESSION_CONFLICT_STATE =
  "SET_PAYMENT_SESSION_CONFLICT_STATE";
export const SET_PAYMENT_ORDER_CONFLICT_STATE =
  "SET_PAYMENT_ORDER_CONFLICT_STATE";
export const SET_PAYMENT_DEVICE_CONFLICT_STATE =
  "SET_PAYMENT_DEVICE_CONFLICT_STATE";
export const SET_SELECT_PAGE_STATE = "SET_SELECT_PAGE_STATE";

export const state = () => ({
  deviceId: null,
  sessions: null,
  basket: null,
  orders: null,
  sessionsLoad: true,
  paymentToken: null,
  selectedServices: null,
  isPaymentSessionConflict: false,
  isPaymentOrderConflict: false,
  isPaymentDeviceConflict: false,
  isSelectPage: false,
  userTokenStorage: false,
});

export const getters = {
  getDeviceId: ({ deviceId }) => deviceId,
  getSessions: ({ sessions }) => sessions,
  getServices: ({ selectedServices }) => selectedServices,
  getBasket: ({ basket }) => basket,
  getPaymentSessionConflictState: ({ isPaymentSessionConflict }) =>
    isPaymentSessionConflict,
  getPaymentOrderConflictState: ({ isPaymentOrderConflict }) =>
    isPaymentOrderConflict,
  getPaymentDeviceConflictState: ({ isPaymentDeviceConflict }) =>
    isPaymentDeviceConflict,
  getSelectPageState: ({ isSelectPage }) => isSelectPage,
  getUserTokenStorage: ({ userTokenStorage }) => userTokenStorage,
};

export const mutations = {
  [RESET_STATE](state) {
    state.sessions = null;
    state.basket = null;
    state.orders = null;
    state.sessionsLoad = true;
    state.paymentToken = null;
    state.selectedServices = null;
    state.isPaymentSessionConflict = false;
    state.isPaymentOrderConflict = false;
    state.userTokenStorage = false;
  },
  [SET_DEVICE_ID](state, value) {
    state.deviceId = value;
  },
  [SET_USER_TOKEN_STORAGE](state, value) {
    state.userTokenStorage = value;
  },
  [SET_PAYMENT_SESSION_CONFLICT_STATE](state, value) {
    state.isPaymentSessionConflict = value;
  },
  [SET_SELECT_PAGE_STATE](state, value) {
    state.isSelectPage = value;
  },
  [SET_PAYMENT_ORDER_CONFLICT_STATE](state, value) {
    state.isPaymentOrderConflict = value;
  },
  [SET_PAYMENT_DEVICE_CONFLICT_STATE](state, value) {
    state.isPaymentDeviceConflict = value;
  },
  [SET_SESSION](state, value) {
    state.sessions = value;
  },
  [SET_BASKET](state, value) {
    state.basket = value;
  },
  [SET_SESSION_LOAD](state, value) {
    state.sessionsLoad = value;
  },
  [SET_PAYMENT_TOKEN](state, value) {
    state.paymentToken = value;
  },
  [ADD_SERVICE_ITEM](state, payload) {
    if (state.selectedServices === null) {
      state.selectedServices = [payload];
    } else {
      state.selectedServices = [...state.selectedServices, payload];
    }
  },
  [REMOVE_SERVICE_ITEM](state) {
    if (state.selectedServices && state.selectedServices.length === 1)
      state.selectedServices = null;
    if (state.selectedServices && state.selectedServices.length > 1)
      state.selectedServices.pop();
  },
  [REMOVE_SERVICE_ITEMS](state, items) {
    const newItems = state.selectedServices.filter(
      (item) => !items.includes(item.id)
    );
    // console.log("####newItems", newItems);
    state.selectedServices = newItems;
  },
  [CHANGE_SERVICE_QUANTITY](state, payload) {
    state.selectedServices.find((item) => item.id === payload.id).quantity =
      payload.quantity;
  },
};

export const actions = {
  fetchSessions({ commit, state, dispatch }, params) {
    const data = {
      deviceId: params.device_id,
      serviceGroupId: params.service_group_id,
      confictResolveData: null,
    };

    // this.$auth.setUserToken(`Bearer ${state.paymentToken}`);

    // const ax = axios.create({
    //   baseURL: process.env.API_URL,
    //   headers: {
    //     appVersion: APP_VERSION,
    //     platform: 'Web',
    //   },
    // });

    // return axios.create()

    // return fetch(process.env.API_URL + 'payments/v2/sessions', {
    //   method: 'POST',
    //   body: JSON.stringify(data),
    //   headers: {
    //     appVersion: APP_VERSION,
    //     platform: 'Web',
    //     Authorization: `Bearer ${state.paymentToken}`,
    //     Accept: ['application/json', 'text/plain', '*/*'],
    //     'Content-type': 'application/json',
    //   },
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log('###ress', data.data);
    //     commit(SET_SESSION, data.data);
    //     this.$modal.show("paymentModal");
    //   })
    //   .catch((err) => {
    //     console.log("#####error", err);
    //     //MOCK conflict, replace for err
    //     errorConflictPaymentHandler.call(this, err, () => {
    //       commit("SET_PAYMENT_SESSION_CONFLICT_STATE", true);
    //     }, {
    //       onRepeat: () => dispatch('fetchSessions', params),
    //       onClose: () => this.$router.push('/dashboard'),
    //     });
    //   })
    //   .finally(() => {
    //     //MOCK, delete
    //     // commit(SET_SESSION, pages);
    //     // this.$modal.show('paymentModal');
    //     // if (state.userTokenStorage) {
    //     //   this.$auth.setUserToken(`Bearer ${state.userTokenStorage}`);
    //     // } else {
    //     //   this.$auth.setUserToken(false);
    //     //   // this.$auth.logout();
    //     // }
    //   });

    const headers = {
      Authorization: `Bearer ${state.paymentToken}`,
    };

    if (params.lang) headers["Accept-Language"] = params.lang;

    return this.$ax
      .post("payments/v2/sessions", data, {
        headers,
      })
      .then((response) => {
        commit(SET_SESSION, response.data.data);
        this.$modal.show("paymentModal");
      })
      .catch((err) => {
        //MOCK conflict, replace for err
        errorConflictPaymentHandler.call(
          this,
          err,
          () => {
            commit("SET_PAYMENT_SESSION_CONFLICT_STATE", true);
          },
          {
            onRepeat: () => dispatch("fetchSessions", params),
            onClose: () => this.$router.push("/dashboard"),
          }
        );
      })
      .finally(() => {
        //MOCK, delete
        // commit(SET_SESSION, pages);
        // this.$modal.show('paymentModal');
        // if (state.userTokenStorage) {
        //   this.$auth.setUserToken(`Bearer ${state.userTokenStorage}`);
        // } else {
        //   this.$auth.setUserToken(false);
        //   // this.$auth.logout();
        // }
      });
    // .then((response) => {
    //   const responseData = response.data.data;
    //   commit(SET_SESSION, responseData);
    // })
    // .catch((err) => {
    //   console.log(err);
    // })
    // .finally(() => {
    //   commit(SET_SESSION_LOAD, false);
    // });
  },
  fetchBasket({ commit, state, dispatch }, { payload, params }) {
    //this.$auth.setUserToken(`Bearer ${state.paymentToken}`);

    // return fetch(process.env.API_URL + 'payments/v2/get-basket', {
    //   method: 'POST',
    //   body: JSON.stringify(payload),
    //   headers: {
    //     appVersion: APP_VERSION,
    //     platform: 'Web',
    //     Authorization: `Bearer ${state.paymentToken}`,
    //     Accept: ['application/json', 'text/plain', '*/*'],
    //     'Content-type': 'application/json',
    //   },
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     commit(SET_BASKET, data.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })
    //   .finally(() => {
    //     //MOCK, delete
    //     //commit(SET_BASKET, basketMock());
    //     // if (state.userTokenStorage) {
    //     //   this.$auth.setUserToken(`Bearer ${state.userTokenStorage}`);
    //     // } else {
    //     //   this.$auth.setUserToken(false);
    //     // }
    //   });

    const headers = {
      Authorization: `Bearer ${state.paymentToken}`,
    };

    if (params.lang) headers["Accept-Language"] = params.lang;

    return this.$ax
      .post("payments/v2/get-basket", payload, {
        headers,
      })
      .then((response) => {
        commit(SET_BASKET, response.data.data);
      })
      .catch((err) => {
        console.log(err);
        errorHandlerWithAction.call(this, err, {
          onRepeat: () =>
            dispatch("fetchBasket", { payload: payload, params: params }),
          onClose: null,
        });
      })
      .finally(() => {
        //MOCK, delete
        //commit(SET_BASKET, basketMock());
        // if (state.userTokenStorage) {
        //   this.$auth.setUserToken(`Bearer ${state.userTokenStorage}`);
        // } else {
        //   this.$auth.setUserToken(false);
        // }
      });
  },
  sendOrder({ commit, state, dispatch }, { payload, params }) {
    // this.$auth.setUserToken(`Bearer ${state.paymentToken}`);

    // return fetch(process.env.API_URL + 'payments/v2/orders', {
    //   method: 'POST',
    //   body: JSON.stringify(payload),
    //   headers: {
    //     appVersion: APP_VERSION,
    //     platform: 'Web',
    //     Authorization: `Bearer ${state.paymentToken}`,
    //     Accept: ['application/json', 'text/plain', '*/*'],
    //     'Content-type': 'application/json',
    //   },
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     window.location.href = data.data.purchaseUrl;
    //   })
    //   .catch((err) => {
    //     //MOCK conflict, replace for err
    //     errorConflictPaymentHandler.call(this, err, () => {
    //       commit("SET_PAYMENT_ORDER_CONFLICT_STATE", true);
    //     }, {
    //       onRepeat: () => dispatch('sendOrder', payload),
    //       onClose: null,
    //     });
    //   })
    //   .finally(() => {
    //     //window.location.href = dcOrder.purchaseUrl;
    //     // this.$auth.setUserToken(`Bearer ${this.$auth.user.token}`);
    //   });

    const headers = {
      Authorization: `Bearer ${state.paymentToken}`,
    };

    if (params.lang) headers["Accept-Language"] = params.lang;

    return new Promise((res, rej) => {
      this.$ax
        .post("payments/v2/orders", payload, {
          headers,
        })
        .then((response) => {
          const status = response.data.data.status;

          if (status === "COMPLETED") {
            // console.log(window.location);
            // this.$router.push("/payments/orders/success");
            const { origin } = window.location;
            window.location.href = `${origin}/payments/orders/success`;
            return;
          }

          window.location.href = response.data.data.purchaseUrl;
          res();
        })
        .catch((err) => {
          rej();
          //MOCK conflict, replace for err
          errorConflictPaymentHandler.call(
            this,
            err,
            () => {
              commit("SET_PAYMENT_ORDER_CONFLICT_STATE", true);
            },
            {
              onRepeat: () => {
                // console.log('####onRepeat payload', payload);
                dispatch("sendOrder", { payload: payload, params: params });
              },
              onClose: null,
            }
          );
        })
        .finally(() => {
          //window.location.href = dcOrder.purchaseUrl;
          // this.$auth.setUserToken(`Bearer ${this.$auth.user.token}`);
        });
    });
  },
  getOrder({ commit, state, dispatch }, { payload, params }) {
    // this.$auth.setUserToken(`Bearer ${state.paymentToken}`);

    // return fetch(process.env.API_URL + 'payments/v2/get-order', {
    //   method: 'POST',
    //   body: JSON.stringify(payload),
    //   headers: {
    //     appVersion: APP_VERSION,
    //     platform: 'Web',
    //     Authorization: `Bearer ${state.paymentToken}`,
    //     Accept: ['application/json', 'text/plain', '*/*'],
    //     'Content-type': 'application/json',
    //   },
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     const status = data.data.status;

    //     if (status === "PURCHASED" || status === "COMPLETED") {
    //       this.$router.push("/payments/orders/success");
    //     }

    //     if (status === "CREATED") {
    //       this.$router.push("/payments/orders/created");
    //     }

    //     if (status === "CANCELED") {
    //       this.$router.push("/payments/orders/canceled");
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })
    //   .finally(() => {
    //     // this.$auth.setUserToken(`Bearer ${this.$auth.user.token}`);
    //   });

    const headers = {
      Authorization: `Bearer ${state.paymentToken}`,
    };

    if (params.lang) headers["Accept-Language"] = params.lang;

    return this.$ax
      .post("payments/v2/get-order", payload, {
        headers,
      })
      .then((response) => {
        const status = response.data.data.status;

        if (status === "COMPLETED") {
          // console.log(window.location);
          // this.$router.push("/payments/orders/success");
          const { origin } = window.location;
          window.location.href = `${origin}/payments/orders/success`;
        }

        if (status === "PURCHASED") {
          const { origin } = window.location;
          window.location.href = `${origin}/payments/orders/success`;
          // this.$router.push("/payments/orders/success");
        }

        if (status === "CREATED") {
          const { origin } = window.location;
          window.location.href = `${origin}/payments/orders/created?uuid=${payload.uuid}&lang=${params.lang}`;
          // this.$router.push("/payments/orders/created");
        }

        if (status === "CANCELED") {
          const { origin } = window.location;
          window.location.href = `${origin}/payments/orders/canceled`;
          // this.$router.push("/payments/orders/canceled");
        }
      })
      .catch((err) => {
        console.log(err);
        errorHandlerWithAction.call(this, err, {
          onRepeat: () =>
            dispatch("getOrder", { payload: payload, params: params }),
          onClose: null,
        });
      })
      .finally(() => {
        // this.$auth.setUserToken(`Bearer ${this.$auth.user.token}`);
      });
  },
  getOrderUrl({ commit, state, dispatch }, payload) {
    const headers = {
      Authorization: `Bearer ${state.paymentToken}`,
    };

    if (payload.lang) headers["Accept-Language"] = payload.lang;

    return new Promise((res, rej) => {
      this.$ax
        .post(
          "payments/v2/get-order",
          {
            uuid: payload.uuid,
            conflictResolveData: null,
          },
          {
            headers,
          }
        )
        .then((response) => res(response))
        .catch((err) => {
          console.log(err);
          rej(err);
        })
        .finally(() => {
          // this.$auth.setUserToken(`Bearer ${this.$auth.user.token}`);
        });
    });
  },
};
