
import { mapActions, mapGetters } from 'vuex';
import { clearUsername } from '@/utils/user';
import AppInput from '../Inputs/AppInput';
import AppButton from '../Buttons/AppButton';
import modalEsc from '../../mixins/modalEsc';

const NAME_MIN_LENGTH = 2;
const NAME_MAX_LENGTH = 120;

export default {
  name: 'AddFastCommandModal',
  components: {
    AppButton, AppInput,
  },
  mixins: [modalEsc],
  data: () => ({
    modalName: 'AddFastCommandModal',
    inputName: '',
    loading: false,
  }),
  computed: {
    ...mapGetters({
      deviceInfo: 'deviceFunctions/getDeviceInfo',
      getTranslations: 'localization/getTranslations',
    }),
    isValueChanged() {
      return this.inputName !== this.inputValue;
    },
    isValueValidMin() {
      return this.inputName.trim().length >= NAME_MIN_LENGTH;
    },
    isValueValidMax() {
      return this.inputName.trim().length <= NAME_MAX_LENGTH;
    },
    isValueValid() {
      return this.isValueValidMin && this.isValueValidMax;
    },
    nameErrorMessage() {
      if (this.isValueValidMin && !this.isValueValidMax) {
        // return `Максимальная длина имени ${NAME_MAX_LENGTH} символов`;
        return `${this.getTranslations['Максимальная длина имени {0} символов'][0]}${NAME_MAX_LENGTH}${this.getTranslations['Максимальная длина имени {0} символов'][1]}`;
      }
      return '';
    },
    submitDisabled() {
      return !this.isValueChanged || !this.isValueValid;
    },
  },
  methods: {
    ...mapActions({
      toggleModal: 'system/setShowModal',
      setFastCommandModalData: 'modalAddFastCommand/setFastCommandModalData',
    }),
    changeInput(value) {
      this.inputName = value;
    },
    async modalOpened() {
      document.addEventListener('keydown', this.clickHandler);
      this.toggleModal(true);
    },
    async modalClosed() {
      document.removeEventListener('keydown', this.clickHandler);
      this.inputName = '';
      this.toggleModal(false);
    },
    submit() {
      if (!this.submitDisabled) {
        this.createFastCommand();
      }
    },
    createFastCommand() {
      this.inputName = clearUsername(this.inputName);

      const requestBody = {
        deviceState: null,
        presetId: null,
        title: this.inputName,
        placeId: this.deviceInfo.id,
        pinned: false,
      };

      this.loading = true;
      this.$axios.post(
        'presets',
        requestBody,
        {
          Authorization: `Bearer ${this.$auth.user.token}`,
        },
      )
        .then((response) => {
          const responseData = response.data.data;
          this.$store.commit('buildingDashboard/ADD_PRESET', responseData);
          this.$store.commit('deviceFunctions/addPreset', responseData);
          this.$store.commit('buildingDashboard/CHANGE_COUNTER_COMMANDS', 1);
          this.$modal.hide(this.modalName);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
