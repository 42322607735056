
import WifiDisconnectIcon from '../../../assets/icons/disconnect36.svg';
import { mapGetters } from 'vuex';

export default {
  components: { WifiDisconnectIcon },
  props: {
    place: Object,
  },
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
  }
};
