
import AppCheckbox from '../checks/AppCheckbox.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ButtonState',
  components: { AppCheckbox },
  props: {
    state: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
  },
  emits: ['click'],
};
