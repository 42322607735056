

// eslint-disable-next-line import/no-unresolved
import CalendarSmall from 'assets/icons/14x14/schedule.svg';
import PlaceStatus from '../../class/PlaceStatus';
import TimeIcon from '../../../assets/icons/time.svg';
import { mapGetters } from 'vuex';

export default {
  components: { TimeIcon, CalendarSmall },
  props: {
    place: Object,
  },
  data: () => ({
    //weekDayName: ['', this.getTranslations['Пн'], this.getTranslations['Вт'], this.getTranslations['Ср'], this.getTranslations['Чт'], this.getTranslations['Пт'], this.getTranslations['Сб'], this.getTranslations['Вс']],
  }),
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
    placeStatus() {
      return new PlaceStatus(this.place);
    },
    weekDayName() {
      return ['', this.getTranslations['Пн'], this.getTranslations['Вт'], this.getTranslations['Ср'], this.getTranslations['Чт'], this.getTranslations['Пт'], this.getTranslations['Сб'], this.getTranslations['Вс']];
    },
  },
  methods: {
    startTime(mins) {
      const hours = Math.trunc(mins / 60);
      let minutes = String(mins % 60);
      if (minutes.length === 1) {
        minutes = `0${minutes}`;
      }
      return `${hours}:${minutes}`;
    },
    endTime() {
      const listData = this.placeStatus.scheduleCurrentPeriodTimes();
      let end;
      listData.forEach((item, index) => {
        if (item.isActive) {
          end = listData[index + 1];
        }
      });
      return this.startTime(end ? end.start : 1439);
    },
  },
};
