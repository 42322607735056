
export default {
  name: "SubscribeBadge",
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    onBadgeClick: {
      type: Function,
      default: () => 1,
    },
    isPreloading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    status() {
      // ACTIVE, BLOCKED, FROZEN
      if (this.subscription.status) {
        return this.subscription.status.toLowerCase();
      }
      return "";
    },
    badgeStyle() {
      const style = {};
      if (this.subscription && this.subscription.backgroundImage) {
        style.backgroundImage = `url(${this.subscription.backgroundImage})`;
      }

      return style;
    },
  },
  methods: {
    openPaymentTab() {
      this.onBadgeClick();
      // if (this.subscription.buttonUrl) {
      //   window.open(this.subscription.buttonUrl, '_blank');
      // }

      // POST /payments/v2/sessions
      // this.$modal.show('paymentModal');
    },
  },
};
