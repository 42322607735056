
import ResizeCol from '~/components/resize/ResizeCol.vue';
import ResizeColDouble from '~/components/resize/ResizeColDouble.vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import { mapGetters } from 'vuex';
import { startTime } from '@/utils/helpers';
import DeviceStatePreview from '../DeviceStatePreview.vue';
import TimeLineContextMenu from '../contextMenu/TimeLineContextMenu.vue';

export default {
  name: 'Timeline',
  components: {
    TimeLineContextMenu, DeviceStatePreview, ResizeCol, ResizeColDouble,
  },
  props: {
    times: {
      type: Array,
    },
    periodIndex: {
      type: Number,
      required: true,
    },
  },
  emits: ['newWidth', 'newInterval', 'choseTime', 'filter-small-times', 'add-first-time'],
  data: () => ({
    maxZoom: 48,
    minutes: 1439,
    scale: 1,
    width: 768,
    scrollLeftPercent: 0,
    scrollRightPercent: 0,
    isDragging: false,
    isResizeTime: null,
    isResizeScroll: false,
    resizeTimeData: {
      left: null,
      time: null,
    },
    cachePosition: {
      oldPositionScroll: null,
    },
  }),
  computed: {
    ...mapGetters({
      currentTimeIndex: 'schedules/getActiveTimeIndex',
      activePeriodIndex: 'schedules/getActivePeriodIndex',
    }),
    calcTimesArray() {
      const arrayTimes = [];
      let minPath = 120;
      if (this.scale >= 1) {
        minPath = Math.floor(minPath / Math.floor(this.scale));
      }
      for (let i = 0; i <= this.minutes + 1; i += minPath) {
        const time = this.parseTime(i);
        arrayTimes.push(time);
      }
      return arrayTimes;
    },
    columns() {
      const cools = [];
      this.times.forEach((item, index) => {
        const col = {};
        const end = this.times[index + 1]
          ? Number((this.times[index + 1].start / 14.39).toFixed(4))
          : Number(((this.minutes + 1) / 14.39).toFixed(4));
        col.width = Number(end - ((item.start + 1) / 14.39).toFixed(4));
        col.width = Math.abs(col.width);
        col.action = item.action;
        col.id = item.id;
        cools.push(col);
      });
      let allWidthPercent = 0;
      cools.forEach((col) => {
        allWidthPercent += col.width;
      });
      const errorWidth = 100 - allWidthPercent;
      if (cools[cools.length - 1]) {
        cools[cools.length - 1].width += errorWidth;
      }
      return cools;
    },
  },
  watch: {
    isResizeTime: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue && !newValue) {
          this.$emit('filter-small-times', this.scale);
        }
      },
    },
  },
  mounted() {
    if (window.innerWidth <= 1024) {
      this.width = 720;
    }
    this.$refs.wrapper.addEventListener('touchmove', (evt) => {
      evt.preventDefault();
    });
    window.addEventListener('resize', this.resizeWindow);
  },
  beforeDestroy() {
    this.$refs.wrapper.addEventListener('touchmove', (evt) => {
      evt.preventDefault();
    });
    window.removeEventListener('resize', this.resizeWindow);
  },
  methods: {
    resizeWindow() {
      if (window.innerWidth <= 1024) {
        this.width = 720;
      } else {
        this.width = 768;
      }
    },
    contextHandler(data) {
      if (this.columns.length > 1) {
        this.$refs.menu.open(data);
      }
    },
    activeCheck(col) {
      return col.action.deviceState.isOn || col.action.presetId;
    },
    resizeHandler(isResize) {
      this.isResizeTime = isResize;
      if (!isResize) {
        this.resizeTimeData = {
          time: null,
          left: null,
        };
      }
    },
    clickHandler(index) {
      this.$emit('choiceTime', index);
    },
    addFirstTime() {
      this.$emit('add-first-time', { scale: this.scale });
    },
    changeHandler(e, colInd) {
      this.$store.commit('schedules/setActivePeriodIndex', this.periodIndex);
      const percent = this.width / 100;
      const newPercentWidth = Number(((e / percent) / this.scale).toFixed(4));
      const oldWidth = Number(JSON.parse(JSON.stringify(this.columns[colInd].width))).toFixed(4);
      const distance = Number((newPercentWidth - oldWidth).toFixed(4));
      let left = 0;

      if (e > 1) {
        if (colInd !== this.columns.length - 1) {
          this.columns.forEach((item, index) => {
            if (index === colInd) {
              this.$emit('newWidth', { distance, index, minutesScale: this.scale });
            }
            if (colInd < index) {
              left += item.width;
            }
          });
          this.resizeTimeData = {
            left,
            time: startTime(this.times[colInd + 1].start),
          };
        } else {
          const minutesLeft = 1438;
          this.$emit('newInterval', minutesLeft);
        }
      } else {
        this.columns.forEach((item, index) => {
          if (index === colInd) {
            this.$emit('newWidth', { distance: -1, index, minutesScale: this.scale });
          }
          if (colInd < index) {
            left += item.width;
          }
        });
      }
    },
    parseTime(number) {
      const hours = String(Math.floor(number / 60));
      const minutes = String(number % 60);
      return `${hours.length === 1 ? `0${hours}` : hours}:${minutes.length === 1 ? `0${minutes}` : minutes}`;
    },
    scrollHandler(e) {
      if (e.bubbles) {
        const { scrollLeft } = this.$refs.wrapper;
        this.scrollLeftPercent = scrollLeft >= 0 ? (scrollLeft * 100) / (this.width * this.scale) : 0;
      }
      if (this.scrollLeftPercent > 0) {
        const testScrollRight = 100 - this.scrollLeftPercent - (Math.floor(this.width / this.scale) * 100 / this.width);
        this.scrollRightPercent = testScrollRight >= 0 ? testScrollRight : 0;
      }
    },
    async wheelHandler(scrollEvent) {
      scrollEvent.preventDefault();

      this.scale += scrollEvent.deltaY * -0.01;
      this.scale = Math.min(Math.max(1, this.scale), this.maxZoom);

      await this.$nextTick();

      const { scrollLeft } = this.$refs.wrapper;
      this.scrollLeftPercent = scrollLeft >= 0 ? (scrollLeft * 100) / (this.width * this.scale) : 0;
      const testScrollRight = 100 - this.scrollLeftPercent - (Math.floor(this.width / this.scale) * 100 / this.width);
      this.scrollRightPercent = testScrollRight >= 0 ? testScrollRight : 0;

      // иначе ломается
      this.$refs.menu.$refs.menu.close();
    },
    dblClickHandler(e) {
      e.preventDefault();
      const { scrollLeft } = this.$refs.wrapper;
      const blockLeft = this.$refs.wrapper.getBoundingClientRect().left;
      const minutesLeft = Math.floor((((e.pageX - blockLeft + scrollLeft)) / (this.width * this.scale)) * this.minutes);
      this.$emit('newInterval', minutesLeft);
    },
    fixScrollLeft() {
      this.$refs.wrapper.scrollLeft = (this.scrollLeftPercent * this.width * this.scale) / 100;
    },
    scrollBlockHandler(scrollData) {
      if (this.isDragging) {
        const {
          newWidth, side,
        } = scrollData;

        const newScale = parseFloat(this.width / newWidth);
        const newScaleIsCorrect = newScale <= this.maxZoom && newScale >= 1;

        if (side === 'right' && newWidth <= this.width && !(this.scrollRightPercent === 0 && newScale < this.scale)) {
          this.scale = newScaleIsCorrect ? newScale : this.scale;

          const testScrollRight = 100 - this.scrollLeftPercent
            - (Math.floor(this.width / this.scale) * 100 / this.width);

          this.scrollRightPercent = testScrollRight >= 0 ? testScrollRight : 0;
          this.$refs.wrapper.scrollLeft = this.scrollLeftPercent * this.width * this.scale / 100;
        } else if (side === 'left' && newWidth <= this.width
          && !(this.scrollLeftPercent === 0 && newScale < this.scale)) {
          this.scale = newScaleIsCorrect ? newScale : this.scale;

          const testScrollLeft = 100 - this.scrollRightPercent
            - (Math.floor(this.width / this.scale) * 100 / this.width);

          this.scrollLeftPercent = testScrollLeft >= 0 ? testScrollLeft : 0;
          this.$refs.wrapper.scrollLeft = (this.scrollLeftPercent * this.width * this.scale) / 100;
        }
      }
    },
    changePosition({ movingDistance }) {
      const percent = 100 / this.width;
      const percentScale = 100 / this.scale;

      const correctMaxPercent = percentScale + this.scrollLeftPercent - (movingDistance * percent) <= 100;
      const correctMinPercent = this.scrollLeftPercent - (movingDistance * percent) >= 0;

      if (correctMaxPercent && correctMinPercent) {
        this.scrollLeftPercent -= (movingDistance * percent);
        if ((this.scrollLeftPercent < 0.15 && this.scale < 14) || this.scrollLeftPercent < 0.11) {
          this.scrollLeftPercent = 0;
        }
      }
      this.fixScrollLeft();
    },
    dropCache() {
      this.cachePosition.oldPositionScroll = null;
      this.cachePosition.oldPositionScrollLeftWidth = null;
    },
    checkMinScrollLeftPercent(scale) {
      if (scale < 10) {
        return 3 / scale;
      }
      if (scale < 20) {
        return 6 / scale;
      }
      return 15 / scale;
    },
  },
};
