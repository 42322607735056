
export default {
  name: 'InfoItem',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
