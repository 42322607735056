
import VueContext from 'vue-context';
import { mapActions, mapGetters } from 'vuex';
import { getRandomIntInclusive } from '@/utils/helpers';
import { errorConflictHandler } from '@/utils/api';
import contextMenuMixin from './contextMenuMixin';

export default {
  components: { VueContext },
  mixins: [contextMenuMixin],
  props: {
    isInMenu: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    currentData: null,
  }),
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
    isShowTurnFunctions() {
      if (this.currentData && this.currentData[1]) {
        const stateArray = this.currentData[1].contents.map((item) => item.deviceState.isOn);
        return stateArray.filter((item) => item === true).length;
      }
      return true;
    },
    isTimerSupported() {
      if (this.currentData && this.currentData[1]) {
        return this.currentData[1].serverTimerSupported;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      setModalData: 'modalInput/setModalData',
      setPanelType: 'system/toggleFastCommandsPanelType',
      showPanel: 'system/toggleFastCommandsPanel',
      unpinCard: 'buildingDashboard/unpinCardFromDashboard',
      pinCard: 'buildingDashboard/pinCard',
      setModalDeleteData: 'modalDelete/setModalData',
      setModalStartTimer: 'modalStartTimer/setModalData',
      cancelTimer: 'deviceFunctions/cancelTimer',
      showPlacePanel: 'system/togglePanelActive',
      showObjectPanel: 'system/setObjectPanel',
    }),
    openInfoPanel(data) {
      this.showPlacePanel(false);
      this.$store.commit('system/TOGGLE_SCHEDULE_PANEL', false);
      this.showObjectPanel(false);
      this.$store.commit('fastCommandsPage/SET_PRESET_IN_PANEL_INFO', data);
      this.setPanelType('info');
      this.showPanel(true);

      this.closeMenu();
    },
    async unpinFromDashboard(data) {
      await this.unpinCard({
        type: 'presetGroup',
        group: data,
      });
      this.$store.commit('deviceFunctions/pinPresetGroup', data);

      this.closeMenu();
    },
    async pinOnDashboard(place) {
      await this.pinCard({
        type: 'presetGroup',
        group: place,
      });
      this.$store.commit('deviceFunctions/pinPresetGroup', place);
      this.$store.commit('buildingDashboard/PIN_DASHBOARD_PRESET_GROUP', place);
      this.$store.commit('fastCommandsPage/PIN_GROUP_PRESET', place.id);

      this.closeMenu();
    },
    turnOffDevices(data) {
      this.$axios.post(
        `/groupPresets/${data.id}/ctrl?ignoreConflicts=false`,
        {
          isOn: false,
          cmdId: getRandomIntInclusive(0, 99999999),
          conflictResolveData: null,
        },
        {
          Authorization: `Bearer ${this.$auth.user.token}`,
        },
      )
        .then((response) => {
          this.$store.commit('system/FORCE_LOCAL_LOADING_CARD_PLACE_ID', data.id);

          const systemStateUpdate = response.data.data;
          const updatedDevices = systemStateUpdate.devices;

          this.$store.dispatch('deviceFunctions/findCurrentDevice', systemStateUpdate);
          for (const updated in updatedDevices) {
            this.$store.dispatch('buildingDashboard/setPlaceData', updatedDevices[updated]);
          }

          this.$store.dispatch('buildingDashboard/updatePresets', systemStateUpdate.presets);
          this.$store.dispatch('buildingDashboard/updateGroupPresets', systemStateUpdate.groupPresets);
        })
        .catch((error) => {
          const payload = {
            forceLoadingCardId: data.id,
          };
          errorConflictHandler.call(this.$store, error, payload);
        })
        .finally(() => {
          this.closeMenu();
        });
    },
    cancelTimerAction(place) {
      if (place.timer.id) {
        this.cancelTimer(place.timer.id);
      } else {
        console.error('Отсутствует timer ID');
      }

      this.closeMenu();
    },
    startTimerAction(data) {
      this.setModalStartTimer(data);
      this.$modal.show('StartTimerModal');

      this.closeMenu();
    },
    edit(data) {
      this.$store.commit('addPreset/setTypeOfCommand', 'groupPreset');
      this.$store.commit('addPreset/setTypeOfModal', 'edit');
      this.$store.commit('addPreset/setCurrentPresetToChange', data);
      this.$modal.show('addCommandModal');

      this.closeMenu();
    },
    clickRename(data) {
      this.setModalData({
        renameType: 'presetGroup',
        title: this.getTranslations['Переименовать'] || 'Переименовать',
        object: data,
        inputValue: data.title,
      });
      this.$modal.show('inputModal');

      this.closeMenu();
    },
    removeCommand(data) {
      this.setModalDeleteData({
        title: this.getTranslations['Вы уверены, что хотите удалить быструю команду?'] || 'Вы уверены, что хотите удалить быструю команду?',
        text: `${this.getTranslations['Быстрая команда']} ${this.getTranslations['{0} будет удалена из вашего аккаунта'][0]}«${data.title}»${this.getTranslations['{0} будет удалена из вашего аккаунта'][1]}.`,
        type: 'presetGroup',
        object: data,
      });
      this.$modal.show('deleteModal');

      this.closeMenu();
    },

    closeMenu() {
      this.$refs.menu.close();
    },
  },
};
