import {
  SET_MODAL_INPUT_TYPE,
  SET_MODAL_INPUT,
  SET_MODAL_TITLE,
  SET_MODAL_OBJECT,
} from '@/utils/types';

export const state = () => ({
  renameType: null, // device, group, fastCommand
  object: {},
  title: 'Переименовать',
  inputValue: null,
});

export const getters = {
  getTitle: ({ title }) => title,
  getInputValue: ({ inputValue }) => inputValue,
  getRenameType: ({ renameType }) => renameType,
  getModalData: ({ object }) => object,
};

export const mutations = {
  [SET_MODAL_TITLE](state, title) {
    state.title = title;
  },
  [SET_MODAL_INPUT](state, input) {
    state.inputValue = input;
  },
  [SET_MODAL_INPUT_TYPE](state, value) {
    state.renameType = value;
  },
  [SET_MODAL_OBJECT](state, value) {
    state.object = value;
  },
};

export const actions = {
  setModalData({ commit }, data) {
    commit(SET_MODAL_INPUT, data.inputValue);
    commit(SET_MODAL_TITLE, data.title);
    commit(SET_MODAL_OBJECT, data.object);
    commit(SET_MODAL_INPUT_TYPE, data.renameType);
  },
};
