
import SwiperCore, {
  Swiper, Navigation,
} from 'swiper';
import { mapGetters } from "vuex";

SwiperCore.use([Navigation]);

import RecommendationChip from "../Cards/RecommendationChip.vue";

export default {
  name: "ClimateOnlineRecommendations",
  components: {
    RecommendationChip,
  },
  props: {
    recommendations: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    slider: null,
  }),
  computed: {
    ...mapGetters({
      getTranslations: "localization/getTranslations",
    }),
  },
  mounted() {
    this.slider = new Swiper(this.$refs.slider.querySelector('.swiper-container'), {
      slidesPerView: 'auto',
      watchOverflow: true,
      navigation: {
        prevEl: this.$refs.slider.querySelector('.slider__navigation-btn--prev'),
        nextEl: this.$refs.slider.querySelector('.slider__navigation-btn--next'),
      },
    });
  },
};
