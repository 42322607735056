export const state = () => ({
  controlFunctionItemDetail: null,
});

export const getters = {
  getControlFunctionItemDetail: ({ controlFunctionItemDetail }) => controlFunctionItemDetail,
};

export const mutations = {
  SET_MODAL_CONTROL_FUNCTION_DETAIL_DATA(state, value) {
    state.controlFunctionItemDetail = value;
  },
};

export const actions = {
  setModalData({ commit }, data) {
    data.controlFunctionItemDetail && commit('SET_MODAL_CONTROL_FUNCTION_DETAIL_DATA', data.controlFunctionItemDetail);
  },
};
