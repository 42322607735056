
import { mapActions, mapGetters } from 'vuex';
import ButtonLaunch from '@/components/Buttons/ButtonLaunch.vue';
import { errorServiceHandler } from '@/utils/api';
import CloseIcon from '../../assets/icons/24x24/close.svg';
import SettingIcon from '../../assets/icons/24x24/Settings.svg';
import PlacePresetCard from '../Cards/PlacePresetCard.vue';
import GroupPresetCard from '../Cards/GroupPresetCard.vue';
import DashboardFastCommandsGroupContextMenu from '../contextMenu/dashboardFastCommandsGroupContextMenu.vue';
import DashboardFastCommandContextMenu from '../contextMenu/dashboardFastCommandContextMenu.vue';
import DashboardPlaceContextMenu from '../contextMenu/dashboardPlaceContextMenu.vue';

export default {
  name: 'FastCommandsPanel',
  components: {
    DashboardFastCommandContextMenu,
    DashboardFastCommandsGroupContextMenu,
    DashboardPlaceContextMenu,
    GroupPresetCard,
    PlacePresetCard,
    ButtonLaunch,
    CloseIcon,
    SettingIcon,
  },
  data: () => ({
    showAnimationLaunch: false,
    blockedToggle: false,
    dataPreparing: false,
    groupPresetInfo: null,
  }),
  computed: {
    ...mapGetters({
      panelType: 'system/getFastCommandsPanelType',
      showPanel: 'system/getFastCommandsPanel',
      panelInfoId: 'fastCommandsPage/getPresetsInPanelId',
      presets: 'fastCommandsPage/getPlacePresets',
      groupPresets: 'fastCommandsPage/getGroupPresets',
      groupPresetInfoId: 'fastCommandsPage/getPresetInPanelInfoId',
      presetInPanelName: 'fastCommandsPage/getPresetInPanelName',
      getTranslations: 'localization/getTranslations',
    }),
    groupPresetInfoContents() {
      if (this.groupPresetInfo?.contents) {
        return this.groupPresetInfo.contents.map((item) => {
          const preparedItem = item;
          preparedItem.id = item.placeId;
          preparedItem.isDevice = true;
          // Необходимо для корректной работы StartTimerModal, т.к. изначальных данных недостаточно
          return preparedItem;
        });
      }
      return [];
    },
    groupPresetsInPanel() {
      if (this.panelInfoId === 'syntheticGroupId') {
        return this.groupPresets;
      }
      const arrayOfPresets = [];
      if (this.groupPresets) {
        const groupPreset = [];
        this.groupPresets.forEach((item) => {
          item.contents.forEach((place) => {
            if (place.placeId === this.panelInfoId) {
              groupPreset.push(item);
            }
          });
        });

        if (groupPreset.length) {
          arrayOfPresets.push(...groupPreset);
        }
      }
      return arrayOfPresets;
    },
    placePresetsInPanel() {
      const arrayOfPresets = [];
      if (this.presets) {
        const presets = this.presets.filter((item) => item.id === this.panelInfoId || item.placeId === this.panelInfoId);
        presets && arrayOfPresets.push(...presets);
      }
      return arrayOfPresets;
    },
  },
  watch: {
    groupPresetInfoId: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue && newValue) {
          this.preparePanelInfo();
        }
      },
    },
  },
  mounted() {
    document.querySelector('.fast-commands-panel').addEventListener('scroll', () => {
      this.$bus.$emit('openContextMenu');
    });
    this.preparePanelInfo();
  },
  beforeDestroy() {
    document.querySelector('.fast-commands-panel').removeEventListener('scroll', () => {
      this.$bus.$emit('openContextMenu');
    });
  },
  methods: {
    ...mapActions({
      toggleFastCommands: 'system/toggleFastCommandsPanel',
      setGroupPresetStatus: 'buildingDashboard/setGroupPresetStatus',
    }),
    async togglePower() {
      if (!this.blockedToggle && !this.showAnimationLaunch) {
        this.blockedToggle = true;
        this.showAnimationLaunch = true;
        setTimeout(() => {
          this.showAnimationLaunch = false;
        }, 2000);
        await this.setGroupPresetStatus({
          id: this.groupPresetInfo.id,
          isOn: this.groupPresetInfo.isOn,
          table: 'groupPresets',
        });
        this.blockedToggle = false;
      }
    },
    closePanel() {
      this.toggleFastCommands(false);
    },
    closePanelOnBg(e) {
      const path = e.path || e.composedPath();
      if (path && path[0] && path[0].classList && path[0].classList.contains('control-panel__bg')) {
        this.closePanel();
      }
    },
    preparePanelInfo() {
      const availableGroupPreset = this.groupPresets?.find((item) => item.id === this.groupPresetInfoId);
      if (availableGroupPreset) {
        this.groupPresetInfo = availableGroupPreset;
      } else if (this.groupPresetInfoId) {
        this.fetchGroupPresetInfo();
      }
    },

    fetchGroupPresetInfo() {
      this.dataPreparing = true;
      this.$axios.get(`groupPresets/${this.groupPresetInfoId}`, {
        headers: {
          Authorization: `Bearer ${this.$auth.user.token}`,
        },
      })
        .then((response) => {
          this.groupPresetInfo = response.data.data;
        })
        .catch((error) => {
          errorServiceHandler.call(this, error);
        })
        .finally(() => {
          this.dataPreparing = false;
        });
    },
  },
};
