
import VueContext from 'vue-context';
import contextMenuMixin from './contextMenuMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'TimeLineContextMenu',
  components: { VueContext },
  mixins: [contextMenuMixin],
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
  },
  methods: {
    removeTime(data) {
      this.$store.commit('schedules/setDeleteTime', {
        periodIndex: data.periodIndex,
        timeIndex: data.index,
      });
      this.$store.commit('schedules/setActiveTimeIndex', 0);

      this.closeMenu();
    },
    closeMenu() {
      this.$refs.menu.close();
    },
  },
};
