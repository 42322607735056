
import { mapGetters } from 'vuex';
import PlaceStatus from '@/components/class/PlaceStatus';
import { secondsToHHMM } from '@/utils/helpers';
import ScheduleIcon from '../assets/icons/14x14/schedule.svg';
import TimeIcon from '../assets/icons/time.svg';

export default {
  name: 'StateBadge',
  components: {
    TimeIcon, ScheduleIcon,
  },
  props: {
    deviceInfo: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    // weekDayName: ['', this.getTranslations['Пн'], this.getTranslations['Вт'], this.getTranslations['Ср'], this.getTranslations['Чт'], this.getTranslations['Пт'], this.getTranslations['Сб'], this.getTranslations['Вс']],
  }),
  computed: {
    ...mapGetters({
      panelDeviceId: 'system/getPanelDeviceId',
      getTranslations: 'localization/getTranslations',
    }),
    weekDayName() {
      return ['', this.getTranslations['Пн'], this.getTranslations['Вт'], this.getTranslations['Ср'], this.getTranslations['Чт'], this.getTranslations['Пт'], this.getTranslations['Сб'], this.getTranslations['Вс']]
    },
    placeStatus() {
      return new PlaceStatus(this.deviceInfo);
    },
    progressValue() {
      return this.placeStatus.progressPercentProgress();
    },
    progressTimeLeft() {
      return this.placeStatus.progressLeftInHHMM();
    },
    timerProgressValue() {
      return this.placeStatus.timerPercentProgress();
    },
    timerTimeLeft() {
      return this.placeStatus.timerLeftHHMM();
    },
    currentTimeInterval() {
      const timeInterval = this.placeStatus.scheduleCurrentTimeIntervalActive();
      if (timeInterval) {
        return `${secondsToHHMM(timeInterval.start * 60)} - ${secondsToHHMM(timeInterval.end * 60)}`;
      }
      return '';
    },
    badgeActiveState() {
      if (this.placeStatus.isScheduleShow()) {
        if (this.placeStatus.isScheduleActive()) {
          return 'schedule'; // Активное расписание
        }
        return 'schedulePostpone'; // Отложенное расписание
      }

      if (this.placeStatus.isTimerShow()) {
        if (this.placeStatus.isTimerPause()) {
          return 'timer'; // {{ getTranslations['Таймер'] }} отложен
        }
        return 'timerProgress'; // Таймер запущен
      }

      if (this.deviceInfo.progress) {
        return 'progress'; // Прогресс вне таймера ({{ getTranslations['Комфортный сон'] }})
      }

      if (this.deviceInfo.currentPreset) {
        return 'fastCommand'; // Применена быстрая команда
      }

      return 'manual'; // Ручной режим
    },
  },
  methods: {},
};
