import Vue from 'vue';

const bus = new Vue();

const eventBus = {
  install: (Vue) => {
    Vue.prototype.$bus = bus;
  },
};

Vue.use(eventBus);

document.addEventListener('keydown', keyDownTextField, false);

function keyDownTextField(e) {
  // Отключаем обработку зажима кнопки
  if (e.repeat) {
    return;
  }
  switch (e.keyCode) {
    case 27: // esc
      bus.$emit('windowKeyDownEsc', e);
      break;
    case 13: // enter
      bus.$emit('windowKeyDownEnter', e);
      break;
  }
}
