import {
  SET_MODAL_DEFAULT_MESSAGE_TEXT,
  SET_MODAL_DEFAULT_MESSAGE_TITLE,
  SET_MODAL_DEFAULT_MESSAGE_ACTION,
  SET_MODAL_DEFAULT_MESSAGE_STATUS,
} from '@/utils/types';

export const state = () => ({
  title: null,
  text: null,
  action: null,
  status: null,
});

export const getters = {
  getTitle: ({ title }) => title,
  getText: ({ text }) => text,
  getAction: ({ action }) => action,
  getStatus: ({ status }) => status,
};

export const mutations = {
  [SET_MODAL_DEFAULT_MESSAGE_TITLE](state, title) {
    state.title = title;
  },
  [SET_MODAL_DEFAULT_MESSAGE_TEXT](state, text) {
    state.text = text;
  },
  [SET_MODAL_DEFAULT_MESSAGE_ACTION](state, action) {
    state.action = action;
  },
  [SET_MODAL_DEFAULT_MESSAGE_STATUS](state, status) {
    state.status = status;
  },
};

export const actions = {
  setModalData({ commit }, data) {
    data.title && commit(SET_MODAL_DEFAULT_MESSAGE_TITLE, data.title);
    data.text && commit(SET_MODAL_DEFAULT_MESSAGE_TEXT, data.text);
    data.action && commit(SET_MODAL_DEFAULT_MESSAGE_ACTION, data.action);
    data.status && commit(SET_MODAL_DEFAULT_MESSAGE_STATUS, data.status);
  },
  setDefaultModalData({ commit }, data) {
    commit(SET_MODAL_DEFAULT_MESSAGE_TITLE, data.title ? data.title : null);
    commit(SET_MODAL_DEFAULT_MESSAGE_TEXT, data.text ? data.text : null);
    commit(SET_MODAL_DEFAULT_MESSAGE_ACTION, data.action ? data.action : null);
    commit(SET_MODAL_DEFAULT_MESSAGE_STATUS, data.status ? data.status : null);
  },
};
