
import VueContext from 'vue-context';
import { mapActions, mapGetters } from 'vuex';
import contextMenuMixin from './contextMenuMixin';

export default {
  components: { VueContext },
  mixins: [contextMenuMixin],
  computed: {
    ...mapGetters({
      buildingSelectDashboard: 'buildingDashboard/getBuildingSelectDashboard',
      places: 'buildingDashboard/getBuildingsSelectedDashboardPlaces',
      groups: 'buildingDashboard/getBuildingsSelectedDashboardGroups',
      getTranslations: 'localization/getTranslations',
    }),
    isLastGroupOnDashboard() {
      if (this.places && this.groups) {
        const sumLength = this.places.filter((item) => item.pinned).length
          + this.groups.filter((item) => item.pinned).length;
        return sumLength < 2;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      setModalInputData: 'modalInput/setModalData',
      setModalDeleteData: 'modalDelete/setModalData',
      setCurrentGroupId: 'editGroupModal/setCurrentGroupId',
      unpinCardFromDashboard: 'buildingDashboard/unpinCardFromDashboard',
      pinCard: 'buildingDashboard/pinCard',
    }),
    async unpin(group) {
      await this.unpinCardFromDashboard({
        type: 'group',
        group,
      });

      this.closeMenu();
    },
    async pin(group) {
      await this.pinCard({
        type: 'group',
        group,
      });

      this.closeMenu();
    },
    renameModal(group) {
      this.setModalInputData({
        renameType: 'group',
        title: this.getTranslations['Переименовать'] || 'Переименовать',
        inputValue: group.title,
        object: group,
      });
      this.$modal.show('inputModal');

      this.closeMenu();
    },
    deleteModal(group) {
      this.setModalDeleteData({
        title: this.getTranslations['Вы уверены, что хотите расформировать группу устройств?'] || 'Вы уверены, что хотите расформировать группу устройств',
        text: `${this.getTranslations['Группа устройств {0} будет расформирована'][0]}«${group.title}»${this.getTranslations['Группа устройств {0} будет расформирована'][1]}. ${this.getTranslations['Устройства в группе добавятся в общий список устройств'] || 'Устройства в группе добавятся в общий список устройств'}.`,
        object: group,
        type: 'group',
      });
      this.$modal.show('deleteModal');

      this.closeMenu();
    },
    edit(group) {
      this.setCurrentGroupId(group.id);
      this.$modal.show('editGroupModal');

      this.closeMenu();
    },

    closeMenu() {
      this.$refs.menu.close();
    },
  },
};
