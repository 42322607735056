
import { mapGetters } from "vuex";
import RangeSlider from "../RangeSlider.vue";
import AppInput from "../Inputs/AppInput";
import { numberWithSpaces } from "../../utils/helpers";

export default {
  name: "CardProgress",
  components: {
    RangeSlider,
    AppInput,
  },
  props: {
    card: Object,
    onProgressChange: Function,
    onProgressInput: Function,
  },
  data: () => ({
    totalDays: 0,
  }),
  computed: {
    ...mapGetters({
      getTranslations: "localization/getTranslations",
    }),
    totalDaysPhrase() {
      return this.$numWord(this.totalDays, [
        this.getTranslations["{0} день"],
        this.getTranslations["{0} дня"],
        this.getTranslations["{0} дней"],
      ]);
    },

    items() {
      if (
        this.card.itemType === "DAYS_ACCESS" ||
        this.card.itemType === "DAYS"
      ) {
        const fm = this.card.formula.daysAccess;
        // const totalSum = numberWithSpaces(
        //   parseFloat(((fm.k * fm.e ** (fm.eK * (this.totalDays + fm.m)) + fm.d) * fm.pK * this.totalDays).toFixed(2))
        // );
        const dayCoast = numberWithSpaces(
          Math.round(
            (fm.k * fm.e ** (fm.eK * (this.totalDays + fm.m)) + fm.d) * fm.pK
          )
        );

        return [
          {
            title: this.getTranslations["Цена доп. дня"] || "Цена доп. дня",
            value: `${dayCoast} ${this.card.price.displayCurrency}`,
          },
          {
            title: this.getTranslations["Выбрано"] || "Выбрано",
            value: `${this.totalDaysPhrase[0]}${this.totalDays}${this.totalDaysPhrase[1]}`,
          },
          {
            title: this.getTranslations["Стоимость"] || "Стоимость",
            value: `${dayCoast * this.totalDays} ${
              this.card.price.displayCurrency
            }`,
          },
        ];
      }

      return [];
    },
  },
  mounted() {
    this.totalDays = this.card.quantity.current;
  },
  methods: {
    onInput(val) {
      this.totalDays = +val;
      this.onProgressInput(this.totalDays);
    },
    onDragEnd() {
      this.onProgressChange(this.totalDays);
    },
  },
};
