
import { mapGetters, mapActions } from "vuex";
import img from "@/assets/images/axioma.jpg";
import Logo from "@/assets/images/climat-online-logo.svg";
import LogoEn from "@/assets/images/climat-online-logo-en.svg";
import InfoList from "@/components/InfoList.vue";
import AppButton from "@/components/Buttons/AppButton";

export default {
  name: "ClimateOnlineInfo",
  components: {
    InfoList,
    AppButton,
    Logo,
    LogoEn,
  },
  props: {
    climateOnlineInfo: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    img,
    isButtonLoading: false,
  }),
  computed: {
    ...mapGetters({
      getTranslations: "localization/getTranslations",
      getLang: "localization/getLang",
      panelDeviceId: "system/getPanelDeviceId",
    }),
    buttonText() {
      if (this.climateOnlineInfo.supportRequestedAt) {
        return this.getTranslations['Заявка отправлена'] || 'Заявка отправлена';
      }

      return this.getTranslations['Отправить заявку'] || 'Отправить заявку';
    },
    showRequestButton() {
      if (this.climateOnlineInfo.errors && this.climateOnlineInfo.errors.length) return true;
      if (this.climateOnlineInfo.supportRequestedAt) return true;

      return false;
    },
    properties() {
      return [
        {
          title: this.getTranslations['Модель устройства'] || 'Модель устройства',
          value: this.climateOnlineInfo.modelName || '-',
        },
        {
          title: this.getTranslations['Модель контроллера'] || 'Модель контроллера',
          value: this.climateOnlineInfo.controllerName || '-',
        },
        {
          title: this.getTranslations['S/N контроллера'] || 'S/N контроллера',
          value: this.climateOnlineInfo.serial || '-',
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      sendSupportRequest: 'deviceFunctions/sendSupportRequest',
      fetchClimateOnline: 'deviceFunctions/fetchClimateOnline',
    }),
    onModelImageError(e) {
      e.target.src = img;
    },
    onButtonClick() {
      this.isButtonLoading = true;
      this.sendSupportRequest(
        {
          deviceId: this.panelDeviceId,
          onClose: () => {
            this.isButtonLoading = false;
          },
        },
      )
        .then(() => this.fetchClimateOnline(
          {
            deviceId: this.panelDeviceId,
            onClose: () => {
              this.$modal.hide('climateOnlineModal');
            },
          },
        ))
        .finally(() => { this.isButtonLoading = false; });
    },
  },
};
