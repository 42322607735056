
import { mapActions, mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import CloseIcon from '../../assets/icons/24x24/close.svg';
import SettingIcon from '../../assets/icons/24x24/Settings.svg';
import CardRoom from '../Cards/CardRoom.vue';
import DashboardGroupContextMenu from '../contextMenu/dashboardGroupContextMenu.vue';
import DashboardPlaceContextMenu from '../contextMenu/dashboardPlaceContextMenu.vue';
import AppButton from '../Buttons/AppButton.vue';

export default {
  name: 'ObjectsPanel',
  components: {
    AppButton,
    DashboardPlaceContextMenu,
    DashboardGroupContextMenu,
    CardRoom,
    CloseIcon,
    SettingIcon,
  },
  mixins: [clickaway],
  data: () => ({
    openedDropDown: false,
    offAll: true,
    countShowPlaces: 10,
    SHOW_BY: 6,
  }),
  computed: {
    ...mapGetters({
      dndActive: 'system/getDndActive',
      buildingSelectDashboard: 'buildingDashboard/getBuildingSelectDashboard',
      panelGroupId: 'system/getPanelGroupId',
      panelActiveFromGroup: 'system/getPanelActiveFromGroup',
      getTranslations: 'localization/getTranslations',
    }),
    currentGroup() {
      return this.buildingSelectDashboard.groups.find((item) => item && item.id === this.panelGroupId);
    },
    currentGroupPlaces() {
      return this.currentGroup?.places ? this.currentGroup.places : [];
    },
    placesToShow() {
      return this.currentGroupPlaces.slice(0, this.countShowPlaces);
    },
    showMorePlacesAmount() {
      let amount = 0;
      const leftToShow = this.currentGroupPlaces.length - this.countShowPlaces;
      if (leftToShow >= this.SHOW_BY) {
        amount = this.SHOW_BY;
      } else if (leftToShow > 0) {
        amount = leftToShow;
      }
      return amount;
    },
  },
  methods: {
    ...mapActions({
      setObjectPanel: 'system/setObjectPanel',
      setPanelGroup: 'system/setPanelGroup',
      changeGroup: 'buildingDashboard/changeGroup',
    }),
    closePanel() {
      this.setObjectPanel(false);
    },
    closePanelOnBg(e) {
      const path = e.path || e.composedPath();
      if (path && path[0] && path[0].classList && path[0].classList.contains('object-panel__bg')) {
        this.closePanel();
      }
    },
    cardDragStart(e, id) {
      e.dataTransfer.setData('Object', JSON.stringify(this.currentGroup));
      e.dataTransfer.setData('Number', id);
    },
    async drop(e) {
      if (Number(e.dataTransfer.getData('Text'))) {
        await this.changeGroup({
          title: this.currentGroup.title,
          places: [...this.currentGroupPlaces.map((item) => item.id), Number(e.dataTransfer.getData('Text'))],
          groupId: this.currentGroup.id,
        });
      }
    },
    dragover(e) {
      if (this.$route.path !== '/dashboard') {
        e.preventDefault();
      }
    },
  },
};
