
import { mask } from 'vue-the-mask';
import { mapGetters } from 'vuex';

export default {
  name: 'TimerInput',
  directives: { mask },
  props: {
    value: {
      type: String,
      default: '00:00',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
      default: '##:##',
    },
    custom: {
      type: Boolean,
      default: true,
    },
    disabledInput: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    customMask: {
      mask: '23:5#',
      tokens: {
        '#': {
          pattern: /\d/,
        },
        2: {
          pattern: /[0-2]/,
        },
        3: {
          pattern: /[0-3]/,
        },
        5: {
          pattern: /[0-5]/,
        },
      },
    },
    focused: false,
    //disabledValue: this.getTranslations['Включено'] || 'Включено', // иначе при переключении девайсов исчезает value
  }),
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
    disabledValue() {
      return this.getTranslations['Включено'] || 'Включено';
    },
  },
  methods: {
    async changeTime(e) {
      this.$emit('timeInput', e.target.value);
      if (e.target.value.length === 5) {
        await this.$nextTick();
        if (this.$refs && this.$refs.input) {
          this.$refs.input.value = e.target.value;
        }
      }
    },
  },
};
