
import { debounce } from '@/utils/helpers';

export default {
  name: 'PowerInputRange',
  props: {
    steps: {
      type: Number,
      default: 5,
    },
    minRange: {
      type: Number,
      default: 0,
    },
    value: {
      type: [Number, String],
      default: 1,
    },
    isOn: {
      type: Boolean,
      default: false,
    },
    controllable: {
      type: Boolean,
      default: false,
    },
    blocked: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    faultWidth: 10,
  }),
  watch: {
    value: {
      handler(value) {
        this.calculateFaultWidth(value);
      },
    },
  },
  mounted() {
    this.calculateFaultWidth(this.value);
  },
  methods: {
    calculateFaultWidth(value) {
      if (value == 3 && this.steps === 3) {
        this.faultWidth = 12;
      } else if (value == 1 && this.steps === 3) {
        this.faultWidth = 4;
      } else {
        this.faultWidth = 10;
      }
    },
    changeValue(event) {
      // if (event.target.value < 1) return;
      this.calculateFaultWidth(event.target.value);
      this.$emit('powerChange', event.target.value);
    },

    debouncedChangeValue: debounce(function (event) {
      //if (event.target.value < 1) return;
      this.$emit('powerChangeApply');
    }, 700),
  },
};
