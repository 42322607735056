
import { mapActions, mapGetters } from 'vuex';
import CloseIcon from '../../assets/icons/24x24/close.svg';
import modalEsc from '../../mixins/modalEsc';

export default {
  name: 'AdditionalModeModal',
  components: {
    CloseIcon,
  },
  mixins: [modalEsc],
  data: () => ({
    modalName: 'additionalModelTip',
  }),
  computed: {
    ...mapGetters({
      icon: 'modalAdditionalMode/getIcon',
      title: 'modalAdditionalMode/getTitle',
      text: 'modalAdditionalMode/getText',
    }),
  },
  methods: {
    ...mapActions({
      toggleModal: 'system/setShowModal',
      setModalData: 'modalAdditionalMode/setModalData',
    }),
    modalOpened() {
      this.toggleModal(true);
      document.addEventListener('keydown', this.clickHandler);
    },
    modalClosed() {
      this.setModalData({
        icon: '',
        title: '',
        text: '',
      });
      this.toggleModal(false);
      document.removeEventListener('keydown', this.clickHandler);
    },
  },
};
