
import { mapGetters } from 'vuex';

export default {
  name: 'AppButton',
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    text: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    link: {
      type: [String, null],
      default: '',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    })
  },
  methods: {
    onClickHandler(e) {
      if (!this.link) {
        e.preventDefault();
        this.$emit('buttonClick');
      }
    },
  },
};
