
import { mapActions, mapGetters } from 'vuex';
import AppButton from '../Buttons/AppButton';
import AppInput from '../Inputs/AppInput';
import CheckMark from '../checks/CheckMark';
import modalEsc from '../../mixins/modalEsc';

export default {
  name: 'FastCommandModal',
  components: { CheckMark, AppInput, AppButton },
  mixins: [modalEsc],
  data: () => ({
    modalName: 'fastCommandModal',
    title: '',
    pinnedOnDash: true,
  }),
  computed: {
    ...mapGetters({
      loading: 'addPreset/getLoading',
      getTranslations: 'localization/getTranslations',
    }),
  },
  methods: {
    changeInput(e) {
      this.value = e.target.value;
    },
    ...mapActions({
      toggleModal: 'system/setShowModal',
      createCommand: 'addPreset/createCommand',
    }),
    async submitCommand() {
      this.$store.commit('addPreset/setTitle', this.title);
      this.$store.commit('addPreset/setPinned', this.pinnedOnDash);
      await this.createCommand();
      this.$modal.hide(this.modalName);
      this.$modal.hide('addCommandModal');
    },
    openModal() {
      document.addEventListener('keydown', this.clickHandler);
    },
    closed() {
      document.removeEventListener('keydown', this.clickHandler);
      this.toggleModal(false);
      this.title = '';
      this.pinnedOnDash = true;
      this.$store.commit('addPreset/setTitle', this.title);
      this.$store.commit('addPreset/setPinned', this.pinnedOnDash);
      this.$store.commit('addPreset/setLoading', false);
    },
  },
};
