
import { mapGetters, mapActions } from "vuex";
import CloseIcon from "../../assets/icons/24x24/close.svg";
import CardPayment from "../Cards/CardPayment.vue";
import CardStep from "../Cards/CardStep.vue";
import CardProgress from "../Cards/CardProgress.vue";
import { pages2 as pages } from "../../mock/tarification/payment-session";
import { basket } from "../../mock/tarification/payment-session";
import ArrowLeftIcon from "../../assets/icons/Arrows/arrowLeft.svg";
import { debounce } from "../../utils/helpers";
import PaymentCardsSlider from "../slider/PaymentCardsSlider.vue";
import BasketItem from "../BasketItem.vue";
import { numberWithSpaces } from "../../utils/helpers";

export default {
  name: "PaymentModal",
  components: {
    CloseIcon,
    CardPayment,
    CardStep,
    CardProgress,
    ArrowLeftIcon,
    PaymentCardsSlider,
    BasketItem,
  },
  data: () => ({
    modalName: "paymentModal",
    pages: null,
    currentPage: 0,
    preloading: false,
    currentServices: [],
    windowWidth: null,
    isOrderPending: false,
  }),
  computed: {
    ...mapGetters({
      getTranslations: "localization/getTranslations",
      getSessions: "payment/getSessions",
      getServices: "payment/getServices",
      getBasket: "payment/getBasket",
      getDeviceInfo: "deviceFunctions/getDeviceInfo",
    }),
    // pages() {
    //   return this.getSessions.pages;
    // },
    // width() {
    //   const width = [];

    //   if (!this.getBasket || !this.getBasket.content) return width;

    //   console.log(this.getBasket);
    //   const items = this.$refs.basketItems;
    //   console.log('###items', this.$refs);
    //   const spans = items.querySelectorAll(".payment-cart__item-title span");

    //   spans.forEach((item, idx) => {
    //     console.log("###item", item.offsetWidth);
    //     width.push(`${item.offsetWidth + 2}px`);
    //   });

    //   return width;
    // },
    basket() {
      return basket;
    },
    basketButtonStyle() {
      if (this.getBasket && this.getBasket.title) {
        return {
          ...this.$textStyle(this.getBasket.button.title),
          ...this.$backgroundStyle(this.getBasket.button),
        };
      }

      return null;
    },
    isLoggedIn() {
      return this.$auth.loggedIn;
    },
    // pages[currentPage]() {
    //   return this.pages[this.currentPage];
    // },
  },
  watch: {
    getSessions() {
      if (this.getSessions) {
        const progressViews = this.getSessions.pages[
          this.currentPage
        ].content.filter((item) => {
          return item.content.progressView;
        });

        this.currentServices.push(
          ...progressViews.map((item) => item.content.progressView.serviceId)
        );
      }
    },
  },
  // mounted() {
  //   console.log("#####mounted");
  //   if (!this.getDeviceInfo) {
  //     this.$modal.hide(this.modalName);
  //   }
  // },
  // beforeMount() {
  //   if (!this.getDeviceInfo) {
  //     this.$modal.hide(this.modalName);
  //   }
  // },
  mounted() {
    this.windowWidth = document.body.clientWidth;
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    ...mapActions({
      fetchBasket: "payment/fetchBasket",
      sendOrder: "payment/sendOrder",
    }),
    onResize() {
      this.windowWidth = document.body.clientWidth;
    },
    getBasketTotalPrice(price) {
      return numberWithSpaces(price);
    },
    onCardButtonClick(item) {
      if (item.button.behaviour === "SELECT_AND_NEXT") {
        if (!this.getServices || !this.getServices.find(service => service.id === item.card.serviceId)) {
          this.$store.commit("payment/ADD_SERVICE_ITEM", {
            id: item.card.serviceId,
            quantity: 1,
          });
        }

        this.currentPage += 1;

        const progressViews = this.getSessions.pages[
          this.currentPage
        ].content.filter((item) => {
          return item.content.progressView;
        });

        this.currentServices.push(item.card.serviceId);

        this.currentServices.push(
          ...progressViews.map((item) => item.content.progressView.serviceId)
        );

        progressViews.forEach((itemProgress) => {
          if (!this.getServices || !this.getServices.find(service => service.id === itemProgress.content.progressView.serviceId)) {
            this.$store.commit("payment/ADD_SERVICE_ITEM", {
              id: itemProgress.content.progressView.serviceId,
              quantity: itemProgress.content.progressView.quantity.current,
            });
          }
        });

        // if (progressViewIdx !== -1) {
        //   this.$store.commit('payment/ADD_SERVICE_ITEM', {
        //     id: this.getSessions.pages[this.currentPage].content[progressViewIdx].content.progressView.serviceId,
        //     quantity: this.getSessions.pages[this.currentPage].content[progressViewIdx].content.progressView.quantity.current,
        //   });
        // }

        //console.log('###service', this.getSessions.pages[this.currentPage].content[progressViewIdx].content.progressView);

        if (this.getSessions.pages[this.currentPage].showBasket === true) {
          const filteredServices = this.getServices.filter(
            (service) => service.quantity > 0
          );

          // if (filteredServices.length > 0) {
          //   this.fetchBasket(
          //     {
          //       payload: {
          //         sessionId: this.getSessions.id,
          //         services: filteredServices,
          //       },
          //       params: {
          //         lang: this.$route.query.lang,
          //       },
          //     },
          //   );
          // } else {
          //   this.$store.commit('payment/SET_BASKET', null);
          // }
          this.isOrderPending = true;

          this.fetchBasket({
            payload: {
              sessionId: this.getSessions.id,
              services: filteredServices,
            },
            params: {
              lang: this.$route.query.lang,
            },
          }).finally(() => {
            this.isOrderPending = false;
          });
        }
      }

      if (item.button.behaviour === "PAY") {
        // this.$store.commit("payment/ADD_SERVICE_ITEM", {
        //   id: item.card.serviceId,
        //   quantity: 1,
        // });

        if (!this.getServices || !this.getServices.find(service => service.id === item.card.serviceId)) {
          this.$store.commit("payment/ADD_SERVICE_ITEM", {
            id: item.card.serviceId,
            quantity: 1,
          });
        }

        this.isOrderPending = true;
        this.sendOrder({
          payload: {
            deviceId: this.$route.query.device_id || null,
            sessionId: this.getSessions.id,
            services: this.getServices.filter((i) => i.quantity > 0),
            conflictResolveData: null,
          },
          params: {
            lang: this.$route.query.lang,
          },
        })
          .catch(() => {
          })
          .finally(() => {
            this.isOrderPending = false;
            this.$store.commit("payment/REMOVE_SERVICE_ITEM");
          });
      }

      if (item.button.behaviour === "NEXT") {
        this.currentPage += 1;

        const progressViews = this.getSessions.pages[
          this.currentPage
        ].content.filter((item) => {
          return item.content.progressView;
        });

        this.currentServices.push(
          ...progressViews.map((item) => item.content.progressView.serviceId)
        );

        // progressViews.forEach((item) => {
        //   this.$store.commit("payment/ADD_SERVICE_ITEM", {
        //     id: item.content.progressView.serviceId,
        //     quantity: item.content.progressView.quantity.current,
        //   });
        // });

        progressViews.forEach((itemProgress) => {
          if (!this.getServices || !this.getServices.find(service => service.id === itemProgress.content.progressView.serviceId)) {
            this.$store.commit("payment/ADD_SERVICE_ITEM", {
              id: itemProgress.content.progressView.serviceId,
              quantity: itemProgress.content.progressView.quantity.current,
            });
          }
        });

        if (this.getSessions.pages[this.currentPage].showBasket === true) {
          const filteredServices = this.getServices.filter(
            (service) => service.quantity > 0
          );

          // if (filteredServices.length > 0) {
          //   this.fetchBasket(
          //     {
          //       payload: {
          //         sessionId: this.getSessions.id,
          //         services: filteredServices,
          //       },
          //       params: {
          //         lang: this.$route.query.lang,
          //       },
          //     },
          //   );
          // } else {
          //   this.$store.commit('payment/SET_BASKET', null);
          // }
          this.isOrderPending = true;
          this.fetchBasket({
            payload: {
              sessionId: this.getSessions.id,
              services: filteredServices,
            },
            params: {
              lang: this.$route.query.lang,
            },
          }).finally(() => {
            this.isOrderPending = false;
          });
        }
      }
    },
    onProgressChange(card) {
      return (value) => {
        // console.log('####value', value);
        // console.log('#####progressCard', card);
        this.$store.commit("payment/CHANGE_SERVICE_QUANTITY", {
          id: card.serviceId,
          quantity: value,
        });

        if (this.getSessions.pages[this.currentPage].showBasket === true) {
          const filteredServices = this.getServices.filter(
            (service) => service.quantity > 0
          );

          // if (filteredServices.length > 0) {
          //   this.fetchBasket(
          //     {
          //       payload: {
          //         sessionId: this.getSessions.id,
          //         services: filteredServices,
          //       },
          //       params: {
          //         lang: this.$route.query.lang,
          //       },
          //     },
          //   );
          // } else {
          //   this.$store.commit('payment/SET_BASKET', null);
          // }
          // this.isOrderPending = true;
          this.fetchBasket({
            payload: {
              sessionId: this.getSessions.id,
              services: filteredServices,
            },
            params: {
              lang: this.$route.query.lang,
            },
          });
          // .finally(() => { this.isOrderPending = false; });
        }
      };
    },
    onProgressInput(card) {
      return debounce((value) => {
        this.$store.commit("payment/CHANGE_SERVICE_QUANTITY", {
          id: card.serviceId,
          quantity: value,
        });

        if (this.getSessions.pages[this.currentPage].showBasket === true) {
          const filteredServices = this.getServices.filter(
            (service) => service.quantity > 0
          );

          // if (filteredServices.length > 0) {
          //   this.fetchBasket(
          //     {
          //       payload: {
          //         sessionId: this.getSessions.id,
          //         services: filteredServices,
          //       },
          //       params: {
          //         lang: this.$route.query.lang,
          //       },
          //     },
          //   );
          // } else {
          //   this.$store.commit('payment/SET_BASKET', null);
          // }
          this.isOrderPending = true;
          this.fetchBasket({
            payload: {
              sessionId: this.getSessions.id,
              services: filteredServices,
            },
            params: {
              lang: this.$route.query.lang,
            },
          }).finally(() => {
            this.isOrderPending = false;
          });
        }
      }, 500);
    },
    prepareData() {
      // this.pages = this.getSessions.pages;
      // this.preloading = true;
      // this.fetchSessions().then((response) => {
      //   this.$store.commit('payment/SET_SESSION', response.data.data);
      // })
      //   .catch((err) => {
      //     console.log(err);
      //   })
      //   .finally(() => {
      //     this.preloading = false;
      //   });
    },
    onCloseModal() {
      this.pages = null;
      this.$store.commit("payment/RESET_STATE");
      this.currentPage = 0;
      this.currentServices = [];
      this.$router.push("/dashboard");
    },
    onBackButtonClick() {
      this.currentPage -= 1;
      // this.$store.commit('payment/REMOVE_SERVICE_ITEM');

      this.$store.commit("payment/REMOVE_SERVICE_ITEMS", this.currentServices);

      this.currentServices = [];

      const progressViews = this.getSessions.pages[
        this.currentPage
      ].content.filter((item) => {
        return item.content.progressView;
      });

      this.currentServices.push(
        ...progressViews.map((item) => item.content.progressView.serviceId)
      );

      // progressViews.forEach((item) => {
      //   this.$store.commit("payment/ADD_SERVICE_ITEM", {
      //     id: item.content.progressView.serviceId,
      //     quantity: item.content.progressView.quantity.current,
      //   });
      // });

      progressViews.forEach((itemProgress) => {
        if (!this.getServices || !this.getServices.find(service => service.id === itemProgress.content.progressView.serviceId)) {
          this.$store.commit("payment/ADD_SERVICE_ITEM", {
            id: itemProgress.content.progressView.serviceId,
            quantity: itemProgress.content.progressView.quantity.current,
          });
        }
      });

      if (this.getSessions.pages[this.currentPage].showBasket === true) {
        const filteredServices = this.getServices.filter(
          (service) => service.quantity > 0
        );

        // if (filteredServices.length > 0) {
        //   this.fetchBasket(
        //     {
        //       payload: {
        //         sessionId: this.getSessions.id,
        //         services: filteredServices,
        //       },
        //       params: {
        //         lang: this.$route.query.lang,
        //       },
        //     },
        //   );
        // } else {
        //   this.$store.commit('payment/SET_BASKET', null);
        // }
        this.isOrderPending = true;
        this.fetchBasket({
          payload: {
            sessionId: this.getSessions.id,
            services: filteredServices,
          },
          params: {
            lang: this.$route.query.lang,
          },
        }).finally(() => {
          this.isOrderPending = false;
        });
      }
    },
    makeOrder() {
      this.isOrderPending = true;

      this.sendOrder({
        payload: {
          deviceId: this.$route.query.device_id || null,
          sessionId: this.getSessions.id,
          services: this.getServices.filter((i) => i.quantity > 0),
          conflictResolveData: null,
        },
        params: {
          lang: this.$route.query.lang,
        },
      }).finally(() => {
        this.isOrderPending = false;
      });
    },
    onCardClick(e, card) {
      if (!card.clickable) return;

      if (card.behaviour === "SELECT_AND_NEXT") {
        // this.$store.commit("payment/ADD_SERVICE_ITEM", {
        //   id: card.serviceId,
        //   quantity: 1,
        // });

        if (!this.getServices || !this.getServices.find(service => service.id === card.serviceId)) {
          this.$store.commit("payment/ADD_SERVICE_ITEM", {
            id: card.serviceId,
            quantity: 1,
          });
        }

        this.currentPage += 1;

        const progressViews = this.getSessions.pages[
          this.currentPage
        ].content.filter((item) => {
          return item.content.progressView;
        });

        this.currentServices.push(card.serviceId);

        this.currentServices.push(
          ...progressViews.map((item) => item.content.progressView.serviceId)
        );

        // progressViews.forEach((item) => {
        //   this.$store.commit("payment/ADD_SERVICE_ITEM", {
        //     id: item.content.progressView.serviceId,
        //     quantity: item.content.progressView.quantity.current,
        //   });
        // });

        progressViews.forEach((itemProgress) => {
          if (!this.getServices || !this.getServices.find(service => service.id === itemProgress.content.progressView.serviceId)) {
            this.$store.commit("payment/ADD_SERVICE_ITEM", {
              id: itemProgress.content.progressView.serviceId,
              quantity: itemProgress.content.progressView.quantity.current,
            });
          }
        });

        // if (progressViewIdx !== -1) {
        //   this.$store.commit('payment/ADD_SERVICE_ITEM', {
        //     id: this.getSessions.pages[this.currentPage].content[progressViewIdx].content.progressView.serviceId,
        //     quantity: this.getSessions.pages[this.currentPage].content[progressViewIdx].content.progressView.quantity.current,
        //   });
        // }

        //console.log('###service', this.getSessions.pages[this.currentPage].content[progressViewIdx].content.progressView);

        if (this.getSessions.pages[this.currentPage].showBasket === true) {
          const filteredServices = this.getServices.filter(
            (service) => service.quantity > 0
          );

          // if (filteredServices.length > 0) {
          //   this.fetchBasket(
          //     {
          //       payload: {
          //         sessionId: this.getSessions.id,
          //         services: filteredServices,
          //       },
          //       params: {
          //         lang: this.$route.query.lang,
          //       },
          //     },
          //   );
          // } else {
          //   this.$store.commit('payment/SET_BASKET', null);
          // }
          this.isOrderPending = true;
          this.fetchBasket({
            payload: {
              sessionId: this.getSessions.id,
              services: filteredServices,
            },
            params: {
              lang: this.$route.query.lang,
            },
          }).finally(() => {
            this.isOrderPending = false;
          });
        }
      }

      if (card.behaviour === "PAY") {
        // this.$store.commit("payment/ADD_SERVICE_ITEM", {
        //   id: card.serviceId,
        //   quantity: 1,
        // });

        if (!this.getServices || !this.getServices.find(service => service.id === card.serviceId)) {
          this.$store.commit("payment/ADD_SERVICE_ITEM", {
            id: card.serviceId,
            quantity: 1,
          });
        }

        this.isOrderPending = true;
        this.sendOrder({
          payload: {
            deviceId: this.$route.query.device_id || null,
            sessionId: this.getSessions.id,
            services: this.getServices.filter((i) => i.quantity > 0),
            conflictResolveData: null,
          },
          params: {
            lang: this.$route.query.lang,
          },
        })
          .catch(() => {
          })
          .finally(() => {
            this.isOrderPending = false;
            this.$store.commit("payment/REMOVE_SERVICE_ITEM");
          });
      }

      if (card.behaviour === "NEXT") {
        this.currentPage += 1;

        const progressViews = this.getSessions.pages[
          this.currentPage
        ].content.filter((item) => {
          return item.content.progressView;
        });

        this.currentServices.push(
          ...progressViews.map((item) => item.content.progressView.serviceId)
        );

        // progressViews.forEach((item) => {
        //   this.$store.commit("payment/ADD_SERVICE_ITEM", {
        //     id: item.content.progressView.serviceId,
        //     quantity: item.content.progressView.quantity.current,
        //   });
        // });

        progressViews.forEach((itemProgress) => {
          if (!this.getServices || !this.getServices.find(service => service.id === itemProgress.content.progressView.serviceId)) {
            this.$store.commit("payment/ADD_SERVICE_ITEM", {
              id: itemProgress.content.progressView.serviceId,
              quantity: itemProgress.content.progressView.quantity.current,
            });
          }
        });

        if (this.getSessions.pages[this.currentPage].showBasket === true) {
          const filteredServices = this.getServices.filter(
            (service) => service.quantity > 0
          );

          // if (filteredServices.length > 0) {
          //   this.fetchBasket(
          //     {
          //       payload: {
          //         sessionId: this.getSessions.id,
          //         services: filteredServices,
          //       },
          //       params: {
          //         lang: this.$route.query.lang,
          //       },
          //     },
          //   );
          // } else {
          //   this.$store.commit('payment/SET_BASKET', null);
          // }
          this.isOrderPending = true;
          this.fetchBasket({
            payload: {
              sessionId: this.getSessions.id,
              services: filteredServices,
            },
            params: {
              lang: this.$route.query.lang,
            },
          }).finally(() => {
            this.isOrderPending = false;
          });
        }
      }
    },
  },
};
