
// eslint-disable-next-line import/no-extraneous-dependencies
import { mapActions, mapGetters } from "vuex";
import {
  endTime,
  pultStateToFunctionConverter,
  startTime,
} from "~/utils/helpers";
import AddFastCommandControlPanel from "./AddFastCommandControlPanel.vue";
import SettingButton from "../Buttons/SettingButton.vue";
import TimePreview from "../TimePreview.vue";
import TimerInput from "../Inputs/TimerInput.vue";
import ArrowRight from "../../assets/icons/24x24/arrowRight.svg";
import TrashIcon from "../../assets/icons/24x24/trash.svg";
import CloseIcon from "../../assets/icons/24x24/close.svg";

export default {
  name: "SchedulePanel",
  components: {
    TimerInput,
    TimePreview,
    SettingButton,
    AddFastCommandControlPanel,
    ArrowRight,
    TrashIcon,
    CloseIcon,
  },
  emits: ["close"],
  data: () => ({
    currentPult: null,
    optionsConfigurate: [],
    loading: false,
  }),
  computed: {
    ...mapGetters({
      activePlaceIndex: "schedules/getActivePlaceIndex",
      places: "schedules/getPlacesInSchedule",
      currentTimeIndex: "schedules/getActiveTimeIndex",
      currentPeriodIndex: "schedules/getActivePeriodIndex",
      panelStep: "schedules/getPanelStep",
      getTranslations: 'localization/getTranslations',
    }),
    weekDayName() {
      return ["", this.getTranslations['Пн'], this.getTranslations['Вт'], this.getTranslations['Ср'], this.getTranslations['Чт'], this.getTranslations['Пт'], this.getTranslations['Сб'], this.getTranslations['Вс']]
    },
    firstInputStart() {
      return this.startTimeTemplate(
        this.currentPeriod.times[this.currentTimeIndex].start
      );
    },
    secondInputStart() {
      return this.endTimeTemplate(
        this.currentPeriod.times[this.currentTimeIndex + 1]
      );
    },
    place() {
      return this.places[this.activePlaceIndex];
    },
    currentPeriod() {
      return this.place.periods[this.currentPeriodIndex];
    },
    typeOfSetting() {
      const { deviceState } = this.currentPeriod.times[
        this.currentTimeIndex
      ].action;
      if (
        this.currentPeriod.times[this.currentTimeIndex].action.presetId ||
        (this.currentPeriod.times[this.currentTimeIndex].action.presetInfo &&
          this.currentPeriod.times[this.currentTimeIndex].action.presetInfo.id)
      ) {
        return "preset";
      }
      if (!deviceState.isOn && deviceState.functions) {
        return "off";
      }
      if (deviceState.pultCache) {
        return "custom";
      }
      if (
        deviceState.isOn &&
        deviceState.functions &&
        deviceState.functions.length
      ) {
        return "current";
      }
      return false;
    },
  },
  watch: {
    place: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.commit("schedules/setPanelStep", 1);
          this.$store.commit("schedules/setActivePeriodIndex", 0);
          this.$store.commit("schedules/setActiveTimeIndex", 0);
          this.currentPult = [];
        }
      },
    },
    panelStep: {
      async handler(newValue) {
        if (newValue === 2) {
          this.loading = true;
          this.optionsConfigurate = await this.fetchConfigurationOptions(
            this.place.placeId
          );
          this.loading = false;
        }
      },
    },
  },
  methods: {
    ...mapActions({
      fetchConfigurationOptions: "schedules/fetchConfigurationOptions",
      fetchPult: "schedules/fetchPult",
      fetchPultPost: "schedules/fetchPultPost",
    }),
    deleteIntervalTime() {
      this.$store.commit("schedules/setPanelStep", 1);
      this.$store.commit("schedules/setDeleteTime", {
        periodIndex: this.currentPeriodIndex,
        timeIndex: this.currentTimeIndex,
      });
      this.$store.commit("schedules/setActiveTimeIndex", 0);
    },
    parseInputToMinutes(input, typeInput) {
      let minutesCount = 0;
      const hours =
        parseInt(input.split(":")[0]) <= 23
          ? parseInt(input.split(":")[0]) * 60
          : 23 * 60;
      minutesCount += hours;
      const minutes =
        parseInt(input.split(":")[1]) <= 59
          ? parseInt(input.split(":")[1])
          : 59;
      minutesCount += minutes;
      if (
        typeInput === "first" &&
        this.currentPeriod.times[this.currentTimeIndex]
      ) {
        return (
          minutesCount - this.currentPeriod.times[this.currentTimeIndex].start
        );
      }
      if (typeInput === "second") {
        if (this.currentPeriod.times[this.currentTimeIndex + 1]) {
          return (
            minutesCount -
            this.currentPeriod.times[this.currentTimeIndex + 1].start
          );
        }
        return 1439 - minutesCount;
      }
      return 0;
    },
    async firstTimeInput(input) {
      if (input.length === 5) {
        const distance = this.parseInputToMinutes(input, "first");
        const nextTime =
          (this.currentPeriod.times[this.currentTimeIndex + 1] &&
            this.currentPeriod.times[this.currentTimeIndex + 1].start) ||
          1439;
        const lessThenNext =
          nextTime >
          this.currentPeriod.times[this.currentTimeIndex].start + distance;
        if (distance !== 0 && lessThenNext) {
          this.$store.commit("schedules/setNewIntervalWidthForce", {
            placeId: this.place.placeId,
            index: this.currentTimeIndex,
            distance,
            periodIndex: this.currentPeriodIndex,
            minutes: true,
            type: "start",
          });
          const firstTimeNotExist =
            this.currentPeriod.times.findIndex((item) => item.start === 0) ===
            -1;
          if (this.currentTimeIndex === 0 && firstTimeNotExist) {
            this.$store.commit("schedules/addFirstStartTime", {
              periodIndex: this.currentPeriodIndex,
              placeId: this.place.placeId,
            });
            this.$store.commit("schedules/setActiveTimeIndex", 1);
          }
        }
      }
    },
    secondTimeInput(input) {
      if (input.length === 5) {
        const addLastTime =
          this.currentTimeIndex === this.currentPeriod.times.length - 1;
        const distance = this.parseInputToMinutes(input, "second");
        const newTime =
          (this.currentPeriod.times[this.currentTimeIndex + 1] &&
            this.currentPeriod.times[this.currentTimeIndex + 1].start) ||
          1439;
        const sameTime =
          this.currentPeriod.times[this.currentTimeIndex].start ===
          newTime - distance;
        if (distance !== 0 && !sameTime) {
          this.$store.commit("schedules/setNewIntervalWidthForce", {
            placeId: this.place.placeId,
            index: this.currentTimeIndex + 1,
            distance,
            periodIndex: this.currentPeriodIndex,
            minutes: true,
            type: "end",
          });
          if (addLastTime) {
            this.$store.commit("schedules/addLastTime", {
              start: 1439 - distance,
              periodIndex: this.currentPeriodIndex,
              placeId: this.place.placeId,
            });
          }
        }
      }
    },
    goBack() {
      this.$store.commit("schedules/setPanelStep", this.panelStep - 1);
    },
    async changePult(data) {
      const configuration = { ...data, pult: this.currentPult.pult };
      this.currentPult = await this.fetchPultPost({
        id: this.place.placeId,
        configuration,
      });
      this.$store.commit("schedules/setDeviceState", {
        periodIndex: this.currentPeriodIndex,
        timeIndex: this.currentTimeIndex,
        info: this.currentPult.state.info,
        isOn: this.currentPult.state.isOn,
        isCustom: true,
        functions: pultStateToFunctionConverter(this.currentPult.pult),
        pultCache: this.currentPult,
      });
    },
    async customClick() {
      const { pultCache } = this.currentPeriod.times[
        this.currentTimeIndex
      ].action.deviceState;
      this.currentPult =
        pultCache || (await this.fetchPult(this.place.placeId));
      this.$store.commit("schedules/setPanelStep", 3);
    },
    choicePreset(preset) {
      this.$store.commit("schedules/setDeviceState", {
        periodIndex: this.currentPeriodIndex,
        timeIndex: this.currentTimeIndex,
        presetId: preset.presetOption.id,
        info: preset.presetOption.deviceState.info,
      });
    },
    currentClick() {
      const options = this.optionsConfigurate[0].options[2].deviceOption;
      this.$store.commit("schedules/setDeviceState", {
        periodIndex: this.currentPeriodIndex,
        timeIndex: this.currentTimeIndex,
        isOn: options.state.isOn,
        functions: options.functions,
        info: options.state.info,
      });
    },
    offClick() {
      this.$store.commit("schedules/setDeviceState", {
        periodIndex: this.currentPeriodIndex,
        timeIndex: this.currentTimeIndex,
        isOn: false,
        functions: [],
      });
    },
    tabClickHandler(newPeriodIndex) {
      this.$store.commit("schedules/setActivePeriodIndex", newPeriodIndex);
    },
    async configurateTime(timeIndex) {
      const options = await this.fetchConfigurationOptions(this.place.placeId);
      this.optionsConfigurate = options;
      this.$store.commit("schedules/setActiveTimeIndex", timeIndex);
      this.$store.commit("schedules/setPanelStep", 2);
    },
    startTimeTemplate(minutes) {
      return startTime(minutes);
    },
    endTimeTemplate(end) {
      return endTime(this.startTimeTemplate, end);
    },
  },
};
