import { arrayDifference } from "@/utils/helpers";
import { errorHandler, errorServiceHandler } from "@/utils/api";

const minutesInDay = 1439;

export const state = () => ({
  placesInSchedule: [],
  activePlaceIndex: 0,
  activeTimeIndex: 0,
  activePeriodIndex: 0,
  panelStep: 1,
  infoForEditedSchedule: null,
  typeOfModal: null,
  modalStep: 1,
  previousValueBeforeCopy: null,
  copyRequestStore: null,
});

export const getters = {
  getPlacesInSchedule: ({ placesInSchedule }) => placesInSchedule,
  getActivePlaceIndex: ({ activePlaceIndex }) => activePlaceIndex,
  getActiveTimeIndex: ({ activeTimeIndex }) => activeTimeIndex,
  getActivePeriodIndex: ({ activePeriodIndex }) => activePeriodIndex,
  getPanelStep: ({ panelStep }) => panelStep,
  getInfoForEditedSchedule: ({ infoForEditedSchedule }) =>
    infoForEditedSchedule,
  getTypeOfModal: ({ typeOfModal }) => typeOfModal,
  getModalStep: ({ modalStep }) => modalStep,
  getCopyRequestStore: ({ copyRequestStore }) => copyRequestStore,
};

export const mutations = {
  setCopyData(store, info) {
    store.copyRequestStore = info;
  },
  returnBackValue(store, data) {
    const prevValue = store.previousValueBeforeCopy;
    store.placesInSchedule = store.placesInSchedule.map((place) => {
      if (place.placeId === prevValue.placeId) {
        place.periods[prevValue.periodIndex].times = prevValue.times;
      }
      return place;
    });
    store.previousValueBeforeCopy = null;
  },
  copyDataToSchedule(store, data) {
    store.placesInSchedule = store.placesInSchedule.map((place) => {
      if (place.placeId === data.placeId) {
        store.previousValueBeforeCopy = {
          times: place.periods[data.pastePeriodIndex].times,
          placeId: data.placeId,
          periodIndex: data.pastePeriodIndex,
        };
        place.periods[data.pastePeriodIndex].times = data.times;
      }
      return place;
    });
  },
  setInfoForEditedSchedule(store, info) {
    store.infoForEditedSchedule = info;
  },
  filterSmallTimes(store, info) {
    store.placesInSchedule = store.placesInSchedule.map((place) => {
      if (place.placeId === info.placeId) {
        const prevLength = place.periods[info.periodIndex].times.length;

        const { times } = place.periods[info.periodIndex];
        place.periods[info.periodIndex].times = times.filter(
          (time, timeIndex) =>
            time.start + 10 / info.scale <
            ((times[timeIndex + 1] && times[timeIndex + 1].start) ||
              minutesInDay)
        );

        place.periods[info.periodIndex].times = place.periods[
          info.periodIndex
        ].times.filter((time, timeIndex, arr) => {
          const nextTime = arr[timeIndex + 1];
          if (nextTime) {
            return time.start !== nextTime.start;
          }
          return true;
        });

        if (place.periods[info.periodIndex].times.length < prevLength) {
          store.activeTimeIndex = 0;
        }
        place.periods[info.periodIndex].times[0].start = 0;
      }
      return place;
    });
  },
  setDeleteTime(store, info) {
    store.placesInSchedule = store.placesInSchedule.map((place, placeIndex) => {
      if (placeIndex === store.activePlaceIndex) {
        place.periods = place.periods.map((period, periodIndex) => {
          if (periodIndex === info.periodIndex) {
            period.times = period.times.filter(
              (time, timeIndex) => timeIndex !== info.timeIndex
            );
            period.times[0].start = 0;
          }
          return period;
        });
      }
      return place;
    });
  },
  setValidSchedulePlaces(store, places) {
    const validPlaces = places.map((place) => {
      const newPlace = {};
      newPlace.placeId = place.placeId;
      newPlace.title = place.placeTitle;
      newPlace.periods = place.periods.map((period) => {
        const newPeriod = {};
        newPeriod.times = period.times.map((time) => {
          const newTime = {};
          newTime.id = Math.random();
          newTime.start = time.start;
          newTime.action = {
            deviceState: {
              isOn: time.action.deviceState.isOn,
              info: time.action.deviceState.info,
            },
            presetId: null,
          };
          if (time.action.presetInfo) {
            newTime.action.presetId = time.action.presetInfo.id;
            newTime.action.deviceState.functions = [];
          }
          if (time.action.functions) {
            newTime.action.deviceState.functions = time.action.functions;
          }
          if (!time.action.deviceState.isOn) {
            newTime.action.deviceState.functions = [];
          }
          return newTime;
        });
        newPeriod.weekDays = period.weekDays;
        return newPeriod;
      });
      return newPlace;
    });
    store.placesInSchedule = validPlaces;
  },
  setTypeOfModal(store, type) {
    store.typeOfModal = type;
  },
  setModalStep(store, step) {
    store.modalStep = step;
  },
  resetAll(store) {
    store.panelStep = 1;
    store.activePlaceIndex = 0;
    store.activePeriodIndex = 0;
    store.activeTimeIndex = 0;
    store.placesInSchedule = [];
    store.infoForEditedSchedule = null;
    store.typeOfModal = null;
    store.copyRequestStore = null;
  },
  setPanelStep(store, step) {
    store.panelStep = step;
  },
  setActivePlaceIndex(store, index) {
    store.activePlaceIndex = index;
  },
  setActivePeriodIndex(store, index) {
    store.activePeriodIndex = index;
  },
  setActiveTimeIndex(store, index) {
    store.activeTimeIndex = index;
  },
  addFirstTime(store, info) {
    store.placesInSchedule = store.placesInSchedule.map((place) => {
      if (place.id === info.placeId) {
        place.periods[info.periodIndex].times.unshift({
          action: {
            presetId: null,
            deviceState: {
              isOn: false,
              functions: false,
            },
          },
          id: Math.random(),
          start: 0,
        });
        const roundedScaledMinutes = Math.floor(15 / info.scale);
        place.periods[info.periodIndex].times[1].start =
          roundedScaledMinutes > 1 ? roundedScaledMinutes : 2;
      }
      return place;
    });
    store.activeTimeIndex += 1;
  },
  addFirstStartTime(store, info) {
    store.placesInSchedule = store.placesInSchedule.map((place) => {
      if (place.placeId === info.placeId) {
        place.periods[info.periodIndex].times = [
          {
            action: {
              presetId: null,
              deviceState: {
                isOn: false,
                functions: false,
              },
            },
            id: Math.random(),
            start: 0,
          },
          ...place.periods[info.periodIndex].times,
        ];
      }
      return place;
    });
  },
  addLastTime(store, info) {
    store.placesInSchedule = store.placesInSchedule.map((place) => {
      if (place.placeId === info.placeId) {
        place.periods[info.periodIndex].times = [
          ...place.periods[info.periodIndex].times,
          {
            action: {
              presetId: null,
              deviceState: {
                isOn: false,
                functions: false,
              },
            },
            id: Math.random(),
            start: info.start,
          },
        ];
      }
      return place;
    });
  },
  setNewPeriod(store, placeId) {
    store.placesInSchedule = store.placesInSchedule.map((place) => {
      if (place.placeId === placeId) {
        const daysArray = [];
        const referenceDaysArray = [1, 2, 3, 4, 5, 6, 7];
        place.periods.forEach((period) => {
          daysArray.push(...period.weekDays);
        });
        const freeDays = arrayDifference(daysArray, referenceDaysArray);
        if (freeDays.length) {
          place.periods.push({
            weekDays: freeDays,
            times: [
              {
                action: {
                  presetId: null,
                  deviceState: {
                    isOn: false,
                    functions: false,
                  },
                },
                start: 0,
                id: Math.random(),
              },
            ],
          });
        }
      }
      return place;
    });
  },
  toggleChoicePlace(store, place) {
    const placeInList = store.placesInSchedule.find(
      (item) => item.placeId === place.id
    );
    if (placeInList) {
      const placeIndex = store.placesInSchedule.findIndex(
        (item) => item.placeId === place.id
      );
      store.placesInSchedule.splice(placeIndex, 1);
    } else {
      store.placesInSchedule.push({
        title: place.title,
        placeId: place.id,
        periods: [
          {
            weekDays: [1, 2, 3, 4, 5, 6, 7],
            times: [
              {
                action: {
                  presetId: null,
                  deviceState: {
                    isOn: false,
                    functions: false,
                  },
                },
                id: Math.random(),
                start: 0,
              },
            ],
          },
        ],
      });
    }
  },
  setNewTimeInterval(store, info) {
    store.placesInSchedule = store.placesInSchedule.map((place) => {
      if (place.placeId === info.placeId) {
        const { times } = place.periods[info.periodIndex];
        const indexToInsert = times.findIndex((item, index) => {
          const nextItem = times[index + 1];
          if (
            item.start < info.start &&
            (!nextItem || (nextItem && nextItem.start > info.start))
          ) {
            return true;
          }
        });
        const newTime = {
          start: info.start,
          action: {
            presetId: null,
            deviceState: {
              isOn: false,
              functions: false,
            },
          },
          id: Math.random(),
        };
        if (indexToInsert !== -1) {
          place.periods[info.periodIndex].times = [
            ...place.periods[info.periodIndex].times.slice(
              0,
              indexToInsert + 1
            ),
            newTime,
            ...place.periods[info.periodIndex].times.slice(indexToInsert + 1),
          ];
        }
      }
      return place;
    });
  },
  setNewIntervalWidth(store, info) {
    store.placesInSchedule = store.placesInSchedule.map((place) => {
      if (place.placeId === info.placeId) {
        const period = place.periods[info.periodIndex];
        period.times = period.times.map((item, index) => {
          if (index === info.index) {
            const distanceInMinutes = info.minutes
              ? info.distance
              : Math.floor(info.distance * 14.39);
            const nextCol = period.times[index + 2];
            const newStart =
              ((period.times[index + 1] && period.times[index + 1].start) ||
                1420) + distanceInMinutes;
            if (info.distance === -1) {
              if (index === 0) {
                period.times[index + 1].start = 5;
              } else {
                period.times[index + 1].start = period.times[index].start;
              }
            } else if (
              ((nextCol && nextCol.start > newStart) || !nextCol) &&
              newStart <= minutesInDay - 1
            ) {
              if (distanceInMinutes <= 0 && newStart <= 0) {
                period.times[index + 1].start = 0;
              } else {
                period.times[index + 1].start += distanceInMinutes;
              }
            } else if (nextCol && nextCol.start < newStart) {
              period.times[index + 1].start = nextCol.start - 1;
            } else if (newStart >= minutesInDay - 1 && !nextCol) {
              if (distanceInMinutes >= 1438) {
                period.times[index + 1].start = minutesInDay;
              } else {
                period.times[index + 1].start = minutesInDay - 4;
              }
            }
          }
          return item;
        });
        place.periods[info.periodIndex] = period;
      }
      return place;
    });
  },
  setNewIntervalWidthForce(store, info) {
    store.placesInSchedule = store.placesInSchedule.map((place) => {
      if (place.placeId === info.placeId) {
        const period = place.periods[info.periodIndex];
        period.times = period.times.map((time, timeIndex) => {
          if (timeIndex === info.index) {
            time.start += info.distance;
          }
          return time;
        });
        place.periods[info.periodIndex] = period;
      }
      return place;
    });
    if (info.type === "start") {
      store.placesInSchedule = store.placesInSchedule.map((place) => {
        if (place.placeId === info.placeId) {
          const period = place.periods[info.periodIndex];

          const timesFiltered = period.times;
          for (let i = 0; i < timesFiltered.length; ) {
            const nextCol = timesFiltered[i + 1];
            if (nextCol && nextCol.start <= timesFiltered[i].start) {
              timesFiltered.splice(i, 1);
              i = 0;
              store.activeTimeIndex -= 1;
            } else {
              i++;
            }
          }
          period.times = timesFiltered;
          place.periods[info.periodIndex] = period;
        }
        return place;
      });
    } else if (info.type === "end") {
      store.placesInSchedule = store.placesInSchedule.map((place) => {
        if (place.placeId === info.placeId) {
          const period = place.periods[info.periodIndex];

          const timesFiltered = period.times;
          for (let i = 0; i < timesFiltered.length; ) {
            const prevCol = timesFiltered[i - 1];

            if (prevCol && prevCol.start >= timesFiltered[i].start) {
              const prevStart = prevCol.start;
              timesFiltered.splice(i - 1, 1);
              timesFiltered[i - 1].start = prevStart;
              i = 0;
            } else {
              i++;
            }
          }
          period.times = timesFiltered.filter((time) => time.start !== 1439);
          place.periods[info.periodIndex] = period;
        }
        return place;
      });
    }
  },
  toggleDayWeek(store, info) {
    let dayUsedPeriodIndex = null;
    store.placesInSchedule.forEach((place) => {
      if (place.placeId === info.placeId) {
        place.periods.forEach((period, periodIndex) => {
          period.weekDays.forEach((day) => {
            if (day === info.day) {
              dayUsedPeriodIndex = periodIndex;
            }
          });
        });
      }
    });
    if (dayUsedPeriodIndex || dayUsedPeriodIndex === 0) {
      store.placesInSchedule = store.placesInSchedule.map((place) => {
        if (
          place.placeId === info.placeId &&
          dayUsedPeriodIndex === info.periodIndex &&
          place.periods[info.periodIndex].weekDays.length > 1
        ) {
          place.periods[info.periodIndex].weekDays = place.periods[
            info.periodIndex
          ].weekDays.filter((item) => item !== info.day);
        }
        return place;
      });
    } else {
      store.placesInSchedule = store.placesInSchedule.map((place) => {
        if (place.placeId === info.placeId) {
          place.periods[info.periodIndex].weekDays.push(info.day);
        }
        return place;
      });
    }
  },
  deletePeriod(store, info) {
    store.placesInSchedule = store.placesInSchedule.map((place) => {
      if (place.placeId === info.placeId) {
        place.periods.splice(info.periodIndex, 1);
      }
      return place;
    });
  },
  setDeviceState(
    store,
    {
      isOn,
      functions,
      timeIndex,
      periodIndex,
      isCustom = false,
      presetId = null,
      info = null,
      pultCache = null,
    }
  ) {
    store.placesInSchedule = store.placesInSchedule.map((place, placeIndex) => {
      if (placeIndex === store.activePlaceIndex) {
        place.periods[periodIndex].times[timeIndex].action = {
          presetId,
          deviceState: {
            isOn,
            functions: functions || [],
            isCustom,
            info,
            pultCache,
          },
        };
      }
      return place;
    });
  },
};

export const actions = {
  async createSchedule({ state, commit }, payload) {
    let readySchedulePlaces = JSON.parse(
      JSON.stringify(state.placesInSchedule)
    );
    readySchedulePlaces = readySchedulePlaces.map((place) => {
      place.periods = place.periods.map((period) => {
        period.times = period.times.map((time) => {
          delete time.id;
          if (time.action.presetId) {
            time.action.deviceState = null;
          } else {
            const { isOn } = time.action.deviceState;
            time.action.deviceState = {
              isOn: time.action.deviceState.isOn,
              functions: isOn ? time.action.deviceState.functions : false,
            };
          }
          return time;
        });
        return period;
      });
      return place;
    });
    try {
      const { data } = await this.$axios.post("schedules", {
        buildingId: payload.buildingId,
        title: payload.title,
        places: readySchedulePlaces,
      });
      commit("buildingDashboard/ADD_CREATED_PRESET", data.data, { root: true });
      return true;
    } catch (e) {
      errorHandler.call(this, e);
      return false;
    }
  },
  async fetchScheduleDetail({ state, commit }, id) {
    try {
      const { data } = await this.$axios.get(`schedules/${id}/`);
      commit("setValidSchedulePlaces", data.data.places);
      commit("setInfoForEditedSchedule", data.data);
    } catch (e) {
      errorServiceHandler.call(this, e);
    }
  },
  async openEditScheduleModal({ state, dispatch, commit }, id) {
    await dispatch("fetchScheduleDetail", id);
    commit("setTypeOfModal", "edit");
    commit("setModalStep", 2);
  },
  async fetchConfigurationOptions({ state }, id) {
    try {
      const { data } = await this.$axios.get(
        `devices/${id}/configuration-options?type=schedule`
      );
      return data.data;
    } catch (error) {
      errorServiceHandler.call(this, error);
      return {};
    }
  },
  async fetchPult({ state }, id) {
    try {
      const { data } = await this.$axios.get(
        `devices/${id}/pults?type=schedule`
      );
      return data.data;
    } catch (error) {
      errorServiceHandler.call(this, error);
      return {};
    }
  },
  async fetchPultPost({ state }, configure) {
    try {
      const { data } = await this.$axios.post(
        `devices/${configure.id}/ctrl/virtual`,
        configure.configuration
      );
      return data.data;
    } catch (error) {
      errorServiceHandler.call(this, error);
      return {};
    }
  },
  async editSchedule({ state, dispatch }) {
    try {
      let readySchedulePlaces = JSON.parse(
        JSON.stringify(state.placesInSchedule)
      );
      readySchedulePlaces = readySchedulePlaces.map((place) => {
        place.periods = place.periods.map((period) => {
          period.times = period.times.map((time) => {
            delete time.id;
            if (time.action.presetId) {
              time.action.deviceState = null;
            } else {
              const { isOn } = time.action.deviceState;
              time.action.deviceState = {
                isOn: time.action.deviceState.isOn,
                functions: isOn ? time.action.deviceState.functions : false,
              };
            }
            return time;
          });
          return period;
        });
        return place;
      });
      const { data } = await this.$axios.put(
        `schedules/${state.infoForEditedSchedule.id}`,
        {
          places: readySchedulePlaces,
          title: state.infoForEditedSchedule.title,
        }
      );
      dispatch("mqtt/updatePlaceSchedules", [data.data.result], { root: true });
      dispatch("mqtt/updateDevices", [data.data.systemStateUpdate], {
        root: true,
      });
      return true;
    } catch (error) {
      errorHandler.call(this, error);
      return false;
    }
  },
  async pasteSchedule({ state, commit }, pasteData) {
    const copyStore = JSON.parse(JSON.stringify(state.copyRequestStore));
    const validPeriod = {
      weekDays: copyStore.periods.weekDays,
      times: copyStore.periods.times.map((time) => {
        if (time.action.presetId) {
          time.action.deviceState = null;
        } else {
          const { isOn } = time.action.deviceState;
          time.action.deviceState = {
            isOn: time.action.deviceState.isOn,
            functions: isOn ? time.action.deviceState.functions : false,
          };
        }
        return time;
      }),
    };
    if (
      pasteData.targetDeviceIds[0] === state.copyRequestStore.sourceDeviceId
    ) {
      commit("copyDataToSchedule", {
        placeId: pasteData.targetDeviceIds[0],
        pastePeriodIndex: pasteData.pastePeriodIndex,
        times: JSON.parse(JSON.stringify(state.copyRequestStore.periods.times)),
      });
    } else {
      try {
        const { data } = await this.$axios.post("/schedules/copy-actions", {
          sourceDeviceId: state.copyRequestStore.sourceDeviceId,
          targetDeviceIds: pasteData.targetDeviceIds,
          periods: [validPeriod],
        });
        const validTimes = data.data[0].periods[0].times.map((time) => {
          if (time.action.success) {
            return {
              id: Math.random(),
              action: time.action,
              start: time.start,
            };
          }
          return {
            start: time.start,
            action: {
              presetId: null,
              deviceState: {
                isOn: false,
                functions: false,
              },
            },
            id: Math.random(),
          };
        });
        commit("copyDataToSchedule", {
          placeId: data.data[0].deviceId,
          pastePeriodIndex: pasteData.pastePeriodIndex,
          times: validTimes,
        });
      } catch (error) {
        console.log(error);
        errorServiceHandler.call(this, error);
      }
    }
  },
};
