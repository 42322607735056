import moment from 'moment';
import { secondsToHHMM } from '@/utils/helpers';

export default class PlaceStatus {
  constructor(place) {
    this.place = place;
  }

  isShowSwitch() {
    if (this.place.subscription) {
      return !this.isScheduleShow() && !this.isTimerActive()
        && !this.place.subscription.isDeviceBlocked;
    }
    return !this.isScheduleShow() && !this.isTimerActive() && !this.place.progress;
  }

  isPinned() {
    return this.place.pinned;
  }

  hasCurrentSchedule() {
    return !!this.place.currentSchedule;
  }

  isScheduleStop() {
    return this.hasCurrentSchedule() && this.place.currentSchedule.postpone !== null;
  }

  isScheduleShow() {
    return this.hasCurrentSchedule() && !this.isScheduleStop();
  }

  isTimerShow() {
    return this.place.timer && (!this.hasCurrentSchedule()
      || this.place.currentSchedule.postpone !== null);
  }

  isTimerPause() {
    return this.isTimerShow() && this.place.timer.progress.progress === null;
  }

  isTimerActive() {
    return this.isTimerShow() && !this.isTimerPause();
  }

  isScheduleActive() {
    return this.isScheduleShow() && this.scheduleCurrentPeriod();
  }

  stateTemperature() {
    return this.place.state && this.place.state.info ? this.place.state.info.text.replace('℃', '°С') : '';
  }

  showIcons(countShowIcons = 4) {
    if (this.place.state && this.place.state.info) {
      return this.place.state.info.iconsSvg.slice(0, countShowIcons);
    }
    return [];
  }

  hideIconsCount(countShowIcons = 4) {
    if (this.place.state && this.place.state.info) {
      return this.place.state.info.iconsSvg.slice(countShowIcons).length;
    }
    return 0;
  }

  timerStartAt() {
    try {
      const { progress } = this.place.timer;
      // Получаем дату по гринвичу, приводим её в локальное время
      return moment.utc(progress.createdAt).utcOffset(moment().utcOffset()).add(progress.delay, 'minutes');
    } catch (e) {
      return null;
    }
  }

  timerEndAt() {
    try {
      if (!this.timerStartAt()) return null;
      const { progress } = this.place.timer;
      return moment(this.timerStartAt()).add(progress.duration, 'minutes');
    } catch (e) {
      return null;
    }
  }

  timerPercentProgress() {
    try {
      // Вычисляем процент прогрессбара таймера
      const diffInSeconds = this.timerEndAt().unix() - this.timerStartAt().unix();
      const progressSeconds = this.place.timer.progress.progress * 60;
      let percent = Math.round((progressSeconds * 100) / diffInSeconds);
      // Приводим его в рамки 0-100
      percent = Math.max(Math.min(percent, 100), 0);
      return percent;
    } catch (e) {
      return 0;
    }
  }

  timerLeftHHMM() {
    try {
      if (this.place?.timer?.progress) {
        const diffMinutes = this.place.timer.progress.duration - this.place.timer.progress.progress;
        return secondsToHHMM(diffMinutes * 60);
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  progressStartAt() {
    try {
      const { progress } = this.place.progress;
      // Получаем дату по гринвичу, приводим её в локальное время
      return moment.utc(progress.createdAt).utcOffset(moment().utcOffset()).add(progress.delay, 'minutes');
    } catch (e) {
      return null;
    }
  }

  progressEndAt() {
    try {
      if (!this.progressStartAt()) return null;
      const { duration } = this.place.progress;
      return moment(this.progressStartAt()).add(duration, 'minutes');
    } catch (e) {
      return null;
    }
  }

  progressPercentProgress() {
    try {
      if (!this.place.progress) return 0;
      return Math.round((this.place.progress.progress * 100) / this.place.progress.duration);
    } catch (e) {
      return 0;
    }
  }

  progressLeftInHHMM() { // оставшееся время
    try {
      if (this.place?.progress) {
        const diffMinutes = this.place.progress.duration - this.place.progress.progress;
        return secondsToHHMM(diffMinutes * 60);
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  scheduleCurrentWeekDay() {
    const weekday = this.scheduleTimeUtc().day();
    // Корректировка на воскресенье=0
    return weekday || 7;
  }

  scheduleCurrentPeriod() {
    return this.findSchedulePeriodByWeekDay(this.scheduleCurrentWeekDay());
  }

  scheduleCurrentPeriodTimeActive() {
    const minutesFromDayStart = this.scheduleTimeUtc().diff(this.scheduleTimeUtc().startOf('day'), 'minutes');
    const { times } = this.scheduleCurrentPeriod();
    const index = times.findIndex((time, timeIndex) => time.start <= minutesFromDayStart
      && ((times[timeIndex + 1] && times[timeIndex + 1].start > minutesFromDayStart) || !times[timeIndex + 1]));
    if (times.length > 1 && index !== -1) {
      return times[index];
    }
    return this.scheduleCurrentPeriod().times[0];
  }

  scheduleCurrentTimeIntervalActive() {
    try {
      const currentActiveTimeIndex = this.scheduleCurrentPeriodTimes()
        .findIndex((time) => time.isActive);
      // конец текущего интервала расписания = начало следующего
      const nextActiveTime = this.scheduleCurrentPeriodTimes()[currentActiveTimeIndex + 1];

      const currentActiveInterval = {
        start: this.scheduleCurrentPeriodTimes()[currentActiveTimeIndex].start,
      };

      if (nextActiveTime) {
        currentActiveInterval.end = nextActiveTime.start;
      } else {
        currentActiveInterval.end = 1439;
      }
      return currentActiveInterval;
    } catch (e) {
      return null;
    }
  }

  scheduleCurrentPeriodTimes() {
    const activeTime = this.scheduleCurrentPeriodTimeActive();
    const timesList = this.scheduleCurrentPeriod().times;
    const list = timesList.map((time, index) => {
      let diff;
      if (timesList[index + 1]) {
        diff = timesList[index + 1]?.start - time.start;
      } else {
        diff = 1439 - time.start;
      }

      return {
        start: time.start,
        // Округляем проценты до сотых
        percent: Math.floor((diff / 1439 * 100) * 100) / 100,
        isOn: time.action && time.action.deviceState ? time.action.deviceState.isOn : false,
        isActive: activeTime && time.id === activeTime.id,
      };
    });

    const sumPercent = list.reduce((a, b) => a + b.percent, 0);

    // Число которое нужно прибавить к последнему промежутку времени, что бы суммарное кол-во процентов было равно 100
    const correctPercent = 100 - sumPercent;

    list[list.length - 1].percent += correctPercent;
    return list;
  }

  scheduleCurrentNextActivePeriodWeekDay() {
    const days = [1, 2, 3, 4, 5, 6, 7];
    // Вычисляем следующий активный день недели
    //days.concat(days.splice(0, this.scheduleCurrentWeekDay()));
    const moreDays = days.splice(0, this.scheduleCurrentWeekDay());
    days.push(...moreDays);

    return days.find((weekDay) => this.findSchedulePeriodByWeekDay(weekDay));
  }

  scheduleIsCurrentDayActive() {
    return this.place.currentSchedule.periods
      .find((period) => period.weekDays
        .find((day) => day === this.scheduleCurrentWeekDay()));
  }

  findSchedulePeriodByWeekDay(weekDay) {
    if (!this.place.currentSchedule) {
      return null;
    }

    return this.place.currentSchedule.periods
      .find((period) => period.weekDays.indexOf(weekDay) !== -1);
  }

  scheduleTimeUtc() {
    return moment.utc().utcOffset(this.place.currentSchedule.utc);
  }
}
