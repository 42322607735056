
import { mapGetters } from 'vuex';

export default {
  name: 'DeviceStatePreview',
  props: {
    state: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showIcons: 4,
  }),
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
    type() {
      if (!this.state.deviceState.isOn
        && ((this.state.deviceState.functions && this.state.deviceState.functions.length) === 0
          || this.state.deviceState.functions === undefined) && !this.state.presetId) {
        return 'off';
      }
      if (!this.state.deviceState.functions && !this.state.deviceState.isOn && !this.state.presetId) {
        return 'needToSet';
      }
      if (this.state.deviceState.isOn || this.state.presetId) {
        return 'on';
      }
      return false;
    },
    hideIconsCount() {
      if (this.state.deviceState && this.state.deviceState.info) {
        return this.state.deviceState.info.iconsSvg.length - this.showIcons;
      }
      return 0;
    },
    icons() {
      if (this.hideIconsCount > 0) {
        return (this.state.deviceState.info
          && this.state.deviceState.info.iconsSvg.slice(0, this.showIcons))
          || this.state.deviceState.stateInfo.icons.slice(0, this.showIcons);
      }
      return (this.state.deviceState.info && this.state.deviceState.info.iconsSvg)
        || this.state.deviceState.stateInfo.icons;
    },
    text() {
      if (this.state.deviceState.info) {
        return this.state.deviceState.info.text.replace(' ℃', '°C');
      }
      return this.state.deviceState.stateInfo.text.replace(' ℃', '°C');
    },
  },
};
