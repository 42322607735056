
import StatusOk from '../../assets/icons/statusOk.svg';
import NotOkay from '../../assets/icons/24x24/notOkay.svg';
import HidePasswordIcon from '../../assets/icons/hidePassword.svg';
import ShowPasswordIcon from '../../assets/icons/showPassword.svg';

export default {
  name: 'AppInput',
  components: {
    StatusOk, NotOkay, HidePasswordIcon, ShowPasswordIcon,
  },
  props: {
    inputType: {
      type: [String],
      default: 'text',
    },
    label: {
      type: String,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    enableHide: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
    },
    autocomplete: {
      type: String,
      default: 'on',
    },
    value: {
      type: [String, Number],
    },
    disableInput: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: '',
    },
    changeOtherTypes: {
      type: Boolean,
      default: false,
    },
    onlyNumber: {
      type: Boolean,
      default: false,
    },
    maxNumber: {
      type: Number,
      default: Infinity,
    },
    maskType: {
      type: String, // phone
      default: null,
    },
    placeholder: {
      type: String,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      type: null,
      emailReg: /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/,
      phoneReg: /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/,
      focused: false,
      submitted: false,
    };
  },

  computed: {
    isPhoneMask() {
      return this.maskType === 'phone';
    },
    mask() {
      if (!this.maskType) return null;

      if (this.isPhoneMask) {
        return '+7(###)###-##-##';
      }
    },
    computedPlaceholder() {
      if (this.placeholder) {
        return this.placeholder;
      }
      if (this.isPhoneMask) {
        return '+7(912)345-67-89';
      }
      return false;
    },
  },
  watch: {
    inputType() {
      // для changeOtherTypes
      this.type = this.inputType;
    },
  },
  mounted() {
    this.type = this.inputType;
    if (this.autofocus) {
      this.$nextTick(() => {
        this.$refs.input && this.$refs.input.focus();
      });
    }
  },

  methods: {
    toggleType(e) {
      e.preventDefault();
      this.type === 'password' ? this.type = 'text' : this.type = 'password';
      if (this.changeOtherTypes) {
        this.$emit('changeOtherPasswordsType', this.type);
      }
    },
    enterClickHandler(e) {
      this.submitted = true;
      e.preventDefault();
      this.$emit('enter', e);
    },
    inputHandler(e) {
      let { value } = e.target;

      if (this.onlyNumber) {
        value = value.replace(/[^0-9]/g, '');
        if (value === '') value = 0;

        if (value > this.maxNumber) value = this.maxNumber;
      }

      if (this.isPhoneMask) {
        if (value.length > 1) {
          if (value.startsWith('7')) {
            value = `+${value.substr(0)}`;
          } else if (value.startsWith('8')) {
            value = `+7${value.substr(1)}`;
          }
        } else if (value.length === 1 && value === '7' || value === '8') {
          value = '+7';
        }
      }

      this.submitted = false;
      e.target.value = value;
      this.$emit('eventInput', value);
    },
    changeHandler(e) {
      this.$emit('eventChange', e.target.value);
    },
    focusInHandler(e) {
      this.focused = true;
      this.$emit('focusIn', e);
    },
    focusOutHandler(e) {
      this.focused = false;
      this.$emit('focusOut');
    },
  },
};
