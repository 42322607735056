
import PowerInputRange from "@/components/Inputs/PowerInputRange";
import FanPowerButton from "@/components/Buttons/FanPowerButton";

export default {
  name: "ControlPanelLinear",
  components: { PowerInputRange, FanPowerButton },
  props: {
    deviceConnected: {
      type: Boolean,
      required: true,
    },
    functionBlockId: {
      type: Number,
      required: true,
    },
    functionItem: {
      type: Object,
      required: true,
    },
    needForUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    value: 1,
    steps: 5,
    minRange: 0,
    activeAuto: false,
  }),
  computed: {
    inputControllable() {
      return this.functionItem.state.controllable && this.deviceConnected;
    },
    buttonControllable() {
      return (
        this.functionItem.linkedFunction?.state.controllable &&
        this.deviceConnected
      );
    },
  },
  watch: {
    functionItem: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.prepareData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  beforeMount() {
    this.prepareData();
  },
  methods: {
    powerChangeHandler(value) {
      this.value = value;
    },
    powerChangeApplyHandler() {
      this.changeFunctionRangeState();
    },
    funPowerToggle() {
      if (this.functionItem.linkedFunction.metaData.applyable) {
        this.activeAuto = true;
      } else {
        this.activeAuto = !this.activeAuto;
      }
      this.changeFunctionAutoState();
    },
    prepareData() {
      const { valueRange } = this.functionItem.state;
      const delta = +valueRange[0] - 1;
      this.value = this.functionItem.state.value - delta;

      this.steps = valueRange[valueRange.length - 1] - delta;
      this.minRange = valueRange[0] - delta;

      this.activeAuto = this.functionItem.linkedFunction?.state.isOn;
    },
    changeFunctionRangeState() {
      const delta = this.functionItem.state.valueRange[0] - 1;
      const requestValue = {
        functionId: this.functionItem.id,
        value: Number(this.value) + delta,
        parameters: null,
      };

      this.$emit("controlStateChanged", requestValue);
    },
    changeFunctionAutoState() {
      const requestValue = {
        functionId: this.functionItem.linkedFunction.id,
        isOn: this.activeAuto,
        parameters: null,
      };

      this.$emit("controlStateChanged", requestValue);
    },
  },
};
