import { render, staticRenderFns } from "./dashboard.vue?vue&type=template&id=755a0a74"
import script from "./dashboard.vue?vue&type=script&lang=js"
export * from "./dashboard.vue?vue&type=script&lang=js"
import style0 from "./dashboard.vue?vue&type=style&index=0&id=755a0a74&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SmallScreen: require('/builds/web-cloud/webfront/components/SmallScreen.vue').default,AuthFooter: require('/builds/web-cloud/webfront/components/auth/AuthFooter.vue').default,StartPage: require('/builds/web-cloud/webfront/components/start-page.vue').default})
