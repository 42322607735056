import moment from 'moment';

export default class PresetStatus {
  constructor(preset) {
    this.preset = preset;
    this.countShowIcons = 4;
  }

  showIcons() {
    return this.preset.deviceState.info ? this.preset.deviceState.info.iconsSvg.slice(0, this.countShowIcons) : [];
  }

  hideIconsCount() {
    return this.preset.deviceState.info ? this.preset.deviceState.info.iconsSvg.slice(this.countShowIcons).length : 0;
  }

  isTimerShow() {
    return !!this.preset.timer;
  }

  isTimerPause() {
    return this.isTimerShow() && this.preset.timer.progress.progress === null;
  }

  isTimerActive() {
    return this.isTimerShow() && !this.isTimerPause();
  }

  timerStartAt() {
    try {
      const { progress } = this.preset.timer;
      // Получаем дату по гринвичу, приводим её в локальное время
      return moment.utc(progress.createdAt).utcOffset(moment().utcOffset()).add(progress.delay, 'minutes');
    } catch (e) {
      return null;
    }
  }

  timerEndAt() {
    try {
      if (!this.timerStartAt()) return null;
      const { progress } = this.preset.timer;
      return moment(this.timerStartAt()).add(progress.duration, 'minutes');
    } catch (e) {
      return null;
    }
  }

  timerPercentProgress() {
    try {
      // Вычисляем процент прогрессбара таймера
      const diffInSeconds = this.timerEndAt().unix() - this.timerStartAt().unix();
      const progressSeconds = this.preset.timer.progress.progress * 60;
      let percent = Math.round(progressSeconds * 100 / diffInSeconds);
      // Приводим его в рамки 0-100
      percent = Math.max(Math.min(percent, 100), 0);
      return percent;
    } catch (e) {
      return 0;
    }
  }
}
