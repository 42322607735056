
import { mapActions, mapGetters } from 'vuex';
import AppButton from '../Buttons/AppButton';
import modalEsc from '../../mixins/modalEsc';

export default {
  name: 'ServiceSuccessModal',
  components: { AppButton },
  mixins: [modalEsc],
  data: () => ({
    modalName: 'serviceSuccessModal',
  }),
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
  },
  methods: {
    ...mapActions({
      setShowModal: 'system/setShowModal',
    }),
    async modalOpened() {
      this.setShowModal(true);
      document.addEventListener('keydown', this.clickHandler);
    },
    async modalClosed() {
      this.setShowModal(false);
      document.removeEventListener('keydown', this.clickHandler);
    },
  },
};
