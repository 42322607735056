
import ScheduleButton from '@/components/Buttons/ScheduleButton.vue';
import PlaceStatus from '@/components/class/PlaceStatus';
import { mapActions, mapGetters } from 'vuex';
import { errorConflictHandler } from '@/utils/api';

export default {
  name: 'ControlPanelSchedule',
  components: { ScheduleButton },
  props: {
    deviceInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
      panelDeviceId: 'system/getPanelDeviceId',
    }),
    placeStatus() {
      return new PlaceStatus(this.deviceInfo);
    },
    text() {
      if (!this.deviceInfo.currentSchedule) {
        return this.getTranslations['Расписание'];
      }
      if (this.deviceInfo.currentSchedule && this.deviceInfo.currentSchedule.scheduleTitle) {
        return this.deviceInfo.currentSchedule.scheduleTitle;
      }
      return '';
    },
    disabled() {
      return this.deviceInfo?.status === 'disconnected' || (this.deviceInfo?.subscription !== null && this.deviceInfo?.subscription?.isDeviceBlocked) || this.deviceInfo?.access === 'guest';
    },
    active() {
      return !!(this.deviceInfo.currentSchedule && !this.deviceInfo.currentSchedule.postpone);
    },
    stopped() {
      return !!(this.deviceInfo.currentSchedule && this.deviceInfo.currentSchedule.postpone);
    },
    state() {
      if (this.disabled) {
        return 'disabled';
      }
      if (this.active) {
        return 'active';
      }
      if (this.stopped) {
        return 'stopped';
      }
      return ' default';
    },
  },
  methods: {
    ...mapActions({
      setModalType: 'system/setGoToAppModalType',
      setModalCancelSchedule: 'modalCancelSchedule/setModalData',
      findCurrentDevice: 'deviceFunctions/findCurrentDevice',
    }),
    clickHandler() {
      if (this.disabled) {
        return;
      }

      this.$router.push(`/schedule?id=${this.panelDeviceId}`);
      return;

      if (this.stopped) {
        this.resumeSchedule();
      } else if (this.active) {
        this.cancelSchedule();
      } else {
        this.setSchedule();
      }
    },

    resumeSchedule() {
      this.$axios.post(
        `placeSchedules/${this.deviceInfo.currentSchedule.scheduleId}/resume?ignoreConflicts=false`,
        {
          conflictResolveData: null,
        },
        {
          headers: {
            Authorization: `Bearer ${this.$auth.user.token}`,
          },
        },
      )
        .then((response) => {
          const systemStateUpdate = response.data.data;
          // TODO: updateSystemState
          this.findCurrentDevice(systemStateUpdate);
        })
        .catch((error) => {
          errorConflictHandler.call(this.$store, error);
        });
    },

    cancelSchedule() {
      // this.setModalCancelSchedule(this.deviceInfo);
      // this.$modal.show('CancelScheduleModal');
      this.setModalType('addSchedule');
      this.$modal.show('goToApp');
    },

    setSchedule() {
      this.setModalType('addSchedule');
      this.$modal.show('goToApp');
    },
  },
};
