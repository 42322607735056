
import CloseIcon from '../assets/icons/24x24/close.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'ToolTip',
  components: { CloseIcon },
  props: {
    text: {
      type: String,
      default: '',
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'cancel'],
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
  },
  methods: {
    closeHandler() {
      this.$emit('close');
    },
    cancelHandler() {
      this.$emit('cancel');
    },
  },
};
