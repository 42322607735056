
import { mapGetters, mapActions } from "vuex";
import CloseIcon from "@/assets/icons/24x24/close.svg";
import AppButton from "@/components/Buttons/AppButton";

export default {
  name: 'RecommendationModal',
  components: {
    CloseIcon,
    AppButton,
  },
  data: () => ({
    modalName: 'recommendationModal',
    isRequest: false,
  }),
  computed: {
    ...mapGetters({
      getTranslations: "localization/getTranslations",
      currentRecommendation: "deviceFunctions/getCurrentRecommendation",
      climateOnlineInfo: "deviceFunctions/getClimateOnlineInfo",
      panelDeviceId: "system/getPanelDeviceId",
    }),
  },
  methods: {
    ...mapActions({
      closeClimateOnlineRecommendation: "deviceFunctions/closeClimateOnlineRecommendation",
      fetchClimateOnline: "deviceFunctions/fetchClimateOnline",
    }),
    onCloseRecommendation() {
      this.isRequest = true;

      this.closeClimateOnlineRecommendation(
        {
          recommendationId: this.currentRecommendation.id,
          deviceId: this.panelDeviceId,
          onClose: () => {
            this.isRequest = false;
          },
        },
      )
        .then(() => {
          this.$modal.hide(this.modalName);

          this.$store.commit("deviceFunctions/setClimateOnlineRequestStatus", true);

          this.fetchClimateOnline(
            {
              deviceId: this.panelDeviceId,
              onClose: () => {
                this.$modal.hide('climateOnlineModal');
              },
            },
          )
            .finally(() => this.$store.commit("deviceFunctions/setClimateOnlineRequestStatus", false));
        })
        .finally(() => {
          this.isRequest = false;
        });
    },
  },
};
