import {
  SET_MODAL_DELETE_CONFIRM, SET_MODAL_DELETE_TEXT, SET_MODAL_DELETE_TITLE, SET_MODAL_TYPE_OF_DELETE, SET_MODAL_DELETE_DATA,
} from '../utils/types';

export const state = () => ({
  title: 'Вы уверены, что хотите удалить быструю команду?',
  text: 'Быстрая команда «Режим для сна» будет удалена из вашего аккаунта.',
  confirmed: false,
  type: null,
  modalData: {},
});

export const getters = {
  getTitle: ({ title }) => title,
  getText: ({ text }) => text,
  getTypeOfDelete: ({ type }) => type,
  getModalData: ({ modalData }) => modalData,
  getConfirmed: ({ confirmed }) => confirmed,
};

export const mutations = {
  [SET_MODAL_DELETE_TITLE](state, title) {
    state.title = title;
  },
  [SET_MODAL_DELETE_TEXT](state, text) {
    state.text = text;
  },
  [SET_MODAL_DELETE_CONFIRM](state, value) {
    state.confirmed = value;
  },
  [SET_MODAL_TYPE_OF_DELETE](state, value) {
    state.type = value;
  },
  [SET_MODAL_DELETE_DATA](state, value) {
    state.modalData = value;
  },
};

export const actions = {
  setModalData({ commit }, data) {
    data.title && commit(SET_MODAL_DELETE_TITLE, data.title);
    data.type && commit(SET_MODAL_TYPE_OF_DELETE, data.type);
    data.object && commit(SET_MODAL_DELETE_DATA, data.object);
    data.text && commit(SET_MODAL_DELETE_TEXT, data.text);
    commit(SET_MODAL_DELETE_CONFIRM, data.confirmed);
  },
};
