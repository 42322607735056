export const BasketItem = () => import('../../components/BasketItem.vue' /* webpackChunkName: "components/basket-item" */).then(c => wrapFunctional(c.default || c))
export const BreadCrumbs = () => import('../../components/BreadCrumbs.vue' /* webpackChunkName: "components/bread-crumbs" */).then(c => wrapFunctional(c.default || c))
export const BuildingGeolocation = () => import('../../components/BuildingGeolocation.vue' /* webpackChunkName: "components/building-geolocation" */).then(c => wrapFunctional(c.default || c))
export const CalendarPicker = () => import('../../components/CalendarPicker.vue' /* webpackChunkName: "components/calendar-picker" */).then(c => wrapFunctional(c.default || c))
export const ClimateOnlineLabel = () => import('../../components/ClimateOnlineLabel.vue' /* webpackChunkName: "components/climate-online-label" */).then(c => wrapFunctional(c.default || c))
export const ClimateOnlineLabelSmall = () => import('../../components/ClimateOnlineLabelSmall.vue' /* webpackChunkName: "components/climate-online-label-small" */).then(c => wrapFunctional(c.default || c))
export const DeviceStatePreview = () => import('../../components/DeviceStatePreview.vue' /* webpackChunkName: "components/device-state-preview" */).then(c => wrapFunctional(c.default || c))
export const EmailStatus = () => import('../../components/EmailStatus.vue' /* webpackChunkName: "components/email-status" */).then(c => wrapFunctional(c.default || c))
export const ErrorStroke = () => import('../../components/ErrorStroke.vue' /* webpackChunkName: "components/error-stroke" */).then(c => wrapFunctional(c.default || c))
export const Geolocation = () => import('../../components/Geolocation.vue' /* webpackChunkName: "components/geolocation" */).then(c => wrapFunctional(c.default || c))
export const InfoItem = () => import('../../components/InfoItem.vue' /* webpackChunkName: "components/info-item" */).then(c => wrapFunctional(c.default || c))
export const InfoList = () => import('../../components/InfoList.vue' /* webpackChunkName: "components/info-list" */).then(c => wrapFunctional(c.default || c))
export const NotificationMessage = () => import('../../components/NotificationMessage.vue' /* webpackChunkName: "components/notification-message" */).then(c => wrapFunctional(c.default || c))
export const ObjectForm = () => import('../../components/ObjectForm.vue' /* webpackChunkName: "components/object-form" */).then(c => wrapFunctional(c.default || c))
export const ObjectItemInner = () => import('../../components/ObjectItemInner.vue' /* webpackChunkName: "components/object-item-inner" */).then(c => wrapFunctional(c.default || c))
export const ObjectsItem = () => import('../../components/ObjectsItem.vue' /* webpackChunkName: "components/objects-item" */).then(c => wrapFunctional(c.default || c))
export const PageError = () => import('../../components/PageError.vue' /* webpackChunkName: "components/page-error" */).then(c => wrapFunctional(c.default || c))
export const PaymentResultModal = () => import('../../components/PaymentResultModal.vue' /* webpackChunkName: "components/payment-result-modal" */).then(c => wrapFunctional(c.default || c))
export const RangeSlider = () => import('../../components/RangeSlider.vue' /* webpackChunkName: "components/range-slider" */).then(c => wrapFunctional(c.default || c))
export const SmallScreen = () => import('../../components/SmallScreen.vue' /* webpackChunkName: "components/small-screen" */).then(c => wrapFunctional(c.default || c))
export const StateBadge = () => import('../../components/StateBadge.vue' /* webpackChunkName: "components/state-badge" */).then(c => wrapFunctional(c.default || c))
export const SubscribeBadge = () => import('../../components/SubscribeBadge.vue' /* webpackChunkName: "components/subscribe-badge" */).then(c => wrapFunctional(c.default || c))
export const SubscribeLabel = () => import('../../components/SubscribeLabel.vue' /* webpackChunkName: "components/subscribe-label" */).then(c => wrapFunctional(c.default || c))
export const TableItemInner = () => import('../../components/TableItemInner.vue' /* webpackChunkName: "components/table-item-inner" */).then(c => wrapFunctional(c.default || c))
export const TimeLineSmall = () => import('../../components/TimeLineSmall.vue' /* webpackChunkName: "components/time-line-small" */).then(c => wrapFunctional(c.default || c))
export const TimePreview = () => import('../../components/TimePreview.vue' /* webpackChunkName: "components/time-preview" */).then(c => wrapFunctional(c.default || c))
export const ToggleView = () => import('../../components/ToggleView.vue' /* webpackChunkName: "components/toggle-view" */).then(c => wrapFunctional(c.default || c))
export const ToolTip = () => import('../../components/ToolTip.vue' /* webpackChunkName: "components/tool-tip" */).then(c => wrapFunctional(c.default || c))
export const StartPage = () => import('../../components/start-page.vue' /* webpackChunkName: "components/start-page" */).then(c => wrapFunctional(c.default || c))
export const ButtonsAddButton = () => import('../../components/Buttons/AddButton.vue' /* webpackChunkName: "components/buttons-add-button" */).then(c => wrapFunctional(c.default || c))
export const ButtonsAddFastCommandButton = () => import('../../components/Buttons/AddFastCommandButton.vue' /* webpackChunkName: "components/buttons-add-fast-command-button" */).then(c => wrapFunctional(c.default || c))
export const ButtonsAddPeriodButton = () => import('../../components/Buttons/AddPeriodButton.vue' /* webpackChunkName: "components/buttons-add-period-button" */).then(c => wrapFunctional(c.default || c))
export const ButtonsAppButton = () => import('../../components/Buttons/AppButton.vue' /* webpackChunkName: "components/buttons-app-button" */).then(c => wrapFunctional(c.default || c))
export const ButtonsButtonArrow = () => import('../../components/Buttons/ButtonArrow.vue' /* webpackChunkName: "components/buttons-button-arrow" */).then(c => wrapFunctional(c.default || c))
export const ButtonsButtonCount = () => import('../../components/Buttons/ButtonCount.vue' /* webpackChunkName: "components/buttons-button-count" */).then(c => wrapFunctional(c.default || c))
export const ButtonsButtonLaunch = () => import('../../components/Buttons/ButtonLaunch.vue' /* webpackChunkName: "components/buttons-button-launch" */).then(c => wrapFunctional(c.default || c))
export const ButtonsButtonState = () => import('../../components/Buttons/ButtonState.vue' /* webpackChunkName: "components/buttons-button-state" */).then(c => wrapFunctional(c.default || c))
export const ButtonsCopyButton = () => import('../../components/Buttons/CopyButton.vue' /* webpackChunkName: "components/buttons-copy-button" */).then(c => wrapFunctional(c.default || c))
export const ButtonsCreatePresetButtonPreview = () => import('../../components/Buttons/CreatePresetButtonPreview.vue' /* webpackChunkName: "components/buttons-create-preset-button-preview" */).then(c => wrapFunctional(c.default || c))
export const ButtonsDayButton = () => import('../../components/Buttons/DayButton.vue' /* webpackChunkName: "components/buttons-day-button" */).then(c => wrapFunctional(c.default || c))
export const ButtonsDeleteButton = () => import('../../components/Buttons/DeleteButton.vue' /* webpackChunkName: "components/buttons-delete-button" */).then(c => wrapFunctional(c.default || c))
export const ButtonsFanPowerButton = () => import('../../components/Buttons/FanPowerButton.vue' /* webpackChunkName: "components/buttons-fan-power-button" */).then(c => wrapFunctional(c.default || c))
export const ButtonsPasteButton = () => import('../../components/Buttons/PasteButton.vue' /* webpackChunkName: "components/buttons-paste-button" */).then(c => wrapFunctional(c.default || c))
export const ButtonsPlusButton = () => import('../../components/Buttons/PlusButton.vue' /* webpackChunkName: "components/buttons-plus-button" */).then(c => wrapFunctional(c.default || c))
export const ButtonsScheduleButton = () => import('../../components/Buttons/ScheduleButton.vue' /* webpackChunkName: "components/buttons-schedule-button" */).then(c => wrapFunctional(c.default || c))
export const ButtonsSettingButton = () => import('../../components/Buttons/SettingButton.vue' /* webpackChunkName: "components/buttons-setting-button" */).then(c => wrapFunctional(c.default || c))
export const ButtonsToggleButton = () => import('../../components/Buttons/ToggleButton.vue' /* webpackChunkName: "components/buttons-toggle-button" */).then(c => wrapFunctional(c.default || c))
export const CardsCardAdd = () => import('../../components/Cards/CardAdd.vue' /* webpackChunkName: "components/cards-card-add" */).then(c => wrapFunctional(c.default || c))
export const CardsCardPayment = () => import('../../components/Cards/CardPayment.vue' /* webpackChunkName: "components/cards-card-payment" */).then(c => wrapFunctional(c.default || c))
export const CardsCardProgress = () => import('../../components/Cards/CardProgress.vue' /* webpackChunkName: "components/cards-card-progress" */).then(c => wrapFunctional(c.default || c))
export const CardsCardRoom = () => import('../../components/Cards/CardRoom.vue' /* webpackChunkName: "components/cards-card-room" */).then(c => wrapFunctional(c.default || c))
export const CardsCardSchedule = () => import('../../components/Cards/CardSchedule.vue' /* webpackChunkName: "components/cards-card-schedule" */).then(c => wrapFunctional(c.default || c))
export const CardsCardStep = () => import('../../components/Cards/CardStep.vue' /* webpackChunkName: "components/cards-card-step" */).then(c => wrapFunctional(c.default || c))
export const CardsGroupCard = () => import('../../components/Cards/GroupCard.vue' /* webpackChunkName: "components/cards-group-card" */).then(c => wrapFunctional(c.default || c))
export const CardsGroupPresetCard = () => import('../../components/Cards/GroupPresetCard.vue' /* webpackChunkName: "components/cards-group-preset-card" */).then(c => wrapFunctional(c.default || c))
export const CardsObjectCard = () => import('../../components/Cards/ObjectCard.vue' /* webpackChunkName: "components/cards-object-card" */).then(c => wrapFunctional(c.default || c))
export const CardsPlaceBlank = () => import('../../components/Cards/PlaceBlank.vue' /* webpackChunkName: "components/cards-place-blank" */).then(c => wrapFunctional(c.default || c))
export const CardsPlacePresetCard = () => import('../../components/Cards/PlacePresetCard.vue' /* webpackChunkName: "components/cards-place-preset-card" */).then(c => wrapFunctional(c.default || c))
export const CardsPresetPlaceList = () => import('../../components/Cards/PresetPlaceList.vue' /* webpackChunkName: "components/cards-preset-place-list" */).then(c => wrapFunctional(c.default || c))
export const CardsPresetTableRow = () => import('../../components/Cards/PresetTableRow.vue' /* webpackChunkName: "components/cards-preset-table-row" */).then(c => wrapFunctional(c.default || c))
export const CardsRecommendationChip = () => import('../../components/Cards/RecommendationChip.vue' /* webpackChunkName: "components/cards-recommendation-chip" */).then(c => wrapFunctional(c.default || c))
export const CardsScheduleTableRow = () => import('../../components/Cards/ScheduleTableRow.vue' /* webpackChunkName: "components/cards-schedule-table-row" */).then(c => wrapFunctional(c.default || c))
export const CardsStoryCard = () => import('../../components/Cards/StoryCard.vue' /* webpackChunkName: "components/cards-story-card" */).then(c => wrapFunctional(c.default || c))
export const InputsAppInput = () => import('../../components/Inputs/AppInput.vue' /* webpackChunkName: "components/inputs-app-input" */).then(c => wrapFunctional(c.default || c))
export const InputsAppPhoneInput = () => import('../../components/Inputs/AppPhoneInput.vue' /* webpackChunkName: "components/inputs-app-phone-input" */).then(c => wrapFunctional(c.default || c))
export const InputsGeoCoderInput = () => import('../../components/Inputs/GeoCoderInput.vue' /* webpackChunkName: "components/inputs-geo-coder-input" */).then(c => wrapFunctional(c.default || c))
export const InputsInputImage = () => import('../../components/Inputs/InputImage.vue' /* webpackChunkName: "components/inputs-input-image" */).then(c => wrapFunctional(c.default || c))
export const InputsInputObjectImage = () => import('../../components/Inputs/InputObjectImage.vue' /* webpackChunkName: "components/inputs-input-object-image" */).then(c => wrapFunctional(c.default || c))
export const InputsInputPhone = () => import('../../components/Inputs/InputPhone.vue' /* webpackChunkName: "components/inputs-input-phone" */).then(c => wrapFunctional(c.default || c))
export const InputsInputRange = () => import('../../components/Inputs/InputRange.vue' /* webpackChunkName: "components/inputs-input-range" */).then(c => wrapFunctional(c.default || c))
export const InputsPowerInputRange = () => import('../../components/Inputs/PowerInputRange.vue' /* webpackChunkName: "components/inputs-power-input-range" */).then(c => wrapFunctional(c.default || c))
export const InputsSearchInput = () => import('../../components/Inputs/SearchInput.vue' /* webpackChunkName: "components/inputs-search-input" */).then(c => wrapFunctional(c.default || c))
export const InputsSelectCountryInput = () => import('../../components/Inputs/SelectCountryInput.vue' /* webpackChunkName: "components/inputs-select-country-input" */).then(c => wrapFunctional(c.default || c))
export const InputsSelectInput = () => import('../../components/Inputs/SelectInput.vue' /* webpackChunkName: "components/inputs-select-input" */).then(c => wrapFunctional(c.default || c))
export const InputsTimerInput = () => import('../../components/Inputs/TimerInput.vue' /* webpackChunkName: "components/inputs-timer-input" */).then(c => wrapFunctional(c.default || c))
export const MenusAddCommandPanel = () => import('../../components/Menus/AddCommandPanel.vue' /* webpackChunkName: "components/menus-add-command-panel" */).then(c => wrapFunctional(c.default || c))
export const MenusAddFastCommandControlPanel = () => import('../../components/Menus/AddFastCommandControlPanel.vue' /* webpackChunkName: "components/menus-add-fast-command-control-panel" */).then(c => wrapFunctional(c.default || c))
export const MenusControlPanel = () => import('../../components/Menus/ControlPanel.vue' /* webpackChunkName: "components/menus-control-panel" */).then(c => wrapFunctional(c.default || c))
export const MenusFastCommandsPanel = () => import('../../components/Menus/FastCommandsPanel.vue' /* webpackChunkName: "components/menus-fast-commands-panel" */).then(c => wrapFunctional(c.default || c))
export const MenusObjectsPanel = () => import('../../components/Menus/ObjectsPanel.vue' /* webpackChunkName: "components/menus-objects-panel" */).then(c => wrapFunctional(c.default || c))
export const MenusScheduleMenu = () => import('../../components/Menus/ScheduleMenu.vue' /* webpackChunkName: "components/menus-schedule-menu" */).then(c => wrapFunctional(c.default || c))
export const MenusScheduleOptions = () => import('../../components/Menus/ScheduleOptions.vue' /* webpackChunkName: "components/menus-schedule-options" */).then(c => wrapFunctional(c.default || c))
export const MenusSchedulePanel = () => import('../../components/Menus/SchedulePanel.vue' /* webpackChunkName: "components/menus-schedule-panel" */).then(c => wrapFunctional(c.default || c))
export const MenusSideMenu = () => import('../../components/Menus/SideMenu.vue' /* webpackChunkName: "components/menus-side-menu" */).then(c => wrapFunctional(c.default || c))
export const TransitionsTransitionExpand = () => import('../../components/Transitions/TransitionExpand.vue' /* webpackChunkName: "components/transitions-transition-expand" */).then(c => wrapFunctional(c.default || c))
export const AccessUser = () => import('../../components/access/AccessUser.vue' /* webpackChunkName: "components/access-user" */).then(c => wrapFunctional(c.default || c))
export const AccessUsersTable = () => import('../../components/access/UsersTable.vue' /* webpackChunkName: "components/access-users-table" */).then(c => wrapFunctional(c.default || c))
export const AuthFooter = () => import('../../components/auth/AuthFooter.vue' /* webpackChunkName: "components/auth-footer" */).then(c => wrapFunctional(c.default || c))
export const AuthChangePassword = () => import('../../components/auth/ChangePassword.vue' /* webpackChunkName: "components/auth-change-password" */).then(c => wrapFunctional(c.default || c))
export const AuthCreatePassword = () => import('../../components/auth/CreatePassword.vue' /* webpackChunkName: "components/auth-create-password" */).then(c => wrapFunctional(c.default || c))
export const AuthEmailSignIn = () => import('../../components/auth/EmailSignIn.vue' /* webpackChunkName: "components/auth-email-sign-in" */).then(c => wrapFunctional(c.default || c))
export const AuthEmailSignUp = () => import('../../components/auth/EmailSignUp.vue' /* webpackChunkName: "components/auth-email-sign-up" */).then(c => wrapFunctional(c.default || c))
export const AuthPhoneMainSingIn = () => import('../../components/auth/PhoneMainSingIn.vue' /* webpackChunkName: "components/auth-phone-main-sing-in" */).then(c => wrapFunctional(c.default || c))
export const AuthPhoneSignUp = () => import('../../components/auth/PhoneSignUp.vue' /* webpackChunkName: "components/auth-phone-sign-up" */).then(c => wrapFunctional(c.default || c))
export const AuthPhoneSingIn = () => import('../../components/auth/PhoneSingIn.vue' /* webpackChunkName: "components/auth-phone-sing-in" */).then(c => wrapFunctional(c.default || c))
export const AuthRestorePassword = () => import('../../components/auth/RestorePassword.vue' /* webpackChunkName: "components/auth-restore-password" */).then(c => wrapFunctional(c.default || c))
export const AuthStartAuth = () => import('../../components/auth/StartAuth.vue' /* webpackChunkName: "components/auth-start-auth" */).then(c => wrapFunctional(c.default || c))
export const AuthStartEmailAuth = () => import('../../components/auth/StartEmailAuth.vue' /* webpackChunkName: "components/auth-start-email-auth" */).then(c => wrapFunctional(c.default || c))
export const AuthStartPhoneAuth = () => import('../../components/auth/StartPhoneAuth.vue' /* webpackChunkName: "components/auth-start-phone-auth" */).then(c => wrapFunctional(c.default || c))
export const AuthWaitConfirm = () => import('../../components/auth/WaitConfirm.vue' /* webpackChunkName: "components/auth-wait-confirm" */).then(c => wrapFunctional(c.default || c))
export const AuthWaitRestore = () => import('../../components/auth/WaitRestore.vue' /* webpackChunkName: "components/auth-wait-restore" */).then(c => wrapFunctional(c.default || c))
export const AuthPasswordChanged = () => import('../../components/auth/passwordChanged.vue' /* webpackChunkName: "components/auth-password-changed" */).then(c => wrapFunctional(c.default || c))
export const ChecksAppCheckbox = () => import('../../components/checks/AppCheckbox.vue' /* webpackChunkName: "components/checks-app-checkbox" */).then(c => wrapFunctional(c.default || c))
export const ChecksCheckMark = () => import('../../components/checks/CheckMark.vue' /* webpackChunkName: "components/checks-check-mark" */).then(c => wrapFunctional(c.default || c))
export const ChecksCheckboxObject = () => import('../../components/checks/checkbox-object.vue' /* webpackChunkName: "components/checks-checkbox-object" */).then(c => wrapFunctional(c.default || c))
export const ClassPlaceStatus = () => import('../../components/class/PlaceStatus.js' /* webpackChunkName: "components/class-place-status" */).then(c => wrapFunctional(c.default || c))
export const ClassPresetStatus = () => import('../../components/class/PresetStatus.js' /* webpackChunkName: "components/class-preset-status" */).then(c => wrapFunctional(c.default || c))
export const ClimateOnlineConnect = () => import('../../components/climate-online/ClimateOnlineConnect.vue' /* webpackChunkName: "components/climate-online-connect" */).then(c => wrapFunctional(c.default || c))
export const ClimateOnlineErrors = () => import('../../components/climate-online/ClimateOnlineErrors.vue' /* webpackChunkName: "components/climate-online-errors" */).then(c => wrapFunctional(c.default || c))
export const ClimateOnlineInfo = () => import('../../components/climate-online/ClimateOnlineInfo.vue' /* webpackChunkName: "components/climate-online-info" */).then(c => wrapFunctional(c.default || c))
export const ClimateOnlineNoErrors = () => import('../../components/climate-online/ClimateOnlineNoErrors.vue' /* webpackChunkName: "components/climate-online-no-errors" */).then(c => wrapFunctional(c.default || c))
export const ClimateOnlinePayment = () => import('../../components/climate-online/ClimateOnlinePayment.vue' /* webpackChunkName: "components/climate-online-payment" */).then(c => wrapFunctional(c.default || c))
export const ClimateOnlinePromo = () => import('../../components/climate-online/ClimateOnlinePromo.vue' /* webpackChunkName: "components/climate-online-promo" */).then(c => wrapFunctional(c.default || c))
export const ClimateOnlinePromoSmall = () => import('../../components/climate-online/ClimateOnlinePromoSmall.vue' /* webpackChunkName: "components/climate-online-promo-small" */).then(c => wrapFunctional(c.default || c))
export const ClimateOnlineRecommendations = () => import('../../components/climate-online/ClimateOnlineRecommendations.vue' /* webpackChunkName: "components/climate-online-recommendations" */).then(c => wrapFunctional(c.default || c))
export const ClimateOnlineStatistics = () => import('../../components/climate-online/ClimateOnlineStatistics.vue' /* webpackChunkName: "components/climate-online-statistics" */).then(c => wrapFunctional(c.default || c))
export const ContextMenuTimeLineContextMenu = () => import('../../components/contextMenu/TimeLineContextMenu.vue' /* webpackChunkName: "components/context-menu-time-line-context-menu" */).then(c => wrapFunctional(c.default || c))
export const ContextMenuControlItemContextMenu = () => import('../../components/contextMenu/controlItemContextMenu.vue' /* webpackChunkName: "components/context-menu-control-item-context-menu" */).then(c => wrapFunctional(c.default || c))
export const ContextMenuDashboardFastCommandContextMenu = () => import('../../components/contextMenu/dashboardFastCommandContextMenu.vue' /* webpackChunkName: "components/context-menu-dashboard-fast-command-context-menu" */).then(c => wrapFunctional(c.default || c))
export const ContextMenuDashboardFastCommandsGroupContextMenu = () => import('../../components/contextMenu/dashboardFastCommandsGroupContextMenu.vue' /* webpackChunkName: "components/context-menu-dashboard-fast-commands-group-context-menu" */).then(c => wrapFunctional(c.default || c))
export const ContextMenuDashboardGroupContextMenu = () => import('../../components/contextMenu/dashboardGroupContextMenu.vue' /* webpackChunkName: "components/context-menu-dashboard-group-context-menu" */).then(c => wrapFunctional(c.default || c))
export const ContextMenuDashboardPlaceContextMenu = () => import('../../components/contextMenu/dashboardPlaceContextMenu.vue' /* webpackChunkName: "components/context-menu-dashboard-place-context-menu" */).then(c => wrapFunctional(c.default || c))
export const ContextMenuDashboardScheduleContextMenu = () => import('../../components/contextMenu/dashboardScheduleContextMenu.vue' /* webpackChunkName: "components/context-menu-dashboard-schedule-context-menu" */).then(c => wrapFunctional(c.default || c))
export const ContextMenuTariffsSettingMenu = () => import('../../components/contextMenu/tariffsSettingMenu.vue' /* webpackChunkName: "components/context-menu-tariffs-setting-menu" */).then(c => wrapFunctional(c.default || c))
export const ControlPanelCard = () => import('../../components/control-panel/ControlPanelCard.vue' /* webpackChunkName: "components/control-panel-card" */).then(c => wrapFunctional(c.default || c))
export const ControlPanelCircular = () => import('../../components/control-panel/ControlPanelCircular.vue' /* webpackChunkName: "components/control-panel-circular" */).then(c => wrapFunctional(c.default || c))
export const ControlPanelIcon = () => import('../../components/control-panel/ControlPanelIcon.vue' /* webpackChunkName: "components/control-panel-icon" */).then(c => wrapFunctional(c.default || c))
export const ControlPanelIndicator = () => import('../../components/control-panel/ControlPanelIndicator.vue' /* webpackChunkName: "components/control-panel-indicator" */).then(c => wrapFunctional(c.default || c))
export const ControlPanelLinear = () => import('../../components/control-panel/ControlPanelLinear.vue' /* webpackChunkName: "components/control-panel-linear" */).then(c => wrapFunctional(c.default || c))
export const ControlPanelLottie = () => import('../../components/control-panel/ControlPanelLottie.vue' /* webpackChunkName: "components/control-panel-lottie" */).then(c => wrapFunctional(c.default || c))
export const ControlPanelSchedule = () => import('../../components/control-panel/ControlPanelSchedule.vue' /* webpackChunkName: "components/control-panel-schedule" */).then(c => wrapFunctional(c.default || c))
export const ControlPanelTimer = () => import('../../components/control-panel/ControlPanelTimer.vue' /* webpackChunkName: "components/control-panel-timer" */).then(c => wrapFunctional(c.default || c))
export const DashboardCommandsBlock = () => import('../../components/dashboard/dashboardCommandsBlock.vue' /* webpackChunkName: "components/dashboard-commands-block" */).then(c => wrapFunctional(c.default || c))
export const DashboardRoomsBlock = () => import('../../components/dashboard/dashboardRoomsBlock.vue' /* webpackChunkName: "components/dashboard-rooms-block" */).then(c => wrapFunctional(c.default || c))
export const DashboardScheduleBlock = () => import('../../components/dashboard/dashboardScheduleBlock.vue' /* webpackChunkName: "components/dashboard-schedule-block" */).then(c => wrapFunctional(c.default || c))
export const DashboardStorySliderBlock = () => import('../../components/dashboard/dashboardStorySliderBlock.vue' /* webpackChunkName: "components/dashboard-story-slider-block" */).then(c => wrapFunctional(c.default || c))
export const LoadersEnergyPreloader = () => import('../../components/loaders/EnergyPreloader.vue' /* webpackChunkName: "components/loaders-energy-preloader" */).then(c => wrapFunctional(c.default || c))
export const LoadersLoaderBorder = () => import('../../components/loaders/LoaderBorder.vue' /* webpackChunkName: "components/loaders-loader-border" */).then(c => wrapFunctional(c.default || c))
export const LoadersLoadingBar = () => import('../../components/loaders/LoadingBar.vue' /* webpackChunkName: "components/loaders-loading-bar" */).then(c => wrapFunctional(c.default || c))
export const LoadersLoadingSpinner = () => import('../../components/loaders/LoadingSpinner.vue' /* webpackChunkName: "components/loaders-loading-spinner" */).then(c => wrapFunctional(c.default || c))
export const LoadersLoadingSpinnerFunction = () => import('../../components/loaders/LoadingSpinnerFunction.vue' /* webpackChunkName: "components/loaders-loading-spinner-function" */).then(c => wrapFunctional(c.default || c))
export const LoadersLoadingSpinnerInput = () => import('../../components/loaders/LoadingSpinnerInput.vue' /* webpackChunkName: "components/loaders-loading-spinner-input" */).then(c => wrapFunctional(c.default || c))
export const LoadersLoadingSpinnerLaunch = () => import('../../components/loaders/LoadingSpinnerLaunch.vue' /* webpackChunkName: "components/loaders-loading-spinner-launch" */).then(c => wrapFunctional(c.default || c))
export const LoadersStorySkeletonLoader = () => import('../../components/loaders/StorySkeletonLoader.vue' /* webpackChunkName: "components/loaders-story-skeleton-loader" */).then(c => wrapFunctional(c.default || c))
export const ModalsActivationModal = () => import('../../components/modals/ActivationModal.vue' /* webpackChunkName: "components/modals-activation-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsAddCommandModal = () => import('../../components/modals/AddCommandModal.vue' /* webpackChunkName: "components/modals-add-command-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsAddFastCommandModal = () => import('../../components/modals/AddFastCommandModal.vue' /* webpackChunkName: "components/modals-add-fast-command-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsAddNumberModal = () => import('../../components/modals/AddNumberModal.vue' /* webpackChunkName: "components/modals-add-number-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsAddUserModal = () => import('../../components/modals/AddUserModal.vue' /* webpackChunkName: "components/modals-add-user-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsAddUserPlacesModal = () => import('../../components/modals/AddUserPlacesModal.vue' /* webpackChunkName: "components/modals-add-user-places-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsAdditionalModeModal = () => import('../../components/modals/AdditionalModeModal.vue' /* webpackChunkName: "components/modals-additional-mode-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsCancelScheduleModal = () => import('../../components/modals/CancelScheduleModal.vue' /* webpackChunkName: "components/modals-cancel-schedule-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsChangePasswordModal = () => import('../../components/modals/ChangePasswordModal.vue' /* webpackChunkName: "components/modals-change-password-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsChangeUserModal = () => import('../../components/modals/ChangeUserModal.vue' /* webpackChunkName: "components/modals-change-user-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsChangeUserPlacesModal = () => import('../../components/modals/ChangeUserPlacesModal.vue' /* webpackChunkName: "components/modals-change-user-places-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsCityModal = () => import('../../components/modals/CityModal.vue' /* webpackChunkName: "components/modals-city-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsCleanerLegendModal = () => import('../../components/modals/CleanerLegendModal.vue' /* webpackChunkName: "components/modals-cleaner-legend-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsClimateOnlineModal = () => import('../../components/modals/ClimateOnlineModal.vue' /* webpackChunkName: "components/modals-climate-online-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsClimateOnlineSummaryModal = () => import('../../components/modals/ClimateOnlineSummaryModal.vue' /* webpackChunkName: "components/modals-climate-online-summary-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsComfortSleepModal = () => import('../../components/modals/ComfortSleepModal.vue' /* webpackChunkName: "components/modals-comfort-sleep-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsCommonMessageModal = () => import('../../components/modals/CommonMessageModal.vue' /* webpackChunkName: "components/modals-common-message-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsConflictResolveModal = () => import('../../components/modals/ConflictResolveModal.vue' /* webpackChunkName: "components/modals-conflict-resolve-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsControlFunctionDetailModal = () => import('../../components/modals/ControlFunctionDetailModal.vue' /* webpackChunkName: "components/modals-control-function-detail-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsCreateEditSchedule = () => import('../../components/modals/CreateEditSchedule.vue' /* webpackChunkName: "components/modals-create-edit-schedule" */).then(c => wrapFunctional(c.default || c))
export const ModalsCreateGroupModal = () => import('../../components/modals/CreateGroupModal.vue' /* webpackChunkName: "components/modals-create-group-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsCreateGroupModalDnd = () => import('../../components/modals/CreateGroupModalDnd.vue' /* webpackChunkName: "components/modals-create-group-modal-dnd" */).then(c => wrapFunctional(c.default || c))
export const ModalsCreateGroupModalPlaces = () => import('../../components/modals/CreateGroupModalPlaces.vue' /* webpackChunkName: "components/modals-create-group-modal-places" */).then(c => wrapFunctional(c.default || c))
export const ModalsDefaultMessageModal = () => import('../../components/modals/DefaultMessageModal.vue' /* webpackChunkName: "components/modals-default-message-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsDeleteModal = () => import('../../components/modals/DeleteModal.vue' /* webpackChunkName: "components/modals-delete-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsDeviceErrorModal = () => import('../../components/modals/DeviceErrorModal.vue' /* webpackChunkName: "components/modals-device-error-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsEditGroupModal = () => import('../../components/modals/EditGroupModal.vue' /* webpackChunkName: "components/modals-edit-group-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsEnergyModal = () => import('../../components/modals/EnergyModal.vue' /* webpackChunkName: "components/modals-energy-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsEnergyTarifficationDeleteModal = () => import('../../components/modals/EnergyTarifficationDeleteModal.vue' /* webpackChunkName: "components/modals-energy-tariffication-delete-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsEnergyTarifficationPointModal = () => import('../../components/modals/EnergyTarifficationPointModal.vue' /* webpackChunkName: "components/modals-energy-tariffication-point-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsEnergyTarificationModal = () => import('../../components/modals/EnergyTarificationModal.vue' /* webpackChunkName: "components/modals-energy-tarification-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsFastCommandModal = () => import('../../components/modals/FastCommandModal.vue' /* webpackChunkName: "components/modals-fast-command-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsGoToAppModal = () => import('../../components/modals/GoToAppModal.vue' /* webpackChunkName: "components/modals-go-to-app-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsGoToObjectModal = () => import('../../components/modals/GoToObjectModal.vue' /* webpackChunkName: "components/modals-go-to-object-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsIndicatorModal = () => import('../../components/modals/IndicatorModal.vue' /* webpackChunkName: "components/modals-indicator-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsInputModal = () => import('../../components/modals/InputModal.vue' /* webpackChunkName: "components/modals-input-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsModal500 = () => import('../../components/modals/Modal500.vue' /* webpackChunkName: "components/modals-modal500" */).then(c => wrapFunctional(c.default || c))
export const ModalsModalDeleteAccount = () => import('../../components/modals/ModalDeleteAccount.vue' /* webpackChunkName: "components/modals-modal-delete-account" */).then(c => wrapFunctional(c.default || c))
export const ModalsModalEmailAdd = () => import('../../components/modals/ModalEmailAdd.vue' /* webpackChunkName: "components/modals-modal-email-add" */).then(c => wrapFunctional(c.default || c))
export const ModalsModalNoConnection = () => import('../../components/modals/ModalNoConnection.vue' /* webpackChunkName: "components/modals-modal-no-connection" */).then(c => wrapFunctional(c.default || c))
export const ModalsModalProblems = () => import('../../components/modals/ModalProblems.vue' /* webpackChunkName: "components/modals-modal-problems" */).then(c => wrapFunctional(c.default || c))
export const ModalsModalTemplate = () => import('../../components/modals/ModalTemplate.vue' /* webpackChunkName: "components/modals-modal-template" */).then(c => wrapFunctional(c.default || c))
export const ModalsNameScheduleModal = () => import('../../components/modals/NameScheduleModal.vue' /* webpackChunkName: "components/modals-name-schedule-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsOptionInfoModal = () => import('../../components/modals/OptionInfoModal.vue' /* webpackChunkName: "components/modals-option-info-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsPaymentInfoModal = () => import('../../components/modals/PaymentInfoModal.vue' /* webpackChunkName: "components/modals-payment-info-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsPaymentModal = () => import('../../components/modals/PaymentModal.vue' /* webpackChunkName: "components/modals-payment-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsPromocodeModal = () => import('../../components/modals/PromocodeModal.vue' /* webpackChunkName: "components/modals-promocode-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsRecommendationModal = () => import('../../components/modals/RecommendationModal.vue' /* webpackChunkName: "components/modals-recommendation-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsRestoreAccountModal = () => import('../../components/modals/RestoreAccountModal.vue' /* webpackChunkName: "components/modals-restore-account-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsStartTimerModal = () => import('../../components/modals/StartTimerModal.vue' /* webpackChunkName: "components/modals-start-timer-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsStoryModal = () => import('../../components/modals/StoryModal.vue' /* webpackChunkName: "components/modals-story-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsTransferAccessModal = () => import('../../components/modals/TransferAccessModal.vue' /* webpackChunkName: "components/modals-transfer-access-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsServiceSuccessModal = () => import('../../components/modals/serviceSuccessModal.vue' /* webpackChunkName: "components/modals-service-success-modal" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersAddFastCommandPlaceholder = () => import('../../components/placeholders/AddFastCommandPlaceholder.vue' /* webpackChunkName: "components/placeholders-add-fast-command-placeholder" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersAddObjectsPlaceholder = () => import('../../components/placeholders/AddObjectsPlaceholder.vue' /* webpackChunkName: "components/placeholders-add-objects-placeholder" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersAddSchedulePlaceholder = () => import('../../components/placeholders/AddSchedulePlaceholder.vue' /* webpackChunkName: "components/placeholders-add-schedule-placeholder" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersNoResultFastCommandPlaceholder = () => import('../../components/placeholders/NoResultFastCommandPlaceholder.vue' /* webpackChunkName: "components/placeholders-no-result-fast-command-placeholder" */).then(c => wrapFunctional(c.default || c))
export const ProfileForm = () => import('../../components/profile/ProfileForm.vue' /* webpackChunkName: "components/profile-form" */).then(c => wrapFunctional(c.default || c))
export const ProfileUserAvatar = () => import('../../components/profile/UserAvatar.vue' /* webpackChunkName: "components/profile-user-avatar" */).then(c => wrapFunctional(c.default || c))
export const ProfileUserAvatarBus = () => import('../../components/profile/UserAvatarBus.vue' /* webpackChunkName: "components/profile-user-avatar-bus" */).then(c => wrapFunctional(c.default || c))
export const ResizeCol = () => import('../../components/resize/ResizeCol.vue' /* webpackChunkName: "components/resize-col" */).then(c => wrapFunctional(c.default || c))
export const ResizeColDouble = () => import('../../components/resize/ResizeColDouble.vue' /* webpackChunkName: "components/resize-col-double" */).then(c => wrapFunctional(c.default || c))
export const ScheduleAccordion = () => import('../../components/schedule/ScheduleAccordion.vue' /* webpackChunkName: "components/schedule-accordion" */).then(c => wrapFunctional(c.default || c))
export const ScheduleEditor = () => import('../../components/schedule/ScheduleEditor.vue' /* webpackChunkName: "components/schedule-editor" */).then(c => wrapFunctional(c.default || c))
export const SchedulePlaceSetting = () => import('../../components/schedule/SchedulePlaceSetting.vue' /* webpackChunkName: "components/schedule-place-setting" */).then(c => wrapFunctional(c.default || c))
export const ScheduleTimeLine = () => import('../../components/schedule/TimeLine.vue' /* webpackChunkName: "components/schedule-time-line" */).then(c => wrapFunctional(c.default || c))
export const SliderDashboardSlider = () => import('../../components/slider/DashboardSlider.vue' /* webpackChunkName: "components/slider-dashboard-slider" */).then(c => wrapFunctional(c.default || c))
export const SliderPaymentCardsSlider = () => import('../../components/slider/PaymentCardsSlider.vue' /* webpackChunkName: "components/slider-payment-cards-slider" */).then(c => wrapFunctional(c.default || c))
export const TablesErrorsTable = () => import('../../components/tables/ErrorsTable.vue' /* webpackChunkName: "components/tables-errors-table" */).then(c => wrapFunctional(c.default || c))
export const TablesFastCommandsTable = () => import('../../components/tables/FastCommandsTable.vue' /* webpackChunkName: "components/tables-fast-commands-table" */).then(c => wrapFunctional(c.default || c))
export const TablesGroupTableItem = () => import('../../components/tables/GroupTableItem.vue' /* webpackChunkName: "components/tables-group-table-item" */).then(c => wrapFunctional(c.default || c))
export const TablesGroupTableWrapper = () => import('../../components/tables/GroupTableWrapper.vue' /* webpackChunkName: "components/tables-group-table-wrapper" */).then(c => wrapFunctional(c.default || c))
export const TablesGroupsTable = () => import('../../components/tables/GroupsTable.vue' /* webpackChunkName: "components/tables-groups-table" */).then(c => wrapFunctional(c.default || c))
export const TablesObjectsTable = () => import('../../components/tables/ObjectsTable.vue' /* webpackChunkName: "components/tables-objects-table" */).then(c => wrapFunctional(c.default || c))
export const TablesPresetPlaceTable = () => import('../../components/tables/PresetPlaceTable.vue' /* webpackChunkName: "components/tables-preset-place-table" */).then(c => wrapFunctional(c.default || c))
export const TablesTableHeader = () => import('../../components/tables/TableHeader.vue' /* webpackChunkName: "components/tables-table-header" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../components/tabs/Tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const CardsCardGroupBlockPlaceBlockInGroup = () => import('../../components/Cards/CardGroupBlock/PlaceBlockInGroup.vue' /* webpackChunkName: "components/cards-card-group-block-place-block-in-group" */).then(c => wrapFunctional(c.default || c))
export const CardsCardRoomStateConnectedCardRoomState = () => import('../../components/Cards/CardRoomState/ConnectedCardRoomState.vue' /* webpackChunkName: "components/cards-card-room-state-connected-card-room-state" */).then(c => wrapFunctional(c.default || c))
export const CardsCardRoomStateDisconnectCardRoomState = () => import('../../components/Cards/CardRoomState/DisconnectCardRoomState.vue' /* webpackChunkName: "components/cards-card-room-state-disconnect-card-room-state" */).then(c => wrapFunctional(c.default || c))
export const CardsCardRoomStateOffCardRoomState = () => import('../../components/Cards/CardRoomState/OffCardRoomState.vue' /* webpackChunkName: "components/cards-card-room-state-off-card-room-state" */).then(c => wrapFunctional(c.default || c))
export const CardsCardRoomStateSubscribeCardRoomState = () => import('../../components/Cards/CardRoomState/SubscribeCardRoomState.vue' /* webpackChunkName: "components/cards-card-room-state-subscribe-card-room-state" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
