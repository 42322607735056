export default ({ app, store }) => {
  app.router.beforeEach((to, from, next) => {
    if (from.name) {
      store.dispatch('system/togglePanelActive', false);
      store.dispatch('system/setObjectPanel', false);
      store.dispatch('system/toggleFastCommandsPanel', false);
      store.commit('system/TOGGLE_SCHEDULE_PANEL', false);
    }
    if (app.store.$auth.loggedIn && from.path !== '/auth') {
      app.$auth.fetchUser(); // обновление для отображения кнопки принятия прав
    }
    if (app.store.$auth.loggedIn && to.name === 'auth') {
      next(false);
    } else if (app.store.$auth.loggedIn && to.path === '/') {
      next('/dashboard');
    } else if (app.store.$auth.loggedIn && !app.store.$auth.user.fio && to.name !== 'profile-initial') {
      next('/profile/initial');
    } else {
      next();
    }
  });
};
