
import VueContext from 'vue-context';
import { mapActions, mapGetters } from 'vuex';
import contextMenuMixin from './contextMenuMixin';

export default {
  components: { VueContext },
  mixins: [contextMenuMixin],
  computed: {
    ...mapGetters({
      dashboard: 'buildingDashboard/getBuildingSelectDashboard',
      getTranslations: 'localization/getTranslations',
    }),
  },
  methods: {
    ...mapActions({
      unpinCardFromDashboard: 'buildingDashboard/unpinCardFromDashboard',
      setModalInputData: 'modalInput/setModalData',
      pinSchedule: 'buildingDashboard/pinCard',
      setModalDeleteData: 'modalDelete/setModalData',
      openEditScheduleModal: 'schedules/openEditScheduleModal',
    }),
    async unpinFromDashboard(data) {
      await this.unpinCardFromDashboard({
        type: 'schedule',
        group: data,
      });

      this.closeMenu();
    },
    async pinCard(data) {
      await this.pinSchedule({
        type: 'schedule',
        group: data,
      });

      this.closeMenu();
    },
    removeSchedule(data) {
      this.setModalDeleteData({
        title: this.getTranslations['Вы уверены, что хотите удалить расписание?'] || 'Вы уверены, что хотите удалить расписание?',
        text: `${this.getTranslations['{0} будет удалено'][0]}«${data.title}»${this.getTranslations['{0} будет удалено'][1]}.`,
        object: data,
        type: 'schedule',
      });
      this.$modal.show('deleteModal');

      this.closeMenu();
    },
    renameSchedule(data) {
      this.setModalInputData({
        renameType: 'schedule',
        title: this.getTranslations['Переименовать'] || 'Переименовать',
        inputValue: data.title,
        object: data,
      });
      this.$modal.show('inputModal');

      this.closeMenu();
    },
    async editSchedule(schedule) {
      await this.openEditScheduleModal(schedule.id);
      this.$modal.show('createEditSchedule');

      this.closeMenu();
    },
    closeMenu() {
      this.$refs.menu.close();
    },
  },
};
