
// eslint-disable-next-line import/no-extraneous-dependencies
import { mapActions, mapGetters } from 'vuex';
import CloseIcon from '../../assets/icons/x.svg';
import SettingIcon from '../../assets/icons/settings.svg';
import TimeLineSmall from '../TimeLineSmall.vue';
import ScheduleAccordion from '../schedule/ScheduleAccordion.vue';
import DashboardScheduleContextMenu from '../contextMenu/dashboardScheduleContextMenu.vue';
import ButtonState from '../Buttons/ButtonState.vue';

export default {
  name: 'ScheduleMenu',
  components: {
    ButtonState,
    DashboardScheduleContextMenu,
    ScheduleAccordion,
    TimeLineSmall,
    CloseIcon,
    SettingIcon,
  },
  data: () => ({
    activePlaceId: null,
    blockOnOffButton: false,
  }),
  computed: {
    ...mapGetters({
      buildingSelectDashboard: 'buildingDashboard/getBuildingSelectDashboard',
      currentScheduleId: 'system/getScheduleActiveId',
      getTranslations: 'localization/getTranslations',
    }),
    objectsText() {
      if (this.currentSchedule.places.length === 1) {
        return this.currentSchedule.places[0].placeTitle;
      }
      return `${this.currentSchedule.places.length} ${this.placesText}`;
    },
    placesText() {
      return this.currentSchedule
        && this.$numWord(this.currentSchedule.places.length, [this.getTranslations['устройство'] || 'устройство', this.getTranslations['устройства'] || 'устройства', this.getTranslations['устройств'] || 'устройств']);
    },
    currentSchedule() {
      return this.buildingSelectDashboard
        && this.buildingSelectDashboard.schedules
        && this.buildingSelectDashboard.schedules.find((item) => item.id === this.currentScheduleId);
    },
    activePlace() {
      return this.currentSchedule && this.currentSchedule.places.find((placeSh) => placeSh.id === this.activePlaceId);
    },
  },
  watch: {
    currentScheduleId: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.activePlaceId = this.currentSchedule.places[0].id;
        }
      },
      immediate: true,
    },
    currentSchedule: {
      handler() {
        this.activePlaceId = this.currentSchedule.places[0].id;
      },
    },
  },
  beforeMount() {
    this.activePlaceId = this.currentSchedule.places[0].id;
  },
  methods: {
    ...mapActions({
      toggleStatus: 'buildingDashboard/setScheduleStatus',
    }),
    closePanelOnBg(e) {
      const path = e.path || e.composedPath();
      if (path && path[0] && path[0].classList && path[0].classList.contains('object-panel__bg')) {
        this.closePanel();
      }
    },
    toggleShowSettings(e) {
      this.$refs.menu.toggle([e, this.currentSchedule]);
    },
    closePanel() {
      this.$store.commit('system/TOGGLE_SCHEDULE_PANEL', false);
    },
    async toggleState() {
      this.blockOnOffButton = true;
      await this.toggleStatus({ id: this.currentSchedule.id, isOn: this.currentSchedule.isOn });
      this.blockOnOffButton = false;
    },
  },
};
