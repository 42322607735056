
export default {
  name: "FanPowerButton",
  props: {
    isOn: {
      type: Boolean,
      default: false,
    },
    controllable: {
      type: Boolean,
      default: false,
    },
    blocked: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
  },
};
