import { render, staticRenderFns } from "./ClimateOnlineConnect.vue?vue&type=template&id=12d87e0f"
import script from "./ClimateOnlineConnect.vue?vue&type=script&lang=js"
export * from "./ClimateOnlineConnect.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ClimateOnlinePromoSmall: require('/builds/web-cloud/webfront/components/climate-online/ClimateOnlinePromoSmall.vue').default,ClimateOnlinePromo: require('/builds/web-cloud/webfront/components/climate-online/ClimateOnlinePromo.vue').default})
