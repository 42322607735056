export const SET_LOCAL = 'SET_LOCAL';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_QUERY_LANGUAGE = 'SET_QUERY_LANGUAGE';

export const state = () => ({
  local: null,
  lang: null,
  queryLang: null,
});

export const mutations = {
  [SET_LOCAL](state, value) {
    state.local = value;
  },
  [SET_LANGUAGE](state, value) {
    state.lang = value;
  },
  [SET_QUERY_LANGUAGE](state, value) {
    state.queryLang = value;
  },
};

export const getters = {
  getTranslations: ({ local, lang, queryLang }) => {
    if (local && lang) {
      const acceptedLang = queryLang ? queryLang : lang

      const res = {};
      Object.keys(local).forEach(key => {
        local[key].forEach(item => {
          if (!item['ru']) return;

          if (res[item['ru']]) return;

          let phrase = item[acceptedLang];

          phrase = phrase.includes('Uygulamaya giriş yapmak ya da kaydolmak için e-posta adresinizi ya da telefon numaranızı girin')
            ? phrase.replace(/e-posta/g, 'e&#8209;posta').toString()
            : phrase;

          res[item['ru']] = phrase.includes('{0}') ? phrase.split('{0}') : phrase;
        });
      });
      return res;
    }

    return {};
  },
  getLang: ({ lang }) => lang,
};
