import json from "../localization/localization.json";
import { LANGS } from "../localization/const";
import { storageLangName } from "../localization/const";

export default (context) => {
  context.store.commit("localization/SET_LOCAL", json);

  if (localStorage.getItem(storageLangName)) {
    context.store.commit(
      "localization/SET_LANGUAGE",
      localStorage.getItem(storageLangName)
    );
  } else {
    const localLanguage = navigator.language;
    const lang = LANGS.find((l) => localLanguage.includes(l));
    const submittedLanguage = lang ? lang : "en";
    context.store.commit("localization/SET_LANGUAGE", submittedLanguage);
  }
};
