export default {
  mounted() {
    this.$bus.$on('openContextMenu', () => {
      if (this.$refs && this.$refs.menu) {
        this.$refs.menu.close();
      }
    });
  },
  methods: {
    open(args) {
      const argsToSend = args;
      if (argsToSend[0].detail && argsToSend[0].detail !== 1) {
        argsToSend[0].clientX = argsToSend[0].detail.clientX;
        argsToSend[0].clientY = argsToSend[0].detail.clientY;
      }
      this.$bus.$emit('openContextMenu');
      if (this.$refs && this.$refs.menu) {
        this.currentData = argsToSend;
        this.$refs.menu.open(...argsToSend);
      }
    },
    toggle(args) {
      if (this.$refs.menu.show) {
        this.$refs.menu.close();
      } else {
        this.open(args);
      }
    },
  },
};
