
export default {
  name: 'CheckboxObject',
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    counter: {
      type: String,
      default: '',
    },
    animation: {
      default: false,
      type: Boolean,
    },
  },
  mounted() {
    if (this.animation && this.$refs.checkbox) {
      setTimeout(() => {
        this.$refs.checkbox.style.opacity = 1;
      }, 300);
    }
  },
};
