
import { mapActions, mapGetters } from "vuex";
import modalEsc from "@/mixins/modalEsc";
import PlaceStatus from "@/components/class/PlaceStatus";
import AppButton from "@/components/Buttons/AppButton";
import TimerInput from "@/components/Inputs/TimerInput";
import { errorConflictHandler } from "@/utils/api";

export default {
  name: "CancelScheduleModal",
  components: {
    AppButton,
    TimerInput,
  },
  mixins: [modalEsc],
  data: () => ({
    modalName: "CancelScheduleModal",
    timeValueInitial: "00:30",
    timeValue: "00:30",
    loading: false,
  }),
  computed: {
    ...mapGetters({
      objectInfo: "modalCancelSchedule/objectInfo",
      getTranslations: 'localization/getTranslations',
    }),
    placeStatus() {
      return new PlaceStatus(this.objectInfo);
    },
    scheduleTitle() {
      if (this.objectInfo?.currentSchedule?.scheduleTitle) {
        return this.objectInfo.currentSchedule.scheduleTitle;
      }
      return "";
    },
    disabledPostpone() {
      return this.timeValue.length < 5;
    },
  },
  methods: {
    ...mapActions({
      toggleModal: "system/setShowModal",
      findCurrentDevice: "deviceFunctions/findCurrentDevice",
    }),
    async modalOpened() {
      document.addEventListener("keydown", this.clickHandler);
      this.toggleModal(true);
    },
    async modalClosed() {
      this.timeValue = "00:30";
      document.removeEventListener("keydown", this.clickHandler);
      this.toggleModal(false);
    },

    onTimeChange(value) {
      this.timeValue = value;
    },

    submitCancel() {
      this.cancelSchedule();
    },
    cancelSchedule() {
      const requestBody = {
        isOn: false,
        conflictResolveData: null,
      };

      this.loading = true;
      this.$axios
        .post(
          `/schedules/${this.objectInfo.currentSchedule.scheduleId}/ctrl?ignoreConflicts=false`,
          requestBody,
          {
            Authorization: `Bearer ${this.$auth.user.token}`,
          }
        )
        .then((response) => {
          const systemStateUpdate = response.data.data;
          this.findCurrentDevice(systemStateUpdate);
          this.$modal.hide(this.modalName);
        })
        .catch((error) => {
          errorConflictHandler.call(this.$store, error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    submitPostpone() {
      this.postponeSchedule();
    },
    postponeSchedule() {
      const timeArr = this.timeValue.split(":");
      const timeInMinutes = Number(timeArr[0]) * 60 + Number(timeArr[1]);

      const periodsIds = this.objectInfo.currentSchedule.periods.map(
        (period) => period.id
      );

      const requestBody = {
        minutes: timeInMinutes,
        placeSchedules: periodsIds,
      };

      this.loading = true;
      this.$axios
        .put("/schedules/postpone", requestBody, {
          Authorization: `Bearer ${this.$auth.user.token}`,
        })
        .then((response) => {
          const systemStateUpdate = response.data.data;
          //console.log(systemStateUpdate);
          // TODO: updateSystemState
          this.findCurrentDevice(systemStateUpdate);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
