
import { mapGetters } from "vuex";
import Logo from "@/assets/images/co-no-errors.svg";

export default {
  name: "ClimateOnlineNoErrors",
  components: {
    Logo,
  },
  computed: {
    ...mapGetters({
      getTranslations: "localization/getTranslations",
      panelDeviceId: "system/getPanelDeviceId",
    }),
  },
  methods: {
    onClick() {
      this.$modal.hide("climateOnlineModal");
    },
  },
};
