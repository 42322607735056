
import HeartIcon from "../assets/images/heart.svg";

export default {
  name: "ClimateOnlineLable",
  components: {
    HeartIcon,
  },
  props: {
    climateOnlineInfo: {
      type: Object,
      default: () => {},
    },
    isShiftedTop: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    console.log(this.climateOnlineInfo);
  },
};
