import Vue from 'vue';

// $numWord.(count, ['1 яблоко', '2 яблока', '5 яблок']);

export default ({ app, store }) => {

  Vue.prototype.$numWord = (number, titles) => {
    const lang = store.getters['localization/getLang'];
    if (lang === 'ru') {
      const cases = [2, 0, 1, 1, 1, 2];
      return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    }

    if (lang === 'en') {
      return titles[number === 1 ? 0 : 1];
    }

    if (lang === 'fr') {
      return titles[number < 2 ? 0 : 1];
    }

    if (lang === 'tr') {
      return titles[0];
    }

    const cases = [2, 0, 1, 1, 1, 2];
    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
  };
};
