
export default {
  name: 'SettingButton',
  props: {
    type: {
      type: String,
      default: 'default',
      validator: (e) => e === 'preset' || e === 'custom' || e === 'default',
    },
    isActive: {
      default: false,
      type: Boolean,
    },
    icons: {
      default: () => [],
      type: Array,
    },
    text: {
      type: String,
      required: true,
    },
    currTemp: {
      type: [String, Boolean],
      required: false,
    },
  },
  emits: ['click'],
  methods: {
    hiddenIcons(icons, number) {
      return icons ? icons.slice(number).length : 0;
    },
  },
};
