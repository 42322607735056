
import { mapGetters } from "vuex";

export default {
  name: "CleanerLegendModal",
  data: () => ({
    modalName: "cleanerLegendModal"
  }),
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
  },
};
