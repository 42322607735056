
import { mapActions, mapGetters } from "vuex";
import modalEsc from "@/mixins/modalEsc";
import AppButton from "@/components/Buttons/AppButton";

export default {
  name: "DefaultMessageModal",
  components: { AppButton },
  mixins: [modalEsc],
  data: () => ({
    modalName: "DefaultMessageModal",
    isActionClick: false,
  }),
  computed: {
    ...mapGetters({
      getTranslations: "localization/getTranslations",
      title: "modalDefaultMessage/getTitle",
      text: "modalDefaultMessage/getText",
      action: "modalDefaultMessage/getAction",
      status: "modalDefaultMessage/getStatus",
    }),
  },
  methods: {
    ...mapActions({
      setShowModal: "system/setShowModal",
      setDefaultMessageModalData: "modalDefaultMessage/setDefaultModalData",
    }),
    openedModal() {
      //console.log(this.$modal);
      document.addEventListener("keydown", this.clickHandler, {
        useCapture: true,
      });
      this.setShowModal(true);
    },
    closedModal() {
      if (!this.isActionClick) {
        this.action && this.action.onClose && this.action.onClose();
      }
      document.removeEventListener("keydown", this.clickHandler);
      this.setShowModal(false);
      if (this.isActionClick) this.action.onRepeat();
      this.setDefaultMessageModalData({
        title: null,
        text: null,
        action: null,
        status: null,
      });
      this.isActionClick = false;
    },
    onRepeat() {
      this.isActionClick = true;
      this.$modal.hide(this.modalName);
      // this.action.onRepeat();
    },
    onCloseClick() {
      this.$modal.hide(this.modalName);
      // if (this.action && this.action.onClose) {
      //   this.action.onClose();
      // }
    },
  },
};
