import { APP_VERSION } from "../versions/app-version";

import { storageLangName } from "../localization/const";

export default function ({ $axios, store }, inject) {
  $axios.defaults.timeout = 30000;

  $axios.onRequest((config) => {
    const lang = localStorage.getItem(storageLangName);
    if (lang) config.headers.common["Accept-Language"] = lang;

    // Иначе в nuxt.config генерируется на сервере один раз при build
    config.headers.common.utcOffset = new Date().getTimezoneOffset() * -1;
  });

  const ax = $axios.create({
    baseURL: process.env.API_URL,
    headers: {
      common: {
        appVersion: APP_VERSION,
        platform: "Web",
      },
    },
  });

  inject("ax", ax);
}
