
import { mixin as clickaway } from "vue-clickaway";
import UserAvatarBus from "~/components/profile/UserAvatarBus.vue";
import { mapActions, mapGetters } from "vuex";
import ObjectsItem from "../ObjectsItem.vue";
import LogoDark from "../../assets/images/logo-dark.svg";
import ArrowIcon from "../../assets/icons/Arrows/arrow.svg";
import DashboardIcon from "../../assets/icons/side-bar/dashboard.svg";
import HousesIcon from "../../assets/icons/side-bar/houses.svg";
import FastCommand from "../../assets/icons/side-bar/fastCommands.svg";
import Schedule from "../../assets/icons/side-bar/schedule.svg";
import Notification from "../../assets/icons/side-bar/notification.svg";
import Rights from "../../assets/icons/side-bar/rights.svg";
import Settings from "../../assets/icons/settings.svg";
import Globe from "../../assets/icons/globe.svg";
import { LongNames } from "../../localization/const";
import StatusOk from "../../assets/icons/statusOk.svg";
import { storageLangName } from "../../localization/const.js";

export default {
  name: "SideMenu",
  components: {
    DashboardIcon,
    ObjectsItem,
    LogoDark,
    ArrowIcon,
    HousesIcon,
    FastCommand,
    Schedule,
    Notification,
    Rights,
    Settings,
    UserAvatarBus,
    Globe,
    StatusOk,
  },
  mixins: [clickaway],
  data() {
    return {
      version: "",
      showObjects: false,
      objects: [
        { name: "Моя квартира", status: "Владелец", img: "myhouse.png" },
        { name: "Загородный дом", status: "Полный доступ", img: "OutCiy.png" },
        { name: "Офис", status: "Ограниченный доступ", img: "office.png" },
      ],
      isLangPopupOpened: false,
    };
  },
  computed: {
    ...mapGetters({
      menuActive: "slideMenu/getMenuState",
      buildingsList: "buildings/getBuildingsList",
      buildingSelect: "buildings/getBuildingSelect",
      getTranslations: "localization/getTranslations",
      getLang: "localization/getLang",
    }),
    userName() {
      if (this.$auth.user?.fio) {
        return this.$auth.user?.fio;
      }
      return "";
    },
    langNames() {
      return LongNames;
    },
  },
  mounted() {
    document.addEventListener("click", this.onDocClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.onDocClick);
  },
  methods: {
    ...mapActions({
      toggleActive: "slideMenu/toggleMenuActive",
    }),
    onDocClick(e) {
      if (!e.target.closest(".side-menu__item--language")) {
        this.isLangPopupOpened = false;
      }
    },
    toggleShowObjects() {
      this.showObjects = !this.showObjects;
    },
    closeObjects() {
      if (this.showObjects) {
        this.showObjects = false;
      }
    },
    goToPage(page) {
      this.$router.push(page);
      this.toggleActive(false);
    },
    closeMenuAway(e) {
      let counter = 0;
      const path = e.path || (e.composedPath && e.composedPath());
      path &&
        path.forEach((block) => {
          if (
            block.className === "side-menu__list" ||
            block.className === "side-menu__header"
          ) {
            counter++;
          }
        });
      if (!counter) {
        this.toggleActive(false);
      }
    },
    async toggleClicked() {
      await this.toggleActive();
    },
    closeMenu() {
      if (this.menuActive) {
        this.toggleActive(false);
      }
    },
    getAccessName(name) {
      return this.getTranslations[name];
    },
    onLangButtonClick(e) {
      const langItem = e.target.closest(".side-menu__item-lang-tooltip-item");
      const tooltip = e.target.closest(".side-menu__item-lang-tooltip");
      if (!tooltip) {
        this.isLangPopupOpened = !this.isLangPopupOpened;
        return;
      }

      if (langItem && langItem.classList.contains("active")) {
        return;
      }

      if (langItem && !langItem.classList.contains("active")) {
        const lang = langItem.getAttribute("data-lang");
        // this.$store.commit("localization/SET_LANGUAGE", lang);
        localStorage.setItem(storageLangName, lang);

        window.location.reload();
        // this.isLangPopupOpened = false;
        // this.toggleActive(false);
      }
    },
  },
};
