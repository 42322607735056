
import { mapActions, mapGetters } from 'vuex';
import { userAccessRoles } from '@/utils/user';
import VrfIcon from '../../assets/icons/vrf.svg';

export default {
  name: 'OptionInfoModal',
  components: { VrfIcon },
  data: () => ({
    modalName: 'optionInfo',
    preparedInfo: null,
  }),
  computed: {
    ...mapGetters({
      optionInfoData: 'modalOptionInfo/getOptionInfoData',
      getTranslations: 'localization/getTranslations',
    }),
    accessName() {
      if (this.preparedInfo.access) {
        const currentAccess = userAccessRoles
          .find((item) => item.role === this.preparedInfo.access);
        if (currentAccess && currentAccess.title) {
          return this.getTranslations[currentAccess.title];
        }
      }
      return '';
    },
  },
  methods: {
    ...mapActions({
      setShowModal: 'system/setShowModal',
    }),
    onBeforeOpenEvent() {
      this.preparedInfo = this.optionInfoData;

      if (this.optionInfoData && !this.optionInfoData.deviceInfo && this.optionInfoData.id) {
        this.$axios.get(
          `/devices/${this.optionInfoData.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$auth.user.token}`,
            },
          },
        )
          .then((response) => {
            this.preparedInfo = response.data.data;
          })
          .catch((e) => console.log(e));
      }
    },
    onCloseEvent() {
      this.setShowModal(false);
      this.preparedInfo = null;
    },
  },
};
