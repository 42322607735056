
import SwiperCore, {
  Swiper, Navigation,
} from 'swiper';
import { mapActions, mapGetters } from 'vuex';
import CloseIcon from "../../assets/icons/24x24/close.svg";
import RangeSlider from '../RangeSlider.vue';
import ArrowDropdown from '../../assets/icons/Arrows/arrowDropdown.svg';
import SelectInput from '../Inputs/SelectInput.vue';
import MinusIcon from '../../assets/icons/count/Minus.svg';
import PlusIcon from '../../assets/icons/count/PLUS.svg';
import AppButton from '../Buttons/AppButton.vue';

SwiperCore.use([Navigation]);

export default {
  name: 'ComfortSleepModal',
  components: {
    CloseIcon,
    RangeSlider,
    ArrowDropdown,
    SelectInput,
    MinusIcon,
    PlusIcon,
    AppButton,
  },
  data: () => ({
    modalName: 'comfortSleepModal',
    graphRowHeight: 31,
    graphLineWeight: 2,
    graphWidth: 600,
    graphLineStartColor: 'rgba(213, 211, 255, 1)',
    graphLineEndColor: 'rgba(94, 169, 255, 1)',
    startPoints: [],
    middlePoints: [],
    endPoints: [],
    currentTime: null,
    xPeriods: [],
    timeInterval: null,
    tempSlider: null,
    initialTimeOption: null,
  }),
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
      comfortSleepData: 'deviceFunctions/getComfortSleepData',
      comfortSleepDataLoading: 'deviceFunctions/getComfortSleepDataLoading',
    }),
    timeOptions() {
      if (!this.comfortSleepData) return null;

      const data = [];

      for (let i = this.comfortSleepData.sleepTimeRange[0]; i <= this.comfortSleepData.sleepTimeRange[1]; i += 15) {
        data.push({
          value: i,
          title: this.getTimeFormat(i),
        });
      }

      return data;
    },
    graphLineGradient() {
      const beginColor = this.graphLineStartColor;
      const endColor = this.graphLineEndColor;
      const r1 = +this.getRgbChannel(beginColor, 0);
      const g1 = +this.getRgbChannel(beginColor, 1);
      const b1 = +this.getRgbChannel(beginColor, 2);
      const r2 = +this.getRgbChannel(endColor, 0);
      const g2 = +this.getRgbChannel(endColor, 1);
      const b2 = +this.getRgbChannel(endColor, 2);
      return `rgba(${this.interpolateByTime(r1, r2)}, ${this.interpolateByTime(g1, g2)}, ${this.interpolateByTime(b1, b2)}, 1)`;
    },
    additionalAboveTemp() {
      const temps = [];
      const amount = this.comfortSleepData.tempCount - 1 - this.comfortSleepData.targetTempIndex;

      for (let i = 0; i < amount; i++) {
        temps.push(this.comfortSleepData.targetTempRange[1] + amount - i);
      }

      return temps;
    },
    additionalUnderTemp() {
      const temps = [];
      const amount = this.comfortSleepData.targetTempIndex;

      for (let i = 1; i <= amount; i++) {
        temps.push(this.comfortSleepData.targetTempRange[0] - i);
      }

      return temps;
    },
    svgPath() {
      let path = '';
      let extremePoint = {};

      this.startPoints.forEach((point, idx) => {
        if (idx === 0) {
          path += `M0 ${this.getHeight(point.tempIndex)} H${this.getWidth(point.time)}`;
        }

        if (idx < this.startPoints.length - 1) {
          const point2 = this.startPoints[idx + 1];
          path += this.getCurve(point, point2);
        }
      });

      [extremePoint] = this.startPoints.slice(-1);

      if (this.middlePoints) {
        this.middlePoints.forEach((point, idx) => {
          if (idx === 0) {
            path += this.getCurve(this.startPoints.slice(-1)[0], point);
          }

          if (idx < this.middlePoints.length - 1) {
            const point2 = this.middlePoints[idx + 1];
            path += this.getCurve(point, point2);
          }
        });

        if (this.middlePoints.length) {
          [extremePoint] = this.middlePoints.slice(-1);
        }
      }

      this.recalculatedEndPoints.forEach((point, idx) => {
        if (idx === 0) {
          path += this.getCurve(extremePoint, point);
        }

        if (idx < this.recalculatedEndPoints.length - 1) {
          const point2 = this.recalculatedEndPoints[idx + 1];
          path += this.getCurve(point, point2);
        }
      });

      return path;
      // let path = `M0 ${(this.comfortSleepData.tempCount - this.comfortSleepData.targetTempIndex) * this.graphRowHeight}`
    },
    recalculatedEndPoints() {
      if (this.currentTime) {
        const delta = this.comfortSleepData.sleepTimeRange[1] - this.currentTime;

        return this.endPoints.map(point => ({
          ...point,
          time: point.time - delta,
        }));
      }

      return this.endPoints;
    },
  },
  methods: {
    ...mapActions({
      applyDeviceFunction: 'deviceFunctions/applyDeviceFunction',
    }),
    getHeight(index) {
      const delta = index === 0 ? this.graphLineWeight / 2 * -1 : this.graphLineWeight / 2;

      return (this.comfortSleepData.tempCount - 1 - index)
        * this.graphRowHeight
        + delta;
    },
    getWidth(num) {
      return Math.round(num * this.graphWidth / this.comfortSleepData.sleepTimeRange[1]);
    },
    getCurve(point1, point2) {
      const x = (point1.time + point2.time) / 2;

      return ` C${this.getWidth(x)} ${this.getHeight(point1.tempIndex)} ${this.getWidth(x)} ${this.getHeight(point2.tempIndex)} ${this.getWidth(point2.time)} ${this.getHeight(point2.tempIndex)}`;
    },
    recalcEndPoints() {

    },
    onTimeChoice(option) {
      this.currentTime = option.value;
    },
    onOpened() {
      this.tempSlider = new Swiper(this.$refs.tempSlider, {
        direction: 'vertical',
        slidesPerView: 'auto',
        initialSlide: this.getActiveTempSlideIndex(16),
        spaceBetween: 13,
        navigation: {
          prevEl: this.$refs.tempSliderPrev,
          nextEl: this.$refs.tempSliderNext,
        },
        // on: {
        //   slideChange: () => {
        //     console.log(this.tempSlider.activeIndex);
        //   },
        // },
      });

      this.tempSlider.on('slideChanged', () => {
        console.log(this.tempSlider.activeIndex);
      });
    },
    beforeOpen() {
      if (this.comfortSleepData && this.comfortSleepData.startPoints) {
        this.startPoints = this.comfortSleepData.startPoints;
      } else {
        this.startPoints = [];
      }
      if (this.comfortSleepData && this.comfortSleepData.middlePoints) {
        this.middlePoints = this.comfortSleepData.middlePoints;
      } else {
        this.middlePoints = [];
      }
      if (this.comfortSleepData && this.comfortSleepData.endPoints) {
        this.endPoints = this.comfortSleepData.endPoints;
      } else {
        this.endPoints = [];
      }

      if (this.comfortSleepData && this.comfortSleepData.defaultSleepTime) {
        this.currentTime = this.comfortSleepData.defaultSleepTime;
      } else {
        this.currentTime = this.comfortSleepData.sleepTimeRange[1];
      }

      this.initialTimeOption = {
        value: this.currentTime,
      };

      this.defineXPeriods();
      this.timeInterval = setInterval(() => {
        this.xPeriods = [];
        this.defineXPeriods();
      }, 60000);
    },
    beforeClose() {
      this.xPeriods = [];
      clearInterval(this.timeInterval);
      this.tempSlider = null;
      this.startPoints = [];
      this.middlePoints = [];
      this.endPoints = [];
      this.currentTime = null;
      this.xPeriods = [];
      this.timeInterval = null;
      this.tempSlider = null;
      this.initialTimeOption = null;
      this.$store.commit('deviceFunctions/setComfortSleepData', null);
    },
    getRgbChannel(color, num) {
      return color.split('(')[1].split(')')[0].split(',').map(i => i.trim())[num];
    },
    interpolateByTime(c1, c2) {
      const x = this.currentTime / this.comfortSleepData.sleepTimeRange[1];

      return Math.round(c1 + x * (c2 - c1));
    },
    defineXPeriods() {
      const time = new Date();
      let hours = time.getHours();
      const minutes = time.getMinutes();
      // let hours = 15;
      // const minutes = 0;

      const getExtendedHour = () => {
        if (hours === 23) {
          hours = 0;
        } else {
          hours += 1;
        }
        return hours;
      };

      let allTime = this.comfortSleepData.sleepTimeRange[1];

      let firstPeriod = minutes === 0 ? 0 : 60 - minutes;

      allTime -= firstPeriod;

      if (allTime >= 0) {
        this.xPeriods.push({
          hours: minutes === 0 ? hours : getExtendedHour(hours),
          minutes: firstPeriod,
        });
      }

      allTime -= 60;

      while (allTime >= 0) {
        this.xPeriods.push({
          hours: getExtendedHour(hours),
          minutes: firstPeriod += 60,
        });

        allTime -= 60;
      }
    },
    getHoursFormat(hours) {
      if (hours > 9) return hours;

      return `0${hours}`;
    },
    getActiveTempSlideIndex(temp) {
      let index;

      if (temp < this.comfortSleepData.targetTempRange[0]) {
        index = this.comfortSleepData.targetTempRange[1] - this.comfortSleepData.targetTempRange[0];
      } else if (temp > this.comfortSleepData.targetTempRange[1]) {
        index = 0;
      } else {
        index = this.comfortSleepData.targetTempRange[1] - temp;
      }

      return index;
    },
    isSubSubTempClass(index) {
      if (!this.tempSlider) return false;
      if (index - this.tempSlider.activeIndex <= this.comfortSleepData.tempCount - 1 - this.comfortSleepData.targetTempIndex - 1 - 2) return true;
      if (index - this.tempSlider.activeIndex >= this.comfortSleepData.tempCount - this.comfortSleepData.targetTempIndex + 2) return true;
      return false;
    },
    isSubTempClass(index) {
      if (!this.tempSlider) return false;
      if (index - this.tempSlider.activeIndex <= this.comfortSleepData.tempCount - 1 - this.comfortSleepData.targetTempIndex - 1 - 1) return true;
      if (index - this.tempSlider.activeIndex >= this.comfortSleepData.tempCount - this.comfortSleepData.targetTempIndex + 1) return true;
      return false;
    },
    isPrevTempClass(index) {
      if (!this.tempSlider) return false;
      if (index - this.tempSlider.activeIndex <= this.comfortSleepData.tempCount - 1 - this.comfortSleepData.targetTempIndex - 1) return true;
      if (index - this.tempSlider.activeIndex >= this.comfortSleepData.tempCount - this.comfortSleepData.targetTempIndex) return true;
      return false;
    },
    getTimeFormat(minutes) {
      let hours = Math.floor(minutes / 60);
      let min = minutes % 60;

      hours = hours > 9 ? hours : `0${hours}`;
      hours += ` ${this.getTranslations['ч']}`;
      min = min > 9 ? min : `0${min}`;
      min += ` ${this.getTranslations['мин']}`;

      return `${hours} ${min}`;
    },
    onMinusTempClick() {
      this.tempSlider.slideNext();
    },
    onPlusTempClick() {
      this.tempSlider.slidePrev();
    },
    onComfortSleepSubmit() {
      const data = {
        functionId: this.comfortSleepData.id,
        isOn: true,
        value: null,
        parameters: {
          comfortSleepParameters: {
            temp: this.comfortSleepData.targetTempRange[1] - this.tempSlider.activeIndex,
            sleepTime: this.currentTime,
          },
        },
      };

      this.applyDeviceFunction(data);
    },
  },
};
