
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PaymentInfoModal',
  data: () => ({
    modalName: 'paymentInfoModal',
    tabIndex: 0,
    subscription: null,
    preloading: false,
    modalMinHeight: 0,
    isError: false,
    errorMessage: null,
  }),
  computed: {
    ...mapGetters({
      subscriptionId: 'modalPaymentInfo/getPaymentInfoId',
      getTranslations: 'localization/getTranslations',
    }),
    headerStyle() {
      const style = {};
      if (this.subscription.headerBackgroundImage) {
        style.backgroundImage = `url(${this.subscription.headerBackgroundImage})`;
      }

      return style;
    },
  },
  methods: {
    ...mapActions({
      setShowModal: 'system/setShowModal',
    }),
    prepareData() {
      this.preloading = true;

      this.$axios.get(
        `subscriptions/${this.subscriptionId}`,
        {
          headers: {
            Authorization: `Bearer ${this.$auth.user && this.$auth.user.token}`,
          },
        },
      )
        .then((response) => {
          // console.log('#####sub', response.data.data);
          this.isError = false;
          this.subscription = response.data.data;
          this.setModalMinHeight();

          //MOCK - УДАЛИТЬ ПРИ ПОЯВЛЕНИИ ДАННЫХ С СЕРВЕРА
          // const data = {
          //   ...response.data.data,
          //   headerBackgroundImage: bg,
          //   headerDeviceImage: cbg,
          //   title: {
          //     text: 'Абонентский доступ на 1 год управления',
          //     textStyle: "MEDIUM",
          //     textColor: "#ffffff",
          //     spanData: null,
          //   },
          //   // title: 'Абонентский доступ на 1 год управления',
          // };
          // this.isError = false;
          // this.subscription = data;
          // this.setModalMinHeight();
        })
        .catch((e) => {
          this.isError = true;
          this.errorMessage = Object.values(e.response.data.errors).join('. ');
          console.error(e);
        })
        .finally(() => {
          this.preloading = false;
        });
    },
    setModalMinHeight() {
      const modal = document.querySelector('.modal-payment-info .vm--modal');
      if (modal) {
        const computedStyle = window.getComputedStyle(modal, null);
        const paddingTop = computedStyle.getPropertyValue('padding-top');
        const paddingBottom = computedStyle.getPropertyValue('padding-bottom');
        const padding = +paddingTop.replace('px', '')
          + +paddingBottom.replace('px', '');

        this.modalMinHeight = modal.offsetHeight - padding;
      }
    },
    onOpenModal() {
      this.setShowModal(true);
    },
    onCloseModal() {
      this.setShowModal(false);
      this.tabIndex = 0;
      this.subscription = null;
      this.modalMinHeight = 0;
      this.errorMessage = null;
      this.isError = false;
    },
  },
};
