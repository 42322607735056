
export default {
  name: "CardStep",
  props: {
    card: Object,
  },
  computed: {
    selectedStep() {
      return this.card.selectedStep;
    },
    titleStyle() {
      const style = {};
      if (!this.card.title) return style;

      if (this.card.title.textColor) {
        style.color = this.card.title.textColor;
      }

      return style;
    },
    subTitleStyle() {
      const style = {};
      if (!this.card.subTitle) return style;

      if (this.card.subTitle.textColor) {
        style.color = this.card.subTitle.textColor;
      }

      return style;
    },
  },
};
