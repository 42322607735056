
import VueContext from 'vue-context';
import { mapActions, mapGetters } from 'vuex';
import { errorConflictHandler } from '@/utils/api';
import contextMenuMixin from './contextMenuMixin';

export default {
  components: { VueContext },
  mixins: [contextMenuMixin],
  data: () => ({
    deletePlaceId: null,
  }),
  computed: {
    ...mapGetters({
      buildingSelectDashboard: 'buildingDashboard/getBuildingSelectDashboard',
      confirmDelete: 'modalDelete/getConfirmed',
      places: 'buildingDashboard/getBuildingsSelectedDashboardPlaces',
      groups: 'buildingDashboard/getBuildingsSelectedDashboardGroups',
      getTranslations: 'localization/getTranslations',
    }),
    isLastPlaceOnDashboard() {
      if (this.places && this.groups) {
        const sumLength = this.places.filter((item) => item.pinned).length
          + this.groups.filter((item) => item.pinned).length;
        return sumLength < 2;
      }
      return false;
    },
  },
  watch: {
    confirmDelete: {
      async handler(newValue, oldValue) {
        if (newValue !== oldValue && newValue === true && this.deletePlaceId) {
          return console.log('Запрос готов, но временно скрыт', `/devices/${this.deletePlaceId}`);

          this.$axios.delete(`/devices/${this.deletePlaceId}`, {
            Authorization: `Bearer ${this.$auth.user.token}`,
          })
            .then((response) => {
              const responseData = response.data.data;
              console.log(responseData);
              // TODO: updateSystemState
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              this.deletePlaceId = null;
            });
        }
      },
    },
  },
  methods: {
    ...mapActions({
      findCurrentDevice: 'deviceFunctions/findCurrentDevice',
      setModalInfoData: 'modalOptionInfo/setModalData',
      setModalStartTimer: 'modalStartTimer/setModalData',
      setModalCancelSchedule: 'modalCancelSchedule/setModalData',
      setModalPaymentId: 'modalPaymentInfo/setModalData',
      setModalInputData: 'modalInput/setModalData',
      setModalDeleteData: 'modalDelete/setModalData',
      setModalType: 'system/setGoToAppModalType',
      unpinCard: 'buildingDashboard/unpinCardFromDashboard',
      pinCard: 'buildingDashboard/pinCard',
      cancelTimer: 'deviceFunctions/cancelTimer',
    }),

    async pinOnDashboard(place) {
      await this.pinCard({
        type: 'device',
        group: place,
      });
    },

    async unpinFromDashboard(place) {
      await this.unpinCard({
        type: 'device',
        group: place,
      });

      this.closeMenu();
    },

    // cancelSchedule(place) {
    //   this.setModalCancelSchedule(place);
    //   this.$modal.show('CancelScheduleModal');
    //   this.closeMenu();
    // },

    resumeSchedule(place) {
      this.$axios.post(
        `placeSchedules/${place.currentSchedule.id}/resume?ignoreConflicts=false`,
        {
          conflictResolveData: null,
        },
        {
          headers: {
            Authorization: `Bearer ${this.$auth.user.token}`,
          },
        },
      ) // возобновление происходит через конфликты
        .catch((error) => {
          errorConflictHandler.call(this.$store, error);
        })
        .finally(() => {
          this.closeMenu();
        });
    },

    cancelTimerAction(place) {
      if (place.timer.id) {
        this.cancelTimer(place.timer.id);
      } else {
        console.error('Отсутствует timer ID');
      }

      this.closeMenu();
    },

    cancelProgressAction(place) {
      this.$axios.delete(`/progresses/${place.progress.id}?ignoreConflicts=false`, {
        Authorization: `Bearer ${this.$auth.user.token}`,
      })
        .then((response) => {
          const systemStateUpdate = response.data.data;
          const updatedDevices = systemStateUpdate.devices;

          this.$store.dispatch('deviceFunctions/findCurrentDevice', systemStateUpdate);
          for (const updated in updatedDevices) {
            this.$store.dispatch('buildingDashboard/setPlaceData', updatedDevices[updated]);
          }

          this.$store.dispatch('buildingDashboard/updatePresets', systemStateUpdate.presets);
          this.$store.dispatch('buildingDashboard/updateGroupPresets', systemStateUpdate.groupPresets);
        })
        .catch((error) => {
          errorConflictHandler.call(this.$store, error);
        })
        .finally(() => {
          this.closeMenu();
        });
    },

    setTimer(place) {
      this.setModalStartTimer(place);
      this.$modal.show('StartTimerModal');

      this.closeMenu();
    },

    clickRename(place) {
      this.setModalInputData({
        renameType: 'device',
        title: this.getTranslations['Переименовать'] || 'Переименовать',
        object: place,
        inputValue: place.title,
      });
      this.$modal.show('inputModal');

      this.closeMenu();
    },

    getInfo(place) {
      this.setModalInfoData({
        id: place.id,
        title: place.title,
        access: place.access,
        cloudType: place.cloudType,
        serial: place.serial,
        // Для контекста CardRoom в данных отсутствуют firmwareVersion и deviceInfo
        firmwareVersion: place.firmwareVersion,
        deviceInfo: place.deviceInfo,
      });
      this.$modal.show('optionInfo');

      this.closeMenu();
    },

    subscriptionInformation(place) {
      this.setModalPaymentId(place.subscriptionId);
      this.$modal.show('paymentInfoModal');

      this.closeMenu();
    },

    deletePlace(place) {
      this.setModalDeleteData({
        title: this.getTranslations['Вы уверены, что хотите удалить устройство?'] || 'Вы уверены, что хотите удалить устройство?',
        text: `${this.getTranslations['Устройство {0} будет удалено из вашего аккаунта. Если вы захотите добавить его, заново пройдите процесс добавления устройства.'][0]}«${place.title}»${this.getTranslations['Устройство {0} будет удалено из вашего аккаунта. Если вы захотите добавить его, заново пройдите процесс добавления устройства.'][1]}`,
        type: 'device',
        object: place,
      });
      this.deletePlaceId = place.id;
      this.$modal.show('deleteModal');

      this.closeMenu();
    },

    closeMenu() {
      this.$refs.menu.close();
    },
  },
  mounted() {
    const dashboardShell = document.querySelector('.dashboard__shell');
    if (dashboardShell) {
      dashboardShell.addEventListener('scroll', () => {
        this.$bus.$emit('openContextMenu');
      });
    }
  },
  unmounted() {
    const dashboardShell = document.querySelector('.dashboard__shell');
    if (dashboardShell) {
      dashboardShell.removeEventListener('scroll', () => {
        this.$bus.$emit('openContextMenu');
      });
    }
  },
};
