
import { numberWithSpaces, debounce } from '../utils/helpers';

export default {
  name: "BasketItem",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    windowWidth: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    spacer: null,
    timeout: null,
  }),
  watch: {
    windowWidth() {
      this.$refs.title.removeAttribute('style');
      //this.$refs.title.style.width = `${this.$refs.titleSpan.offsetWidth + 2}px`;
      //debounce(() => this.transformTitle(), 500);
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.transformTitle, 100);
    },
  },
  mounted() {
    this.spacer = document.createElement('div');
    this.spacer.style.float = 'right';
    this.$refs.titleSpan.prepend(this.spacer);

    this.transformTitle();
    //console.log(`${this.$refs.titleSpan.offsetWidth + 2}px`)
    //this.$refs.title.style.width = `${this.$refs.titleSpan.offsetWidth + 2}px`;
  },
  methods: {
    getItemPrice(price) {
      return numberWithSpaces(price);
    },
    transformTitle() {
      console.log('###transform');
      let width = 0;
      let originalHeight = this.$refs.titleSpan.offsetHeight;
      // let spacer = document.createElement('div');
      // spacer.style.float = 'right';
      this.spacer.style.height = originalHeight > 32 ? '27px' : '1px';
      // this.$refs.titleSpan.prepend(this.spacer);
      while (originalHeight == this.$refs.titleSpan.offsetHeight) {
        this.spacer.style.width = `${++width}px`;
      }
      this.spacer.style.width = `${--width}px`;
      //console.log('###span', `${this.$refs.titleSpan.offsetWidth + 2}px`);
      //console.log(`${width - (this.$refs.title.offsetWidth - this.$refs.titleSpan.offsetWidth) + 1}px`);
      //this.spacer.style.width = `${width - (this.$refs.title.offsetWidth - this.$refs.titleSpan.offsetWidth) + 1}px`;

      this.$refs.title.style.width = `${this.$refs.titleSpan.offsetWidth + 2}px`;
      this.spacer.style.width = 0;
      width = 0;
      while (originalHeight == this.$refs.titleSpan.offsetHeight) {
        this.spacer.style.width = `${++width}px`;
      }
      this.spacer.style.width = `${--width}px`;
    },
  },
};
