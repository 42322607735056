
import VueContext from 'vue-context';
import { mapActions } from 'vuex';
import contextMenuMixin from './contextMenuMixin';
import { mapGetters } from 'vuex';

export default {
  components: { VueContext },
  mixins: [contextMenuMixin],
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
  },
  methods: {
    ...mapActions({
      setModalData: 'modalControlFunctionDetail/setModalData',
    }),

    settingClick(functionItem) {
      this.setModalData({
        controlFunctionItemDetail: functionItem,
      });
      this.$modal.show('controlFunctionDetailModal');

      this.closeMenu();
    },

    async helpClick(data) {
      const functionDescription = await this.getFunctionDescription(data.id);

      this.setModalData({
        iconSvg: functionDescription.icon || functionDescription.iconSvg,
        title: functionDescription.fullTitle,
        text: functionDescription.description,
      });

      this.$modal.show('additionalModelTip');

      this.closeMenu();
    },

    getFunctionDescription(functionItemId) {
      return this.$axios.get(
        `functions/${functionItemId}/description`,
        {
          headers: {
            Authorization: `Bearer ${this.$auth.user.token}`,
          },
        },
      )
        .then((response) => response.data.data)
        .catch((error) => {
          console.error(error);
        });
    },

    closeMenu() {
      this.$refs.menu.close();
    },
  },
};
