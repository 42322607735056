
// eslint-disable-next-line import/no-extraneous-dependencies
import { mapGetters } from 'vuex';
import SchedulePlaceSetting from './SchedulePlaceSetting.vue';
import SchedulePanel from '../Menus/SchedulePanel.vue';
import ToolTip from '../ToolTip.vue';

export default {
  name: 'ScheduleEditor',
  components: { SchedulePanel, SchedulePlaceSetting, ToolTip },
  emits: ['to-first-step'],
  data: () => ({
    notifications: [],
    showPanel: true,
  }),
  computed: {
    ...mapGetters({
      activePlaceIndex: 'schedules/getActivePlaceIndex',
      places: 'schedules/getPlacesInSchedule',
      getTranslations: 'localization/getTranslations',
    }),
    activePlace() {
      return this.places[this.activePlaceIndex];
    },
  },
  mounted() {
    if (window.innerWidth <= 1270) {
      this.showPanel = false;
    }
  },
  methods: {
    closePanel(e) {
      const path = e.path || e.composedPath();
      if (path && path[0] && path[0].classList && path[0].classList.contains('schedule-editor__panel')) {
        this.showPanel = false;
      }
    },
    setNewInterval(info) {
      this.$store.commit(
        'schedules/setNewTimeInterval',
        { ...info, placeId: this.activePlace.placeId },
      );
    },
    setNewWidth(info) {
      this.$store.commit(
        'schedules/setNewIntervalWidth',
        { ...info, placeId: this.activePlace.placeId },
      );
    },
    goToFirstStep() {
      this.$store.commit('schedules/setModalStep', 1);
      this.$store.commit('schedules/setPanelStep', 1);
      this.$store.commit('schedules/setActivePlaceIndex', 0);
    },
    toggleDay(info) {
      this.$store.commit(
        'schedules/toggleDayWeek',
        { ...info, placeId: this.activePlace.placeId },
      );
    },
    setActivePlaceIndex(index) {
      this.$store.commit('schedules/setActivePlaceIndex', index);
    },
    notificationFilter() {
      if (this.notifications.length === 2) {
        const reverseArray = this.notifications.reverse();
        reverseArray.length = 1;
        this.notifications = reverseArray.reverse();
      }
    },
    copyHandler() {
      this.notificationFilter();
      const id = String(Math.random().toFixed(7));
      const toolObject = {
        text: this.getTranslations['Настройки временных интервалов скопированы'] || 'Настройки временных интервалов скопированы',
        id,
        toolFunction: false,
      };
      this.notifications.push(toolObject);
      const timeOutClose = setInterval(() => {
        this.notifications = this.notifications.filter((item) => item.id !== id);
        clearInterval(timeOutClose);
      }, 5000);
    },
    closeToolHandler(id) {
      this.notifications = this.notifications.filter((item) => item.id !== id);
    },
    pasteHandler() {
      this.notificationFilter();
      const id = String(Math.random().toFixed(7));
      const toolObject = {
        text: this.getTranslations['Настройки временных интервалов применены'] || 'Настройки временных интервалов применены',
        id,
        toolFunction: true,
      };
      this.notifications.push(toolObject);
      const timeOutClose = setInterval(() => {
        this.closeToolHandler(id);
        clearInterval(timeOutClose);
      }, 5000);
    },
    returnHandler(id) {
      this.$store.commit('schedules/returnBackValue');
      this.closeToolHandler(id);
    },
  },
};
