
import ArrowIcon from '../../assets/icons/Arrows/arrowDropdown.svg';
import { startTime, endTime } from '../../utils/helpers';
import { mapGetters } from 'vuex';

export default {
  name: 'ScheduleAccordion',
  components: { ArrowIcon },
  props: {
    period: {
      type: Object,
      required: true,
      validator: (value) => value.weekDays.length && value.times.length,
    },
  },
  data: () => ({
    isActive: false,
    // weekDayName: ['', this.getTranslations['Пн'], this.getTranslations['Вт'], this.getTranslations['Ср'], this.getTranslations['Чт'], this.getTranslations['Пт'], this.getTranslations['Сб'], this.getTranslations['Вс']],
  }),
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
    weekDayName() {
      return ['', this.getTranslations['Пн'], this.getTranslations['Вт'], this.getTranslations['Ср'], this.getTranslations['Чт'], this.getTranslations['Пт'], this.getTranslations['Сб'], this.getTranslations['Вс']];
    },
    weekDays() {
      const days = {};
      this.period.weekDays.forEach((weekDay) => {
        days[weekDay] = true;
      });
      return Object.keys(days).sort();
    },
  },
  methods: {
    toggleShow() {
      this.isActive = !this.isActive;
    },
    startTimeTemplate(minutess) {
      return startTime(minutess);
    },
    endTimeTemplate(end) {
      return endTime(this.startTimeTemplate, end);
    },
  },
};
