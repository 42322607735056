
import { endTime, startTime } from '~/utils/helpers';
import { mapGetters } from 'vuex';

export default {
  name: 'TimePreview',
  props: {
    times: {
      type: Array,
      required: true,
    },
    timeIndex: {
      type: Number,
      required: true,
    },
    currentTime: {
      type: Object,
      required: true,
    },
  },
  emits: ['click'],
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
    type() {
      const { action } = this.currentTime;
      if (!action.deviceState.isOn
        && ((action.deviceState.functions && action.deviceState.functions.length) === 0
          || action.deviceState.functions === undefined) && !action.presetId) {
        return 'off';
      }
      if (!action.deviceState.functions && !action.deviceState.isOn && !action.presetId) {
        return 'needToSet';
      }
      if (action.deviceState.isOn || action.presetId) {
        return 'on';
      }
      return false;
    },
    text() {
      if (this.currentTime.action.deviceState.info) {
        return this.currentTime.action.deviceState.info.text.replace(' ℃', '°C');
      }
      return this.currentTime.action.deviceState.stateInfo.text.replace(' ℃', '°C');
    },
    icons() {
      return (this.currentTime.action.deviceState.info
        && this.currentTime.action.deviceState.info.iconsSvg.slice(0, 4))
        || this.currentTime.action.deviceState.stateInfo.icons.slice(0, 4);
    },
    iconsCountExtra() {
      const iconsCount = this.currentTime.action.deviceState.info
        && (this.currentTime.action.deviceState.info.iconsSvg.length
          || this.currentTime.action.deviceState.stateInfo.icons.length);
      if (iconsCount) {
        return (iconsCount - 4) > 0 ? (iconsCount - 4) : false;
      }
      return false;
    },
  },
  methods: {
    startTimeTemplate(minutess) {
      return startTime(minutess);
    },
    endTimeTemplate(end) {
      return endTime(this.startTimeTemplate, end);
    },
  },
};
