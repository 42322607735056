
export default {
  name: "CardPayment",
  props: {
    card: Object,
    onCardButtonClick: Function,
    onCardClick: Function,
  },
  computed: {
    cardType() {
      return this.card.type.toLowerCase();
    },
  },
  methods: {
    onButtonClick(e, item) {
      this.onCardButtonClick(item);
    },
  },
};
