
export default {
  name: 'InputRange',
  props: {
    value: {
      type: Number,
      default: 26,
    },
    max: {
      type: Number,
      default: 40,
    },
    min: {
      type: Number,
      default: 12,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    // тут крч магия чтоб у нас градиент был до ползунка, не трогать
    gradientValue() {
      return 100 - (this.lineValue / this.lengthOfLine * 100);
    },
    lengthOfLine() {
      return this.max - this.min;
    },
    lineValue() {
      return this.value - this.min;
    },
  },
  methods: {
    changeInput(e) {
      this.$emit('valueChange', Number(e.target.value));
    },
  },
};
