
import ScheduleButton from '@/components/Buttons/ScheduleButton';
import PlaceStatus from '@/components/class/PlaceStatus';
import { secondsToHHMM } from '@/utils/helpers';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ControlPanelTimer',
  components: { ScheduleButton },
  props: {
    deviceInfo: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      panelDeviceId: 'system/getPanelDeviceId',
      getTranslations: 'localization/getTranslations',
    }),
    placeStatus() {
      return new PlaceStatus(this.deviceInfo);
    },
    timerTimeLeft() {
      return this.placeStatus.timerLeftHHMM();
    },
    text() {
      if (!this.deviceInfo.timer) {
        return this.getTranslations['Таймер'];
      }
      if (this.deviceInfo.timer.progress.delay) {
        return `${this.placeStatus.timerStartAt().format('HH:mm')} - ${this.placeStatus.timerEndAt().format('HH:mm')}`;
      }
      if (this.timerTimeLeft) {
        return `${this.getTranslations['До Выкл {0}'][0]}${this.timerTimeLeft}${this.getTranslations['До Выкл {0}'][1]}`;
      }
      if (this.deviceInfo.timer.progress.title) {
        return this.deviceInfo.timer.progress.title;
      }
      if (this.deviceInfo.timer.state.text) {
        return this.deviceInfo.timer.state.text;
      }
      return '';
    },
    disabled() {
      return this.deviceInfo?.status === 'disconnected'
        || (this.deviceInfo?.subscription !== null && this.deviceInfo?.subscription?.isDeviceBlocked);
    },
    active() {
      return !!(this.deviceInfo.timer && this.deviceInfo.timer.progress && this.deviceInfo.timer.progress.duration && !this.deviceInfo.timer.progress.delay);
    },
    planing() {
      return !!(this.deviceInfo.timer && this.deviceInfo.timer.progress.delay);
    },
    state() {
      if (this.disabled) {
        return 'disabled';
      }
      if (this.active) {
        return 'active';
      }
      if (this.planing) {
        return 'planing';
      }
      return ' default';
    },
  },
  methods: {
    ...mapActions({
      setModalStartTimer: 'modalStartTimer/setModalData',
      cancelTimer: 'deviceFunctions/cancelTimer',
    }),
    clickHandler() {
      if (this.disabled) {
        return;
      }
      if (this.active || this.planing) {
        this.cancelTimer(this.deviceInfo.timer.id);
      } else {
        this.startTimer();
      }
    },
    startTimer() {
      this.setModalStartTimer(this.deviceInfo);
      this.$modal.show('StartTimerModal');
    },
  },
};
