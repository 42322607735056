
import DefaultAvatarIcon from '~/assets/icons/avatar.svg';
import { getUserInitials } from '@/utils/user';

export default {
  name: 'UserAvatar',
  components: {
    DefaultAvatarIcon,
  },
  props: {
    imageSrc: {
      type: String,
    },
    userName: {
      type: String,
    },
    size: {
      type: String,
      default: 'stable',
    },
  },
  data() {
    return {};
  },
  computed: {
    mainClass() {
      return `user-avatar--${this.size}`;
    },
    initials() {
      if (this.userName) {
        return getUserInitials(this.userName);
      }
      return '';
    },
  },
};
