
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'GoToAppModal',
  data: () => ({
    modalName: 'goToApp',
  }),
  methods: {
    ...mapActions({
      setShowModal: 'system/setShowModal',
    }),
    beforeCloseEvent() {
      this.setShowModal(false);
      if (this.modalTitleType === 'auth') {
        this.$auth.logout();
      }
      this.$emit('goToAppClosed');
    },
  },
  computed: {
    ...mapGetters({
      modalTitleType: 'system/getGoToAppModalType',
      getTranslations: 'localization/getTranslations',
    }),
    title() {
      if (this.modalTitleType === 'auth') {
        return `${this.getTranslations['Перейдите в мобильное приложение'] || 'Перейдите в мобильное приложение'}!`;
      } if (this.modalTitleType === 'addObject') {
        return this.getTranslations['Добавить устройство'] || 'Добавить устройство';
      } if (this.modalTitleType === 'comfortSleep' || this.modalTitleType === 'addSchedule') {
        return this.getTranslations['Функция доступна только в мобильном приложении'] || 'Функция доступна только в мобильном приложении';
      }
      return this.getTranslations['Перейдите в мобильное приложение'] || 'Перейдите в мобильное приложение';
    },
    text() {
      if (this.modalTitleType === 'auth') {
        return this.getTranslations['Использование этого аккаунта возможно только в приложении Daichi Comfort'] || 'Использование этого аккаунта возможно только в приложении Daichi Comfort';
      } if (this.modalTitleType === 'addObject') {
        return this.getTranslations['Для добавления устройства скачайте приложение Daichi Comfort, выберите нужный объект, затем нажмите кнопку «Добавить устройство».'] || 'Для добавления устройства скачайте приложение Daichi Comfort, выберите нужный объект, затем нажмите кнопку «Добавить устройство».';
      } if (this.modalTitleType === 'comfortSleep') {
        return this.getTranslations['Использование функции “Комфортный сон” доступно только для пользователей мобильной версии приложения Daichi Comfort.'] || 'Использование функции “Комфортный сон” доступно только для пользователей мобильной версии приложения Daichi Comfort.';
      } if (this.modalTitleType === 'addSchedule') {
        return this.getTranslations['Использование функции “Расписание” доступно только для пользователей мобильной версии приложения Daichi Comfort.'] || 'Использование функции “Расписание” доступно только для пользователей мобильной версии приложения Daichi Comfort.';
      }
      return '';
    },
  },
};
