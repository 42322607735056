import {
  SET_STORIES_LIST,
  SET_CURRENT_STORY_ITEMS_INDEX,
  SET_SERVICE_FETCHED_STATUS,
  SET_STORY_WATCHED,
  SET_LOADED_STORY,
} from '../utils/types';

export const state = () => ({
  storiesList: [],
  currentStoryItemsIndex: null,
  serviceFetchedSuccess: false,
});

export const getters = {
  getStoriesList: ({ storiesList }) => storiesList,
  getCurrentStoryItemsIndex: ({ currentStoryItemsIndex }) => currentStoryItemsIndex,
  getServiceFetchedSuccess: ({ serviceFetchedSuccess }) => serviceFetchedSuccess,
};

export const mutations = {
  [SET_STORIES_LIST](state, value) {
    value.map((item) => {
      item.storyItems.map((story) => {
        story.loaded = false;
      });
    });
    state.storiesList = value;
  },
  [SET_SERVICE_FETCHED_STATUS](state, value) {
    state.serviceFetchedSuccess = value;
  },
  [SET_CURRENT_STORY_ITEMS_INDEX](state, index) {
    state.currentStoryItemsIndex = index;
  },
  [SET_STORY_WATCHED](state, id) {
    const index = state.storiesList.findIndex((item) => item.id === id);
    state.storiesList[index].isRead = true;
  },
  [SET_LOADED_STORY](state, { index, smallIndex }) {
    state.storiesList[index].storyItems[smallIndex].loaded = true;
  },
};

export const actions = {
  setLoadedStoryImage({ commit }, data) {
    commit(SET_LOADED_STORY, data);
  },
  async fetchStoriesList({ commit }, buildingId) {
    try {
      const { data } = await this.$axios.get(`stories?buildingId=${buildingId}`);
      const sortedStories = data.data.sort((a, b) => a.isRead - b.isRead);
      commit(SET_STORIES_LIST, sortedStories);
    } catch (e) {
      console.log(e);
    }
  },
  async setCurrentStoryItemsIndex({ commit }, index) {
    await commit(SET_CURRENT_STORY_ITEMS_INDEX, index);
  },
  async watchedStory({ commit }, id) {
    try {
      const { data } = await this.$axios.post(`stories/${id}/read`);
      commit(SET_STORY_WATCHED, id);
    } catch (e) {
      console.log(e);
    }
  },
  async sendStat({ commit }, button) {
    try {
      const data = await this.$axios.post(`storyItem/${button.id}/stat`, {
        buttonText: button.title,
      });
    } catch (e) {
      console.log(e);
    }
  },
  async fetchServices({ commit }, id) {
    try {
      const data = await this.$axios.post(`storyItem/${id}/request`);
      commit(SET_SERVICE_FETCHED_STATUS, true);
    } catch (e) {
      console.log(e);
      commit(SET_SERVICE_FETCHED_STATUS, false);
    }
  },
};
