
export default {
  name: 'ResizeColDouble',
  emits: ['isDragging', 'dragging', 'changePosition', 'isResize', 'changePositionDrop'],
  data() {
    return {
      width: null,
      reWidth: null,
      isDragging: false,
      isResize: false,
    };
  },
  methods: {
    resizeCol(e, side) {
      e.stopPropagation();
      const oldPos = e.clientX || (e.changedTouches && e.changedTouches[0].clientX);
      const oldWidth = this.$refs.col.getBoundingClientRect().width;
      let newPos = 0;
      let newWidth = 0;
      const vue = this;
      this.isDragging = true;
      this.isResize = true;
      this.$emit('isDragging', this.isDragging);
      this.$emit('isResize', this.isResize);
      document.onmousemove = sliderDrag;
      document.onmouseup = cancelSliderDrag;
      document.ontouchmove = sliderDrag;
      document.ontouchend = cancelSliderDrag;
      function sliderDrag(e) {
        this.time = Date.now();
        e.stopPropagation();
        newPos = e.clientX || (e.changedTouches && e.changedTouches[0].clientX);
        const movingDistance = oldPos - newPos;
        if (side === 'left') {
          newWidth = parseInt(oldWidth + movingDistance);
        } else {
          newWidth = parseInt(oldWidth - movingDistance);
        }
        vue.$emit('dragging', {
          newWidth, side, oldWidth,
        });
      }
      function cancelSliderDrag() {
        vue.isDragging = false;
        vue.isResize = false;
        vue.$emit('isDragging', vue.isDragging);
        vue.$emit('isResize', vue.isResize);
        vue.$emit('changePositionDrop', vue.isDragging);
        document.onmouseup = null;
        document.onmousemove = null;
        document.ontouchmove = null;
        document.ontouchend = null;
      }
    },
    changePosition(e) {
      e.stopPropagation();
      let oldPos = e.clientX || (e.changedTouches && e.changedTouches[0].clientX);
      let newPos = 0;
      const vue = this;
      this.isDragging = true;
      this.$emit('isDragging', this.isDragging);
      document.onmousemove = sliderDrag;
      document.onmouseup = cancelSliderDrag;
      document.ontouchmove = sliderDrag;
      document.ontouchend = cancelSliderDrag;
      function sliderDrag(e) {
        e.stopPropagation();
        newPos = e.clientX || (e.changedTouches && e.changedTouches[0].clientX);
        const movingDistance = oldPos - newPos;
        oldPos = newPos;
        vue.$emit('changePosition', { movingDistance, oldPos, newPos });
      }
      function cancelSliderDrag() {
        vue.isDragging = false;
        vue.$emit('changePositionDrop', vue.isDragging);
        document.onmouseup = null;
        document.onmousemove = null;
        document.ontouchmove = null;
        document.ontouchend = null;
      }
    },
  },
};
