
import { mapActions, mapGetters } from "vuex";

export default {
  name: "RecommendationChip",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      deviceInfo: "deviceFunctions/getDeviceInfo",
      panelDeviceId: "system/getPanelDeviceId",
    }),
  },
  methods: {
    ...mapActions({
      fetchRecommendation: "deviceFunctions/fetchRecommendation",
    }),
    onCardClick(e, id) {
      this.$store.commit("deviceFunctions/setClimateOnlineRequestStatus", true);

      this.fetchRecommendation(
        {
          deviceId: this.panelDeviceId,
          recommendationId: id,
          onClose: () => {
            this.$store.commit("deviceFunctions/setClimateOnlineRequestStatus", false);
          },
        },
      )
        .then(() => this.$modal.show('recommendationModal'))
        .finally(() => this.$store.commit("deviceFunctions/setClimateOnlineRequestStatus", false));
      // this.$store.commit('deviceFunctions/setCurrentRecommendation', {
      //   id: 64,
      //   icon: "https://simpleicon.com/wp-content/uploads/rocket.svg",
      //   backgroundColor: "red",
      //   title: "Время для ТО!",
      //   description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec at rhoncus diam. Praesent orci tellus, suscipit sed nisi nec, scelerisque efficitur nulla. Quisque suscipit vel elit non porttitor.",
      //   recommendations: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec at rhoncus diam. Praesent orci tellus, suscipit sed nisi nec, scelerisque efficitur nulla. Quisque suscipit vel elit non porttitor.",
      // });
    },
  },
};
