
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import TouchSlider from '../assets/images/touch-slider.svg';

export default {
  name: 'RangeSlider',
  components: {
    VueSlider,
    TouchSlider,
  },
  props: {
    value: Number,
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    dotSize: {
      type: Number,
      default: 48,
    },
    height: {
      type: Number,
      default: 8,
    },
    contained: {
      type: Boolean,
      default: true,
    },
    tooltip: {
      type: String,
      default: 'none',
    },
    onDragEnd: {
      type: Function,
      default: () => 1,
    },
    step: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    isDragging: false,
  }),
  computed: {
    sliderVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    dragStartHandler() {
      this.isDragging = true;
    },
    dragEndHandler() {
      this.isDragging = false;
      this.onDragEnd();
    },
    onChange() {
      if (this.isDragging) return;
      this.onDragEnd();
    },
  },
};
