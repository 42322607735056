
import { mapActions, mapGetters } from "vuex";
import Icon from '@/assets/images/icon-default.svg';
import { getPrettyDate } from '@/utils/helpers';

export default {
  name: 'ErrorStroke',
  components: {
    Icon,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    img: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isImageFail: false,
      isLoading: false
    };
  },
  computed: {
    ...mapGetters({
      deviceInfo: "deviceFunctions/getDeviceInfo",
      panelDeviceId: "system/getPanelDeviceId",
    }),
    prettyDate() {
      return getPrettyDate(this.date);
    },
  },
  methods: {
    ...mapActions({
      fetchError: "deviceFunctions/fetchError",
    }),
    onImageFail(e) {
      this.isImageFail = true;
    },
    onErrorClick(e, id) {
      this.$store.commit("deviceFunctions/setClimateOnlineRequestStatus", true);

      this.fetchError(
        {
          deviceId: this.panelDeviceId,
          errorId: id,
          onClose: () => {
            this.$store.commit("deviceFunctions/setClimateOnlineRequestStatus", false);
          },
        },
      )
        .then(() => this.$modal.show('deviceErrorModal'))
        .finally(() => this.$store.commit("deviceFunctions/setClimateOnlineRequestStatus", false));
    },
  },
};
