
import UserAvatar from '@/components/profile/UserAvatar.vue';

export default {
  name: 'UserAvatarBus',
  components: {
    UserAvatar,
  },
  props: {
    size: {
      type: String,
    },
    localSrc: {
      type: String, // для InputImage
    },
  },
  data() {
    return {};
  },
  computed: {
    userName() {
      return this.$auth.user?.fio ? this.$auth.user.fio : '';
    },
    imageSrc() {
      if (this.localSrc) {
        return this.localSrc;
      } if (this.$auth.user?.image) {
        return this.$auth.user.image;
      }
      return '';
    },
  },
};
