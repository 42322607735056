
import Chevron from "@/assets/images/chevron.svg";

export default {
  name: "ButtonArrow",
  components: {
    Chevron,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },
};
