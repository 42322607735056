
import { mapActions, mapGetters } from 'vuex';
import AppButton from '@/components/Buttons/AppButton';

export default {
  name: 'ControlFunctionDetailModal',
  components: { AppButton },
  data: () => ({
    modalName: 'controlFunctionDetailModal',
    selectedOption: '',
    options: [],
    preloading: false,
  }),
  computed: {
    ...mapGetters({
      controlFunctionItemDetail: 'modalControlFunctionDetail/getControlFunctionItemDetail',
      getTranslations: 'localization/getTranslations',
    }),
    heading() {
      if (this.controlFunctionItemDetail?.metaData?.tag === 'VERTICAL_FLOW_DETAILED') {
        return this.getTranslations['Выберите режим заслонок'] || 'Выберите режим заслонок';
      }
      return '';
    },
  },
  methods: {
    ...mapActions({
      toggleShowModal: 'system/setShowModal',
      applyDeviceFunction: 'deviceFunctions/applyDeviceFunction',
    }),
    prepareData() {
      // Пока реализовано для VERTICAL_FLOW_DETAILED.
      // При необходимости добавления COMFORT_SLEEP добавить на этом этапе проверку this.controlFunctionItemDetail.metaData.tag

      this.preloading = true;
      this.$axios.get(
        `functions/${this.controlFunctionItemDetail.id}/details?deviceId=${this.controlFunctionItemDetail.deviceId}`,
        {
          headers: {
            Authorization: `Bearer ${this.$auth.user.token}`,
          },
        },
      )
        .then((response) => {
          const responseOptions = response.data.data;

          responseOptions.find((option) => {
            if (option.isSelected) {
              this.selectedOption = option.value;
            }
          });

          this.options = responseOptions;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.preloading = false;
        });
    },
    submitHandler() {
      const requestValue = {
        functionId: this.controlFunctionItemDetail.id,
        parameters: {},
      };

      if (this.controlFunctionItemDetail.metaData.tag === 'VERTICAL_FLOW_DETAILED') {
        const { currentValue } = this.controlFunctionItemDetail.metaData.parameters.verticalFlowDetailedParameters;
        requestValue.parameters.verticalFlowDetailedParameters = {
          currentValue,
          nextValue: this.selectedOption,
        }; // во избежание мутаций

        if (this.controlFunctionItemDetail.metaData.applyable) {
          requestValue.isOn = true;
        } else {
          requestValue.isOn = !this.controlFunctionItemDetail.state.isOn;
        }
      }

      this.$store.commit('deviceFunctions/setForceLocalLoadingFunctionId', this.controlFunctionItemDetail.id);
      this.applyDeviceFunction(requestValue);
      this.$modal.hide(this.modalName);
    },

    onCloseModal() {
      this.selectedOption = '';
      this.options = [];
      this.toggleShowModal(false);
    },
  },
};
