
import Calendar from 'assets/icons/calendar.svg';
import CalendarSmall from 'assets/icons/14x14/schedule.svg';
import TimeIcon from '../../../assets/icons/time.svg';
import PlaceStatus from '../../class/PlaceStatus';
import AppCheckbox from '../../checks/AppCheckbox.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    AppCheckbox, TimeIcon, Calendar, CalendarSmall,
  },
  props: {
    place: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      required: true,
    },
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
    placeStatus() {
      return new PlaceStatus(this.place);
    },
    countShowIcons() {
      return this.size === 'small' ? 3 : 4;
    },
    weekDayName() {
      return ['', this.getTranslations['Пн'], this.getTranslations['Вт'], this.getTranslations['Ср'], this.getTranslations['Чт'], this.getTranslations['Пт'], this.getTranslations['Сб'], this.getTranslations['Вс']]
    },
  },
  methods: {
    startTime(mins) {
      const hours = Math.trunc(mins / 60);
      let minutes = String(mins % 60);
      minutes.length === 1 ? minutes = `0${minutes}` : minutes = minutes;
      return `${hours}:${minutes}`;
    },
    endTime() {
      const listData = this.placeStatus.scheduleCurrentPeriodTimes();
      let end;
      listData.forEach((item, index) => {
        if (item.isActive) {
          end = listData[index + 1];
        }
      });
      return this.startTime(end ? end.start : 1439);
    },
  },
};
