
import { mapGetters, mapActions } from "vuex";
import ActivationError from '@/assets/images/activation.svg';
import CloseIcon from "../../assets/icons/24x24/close.svg";
import AppButton from "../Buttons/AppButton.vue";

export default {
  name: "ActivationModal",
  components: {
    ActivationError,
    CloseIcon,
    AppButton,
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
    onActivationClick: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      getTranslations: "localization/getTranslations",
    }),
    errorText() {
      return `
        ${this.getTranslations['Ранее была приобретена услуга «{0}». Активируйте услугу на устройстве для продолжения работы.'][0]}${this.getTranslations['Климат Онлайн']}${this.getTranslations['Ранее была приобретена услуга «{0}». Активируйте услугу на устройстве для продолжения работы.'][1]}
      `;
    },
  },
  mounted() {
    this.$modal.show(`activationModal-${this.order.id}`);
  },
  methods: {
    onClick(e, id) {
      this.onActivationClick(id);
    },
  },
};
