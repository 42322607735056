import { getRandomIntInclusive } from '../../utils/helpers';

export const pages1 = {
  id: 5,
  pages: [
    {
      title: "Оплата услуг",
      showBasket: false,
      content: [
        {
          title: "Оплатите подписку!",
          subTitle: null,
          description: "Выберите тарифный план для подключения услуги.",
          content: {
            cardViews: [
              {
                serviceId: "DEVICE_ACCESS_SUBSCRIPTION_1Y",
                type: "MEDIUM",
                title: null,
                subTitle: null,
                price: {
                  price: {
                    text: 2799,
                    textStyle: "REGULAR",
                    textColor: "#000000",
                    spanData: null,
                  },
                  displayCurrency: {
                    text: "₽",
                    textStyle: "REGULAR",
                    textColor: "#000000",
                    spanData: null,
                  },
                  currencyIsoCode: {
                    text: "RUR",
                    textStyle: "REGULAR",
                    textColor: "#000000",
                    spanData: null,
                  },
                },
                label: {
                  title: "1 год",
                  titleColor: "#ffffff",
                  background: {
                    backgroundColor: "#F25248",
                    backgroundImage: null,
                  },
                },
                background: null,
                buttons: null,
                items: null,
                statistic: null,
                clickable: true,
                selected: true,
                description: {
                  text: '365 дней доступа\nк управлению устройством и Облачному сервису',
                  textStyle: "REGULAR", //для настройки всего текста
                  textColor: "#000000", //для настройки всего текста
                  spanData: null, //для настройки части текста
                },
                subDescription: null,
                behaviour: 'PAY',
              },
              {
                serviceId: "DEVICE_ACCESS_SUBSCRIPTION_1Y",
                type: "MEDIUM",
                title: null,
                subTitle: null,
                price: {
                  price: {
                    text: 5598,
                    textStyle: "REGULAR",
                    textColor: "#000000",
                    spanData: null,
                  },
                  displayCurrency: {
                    text: "₽",
                    textStyle: "REGULAR",
                    textColor: "#000000",
                    spanData: null,
                  },
                  currencyIsoCode: {
                    text: "RUR",
                    textStyle: "REGULAR",
                    textColor: "#000000",
                    spanData: null,
                  },
                },
                label: {
                  title: "2 года",
                  titleColor: "#ffffff",
                  background: {
                    backgroundColor: "#F25248",
                    backgroundImage: null,
                  },
                },
                background: null,
                buttons: null,
                items: null,
                statistic: null,
                clickable: true,
                selected: true,
                description: {
                  text:
                    "730 дней доступа\nк управлению устройством и Облачному сервису",
                  textStyle: "REGULAR", //для настройки всего текста
                  textColor: "#000000", //для настройки всего текста
                  spanData: null, //для настройки части текста
                },
                subDescription: null,
                behaviour: 'PAY',
              },
              {
                serviceId: "DEVICE_ACCESS_SUBSCRIPTION_1Y",
                type: "MEDIUM",
                title: null,
                subTitle: null,
                price: {
                  price: {
                    text: 8397,
                    textStyle: "REGULAR",
                    textColor: "#000000",
                    spanData: null,
                  },
                  displayCurrency: {
                    text: "₽",
                    textStyle: "REGULAR",
                    textColor: "#000000",
                    spanData: null,
                  },
                  currencyIsoCode: {
                    text: "RUR",
                    textStyle: "REGULAR",
                    textColor: "#000000",
                    spanData: null,
                  },
                },
                label: {
                  title: "3 года",
                  titleColor: "#ffffff",
                  background: {
                    backgroundColor: "#F25248",
                    backgroundImage: null,
                  },
                },
                background: null,
                buttons: null,
                items: null,
                statistic: null,
                clickable: true,
                selected: true,
                description: {
                  text:
                    "1095 дней доступа\nк управлению устройством и Облачному сервису",
                  textStyle: "REGULAR", //для настройки всего текста
                  textColor: "#000000", //для настройки всего текста
                  spanData: null, //для настройки части текста
                },
                subDescription: null,
                behaviour: 'PAY',
              },
              {
                serviceId: "DEVICE_ACCESS_SUBSCRIPTION_1Y",
                type: "MEDIUM",
                title: null,
                subTitle: null,
                price: {
                  price: {
                    text: 11196,
                    textStyle: "REGULAR",
                    textColor: "#ffffff",
                    spanData: null,
                  },
                  displayCurrency: {
                    text: "₽",
                    textStyle: "REGULAR",
                    textColor: "#ffffff",
                    spanData: null,
                  },
                  currencyIsoCode: {
                    text: "RUR",
                    textStyle: "REGULAR",
                    textColor: "#ffffff",
                    spanData: null,
                  },
                },
                label: {
                  title: "Бессрочный доступ*",
                  titleColor: "#ffffff",
                  background: {
                    backgroundColor: "#F25248",
                    backgroundImage: null,
                  },
                },
                background: {
                  backgroundColor: null,
                  backgroundImage:
                    "https://img.freepik.com/free-photo/abstract-grunge-decorative-relief-navy-blue-stucco-wall-texture-wide-angle-rough-colored-background_1258-28311.jpg?size=626&ext=jpg",
                },
                buttons: null,
                items: null,
                statistic: null,
                clickable: true,
                selected: true,
                description: {
                  text:
                    "Безлимитный доступ к управлению устройством и Облачному сервису.",
                  textStyle: "REGULAR",
                  textColor: "#ffffff",
                  spanData: null,
                },
                subDescription: null,
                behaviour: 'PAY',
              },
            ],
            progressView: null,
            codeActivationView: null,
            stepView: null,
          },
          underBlockInfo: [
            'Вы можете отвязать кондиционер от подписочной модели, оплатив тариф «Бессрочный доступ» в любой момент. Стоимость тарифа учитывает ранее совершённые платежи по подписке.\nПодписочная модель автоматически изменится на тариф «Бессрочный доступ» после 4 ежегодных платежей.',
          ],
        },
        {
          title: null,
          subTitle: null,
          description: null,
          content: {
            progressView: null,
            codeActivationView: null,
            stepView: {
              title: {
                text: "Информация о состоянии подписки",
                textStyle: null,
                textColor: "#151515",
                spanData: null,
              },
              subTitle: {
                text: "Осталось 4 ежегодных платежа для перехода на тариф «Бессрочный доступ»",
                textStyle: null,
                textColor: "#6C7684",
                spanData: null,
              },
              steps: [
                {
                  text: "1 год",
                  textStyle: null,
                  textColor: null,
                  spanData: null,
                },
                {
                  text: "2 года",
                  textStyle: null,
                  textColor: null,
                  spanData: null,
                },
                {
                  text: "3 года",
                  textStyle: null,
                  textColor: null,
                  spanData: null,
                },
                {
                  text: "4 года",
                  textStyle: null,
                  textColor: null,
                  spanData: null,
                },
                {
                  text: "Бессрочный доступ",
                  textStyle: null,
                  textColor: null,
                  spanData: null,
                },
              ],
              selectedStep: 2,
            },
          },
          underBlockInfo: null,
        },
      ],
    },
    {
      title: "Оплата",
      showBasket: false,
      content: [
        {
          content: {
            cardViews: [
              {
                serviceId: "DEVICE_ACCESS_SUBSCRIPTION_1Y",
                type: "BIG",
                title: {
                  text: "1 год",
                  textStyle: "MEDIUM",
                  textColor: "#151515",
                  spanData: null,
                },
                subTitle: {
                  text: "Абонентский доступ на 1 год использования",
                  textStyle: "MEDIUM",
                  textColor: "#151515",
                  spanData: null,
                },
                price: {
                  price: {
                    text: 2990,
                    textStyle: "REGULAR",
                    textColor: "#000000",
                    spanData: null,
                  },
                  displayCurrency: {
                    text: "₽",
                    textStyle: "REGULAR",
                    textColor: "#000000",
                    spanData: null,
                  },
                  currencyIsoCode: {
                    text: "RUR",
                    textStyle: "REGULAR",
                    textColor: "#000000",
                    spanData: null,
                  },
                },
                label: {
                  title: "Не оплачен",
                  titleColor: "#ffffff",
                  background: {
                    backgroundColor: "#F25248",
                    backgroundImage: null,
                  },
                },
                background: null,
                buttons: [
                  {
                    title: {
                      text: "Оплатить",
                      textStyle: null,
                      textColor: "#ffffff",
                      spanData: null,
                    },
                    background: {
                      backgroundColor: "#5EA9FF",
                      bakcgroundImage: null,
                    },
                    behaviour: null,
                    url: "https://daichi.ru/devices/34/",
                  },
                ],
                items: [
                  {
                    text: "10 дней доступа, дополнительные дни использования можно докупить в любое время",
                    textStyle: null,
                    textColor: "#6C7684",
                    spanData: null,
                  },
                  {
                    text: "Управление со смартфона",
                    textStyle: null,
                    textColor: "#6C7684",
                    spanData: null,
                  },
                  {
                    text: "Бесплатные запчасти",
                    textStyle: null,
                    textColor: "#6C7684",
                    spanData: null,
                  },
                  {
                    text: "Круглосуточный Онлайн-мониторинг",
                    textStyle: null,
                    textColor: "#6C7684",
                    spanData: null,
                  },
                ],
                statistic: null,
                clickable: false,
                selected: false,
                description: null,
                subDescription: null,
              },
              {
                serviceId: "DEVICE_ACCESS_SUBSCRIPTION_1Y",
                type: "BIG",
                title: {
                  text: "Бессрочный доступ*",
                  textStyle: "MEDIUM",
                  textColor: "#ffffff",
                  spanData: null,
                },
                subTitle: {
                  text: "Управляй кондиционером без абонентской платы",
                  textStyle: "MEDIUM",
                  textColor: "#ffffff",
                  spanData: null,
                },
                price: {
                  price: {
                    text: '23 920',
                    textStyle: "MEDIUM",
                    textColor: "#ffffff",
                    spanData: null,
                  },
                  displayCurrency: {
                    text: "₽",
                    textStyle: "MEDIUM",
                    textColor: "#ffffff",
                    spanData: null,
                  },
                  currencyIsoCode: {
                    text: "RUR",
                    textStyle: "MEDIUM",
                    textColor: "#ffffff",
                    spanData: null,
                  },
                },
                label: null,
                background: {
                  backgroundColor: null,
                  backgroundImage: 'https://img.freepik.com/free-photo/abstract-grunge-decorative-relief-navy-blue-stucco-wall-texture-wide-angle-rough-colored-background_1258-28311.jpg?size=626&ext=jpg',
                },
                buttons: [
                  {
                    title: {
                      text: "Оплатить",
                      textStyle: null,
                      textColor: "#ffffff",
                      spanData: null,
                    },
                    background: {
                      backgroundColor: "#5EA9FF",
                      bakcgroundImage: null,
                    },
                    behaviour: null,
                    url: "https://daichi.ru/devices/34/",
                  },
                ],
                items: [
                  {
                    text: "Безлимитный доступ к управлению без абонентской платы",
                    textStyle: null,
                    textColor: "#ffffff",
                    spanData: null,
                  },
                  {
                    text: "Управление со смартфона",
                    textStyle: null,
                    textColor: "#ffffff",
                    spanData: null,
                  },
                  {
                    text: "Гарантия до 5 лет с момента установки",
                    textStyle: null,
                    textColor: "#ffffff",
                    spanData: null,
                  },
                  {
                    text: "Круглосуточный Онлайн-мониторинг",
                    textStyle: null,
                    textColor: "#ffffff",
                    spanData: null,
                  },
                ],
                statistic: null,
                clickable: false,
                selected: false,
                description: null,
                subDescription: null,
              },
            ],
            progressView: null,
            codeActivationView: null,
            stepView: null,
          },
          underBlockInfo: [
            'Вы можете приобрести кондиционер в собственность, оплатив тариф «Бессрочный доступ» в любой момент. Стоимость тарифа учитывает ранее приобретённые дни использования.\nВы можете перейти на тариф «Бессрочный доступ» после оплаты 700 дней.',
          ],
        },
      ],
    },
    {
      title: "Настройте ваш тариф",
      showBasket: true,
      content: [
        {
          title: "Покупайте дополнительные дни!",
          subTitle: null,
          description: "В абонентский доступ входит 10 дней управления, но вы можете увеличить их количество в любое время.",
          content: {
            cardViews: null,
            progressView: {
              serviceId: "DEVICE_ACCESS_SUBSCRIPTION_1Y",
              itemType: "DAYS_ACCESS",
              formula: {
                daysAccess: {
                  k: 1.710776,
                  e: 2.718281,
                  ek: -0.036366,
                  m: 44,
                  d: 0.461428,
                  pK: 3.3,
                },
              },
              quantity: {
                min: 0,
                current: 23,
                max: 365,
              },
              price: {
                price: {
                  text: '121',
                  textStyle: "MEDIUM",
                  textColor: "#151515",
                  spanData: null,
                },
                displayCurrency: {
                  text: "₽",
                  textStyle: "MEDIUM",
                  textColor: "#151515",
                  spanData: null,
                },
                currencyIsoCode: {
                  text: "RUR",
                  textStyle: "MEDIUM",
                  textColor: "#151515",
                  spanData: null,
                },
              },
              manualInputTitle: "Укажите кол-во часов текстом",
            },
            codeActivationView: null,
            stepView: null,
          },
          underBlockInfo: null,
          banner: {
            title: "Покупайте больше, тратьте меньше!",
            description: "Цена доп. дня уменьшается при выборе большего количества дней",
            image: "https://img.freepik.com/premium-vector/cute-pig-pattern-background_41365-206.jpg?w=2000",
          },
        },
      ],
    },
  ],
};

export const pages2 = {
  id: 5,
  pages: [
    // {
    //   title: "Оплата услуг",
    //   showBasket: false,
    //   content: [
    //     {
    //       title: "Оплатите подписку!",
    //       subTitle: null,
    //       description: "Выберите тарифный план для подключения услуги.",
    //       content: {
    //         cardViews: [
    //           {
    //             serviceId: "DEVICE_ACCESS_SUBSCRIPTION_1Y",
    //             type: "MEDIUM",
    //             title: null,
    //             subTitle: null,
    //             price: {
    //               price: {
    //                 text: 2799,
    //                 textStyle: "REGULAR",
    //                 textColor: "#000000",
    //                 spanData: null,
    //               },
    //               displayCurrency: {
    //                 text: "₽",
    //                 textStyle: "REGULAR",
    //                 textColor: "#000000",
    //                 spanData: null,
    //               },
    //               currencyIsoCode: {
    //                 text: "RUR",
    //                 textStyle: "REGULAR",
    //                 textColor: "#000000",
    //                 spanData: null,
    //               },
    //             },
    //             label: {
    //               title: "1 год",
    //               titleColor: "#ffffff",
    //               background: {
    //                 backgroundColor: "#F25248",
    //                 backgroundImage: null,
    //               },
    //             },
    //             background: null,
    //             buttons: null,
    //             items: null,
    //             statistic: null,
    //             clickable: false,
    //             selected: true,
    //             description: {
    //               text: '365 дней доступа\nк управлению устройством и Облачному сервису',
    //               textStyle: "REGULAR", //для настройки всего текста
    //               textColor: "#000000", //для настройки всего текста
    //               spanData: null, //для настройки части текста
    //             },
    //             subDescription: null,
    //           },
    //           {
    //             serviceId: "DEVICE_ACCESS_SUBSCRIPTION_1Y",
    //             type: "MEDIUM",
    //             title: null,
    //             subTitle: null,
    //             price: {
    //               price: {
    //                 text: 5598,
    //                 textStyle: "REGULAR",
    //                 textColor: "#000000",
    //                 spanData: null,
    //               },
    //               displayCurrency: {
    //                 text: "₽",
    //                 textStyle: "REGULAR",
    //                 textColor: "#000000",
    //                 spanData: null,
    //               },
    //               currencyIsoCode: {
    //                 text: "RUR",
    //                 textStyle: "REGULAR",
    //                 textColor: "#000000",
    //                 spanData: null,
    //               },
    //             },
    //             label: {
    //               title: "2 года",
    //               titleColor: "#ffffff",
    //               background: {
    //                 backgroundColor: "#F25248",
    //                 backgroundImage: null,
    //               },
    //             },
    //             background: null,
    //             buttons: null,
    //             items: null,
    //             statistic: null,
    //             clickable: false,
    //             selected: true,
    //             description: {
    //               text:
    //                 "730 дней доступа\nк управлению устройством и Облачному сервису",
    //               textStyle: "REGULAR", //для настройки всего текста
    //               textColor: "#000000", //для настройки всего текста
    //               spanData: null, //для настройки части текста
    //             },
    //             subDescription: null,
    //           },
    //           {
    //             serviceId: "DEVICE_ACCESS_SUBSCRIPTION_1Y",
    //             type: "MEDIUM",
    //             title: null,
    //             subTitle: null,
    //             price: {
    //               price: {
    //                 text: 8397,
    //                 textStyle: "REGULAR",
    //                 textColor: "#000000",
    //                 spanData: null,
    //               },
    //               displayCurrency: {
    //                 text: "₽",
    //                 textStyle: "REGULAR",
    //                 textColor: "#000000",
    //                 spanData: null,
    //               },
    //               currencyIsoCode: {
    //                 text: "RUR",
    //                 textStyle: "REGULAR",
    //                 textColor: "#000000",
    //                 spanData: null,
    //               },
    //             },
    //             label: {
    //               title: "3 года",
    //               titleColor: "#ffffff",
    //               background: {
    //                 backgroundColor: "#F25248",
    //                 backgroundImage: null,
    //               },
    //             },
    //             background: null,
    //             buttons: null,
    //             items: null,
    //             statistic: null,
    //             clickable: false,
    //             selected: true,
    //             description: {
    //               text:
    //                 "1095 дней доступа\nк управлению устройством и Облачному сервису",
    //               textStyle: "REGULAR", //для настройки всего текста
    //               textColor: "#000000", //для настройки всего текста
    //               spanData: null, //для настройки части текста
    //             },
    //             subDescription: null,
    //           },
    //           {
    //             serviceId: "DEVICE_ACCESS_SUBSCRIPTION_1Y",
    //             type: "MEDIUM",
    //             title: null,
    //             subTitle: null,
    //             price: {
    //               price: {
    //                 text: 11196,
    //                 textStyle: "REGULAR",
    //                 textColor: "#ffffff",
    //                 spanData: null,
    //               },
    //               displayCurrency: {
    //                 text: "₽",
    //                 textStyle: "REGULAR",
    //                 textColor: "#ffffff",
    //                 spanData: null,
    //               },
    //               currencyIsoCode: {
    //                 text: "RUR",
    //                 textStyle: "REGULAR",
    //                 textColor: "#ffffff",
    //                 spanData: null,
    //               },
    //             },
    //             label: {
    //               title: "Бессрочный доступ*",
    //               titleColor: "#ffffff",
    //               background: {
    //                 backgroundColor: "#F25248",
    //                 backgroundImage: null,
    //               },
    //             },
    //             background: {
    //               backgroundColor: null,
    //               backgroundImage:
    //                 "https://img.freepik.com/free-photo/abstract-grunge-decorative-relief-navy-blue-stucco-wall-texture-wide-angle-rough-colored-background_1258-28311.jpg?size=626&ext=jpg",
    //             },
    //             buttons: null,
    //             items: null,
    //             statistic: null,
    //             clickable: false,
    //             selected: true,
    //             description: {
    //               text:
    //                 "Безлимитный доступ к управлению устройством и Облачному сервису.",
    //               textStyle: "REGULAR",
    //               textColor: "#ffffff",
    //               spanData: null,
    //             },
    //             subDescription: null,
    //           },
    //         ],
    //         progressView: null,
    //         codeActivationView: null,
    //         stepView: null,
    //       },
    //       underBlockInfo: [
    //         'Вы можете отвязать кондиционер от подписочной модели, оплатив тариф «Бессрочный доступ» в любой момент. Стоимость тарифа учитывает ранее совершённые платежи по подписке.\nПодписочная модель автоматически изменится на тариф «Бессрочный доступ» после 4 ежегодных платежей.',
    //       ],
    //     },
    //     {
    //       title: null,
    //       subTitle: null,
    //       description: null,
    //       content: {
    //         progressView: null,
    //         codeActivationView: null,
    //         stepView: {
    //           title: {
    //             text: "Информация о состоянии подписки",
    //             textStyle: null,
    //             textColor: "#151515",
    //             spanData: null,
    //           },
    //           subTitle: {
    //             text: "Осталось 4 ежегодных платежа для перехода на тариф «Бессрочный доступ»",
    //             textStyle: null,
    //             textColor: "#6C7684",
    //             spanData: null,
    //           },
    //           steps: [
    //             {
    //               text: "1 год",
    //               textStyle: null,
    //               textColor: null,
    //               spanData: null,
    //             },
    //             {
    //               text: "2 года",
    //               textStyle: null,
    //               textColor: null,
    //               spanData: null,
    //             },
    //             {
    //               text: "3 года",
    //               textStyle: null,
    //               textColor: null,
    //               spanData: null,
    //             },
    //             {
    //               text: "4 года",
    //               textStyle: null,
    //               textColor: null,
    //               spanData: null,
    //             },
    //             {
    //               text: "Бессрочный доступ",
    //               textStyle: null,
    //               textColor: null,
    //               spanData: null,
    //             },
    //           ],
    //           selectedStep: 2,
    //         },
    //       },
    //       underBlockInfo: null,
    //     },
    //   ],
    // },
    {
      title: "Оплата",
      showBasket: false,
      content: [
        {
          content: {
            cardViews: [
              {
                serviceId: "DEVICE_ACCESS_SUBSCRIPTION_1Y",
                type: "BIG",
                title: {
                  text: "1 год",
                  textStyle: "MEDIUM",
                  textColor: "#151515",
                  spanData: null,
                },
                subTitle: {
                  text: "Абонентский доступ на 1 год использования",
                  textStyle: "MEDIUM",
                  textColor: "#151515",
                  spanData: null,
                },
                price: {
                  price: {
                    text: 2990,
                    textStyle: "REGULAR",
                    textColor: "#000000",
                    spanData: null,
                  },
                  displayCurrency: {
                    text: "₽",
                    textStyle: "REGULAR",
                    textColor: "#000000",
                    spanData: null,
                  },
                  currencyIsoCode: {
                    text: "RUR",
                    textStyle: "REGULAR",
                    textColor: "#000000",
                    spanData: null,
                  },
                },
                label: {
                  title: "Не оплачен",
                  titleColor: "#ffffff",
                  background: {
                    backgroundColor: "#F25248",
                    backgroundImage: null,
                  },
                },
                background: null,
                buttons: [
                  {
                    title: {
                      text: "Оплатить",
                      textStyle: null,
                      textColor: "#ffffff",
                      spanData: null,
                    },
                    background: {
                      backgroundColor: "#5EA9FF",
                      bakcgroundImage: null,
                    },
                    behaviour: 'SELECT_AND_NEXT',
                    url: "https://daichi.ru/devices/34/",
                  },
                ],
                items: [
                  {
                    text: "10 дней доступа, дополнительные дни использования можно докупить в любое время",
                    textStyle: null,
                    textColor: "#6C7684",
                    spanData: null,
                  },
                  {
                    text: "Управление со смартфона",
                    textStyle: null,
                    textColor: "#6C7684",
                    spanData: null,
                  },
                  {
                    text: "Бесплатные запчасти",
                    textStyle: null,
                    textColor: "#6C7684",
                    spanData: null,
                  },
                  {
                    text: "Круглосуточный Онлайн-мониторинг",
                    textStyle: null,
                    textColor: "#6C7684",
                    spanData: null,
                  },
                ],
                statistic: null,
                clickable: false,
                selected: false,
                description: null,
                subDescription: null,
              },
              {
                serviceId: "DEVICE_ACCESS_SUBSCRIPTION_1Y",
                type: "BIG",
                title: {
                  text: "Бессрочный доступ*",
                  textStyle: "MEDIUM",
                  textColor: "#ffffff",
                  spanData: null,
                },
                subTitle: {
                  text: "Управляй кондиционером без абонентской платы",
                  textStyle: "MEDIUM",
                  textColor: "#ffffff",
                  spanData: null,
                },
                price: {
                  price: {
                    text: '23 920',
                    textStyle: "MEDIUM",
                    textColor: "#ffffff",
                    spanData: null,
                  },
                  displayCurrency: {
                    text: "₽",
                    textStyle: "MEDIUM",
                    textColor: "#ffffff",
                    spanData: null,
                  },
                  currencyIsoCode: {
                    text: "RUR",
                    textStyle: "MEDIUM",
                    textColor: "#ffffff",
                    spanData: null,
                  },
                },
                label: null,
                background: {
                  backgroundColor: null,
                  backgroundImage: 'https://img.freepik.com/free-photo/abstract-grunge-decorative-relief-navy-blue-stucco-wall-texture-wide-angle-rough-colored-background_1258-28311.jpg?size=626&ext=jpg',
                },
                buttons: [
                  {
                    title: {
                      text: "Оплатить",
                      textStyle: null,
                      textColor: "#ffffff",
                      spanData: null,
                    },
                    background: {
                      backgroundColor: "#5EA9FF",
                      bakcgroundImage: null,
                    },
                    behaviour: 'PAY',
                    url: "https://daichi.ru/devices/34/",
                  },
                ],
                items: [
                  {
                    text: "Безлимитный доступ к управлению без абонентской платы",
                    textStyle: null,
                    textColor: "#ffffff",
                    spanData: null,
                  },
                  {
                    text: "Управление со смартфона",
                    textStyle: null,
                    textColor: "#ffffff",
                    spanData: null,
                  },
                  {
                    text: "Гарантия до 5 лет с момента установки",
                    textStyle: null,
                    textColor: "#ffffff",
                    spanData: null,
                  },
                  {
                    text: "Круглосуточный Онлайн-мониторинг",
                    textStyle: null,
                    textColor: "#ffffff",
                    spanData: null,
                  },
                ],
                statistic: null,
                clickable: false,
                selected: false,
                description: null,
                subDescription: null,
              },
            ],
            progressView: null,
            codeActivationView: null,
            stepView: null,
          },
          underBlockInfo: [
            'Вы можете приобрести кондиционер в собственность, оплатив тариф «Бессрочный доступ» в любой момент. Стоимость тарифа учитывает ранее приобретённые дни использования.\nВы можете перейти на тариф «Бессрочный доступ» после оплаты 700 дней.',
          ],
        },
      ],
    },
    {
      title: "Настройте ваш тариф",
      showBasket: true,
      content: [
        {
          title: "Покупайте дополнительные дни!",
          subTitle: null,
          description: "В абонентский доступ входит 10 дней управления, но вы можете увеличить их количество в любое время.",
          content: {
            cardViews: null,
            progressView: {
              serviceId: "DEVICE_ACCESS_DAYS",
              itemType: "DAYS_ACCESS",
              formula: {
                daysAccess: {
                  k: 1.710776,
                  e: 2.718281,
                  ek: -0.036366,
                  m: 44,
                  d: 0.461428,
                  pK: 3.3,
                },
              },
              quantity: {
                min: 0,
                current: 0,
                max: 365,
              },
              price: {
                price: {
                  text: '121',
                  textStyle: "MEDIUM",
                  textColor: "#151515",
                  spanData: null,
                },
                displayCurrency: {
                  text: "₽",
                  textStyle: "MEDIUM",
                  textColor: "#151515",
                  spanData: null,
                },
                currencyIsoCode: {
                  text: "RUR",
                  textStyle: "MEDIUM",
                  textColor: "#151515",
                  spanData: null,
                },
              },
              manualInputTitle: "Укажите кол-во часов текстом",
            },
            codeActivationView: null,
            stepView: null,
          },
          underBlockInfo: null,
          banner: {
            title: "Покупайте больше, тратьте меньше!",
            description: "Цена доп. дня уменьшается при выборе большего количества дней",
            image: "https://img.freepik.com/premium-vector/cute-pig-pattern-background_41365-206.jpg?w=2000",
          },
        },
      ],
    },
  ],
};

export const pages3 = {
  id: 5,
  pages: [
    {
      title: "Оплата 2",
      showBasket: false,
      content: [
        {
          content: {
            cardViews: [
              {
                serviceId: "DEVICE_ACCESS_SUBSCRIPTION_1Y",
                type: "BIG",
                title: {
                  text: "1 год",
                  textStyle: "MEDIUM",
                  textColor: "#151515",
                  spanData: null,
                },
                subTitle: {
                  text: "Абонентский доступ на 1 год использования",
                  textStyle: "MEDIUM",
                  textColor: "#151515",
                  spanData: null,
                },
                price: {
                  price: {
                    text: 2990,
                    textStyle: "REGULAR",
                    textColor: "#000000",
                    spanData: null,
                  },
                  displayCurrency: {
                    text: "₽",
                    textStyle: "REGULAR",
                    textColor: "#000000",
                    spanData: null,
                  },
                  currencyIsoCode: {
                    text: "RUR",
                    textStyle: "REGULAR",
                    textColor: "#000000",
                    spanData: null,
                  },
                },
                label: {
                  title: "Не оплачен",
                  titleColor: "#ffffff",
                  background: {
                    backgroundColor: "#F25248",
                    backgroundImage: null,
                  },
                },
                background: null,
                buttons: [
                  {
                    title: {
                      text: "Оплатить сейчас",
                      textStyle: null,
                      textColor: "#ffffff",
                      spanData: null,
                    },
                    background: {
                      backgroundColor: "#5EA9FF",
                      bakcgroundImage: null,
                    },
                    behaviour: 'PAY',
                    url: "https://daichi.ru/devices/34/",
                  },
                  {
                    title: {
                      text: "Добавить дни",
                      textStyle: null,
                      textColor: "#6C7684",
                      spanData: null,
                    },
                    background: {
                      backgroundColor: "#F4F4F8",
                      bakcgroundImage: null,
                    },
                    behaviour: 'NEXT',
                    url: "https://daichi.ru/devices/34/",
                  },
                ],
                items: [
                  {
                    text: "10 дней доступа, дополнительные дни использования можно докупить в любое время",
                    textStyle: null,
                    textColor: "#6C7684",
                    spanData: null,
                  },
                  {
                    text: "Управление со смартфона",
                    textStyle: null,
                    textColor: "#6C7684",
                    spanData: null,
                  },
                  {
                    text: "Бесплатные запчасти",
                    textStyle: null,
                    textColor: "#6C7684",
                    spanData: null,
                  },
                  {
                    text: "Круглосуточный Онлайн-мониторинг",
                    textStyle: null,
                    textColor: "#6C7684",
                    spanData: null,
                  },
                ],
                statistic: {
                  items: [
                    {
                      header: "Действует до",
                      title: {
                        text: "12.06.24",
                        textStyle: null,
                        textColor: "#FF9900",
                        spanData: null,
                      },
                    },
                    {
                      header: "Дни*",
                      title: {
                        text: "8 дней",
                        textStyle: null,
                        textColor: "#151515",
                        spanData: null,
                      },
                    },
                    {
                      header: "Часы",
                      title: {
                        text: "6 часов",
                        textStyle: null,
                        textColor: "#151515",
                        spanData: null,
                      },
                    },
                  ],
                  underText: "по состоянию на dd.mm.yy"
                },
                clickable: false,
                selected: false,
                description: null,
                subDescription: null,
              },
              {
                serviceId: "DEVICE_ACCESS_SUBSCRIPTION_1Y",
                type: "BIG",
                title: {
                  text: "Бессрочный доступ*",
                  textStyle: "MEDIUM",
                  textColor: "#ffffff",
                  spanData: null,
                },
                subTitle: {
                  text: "Управляй кондиционером без абонентской платы",
                  textStyle: "MEDIUM",
                  textColor: "#ffffff",
                  spanData: null,
                },
                price: {
                  price: {
                    text: '23 920',
                    textStyle: "MEDIUM",
                    textColor: "#ffffff",
                    spanData: null,
                  },
                  displayCurrency: {
                    text: "₽",
                    textStyle: "MEDIUM",
                    textColor: "#ffffff",
                    spanData: null,
                  },
                  currencyIsoCode: {
                    text: "RUR",
                    textStyle: "MEDIUM",
                    textColor: "#ffffff",
                    spanData: null,
                  },
                },
                label: null,
                background: {
                  backgroundColor: null,
                  backgroundImage: 'https://img.freepik.com/free-photo/abstract-grunge-decorative-relief-navy-blue-stucco-wall-texture-wide-angle-rough-colored-background_1258-28311.jpg?size=626&ext=jpg',
                },
                buttons: [
                  {
                    title: {
                      text: "Оплатить",
                      textStyle: null,
                      textColor: "#ffffff",
                      spanData: null,
                    },
                    background: {
                      backgroundColor: "#5EA9FF",
                      bakcgroundImage: null,
                    },
                    behaviour: 'PAY',
                    url: "https://daichi.ru/devices/34/",
                  },
                ],
                items: [
                  {
                    text: "Безлимитный доступ к управлению без абонентской платы",
                    textStyle: null,
                    textColor: "#ffffff",
                    spanData: null,
                  },
                  {
                    text: "Управление со смартфона",
                    textStyle: null,
                    textColor: "#ffffff",
                    spanData: null,
                  },
                  {
                    text: "Гарантия до 5 лет с момента установки",
                    textStyle: null,
                    textColor: "#ffffff",
                    spanData: null,
                  },
                  {
                    text: "Круглосуточный Онлайн-мониторинг",
                    textStyle: null,
                    textColor: "#ffffff",
                    spanData: null,
                  },
                ],
                statistic: null,
                clickable: false,
                selected: false,
                description: null,
                subDescription: null,
              },
              {
                serviceId: "DEVICE_ACCESS_SUBSCRIPTION_1Y",
                type: "BIG",
                title: {
                  text: "Бессрочный доступ*",
                  textStyle: "MEDIUM",
                  textColor: "#ffffff",
                  spanData: null,
                },
                subTitle: {
                  text: "Управляй кондиционером без абонентской платы",
                  textStyle: "MEDIUM",
                  textColor: "#ffffff",
                  spanData: null,
                },
                price: {
                  price: {
                    text: '23 920',
                    textStyle: "MEDIUM",
                    textColor: "#ffffff",
                    spanData: null,
                  },
                  displayCurrency: {
                    text: "₽",
                    textStyle: "MEDIUM",
                    textColor: "#ffffff",
                    spanData: null,
                  },
                  currencyIsoCode: {
                    text: "RUR",
                    textStyle: "MEDIUM",
                    textColor: "#ffffff",
                    spanData: null,
                  },
                },
                label: null,
                background: {
                  backgroundColor: null,
                  backgroundImage: 'https://img.freepik.com/free-photo/abstract-grunge-decorative-relief-navy-blue-stucco-wall-texture-wide-angle-rough-colored-background_1258-28311.jpg?size=626&ext=jpg',
                },
                buttons: [
                  {
                    title: {
                      text: "Оплатить",
                      textStyle: null,
                      textColor: "#ffffff",
                      spanData: null,
                    },
                    background: {
                      backgroundColor: "#5EA9FF",
                      bakcgroundImage: null,
                    },
                    behaviour: 'PAY',
                    url: "https://daichi.ru/devices/34/",
                  },
                ],
                items: [
                  {
                    text: "Безлимитный доступ к управлению без абонентской платы",
                    textStyle: null,
                    textColor: "#ffffff",
                    spanData: null,
                  },
                  {
                    text: "Управление со смартфона",
                    textStyle: null,
                    textColor: "#ffffff",
                    spanData: null,
                  },
                  {
                    text: "Гарантия до 5 лет с момента установки",
                    textStyle: null,
                    textColor: "#ffffff",
                    spanData: null,
                  },
                  {
                    text: "Круглосуточный Онлайн-мониторинг",
                    textStyle: null,
                    textColor: "#ffffff",
                    spanData: null,
                  },
                ],
                statistic: null,
                clickable: false,
                selected: false,
                description: null,
                subDescription: null,
              },
            ],
            progressView: null,
            codeActivationView: null,
            stepView: null,
          },
          underBlockInfo: [
            'Вы можете приобрести кондиционер в собственность, оплатив тариф «Бессрочный доступ» в любой момент. Стоимость тарифа учитывает ранее приобретённые дни использования.\nВы можете перейти на тариф «Бессрочный доступ» после оплаты 700 дней.',
          ],
        },
      ],
    },
    {
      title: "Настройте ваш тариф",
      showBasket: true,
      content: [
        {
          title: "Покупайте дополнительные дни!",
          subTitle: null,
          description: "В абонентский доступ входит 10 дней управления, но вы можете увеличить их количество в любое время.",
          content: {
            cardViews: null,
            progressView: {
              serviceId: "DEVICE_ACCESS_DAYS",
              itemType: "DAYS_ACCESS",
              formula: {
                daysAccess: {
                  k: 1.710776,
                  e: 2.718281,
                  ek: -0.036366,
                  m: 44,
                  d: 0.461428,
                  pK: 3.3,
                },
              },
              quantity: {
                min: 0,
                current: 0,
                max: 365,
              },
              price: {
                price: {
                  text: '121',
                  textStyle: "MEDIUM",
                  textColor: "#151515",
                  spanData: null,
                },
                displayCurrency: {
                  text: "₽",
                  textStyle: "MEDIUM",
                  textColor: "#151515",
                  spanData: null,
                },
                currencyIsoCode: {
                  text: "RUR",
                  textStyle: "MEDIUM",
                  textColor: "#151515",
                  spanData: null,
                },
              },
              manualInputTitle: "Укажите кол-во часов текстом",
            },
            codeActivationView: null,
            stepView: null,
          },
          underBlockInfo: null,
          banner: {
            title: "Покупайте больше, тратьте меньше!",
            description: "Цена доп. дня уменьшается при выборе большего количества дней",
            image: "https://img.freepik.com/premium-vector/cute-pig-pattern-background_41365-206.jpg?w=2000",
          },
        },
      ],
    },
  ],
};

export const basket = () => ({
  title: "Состав заказа",
  description: null,
  content: [
    {
      title: "Абонентская плата за 1 год использования",
      price: {
        price: {
          text: getRandomIntInclusive(100, 5000),
          textStyle: "MEDIUM",
          textColor: "#151515",
          spanData: null,
        },
        displayCurrency: {
          text: "₽",
          textStyle: "MEDIUM",
          textColor: "#151515",
          spanData: null,
        },
        currencyIsoCode: {
          text: "RUR",
          textStyle: "MEDIUM",
          textColor: "#151515",
          spanData: null,
        },
      },
    },
    {
      title: "1 день (x50)",
      price: {
        price: {
          text: getRandomIntInclusive(100, 5000),
          textStyle: "MEDIUM",
          textColor: "#151515",
          spanData: null,
        },
        displayCurrency: {
          text: "₽",
          textStyle: "MEDIUM",
          textColor: "#151515",
          spanData: null,
        },
        currencyIsoCode: {
          text: "RUR",
          textStyle: "MEDIUM",
          textColor: "#151515",
          spanData: null,
        },
      },
    },
  ],
  totalPrice: {
    price: {
      text: '0',
      textStyle: "MEDIUM",
      textColor: "#151515",
      spanData: null,
    },
    displayCurrency: {
      text: "₽",
      textStyle: "MEDIUM",
      textColor: "#151515",
      spanData: null,
    },
    currencyIsoCode: {
      text: "RUR",
      textStyle: "MEDIUM",
      textColor: "#151515",
      spanData: null,
    },
  },
  button: {
    title: {
      text: "Оформить",
      textStyle: "MEDIUM",
      textColor: "#ffffff",
      spanData: null,
    },
    background: {
      backgroundColor: "#5EA9FF",
      backgroundImage: null,
    },
    behaviour: "ServiceButtonBehaviourEnum",
    url: "https://daichi.ru/devices/34/",
  },
});

export const conflict = {
  response: {
    data: {
      title: "Выключить комфортный сон",
      description: {
        text: '5 444',
        textStyle: "MEDIUM",
        textColor: "#151515",
        spanData: null,
      },
      contentTitle: {
        text: '5 444',
        textStyle: "MEDIUM",
        textColor: "#151515",
        spanData: null,
      },
      contents: [{
        title: "Настройка",
        lineContent: {
          text: '5 444',
          textStyle: "MEDIUM",
          textColor: "#151515",
          spanData: null,
        },
        stateContent:{
          text: "23C, 18ч",
          icons: ["icon1","icon2","icon3"],
          iconsSvg: ["icon.svg", "icon2.svg"],
        },
      }],
      actions: [{
        title: "Ок",
        type: "PRIMARY",
        conflictResolveData: { confirm: true },
        behaviour: "REQUEST",
        urlData: {
          url: "https://daichi.ru",
          isExternal: true,
          finishUrls: ["https://web.daichicloud.ru/payments/success"],
        },
      }],
      headerImage: "https://daichi.ru/image123.png",
    },
    config: {
      method: 'post',
      url: 'payments/v2/sessions',
    }
  },
};

export const dcOrder = {
  number: "KA-53",
  status: "COMPLETED",
  uuid: "122222222",
  purchaseUrl: "https://daichi.ru"
};
