
import { mapGetters, mapActions } from "vuex";
import CloseIcon from "../../assets/icons/24x24/close.svg";
import ClimateOnlinePromo from "../climate-online/ClimateOnlinePromo.vue";
import ClimateOnlineStatistics from "../climate-online/ClimateOnlineStatistics.vue";
import ClimateOnlineConnect from "../climate-online/ClimateOnlineConnect.vue";

export default {
  name: "ClimateOnlineSummaryModal",
  components: {
    CloseIcon,
    ClimateOnlinePromo,
    ClimateOnlineStatistics,
    ClimateOnlineConnect,
  },
  data: () => ({
    modalName: 'climateOnlineSummaryModal',
    isClimateOnlineSummaryRequest: false,
  }),
  computed: {
    ...mapGetters({
      getTranslations: "localization/getTranslations",
      buildingClimateOnline: 'buildingDashboard/getBuildingClimateOnline',
    }),
  },
  methods: {
    ...mapActions({
      fetchClimateOnline: "deviceFunctions/fetchClimateOnline",
      fetchDeviceInfo: "deviceFunctions/fetchDeviceInfo",
    }),
    onDeviceClick(id) {
      this.isClimateOnlineSummaryRequest = true;

      this.fetchDeviceInfo(id)
        .then(() => {
          this.$store.commit('system/CONTROL_PANEL_DEVICE_ID', id);
          this.fetchClimateOnline(
            {
              deviceId: id,
              onClose: () => {
                this.isClimateOnlineSummaryRequest = false;
                this.$modal.hide('climateOnlineModal');
              },
            },
          )
            .then(() => {
              this.$modal.show("climateOnlineModal");
            })
            .finally(() => {
              this.isClimateOnlineSummaryRequest = false;
            });
        })
        .finally(() => {
          this.isClimateOnlineSummaryRequest = false;
        });
    },
    onOpen() {
      this.$store.commit("buildingDashboard/SET_DASHBOARD_CLIMATE_ONLINE_STATUS", true);
    },
    onClose() {
      this.$store.commit("buildingDashboard/SET_DASHBOARD_CLIMATE_ONLINE_STATUS", false);
    },
  },
};
