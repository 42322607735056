
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ObjectsList',
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
    title() {
      if (this.object) {
        const name = this.object.title;
        return name.slice(0, 70).length < name.length ? `${name.slice(0, 70)}...` : name;
      }
      return '';
    },
  },
  methods: {
    ...mapActions({
      setBuildingSelectId: 'buildings/setBuildingSelectId',
    }),
    selectBuilding() {
      this.setBuildingSelectId(this.object.id);
      this.$emit('selectBuilding');
    },
    getAccessName(name) {
      return this.getTranslations[name];
    }
  },
};
