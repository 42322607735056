import Vue from 'vue';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

Vue.component('date-picker', DatePicker);

export default {
  components: {
    DatePicker,
  },
};

// export default ({ app }) => {
//   console.log('####app', app);
//   app.use(setupCalendar, {});
// };
