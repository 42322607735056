
import { mapGetters, mapActions } from "vuex";

import CloseIcon from "../../assets/icons/24x24/close.svg";
import ClimateOnlineRecommendations from "../climate-online/ClimateOnlineRecommendations.vue";
import ClimateOnlineInfo from "../climate-online/ClimateOnlineInfo.vue";
import ClimateOnlineErrors from "../climate-online/ClimateOnlineErrors.vue";
import ClimateOnlineNoErrors from "../climate-online/ClimateOnlineNoErrors.vue";
import ClimateOnlinePayment from "../climate-online/ClimateOnlinePayment.vue";
import ActivationModal from "../modals/ActivationModal.vue";
import CityModal from "../modals/CityModal.vue";
import PromocodeModal from "../modals/PromocodeModal.vue";

export default {
  name: "ClimateOnlineModal",
  components: {
    CloseIcon,
    ClimateOnlineRecommendations,
    ClimateOnlineInfo,
    ClimateOnlineErrors,
    ClimateOnlineNoErrors,
    ClimateOnlinePayment,
    ActivationModal,
    CityModal,
    PromocodeModal,
  },
  data: () => ({
    modalName: "climateOnlineModal",
    slider: null,
    purchasedOrders: null,
    citySelected: false,
    isCityRequest: false,
    isPromocodeRequest: false,
    isCreatingOrder: false,
    mockOrders: [
      {
        id: 1,
        status: 'PURCHASED',
        type: 'CLIMATE_ONLINE_SUBSCRIPTION',
        purchaseUrl: "https://daichi.ru/purchase/123213",
        price: {
          type: "CLIMATE_ONLINE_SUBSCRIPTION",
	        price: 123.231,
	        currency: "Р",
          data: {
            id: 1111,
            content: 365,
          },
        },
      },
      {
        id: 2,
        status: 'PURCHASED',
        type: 'CLIMATE_ONLINE_SUBSCRIPTION',
        purchaseUrl: "https://daichi.ru/purchase/123213",
        price: {
          type: "CLIMATE_ONLINE_SUBSCRIPTION",
	        price: 123.231,
	        currency: "Р",
          data: {
            id: 1111,
            content: 365,
          },
        },
      },
    ],
    citySubmitActionType: null,
  }),
  computed: {
    ...mapGetters({
      getTranslations: "localization/getTranslations",
      deviceInfo: "deviceFunctions/getDeviceInfo",
      climateOnlineInfo: "deviceFunctions/getClimateOnlineInfo",
      isClimateOnlineRequest: "deviceFunctions/getClimateOnlineRequestStatus",
      climateOnlineSummaryModalOpened: "buildingDashboard/getBuildingClimateOnlineStatus",
      buildingSelectId: "buildings/getBuildingSelectId",
    }),
    showNoErrorsBlock() {
      // if (this.climateOnlineInfo.isActive) {
      //   if (!this.climateOnlineInfo.errors || !this.climateOnlineInfo.errors.length) {
      //     return true;
      //   }
      // } else if (!this.climateOnlineInfo.errors || !this.climateOnlineInfo.errors.length) {
      //   if (!this.climateOnlineInfo.recommendations || !this.climateOnlineInfo.recommendations.length) {
      //     if (!this.climateOnlineInfo.canInteractWithDevice) {
      //       return true;
      //     }
      //   }
      // }

      // return false;
      if (this.climateOnlineInfo.isActive) {
        if (!this.showClimateOnlineErrors) return true;
      }

      if (!this.climateOnlineInfo.isActive) {
        if (!this.showClimateOnlinePayment && !this.showClimateOnlineRecommendations) return true;
      }

      return false;
    },
    showClimateOnlinePayment() {
      if (this.deviceInfo.subscription && this.deviceInfo.subscription.status === "BLOCKED") {
        return false;
      }

      if (!this.climateOnlineInfo.isActive && this.climateOnlineInfo.canInteractWithDevice && this.climateOnlineInfo.canBeActivated) {
        return true;
      }

      return false;
    },
    showClimateOnlineRecommendations() {
      return this.climateOnlineInfo.recommendations && this.climateOnlineInfo.recommendations.length;
    },
    showClimateOnlineInfo() {
      return this.climateOnlineInfo.isActive && this.climateOnlineInfo.canInteractWithDevice;
    },
    showClimateOnlineErrors() {
      return this.climateOnlineInfo.isActive && this.climateOnlineInfo.errors && this.climateOnlineInfo.errors.length;
    },
  },
  methods: {
    ...mapActions({
      getPurchasedClimateOnlineOrders: "deviceFunctions/getPurchasedClimateOnlineOrders",
      activateClimateOnlineOrder: "deviceFunctions/activateClimateOnlineOrder",
      fetchClimateOnline: "deviceFunctions/fetchClimateOnline",
      fetchDeviceInfo: "deviceFunctions/fetchDeviceInfo",
      setClimateOnlineCity: "deviceFunctions/setClimateOnlineCity",
      createClimateOnlineOrder: "deviceFunctions/createClimateOnlineOrder",
      sendClimateOnlinePromocode: "deviceFunctions/sendClimateOnlinePromocode",
      fetchBuildingClimateOnline: 'buildingDashboard/fetchBuildingClimateOnline',
    }),
    onOpen() {
      this.$store.commit("deviceFunctions/setClimateOnlineRequestStatus", true);

      this.getPurchasedClimateOnlineOrders(
        {
          deviceId: this.deviceInfo.id,
          onClose: () => {
            this.$store.commit("deviceFunctions/setClimateOnlineRequestStatus", false);
            this.$modal.hide('climateOnlineModal');
          },
        },
      )
        .then(data => {
          this.purchasedOrders = data;
          // this.purchasedOrders = this.mockOrders;
        })
        .finally(() => {
          this.$store.commit("deviceFunctions/setClimateOnlineRequestStatus", false);
        });
    },
    beforeClose() {
      this.purchasedOrders = null;
      this.citySelected = false;
      this.isCityRequest = false;
      this.isPromocodeRequest = false;
      this.isCreatingOrder = false;
      this.fetchDeviceInfo(this.deviceInfo.id);
    },
    onActivationClick(id) {
      this.activateClimateOnlineOrder(id)
        .then(res => {
          this.purchasedOrders = this.purchasedOrders.filter(order => order.id !== id);
          // this.$modal.hide(`activationModal-${id}`);

          if (!this.purchasedOrders.length) {
            this.$store.commit("deviceFunctions/setClimateOnlineRequestStatus", true);

            this.fetchClimateOnline(
              {
                deviceId: this.deviceInfo.id,
                onClose: () => {
                  this.$modal.hide('climateOnlineModal');
                },
              },
            )
              .finally(() => this.$store.commit("deviceFunctions/setClimateOnlineRequestStatus", false));
          }
        });
    },
    onCitySubmit(val) {
      this.isCityRequest = true;

      this.setClimateOnlineCity(
        {
          deviceId: this.deviceInfo.id,
          city: val,
          onClose: () => {
            this.isCityRequest = false;
          },
        },
      )
        .then(() => {
          this.citySelected = true;
          this.$modal.hide('cityModal');

          if (this.citySubmitActionType === 'buy') {
            this.$store.commit("deviceFunctions/setClimateOnlineRequestStatus", true);

            this.createClimateOnlineOrder(
              {
                requestBody: {
                  deviceId: this.deviceInfo.id,
                  type: "CLIMATE_ONLINE_SUBSCRIPTION",
                },
                onClose: () => {
                  this.$store.commit("deviceFunctions/setClimateOnlineRequestStatus", false);
                },
              },
            )
              .then(data => {
                window.location.href = data.purchaseUrl;
              })
              .finally(() => {
                this.$store.commit("deviceFunctions/setClimateOnlineRequestStatus", false);
              });
          }

          if (this.citySubmitActionType === 'promocode') {
            this.$modal.show('promocodeModal');
          }
        })
        .finally(() => {
          this.isCityRequest = false;
        });
    },
    onPromocodeClick() {
      if (!this.citySelected) {
        this.$modal.show('cityModal');
        this.citySubmitActionType = 'promocode';
        return;
      }

      this.$modal.show('promocodeModal');
    },
    onPromocodeSubmit(val) {
      this.isPromocodeRequest = true;

      this.sendClimateOnlinePromocode(
        {
          requestBody: {
            licenseKey: val,
          },
          deviceId: this.deviceInfo.id,
          onClose: () => {
            this.isPromocodeRequest = false;
          },
        },
      )
        .then(() => {
          this.$modal.hide('promocodeModal');

          this.$store.commit("deviceFunctions/setClimateOnlineRequestStatus", true);

          this.fetchClimateOnline(
            {
              deviceId: this.deviceInfo.id,
              onClose: () => {
                this.$modal.hide('climateOnlineModal');
              },
            },
          )
            .finally(() => this.$store.commit("deviceFunctions/setClimateOnlineRequestStatus", false));
        })
        .finally(() => {
          this.isPromocodeRequest = false;
        });
    },
    onBuyClick() {
      if (!this.citySelected) {
        this.$modal.show('cityModal');
        this.citySubmitActionType = 'buy';
        return;
      }

      this.$store.commit("deviceFunctions/setClimateOnlineRequestStatus", true);

      this.createClimateOnlineOrder(
        {
          requestBody: {
            deviceId: this.deviceInfo.id,
            type: "CLIMATE_ONLINE_SUBSCRIPTION",
          },
          onClose: () => {
            this.$store.commit("deviceFunctions/setClimateOnlineRequestStatus", false);
          },
        },
      )
        .then(data => {
          window.location.href = data.purchaseUrl;
        })
        .finally(() => {
          this.$store.commit("deviceFunctions/setClimateOnlineRequestStatus", false);
        });
    },
    onClose() {
      if (this.climateOnlineSummaryModalOpened) {
        this.fetchBuildingClimateOnline(this.buildingSelectId);
      }
    },
  },
};
