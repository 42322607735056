
import { mapGetters, mapActions } from "vuex";
import CloseIcon from "../../assets/icons/24x24/close.svg";
import AppInput from "../Inputs/AppInput.vue";
import AppButton from "../Buttons/AppButton.vue";

export default {
  name: "CityModal",
  components: {
    CloseIcon,
    AppInput,
    AppButton
  },
  props: {
    city: {
      type: String,
      default: '',
    },
    onCitySubmit: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    modalName: "cityModal",
    cityValue: '',
  }),
  computed: {
    ...mapGetters({
      getTranslations: "localization/getTranslations",
    }),
  },
  mounted() {
    this.cityValue = this.city ? this.city : '';
  },
  methods: {
    onSubmit() {
      this.onCitySubmit(this.cityValue);
    },
    onInput(val) {
      this.cityValue = val;
    }
  },
};
