import Vue from 'vue';
import { errorServiceHandler } from '@/utils/api';

export const SET_PLACE_PRESETS = 'SET_PLACE_PRESETS';
export const SET_GROUP_PRESETS = 'SET_GROUP_PRESETS';
export const SET_PAGE_DATA = 'SET_PAGE_DATA';
export const SET_PRESET_IN_PANEL = 'SET_PRESET_IN_PANEL';
export const UNPIN_PRESET = 'UNPIN_PRESET';
export const RENAME_PRESET = 'RENAME_PRESET';
export const RENAME_PRESET_GROUP = 'RENAME_PRESET_GROUP';
export const UNPIN_GROUP_PRESET = 'UNPIN_GROUP_PRESET';
export const SET_PRESET_IN_PANEL_INFO = 'SET_PRESET_IN_PANEL_INFO';
export const ADD_PLACE_PRESET = 'ADD_PLACE_PRESET';
export const ADD_GROUP_PRESET = 'ADD_GROUP_PRESET';
export const ADD_GROUP_PRESET_TIMER = 'ADD_GROUP_PRESET_TIMER';
export const EDIT_PLACE_PRESET = 'EDIT_PLACE_PRESET';
export const EDIT_GROUP_PRESET = 'EDIT_GROUP_PRESET';
export const SET_PRESET_IN_PANEL_NAME = 'SET_PRESET_IN_PANEL_NAME';
export const PIN_PRESET = 'PIN_PRESET';
export const PIN_GROUP_PRESET = 'PIN_GROUP_PRESET';

export const state = () => ({
  placePresets: null,
  groupPresets: null,
  presetsInPanelId: localStorage.getItem('presetInPanelInfoId') === 'syntheticGroupId'
    ? 'syntheticGroupId'
    : Number(localStorage.getItem('presetInPanelInfoId')),
  presetInPanelInfoId: localStorage.getItem('presetInPanelInfoId') === 'syntheticGroupId'
    ? 'syntheticGroupId'
    : Number(localStorage.getItem('presetInPanelInfoId')),
  presetInPanelName: localStorage.getItem('presetInPanelName') || '',
});

export const getters = {
  getPlacePresets: ({ placePresets }) => placePresets,
  getGroupPresets: ({ groupPresets }) => groupPresets,
  getPresetsInPanelId: ({ presetsInPanelId }) => presetsInPanelId,
  getPresetInPanelInfoId: ({ presetInPanelInfoId }) => presetInPanelInfoId,
  getPresetInPanelName: ({ presetInPanelName }) => presetInPanelName,
};

export const mutations = {
  [SET_PLACE_PRESETS](state, value) {
    state.placePresets = value;
  },
  [SET_GROUP_PRESETS](state, value) {
    state.groupPresets = value;
  },
  [SET_PAGE_DATA](state, value) {
    state.placePresets = value.placePresets;
    state.groupPresets = value.groupPresets;
  },
  [SET_PRESET_IN_PANEL_INFO](state, value) {
    if (value) {
      if (!state.groupPresets) {
        state.groupPresets = [value];
        state.presetInPanelInfoId = value.id;
        localStorage.setItem('presetInPanelInfoId', value.id);
      } else {
        localStorage.setItem('presetInPanelInfoId', value.id);
        state.presetInPanelInfoId = value.id;
      }
    } else {
      localStorage.removeItem('presetInPanelInfoId');
      state.presetInPanelInfoId = null;
    }
  },
  [SET_PRESET_IN_PANEL](state, value) {
    state.presetsInPanelId = value;
    localStorage.setItem('presetInPanelInfoId', value);
  },
  [SET_PRESET_IN_PANEL_NAME](state, name) {
    state.presetInPanelName = name;
    localStorage.setItem('presetInPanelName', name);
  },
  [UNPIN_PRESET](state, id) {
    if (state.placePresets) {
      const index = state.placePresets.findIndex((item) => item.id == id);
      if (index !== -1) {
        state.placePresets[index].pinned = false;
      }
    }
  },
  [UNPIN_GROUP_PRESET](state, id) {
    if (state.groupPresets) {
      const index = state.groupPresets.findIndex((item) => item.id == id);
      if (index !== -1) {
        state.groupPresets[index].pinned = false;
      }
    }
  },
  [PIN_PRESET](state, id) {
    if (state.placePresets) {
      state.placePresets = state.placePresets.map((item) => {
        if (item.id == id) {
          item.pinned = true;
        }
        return item;
      });
    }
  },
  [PIN_GROUP_PRESET](state, id) {
    if (state.groupPresets) {
      state.groupPresets = state.groupPresets.map((item) => {
        if (item.id == id) {
          item.pinned = true;
        }
        return item;
      });
    }
  },
  [RENAME_PRESET](state, preset) {
    if (state.placePresets) {
      state.placePresets = state.placePresets.map((item) => {
        if (item.id === preset.id) {
          Object.assign(item, preset);
        }
        return item;
      });
    }
  },
  [RENAME_PRESET_GROUP](state, preset) {
    if (state.groupPresets) {
      state.groupPresets = state.groupPresets.map((item) => {
        if (item.id === preset.id) {
          Object.assign(item, preset);
        }
        return item;
      });
    }
  },
  [ADD_PLACE_PRESET](state, value) {
    if (state.placePresets) {
      state.placePresets.unshift(value);
    }
  },
  [ADD_GROUP_PRESET](state, value) {
    if (state.groupPresets) {
      state.groupPresets.unshift(value);
    }
  },
  [ADD_GROUP_PRESET_TIMER](state) {
    if (state.groupPresets) {
      state.groupPresets.map((groupPreset) => {
        if (!Object.prototype.hasOwnProperty.call(groupPreset, 'timer')) {
          Vue.set(groupPreset, 'timer', null);
        }
        return groupPreset;
      });
    }
  },
  [EDIT_PLACE_PRESET](state, value) {
    if (state.placePresets) {
      state.placePresets = state.placePresets.map((item) => {
        if (item.id === value.id) {
          Object.assign(item, value);
        }
        return item;
      });
    }
  },
  [EDIT_GROUP_PRESET](state, value) {
    if (state.groupPresets) {
      state.groupPresets = state.groupPresets.map((item) => {
        if (item.id === value.id) {
          Object.assign(item, value);
        }
        return item;
      });
    }
  },
};

export const actions = {
  fetchPageData({ commit }, buildingId) {
    this.$axios.get(
      `/buildings/${buildingId}/presets`,
      {
        headers: {
          Authorization: `Bearer ${this.$auth.user.token}`,
        },
      },
    )
      .then((response) => {
        const responseData = response.data.data;
        commit(SET_PAGE_DATA, responseData);
        commit(ADD_GROUP_PRESET_TIMER);
      })
      .catch((error) => {
        errorServiceHandler.call(this, error);
      });
  },

  deletePlacePreset({ commit, getters }, id) {
    if (getters.getPlacePresets) {
      const placePresets = getters.getPlacePresets.filter((item) => item.id !== id);
      commit(SET_PLACE_PRESETS, placePresets);
    }
  },

  deleteGroupPreset({ commit, getters }, id) {
    if (getters.getGroupPresets) {
      const groupPresets = getters.getGroupPresets.filter((item) => item.id !== id);
      commit(SET_GROUP_PRESETS, groupPresets);
    }
  },

  renamePreset({ commit }, preset) {
    commit(RENAME_PRESET, preset);
  },

  renameGroupPreset({ commit }, preset) {
    commit(RENAME_PRESET_GROUP, preset);
  },
};
