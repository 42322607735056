const TOGGLE_MENU_ACTIVE = 'TOGGLE_MENU_ACTIVE';

export const state = () => ({
  menuActive: null,
});

export const getters = {
  getMenuState: (e) => e.menuActive,
};

export const mutations = {
  [TOGGLE_MENU_ACTIVE](state, value) {
    if (typeof arguments[1] === 'undefined') {
      state.menuActive = !state.menuActive;
    } else {
      state.menuActive = value;
    }
  },
};

export const actions = {
  toggleMenuActive({ commit }, value) {
    commit(TOGGLE_MENU_ACTIVE, value);
  },
};
