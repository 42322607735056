
import NoConnection from '@/assets/images/errors/no-connection.svg';
import modalEsc from '@/mixins/modalEsc';
import { mapGetters } from 'vuex';

export default {
  name: 'ModalNoConnection',
  components: { NoConnection },
  mixins: [modalEsc],
  data() {
    return {
      modalName: 'noConnection',
    };
  },
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
  },
};
