export default {
  methods: {
    clickHandler(e) {
      const { keyCode } = e;
      if (keyCode === 27) {
        this.$modal.hide(this.modalName);
      }
    },
  },
};
