
import { mapGetters } from 'vuex';

export default {
  name: 'AddFastCommandButton',
  props: {
    deviceInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
    disabled() {
      return !this.deviceInfo?.state?.isOn || this.deviceInfo?.status !== 'connected';
    },
  },
};
