
import OnOffIcon from '../../assets/icons/OnOff.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'ToggleButton',
  components: { OnOffIcon },
  props: {
    controllable: {
      type: Boolean,
      default: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    })
  },
  methods: {
    changeState() {
      if (!this.controllable) {
        return;
      }
      this.$emit('changeOnOffState', !this.active);
    },
  },
};
