import { render, staticRenderFns } from "./AddPeriodButton.vue?vue&type=template&id=095f6ba2&scoped=true"
import script from "./AddPeriodButton.vue?vue&type=script&lang=js"
export * from "./AddPeriodButton.vue?vue&type=script&lang=js"
import style0 from "./AddPeriodButton.vue?vue&type=style&index=0&id=095f6ba2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "095f6ba2",
  null
  
)

export default component.exports