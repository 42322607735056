import { render, staticRenderFns } from "./ClimateOnlineInfo.vue?vue&type=template&id=57fb3fed"
import script from "./ClimateOnlineInfo.vue?vue&type=script&lang=js"
export * from "./ClimateOnlineInfo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InfoList: require('/builds/web-cloud/webfront/components/InfoList.vue').default})
