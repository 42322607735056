
import { mapGetters } from 'vuex';

export default {
  name: 'AddPeriodButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
  },
};
