
import ArrowDropdownIcon from '../../assets/icons/Arrows/arrowDropdown.svg';

export default {
  name: 'SelectInput',
  components: { ArrowDropdownIcon },
  props: {
    options: {
      type: Array,
      required: true,
    },
    initialOption: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: 'Выберите что-то',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    extraTitle: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    showList: false,
    currentChoice: null,
  }),
  mounted() {
    this.setInitialChoice();
  },
  methods: {
    clickOutside() {
      if (this.showList) this.showList = false;
    },
    choiceOption(option) {
      this.currentChoice = option;
      this.showList = false;
      this.$emit('valueChoice', option);
    },
    setInitialChoice() {
      if (this.initialOption?.value) {
        this.choiceOption(this.options.find((option) => option.value === this.initialOption.value));
      } else {
        this.choiceOption(this.options[0]);
      }
    },
    setChoiceCallback(callback) {
      const option = this.options.find(callback);
      if (option) {
        this.currentChoice = option;
        this.$emit('valueChoice', option);
      }
    },
  },
};
