import Vue from 'vue';
import { errorServiceHandler, errorHandlerWithAction } from '@/utils/api';

export const SET_BUILDINGS_LIST_LOAD = 'SET_BUILDINGS_LIST_LOAD';
export const SET_BUILDINGS_LIST = 'SET_BUILDINGS_LIST';
export const SET_BUILDING_SELECT_ID = 'SET_BUILDING_SELECT_ID';
export const SET_BUILDING_SELECT_PERMISSIONS = 'SET_BUILDING_SELECT_PERMISSIONS';
export const PREPARE_BUILDING_SELECT_PLACES_PRESETS = 'PREPARE_BUILDING_SELECT_PLACES_PRESETS';
export const SET_BUILDING_SELECT_PLACES_GROUP_PRESETS = 'SET_BUILDING_SELECT_PLACES_GROUP_PRESETS';
export const SET_BUILDING_SELECT_PLACES_PRESETS = 'SET_BUILDING_SELECT_PLACES_PRESETS';
export const SET_BUILDING_ENERGY_CONSUMPTION = 'SET_BUILDING_ENERGY_CONSUMPTION';
export const SET_BUILDING_RANGED_ENERGY_CONSUMPTION = 'SET_BUILDING_RANGED_ENERGY_CONSUMPTION';
export const SET_BUILDING_ENERGY_REQUEST_DATA = 'SET_BUILDING_ENERGY_REQUEST_DATA';
export const SET_ENERGY_TARIFFS = 'SET_ENERGY_TARIFFS';

export const BUILDINGS_ACCESS_LANG = {
  owner: 'Владелец',
  master: 'Полный доступ к объекту',
  guest: 'Гость',
};

export const state = () => {
  let buildingSelectId = localStorage.getItem('buildingSelectId');
  if (buildingSelectId) {
    buildingSelectId = parseInt(buildingSelectId);
    if (isNaN(buildingSelectId)) buildingSelectId = null;
  }

  return {
    buildingsList: [],
    buildingsListLoad: true,
    buildingSelect: null,
    buildingSelectId,
    buildingSelectPermissions: {},
    buildingEnergyConsumption: null,
    buildingRangedEnergyConsumption: null,
    energyRequestData: {
      devices: [],
      start: null,
      end: null,
      selectedPeriod: 'DAY',
    },
    energyTariffs: null,
  };
};

export const getters = {
  getBuildingsList: (e) => e.buildingsList,
  getBuildingsListLoad: (e) => e.buildingsListLoad,
  getBuildingSelect: (e) => e.buildingSelect,
  getBuildingSelectId: (e) => e.buildingSelectId,
  getBuildingSelectPermissions: (e) => e.buildingSelectPermissions,
  getBuildingEnergyConsumption: (e) => e.buildingEnergyConsumption,
  getBuildingRangedEnergyConsumption: (e) => e.buildingRangedEnergyConsumption,
  getEnergyRequestData: (e) => e.energyRequestData,
  getEnergyTariffs: (e) => e.energyTariffs,
};

function getBuildingPermissions(building) {
  const isOwner = building.access === 'owner';
  const isMaster = building.access === 'master';
  const isGuest = building.access === 'guest';
  const hasPlaces = building.places && building.places.length > 0;
  const isOwnerOrMaster = isOwner || isMaster;

  return {
    'building.delete': isOwner,

    'places.create': isOwnerOrMaster,

    'schedule.create': isOwnerOrMaster,
    'schedule.view': isOwnerOrMaster,

    'accessRights.view': isOwnerOrMaster,
    'accessRights.user.create': isOwnerOrMaster,

    'fastCommand.create': isOwnerOrMaster,
    'fastCommand.edit': isOwnerOrMaster,

    'group.create': isOwnerOrMaster,
  };
}

export const mutations = {
  [SET_BUILDINGS_LIST](state, value) {
    state.buildingsList = value;
  },
  [SET_BUILDINGS_LIST_LOAD](state, value) {
    state.buildingsListLoad = value;
  },
  [SET_BUILDING_ENERGY_CONSUMPTION](state, value) {
    state.buildingEnergyConsumption = value;
  },
  [SET_BUILDING_RANGED_ENERGY_CONSUMPTION](state, value) {
    state.buildingRangedEnergyConsumption = value;
  },
  [SET_ENERGY_TARIFFS](state, value) {
    state.energyTariffs = value;
  },
  [SET_BUILDING_ENERGY_REQUEST_DATA](state, value) {
    state.energyRequestData = value;
  },
  [SET_BUILDING_SELECT_ID](state, value) {
    const building = state.buildingsList.find((e) => e.id === value);
    if (building) {
      state.buildingSelectId = value;
      state.buildingSelect = building;
      state.buildingSelectPermissions = getBuildingPermissions(building);
      localStorage.setItem('buildingSelectId', state.buildingSelectId);
    } else {
      state.buildingSelectId = null;
      state.buildingSelect = null;
      state.buildingSelectPermissions = {};
    }
  },

  [PREPARE_BUILDING_SELECT_PLACES_PRESETS](state) {
    state.buildingSelect.places.map((place) => {
      if (!Object.prototype.hasOwnProperty.call(place, 'placePresets')) {
        Vue.set(place, 'placePresets', null);
      }
      if (!Object.prototype.hasOwnProperty.call(place, 'groupPresets')) {
        Vue.set(place, 'groupPresets', null);
      }
      return place;
    });
  },

  [SET_BUILDING_SELECT_PLACES_PRESETS](state, placePresets) {
    state.buildingSelect.places.map((place) => {
      const newPlace = place;
      newPlace.placePresets = placePresets.filter((preset) => preset.placeId === place.id);
      return newPlace;
    });
  },

  [SET_BUILDING_SELECT_PLACES_GROUP_PRESETS](state, groupPresets) {
    state.buildingSelect.places.map((place) => {
      const newPlace = place;
      newPlace.groupPresets = groupPresets.filter((preset) => !!preset.contents
        .find((content) => content.placeId === place.id));
      return newPlace;
    });
  },
};

export const actions = {
  async fetchBuildingList({ commit, state }) {
    try {
      const { data } = await this.$axios.get('buildings');
      commit(SET_BUILDINGS_LIST_LOAD, true);
      commit(SET_BUILDINGS_LIST, data.data.map((e) => Object.assign(e, {
        access_name: BUILDINGS_ACCESS_LANG[e.access] ? BUILDINGS_ACCESS_LANG[e.access] : e.access,
        title_corp: e.title,
        image_thumb: e.image ? e.image : require('~/static/content/myhouse.png'),
        image_background: e.image ? e.image : require('~/static/objectCard.png'),
      })));

      // Выбор первого объекта из доступных, если необходимо
      if (state.buildingSelectId && typeof data.data.find((e) => e.id === state.buildingSelectId) !== 'undefined') {
        commit(SET_BUILDING_SELECT_ID, state.buildingSelectId);
      } else if (data.data[0] && (
        state.buildingSelect === null
          || typeof data.data.find((e) => e.id === state.buildingSelectId) === 'undefined')
      ) {
        commit(SET_BUILDING_SELECT_ID, data.data[0].id);
      } else if (data.data.length === 0) {
        commit(SET_BUILDING_SELECT_ID, null);
      }
      commit(PREPARE_BUILDING_SELECT_PLACES_PRESETS);
    } catch (error) {
      errorServiceHandler.call(this, error);
    } finally {
      commit(SET_BUILDINGS_LIST_LOAD, false);
    }
  },
  async setBuildingSelectId({ commit, state, dispatch }, id) {
    commit(SET_BUILDING_SELECT_ID, id);
    commit(PREPARE_BUILDING_SELECT_PLACES_PRESETS);
  },
  fetchBuildingEnergyConsumption({ commit, state, dispatch }, onClose) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(
          `buildings/${state.buildingSelectId}/get-energy-consumption`,
          state.energyRequestData,
          {
            headers: {
              Authorization: `Bearer ${this.$auth.user.token}`,
            },
          },
        )
        .then((res) => {
          commit(SET_BUILDING_ENERGY_CONSUMPTION, res.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          errorHandlerWithAction.call(this, err, {
            onRepeat: () => dispatch('fetchBuildingEnergyConsumption', onClose),
            onClose,
          });
        });
    });
  },
  fetchRangedBuildingEnergyConsumption({ commit, state, dispatch }, onClose) {
    return new Promise((res, rej) => {
      const promises = [];

      state.buildingEnergyConsumption.devices.forEach(device => {
        promises.push(new Promise((resolve, reject) => {
          this.$axios
            .post(
              `buildings/${state.buildingSelectId}/get-energy-consumption`,
              {
                ...state.energyRequestData,
                devices: [device.id],
              },
              {
                headers: {
                  Authorization: `Bearer ${this.$auth.user.token}`,
                },
              },
            )
            .then((res) => {
              // commit(SET_BUILDING_ENERGY_CONSUMPTION, res.data.data);
              resolve(res.data.data);
            })
            .catch((err) => {
              errorHandlerWithAction.call(this, err, {
                onRepeat: () => dispatch('fetchBuildingEnergyConsumption', onClose),
                onClose,
              });
            });
        }));
      });

      Promise.all(promises).then((values) => {
        res(values);
      });
    });
    // return new Promise((resolve, reject) => {
    //   this.$axios
    //     .post(
    //       `buildings/${state.buildingSelectId}/get-energy-consumption`,
    //       state.energyRequestData,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${this.$auth.user.token}`,
    //         },
    //       },
    //     )
    //     .then((res) => {
    //       commit(SET_BUILDING_ENERGY_CONSUMPTION, res.data.data);
    //       resolve(res.data.data);
    //     })
    //     .catch((err) => {
    //       errorHandlerWithAction.call(this, err, {
    //         onRepeat: () => dispatch('fetchBuildingEnergyConsumption', onClose),
    //         onClose,
    //       });
    //     });
    // });
  },
  fetchEnergyTariffs({ commit, state, dispatch }, onClose) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(
          '/energy-tariffs',
          {
            headers: {
              Authorization: `Bearer ${this.$auth.user.token}`,
            },
          },
        )
        .then((res) => {
          commit(SET_ENERGY_TARIFFS, res.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          errorHandlerWithAction.call(this, err, {
            onRepeat: () => dispatch('fetchEnergyTariffs', onClose),
            onClose,
          });
        });
    });
  },
  setEnergyTariffs({ commit, state, dispatch }, { data, onClose }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .put(
          `/buildings/${state.buildingSelectId}/energy-tariffs`,
          data,
          {
            headers: {
              Authorization: `Bearer ${this.$auth.user.token}`,
            },
          },
        )
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          errorHandlerWithAction.call(this, err, {
            onRepeat: () => dispatch('setEnergyTariffs', { data, onClose }),
            onClose,
          });
        });
    });
  },
  deleteEnergyTariff({ commit, state, dispatch }, onClose) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(
          `/buildings/${state.buildingSelectId}/energy-tariffs`,
          {
            headers: {
              Authorization: `Bearer ${this.$auth.user.token}`,
            },
          },
        )
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          errorHandlerWithAction.call(this, err, {
            onRepeat: () => dispatch('deleteEnergyTariff', onClose),
            onClose,
          });
        });
    });
  },
  fetchTarifficationPointDetails({ commit, state, dispatch }, { data, onClose }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(
          `buildings/${state.buildingSelectId}/energy-consumption/get-details`,
          data,
          {
            headers: {
              Authorization: `Bearer ${this.$auth.user.token}`,
            },
          },
        )
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          errorHandlerWithAction.call(this, err, {
            onRepeat: () => dispatch('fetchTarifficationPointDetails', { data, onClose }),
            onClose,
          });
        });
    });
  },
};
