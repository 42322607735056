
import ErrorStroke from '@/components/ErrorStroke.vue';

export default {
  name: "ClimateOnlineErrors",
  components: {
    ErrorStroke,
  },
  props: {
    errors: {
      type: Array,
      default: () => {},
    },
  },
};
