

export default {
  name: 'ResizeCol',
  props: {
    isFirst: Boolean,
    isSecond: Boolean,
    isLast: Boolean,
    index: Number,
    selected: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click', 'context-menu-show', 'dragging', 'add-first', 'is-dragging'],
  data() {
    return {
      isDragging: false,
      showContent: true,
    };
  },
  mounted() {
    this.showContentHandler();
  },
  updated() {
    this.showContentHandler();
  },
  methods: {
    showContextMenu(e) {
      let isResize = false;
      const path = e.composedPath();
      path.forEach((div) => {
        if (div && div.classList && div.classList.contains('slider_col')) {
          isResize = true;
        }
      });
      if (!isResize) {
        e.preventDefault();
        this.$emit('context-menu-show', e);
      }
    },
    showContentHandler() {
      if (this.$refs.col && this.$refs.content) {
        this.showContent = this.$refs.col.clientWidth > this.$refs.content.clientWidth + 4;
      }
    },
    clickHandler(e) {
      let isResize = false;
      const path = e.path || e.composedPath();
      path.forEach((div) => {
        if (div && div.classList && div.classList.contains('slider_col')) {
          isResize = true;
        }
      });
      if (!isResize) {
        this.$emit('click');
      }
    },
    mobileResizeCol(e) {
      e.stopPropagation();
      const oldPos = e.changedTouches[0].clientX;
      const oldWidth = this.$refs.col.getBoundingClientRect().width;
      let newPos = 0;
      let newWidth = 0;
      const vue = this;
      this.isDragging = true;
      this.$emit('is-dragging', this.isDragging);
      // eslint-disable-next-line no-shadow
      function sliderDrag(e) {
        if (this.time && Date.now() - this.time < 40) return;
        this.time = Date.now();
        e = e || window.event;
        e.stopPropagation();
        newPos = e.changedTouches[0].clientX;

        const movingDistance = oldPos - newPos;
        newWidth = parseFloat(oldWidth - movingDistance);

        vue.$emit('dragging', [newWidth, vue.index]);
      }
      function cancelSliderDrag() {
        vue.isDragging = false;
        vue.$emit('is-dragging', vue.isDragging);
        document.ontouchmove = null;
        document.ontouchend = null;
      }
      document.ontouchmove = sliderDrag;
      document.ontouchend = cancelSliderDrag;
    },
    dragFirstMob(e) {
      e.stopPropagation();
      const oldPos = e.changedTouches[0].clientX;
      const oldWidth = 1;
      let newPos = 0;
      let newWidth = 0;
      this.isDragging = true;
      const vue = this;
      this.$emit('is-dragging', this.isDragging);
      this.$emit('add-first');
      function sliderDrag(e) {
        if (this.time && Date.now() - this.time < 40) return;
        this.time = Date.now();
        e = e || window.event;
        e.stopPropagation();
        newPos = e.changedTouches[0].clientX;

        const movingDistance = oldPos - newPos;
        newWidth = parseFloat(oldWidth - movingDistance);

        vue.$emit('dragging', [newWidth, 0]);
      }
      function cancelSliderDrag() {
        vue.isDragging = false;
        vue.$emit('is-dragging', vue.isDragging);
        document.ontouchmove = null;
        document.ontouchend = null;
      }
      document.ontouchmove = sliderDrag;
      document.ontouchend = cancelSliderDrag;
    },
    dragFirst(e) {
      e.preventDefault();
      const oldPos = e.clientX;
      const oldWidth = 1;
      let newPos = 0;
      let newWidth = 0;
      this.isDragging = true;
      const vue = this;
      this.$emit('is-dragging', this.isDragging);
      this.$emit('add-first');
      function sliderDrag(e) {
        if (this.time && Date.now() - this.time < 40) return;
        this.time = Date.now();
        e = e || window.event;
        e.preventDefault();
        e.stopPropagation();
        newPos = e.clientX;

        const movingDistance = oldPos - newPos;
        newWidth = parseFloat(oldWidth - movingDistance);

        vue.$emit('dragging', [newWidth, 0]);
      }
      function cancelSliderDrag() {
        vue.isDragging = false;
        vue.$emit('is-dragging', vue.isDragging);
        document.onmouseup = null;
        document.onmousemove = null;
      }
      document.onmousemove = sliderDrag;
      document.onmouseup = cancelSliderDrag;
    },
    resizeCol(e) {
      e.preventDefault();
      e.stopPropagation();
      const oldPos = e.clientX;
      const oldWidth = this.$refs.col.getBoundingClientRect().width;
      let newPos = 0;
      let newWidth = 0;
      const vue = this;
      this.isDragging = true;
      this.$emit('is-dragging', this.isDragging);
      // eslint-disable-next-line no-shadow
      function sliderDrag(e) {
        if (this.time && Date.now() - this.time < 40) return;
        this.time = Date.now();
        e = e || window.event;
        e.preventDefault();
        e.stopPropagation();
        newPos = e.clientX;

        const movingDistance = oldPos - newPos;
        newWidth = parseFloat(oldWidth - movingDistance);

        vue.$emit('dragging', [newWidth, vue.index]);
      }
      function cancelSliderDrag() {
        vue.isDragging = false;
        vue.$emit('is-dragging', vue.isDragging);
        document.onmouseup = null;
        document.onmousemove = null;
      }
      document.onmousemove = sliderDrag;
      document.onmouseup = cancelSliderDrag;
    },
  },
};
