
import { mixin as clickaway } from "vue-clickaway";
import { mapActions, mapGetters } from "vuex";
import { getSignDirection } from "@/utils/helpers";
import ControlPanelCircular from "@/components/control-panel/ControlPanelCircular.vue";
import ControlPanelLottie from "@/components/control-panel/ControlPanelLottie.vue";
import ControlPanelIcon from "@/components/control-panel/ControlPanelIcon.vue";
import ControlPanelCard from "@/components/control-panel/ControlPanelCard.vue";
import ControlPanelLinear from "@/components/control-panel/ControlPanelLinear.vue";
import ControlPanelTimer from "@/components/control-panel/ControlPanelTimer.vue";
import ControlPanelSchedule from "@/components/control-panel/ControlPanelSchedule.vue";
import ControlPanelIndicator from "@/components/control-panel/ControlPanelIndicator.vue";
import ControlItemContextMenu from "@/components/contextMenu/controlItemContextMenu.vue";
import CloseIcon from "../../assets/icons/x.svg";
import WifiIcon from "../../assets/icons/wifi.svg";
import WifiIconOff from "../../assets/icons/wifiOff.svg";
import SettingIcon from "../../assets/icons/settings.svg";
import StateBadge from "../StateBadge.vue";
import FastCommand from "../../assets/icons/side-bar/fastCommands.svg";
import SubscribeBadge from "../SubscribeBadge.vue";
import SubscribeLabel from "../SubscribeLabel.vue";
import AddFastCommandButton from "../Buttons/AddFastCommandButton.vue";
import DashboardPlaceContextMenu from "../contextMenu/dashboardPlaceContextMenu.vue";
import PlacePresetCard from "../Cards/PlacePresetCard.vue";
import GroupPresetCard from "../Cards/GroupPresetCard.vue";
import ArrowLeftIcon from "../../assets/icons/36x36/arrowLeft.svg";
import DashboardFastCommandsGroupContextMenu from "../contextMenu/dashboardFastCommandsGroupContextMenu.vue";
import DashboardFastCommandContextMenu from "../contextMenu/dashboardFastCommandContextMenu.vue";
import AppButton from "../Buttons/AppButton.vue";
import WalletIcon from "../../assets/images/wallet.svg";
// import { pages2 as pages } from "../../mock/tarification/payment-session";
import { errorConflictDeviceHandler } from "@/utils/api";
import ClimateOnlineLabel from "../ClimateOnlineLabel.vue";
import lottieAnimations from "@/static/lottie-circle-animations";

export default {
  name: "ControlPanel",
  components: {
    AppButton,
    DashboardFastCommandContextMenu,
    DashboardFastCommandsGroupContextMenu,
    GroupPresetCard,
    PlacePresetCard,
    DashboardPlaceContextMenu,
    ControlItemContextMenu,
    AddFastCommandButton,
    SubscribeBadge,
    ArrowLeftIcon,
    StateBadge,
    CloseIcon,
    SettingIcon,
    FastCommand,
    WifiIcon,
    WifiIconOff,
    ControlPanelCircular,
    ControlPanelLottie,
    ControlPanelIcon,
    ControlPanelCard,
    ControlPanelLinear,
    ControlPanelTimer,
    ControlPanelSchedule,
    WalletIcon,
    SubscribeLabel,
    ClimateOnlineLabel,
  },
  mixins: [clickaway],
  props: {},
  data: () => ({
    setTimer: false,
    tabNumber: 0,
    showSettings: false,
    deviceInfoSaveState: null,
    panelSettingsData: null,
    dataPreparing: false,
    presetsPaginationCounter: 10,
    groupPresetsPaginationCounter: 10,
    paymentSessionsLoading: false,
    climateOnlineLabelDisabled: false,
    lottieAnimations: null,
  }),
  computed: {
    ...mapGetters({
      deviceInfo: "deviceFunctions/getDeviceInfo",
      globalFunctionsLoading: "deviceFunctions/getGlobalFunctionsLoading",
      globalNeedForUpdate: "deviceFunctions/getGlobalNeedForUpdate",
      isShowModal: "system/getShowModal",
      panelDeviceId: "system/getPanelDeviceId",
      panelActiveFromGroup: "system/getPanelActiveFromGroup",
      getTranslations: "localization/getTranslations",
    }),
    deviceConnected() {
      return this.deviceInfo?.status === "connected";
    },
    deviceAvailable() {
      return this.deviceConnected && this.deviceInfo?.state?.isOn;
    },
    curTempSign() {
      return getSignDirection(this.deviceInfo.curTemp);
    },
    devicePlacePresets() {
      if (this.deviceInfo) {
        const presets = [...this.deviceInfo.presets];
        return presets ? presets.splice(0, this.presetsPaginationCounter) : [];
      }
      return [];
    },
    deviceGroupPlacePresets() {
      if (this.deviceInfo) {
        const groupPresets = [...this.deviceInfo.groupPresets];
        return groupPresets
          ? groupPresets.splice(0, this.groupPresetsPaginationCounter)
          : [];
      }
      return [];
    },
  },
  watch: {
    panelDeviceId: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue && newValue) {
          this.prepareDeviceInfo();
        }
      },
    },
  },
  mounted() {
    this.prepareDeviceInfo();
  },
  beforeMount() {
    this.lottieAnimations = lottieAnimations;
  },
  beforeDestroy() {
    // console.log("destroy");
  },
  methods: {
    ...mapActions({
      fetchDeviceInfo: "deviceFunctions/fetchDeviceInfo",
      fetchClimateOnline: "deviceFunctions/fetchClimateOnline",
      applyDeviceFunction: "deviceFunctions/applyDeviceFunction",
      togglePanelActive: "system/togglePanelActive",
      setObjectPanel: "system/setObjectPanel",
      fetchSessions: "payment/fetchSessions",
      setPanelGroup: "system/setPanelGroup",
      setModalPaymentId: "modalPaymentInfo/setModalData",
    }),
    backToObjectPanel() {
      this.togglePanelActive(false);
      this.setObjectPanel(true);
    },
    closePanel() {
      if (!this.isShowModal) {
        this.togglePanelActive(false);
        this.setObjectPanel(false);
      }
    },
    closePanelOnBg(e) {
      const path = e.path || e.composedPath();
      if (
        path &&
        path[0] &&
        path[0].classList &&
        path[0].classList.contains("control-panel__bg")
      ) {
        this.closePanel();
      }
    },
    toggleShowSettings(e) {
      this.$refs.contextMenu.toggle([e, this.deviceInfo]);
    },
    toggleControlItemContextMenu(e) {
      this.$refs.controlItemContextMenu.toggle(e);
    },
    setDeviceConflict() {
      errorConflictDeviceHandler.call(
        this,
        this.deviceInfo.tarificationConflictPopUp,
        () => {
          this.$store.commit("payment/SET_PAYMENT_DEVICE_CONFLICT_STATE", true);
        }
      );
    },
    prepareDeviceInfo() {
      if (this.panelDeviceId) {
        this.dataPreparing = true;
        this.fetchDeviceInfo(this.panelDeviceId).then(() => {
          this.dataPreparing = false;
          if (this.deviceInfo.tarificationConflictPopUp) {
            this.setDeviceConflict();
          }
        });
      } else {
        console.error("Не задан ID девайса для пульта");
      }
    },
    openAddFastCommand() {
      this.$modal.show("AddFastCommandModal");
    },
    onBadgeClick() {
      if (!this.deviceInfo.subscription.buttonUrl) return;
      // this.$router.push(this.deviceInfo.subscription.buttonUrl.url);
      if (typeof this.deviceInfo.subscription.buttonUrl === "object") {
        window.location.href = this.deviceInfo.subscription.buttonUrl.url;
      } else {
        window.location.href = this.deviceInfo.subscription.buttonUrl;
      }
      //this.$modal.show('paymentModal');
      // this.paymentSessionsLoading = true;
      // this.fetchSessions().then((response) => {
      //   this.$store.commit('payment/SET_SESSION', response.data.data);
      //   this.$modal.show('paymentModal');
      // })
      //   .catch((err) => {
      //     console.log(err);

      //     if (err.response.status === 404) {
      //       this.$store.commit('payment/SET_SESSION', pages);
      //       this.$modal.show('paymentModal');
      //     }
      //   })
      //   .finally(() => {
      //     this.paymentSessionsLoading = false;
      //   });
    },
    onLabelClick() {
      if (
        this.deviceInfo &&
        this.deviceInfo.tarificationInfo &&
        !this.deviceInfo.tarificationInfo.isLabelButtonInteractable
      )
        return;

      this.setModalPaymentId(this.deviceInfo.subscriptionId);
      this.$modal.show("paymentInfoModal");
    },
    onClimateOnlineLabelClick() {
      this.climateOnlineLabelDisabled = true;
      this.fetchClimateOnline(
        {
          deviceId: this.panelDeviceId,
          onClose: () => {
            this.$modal.hide('climateOnlineModal');
          },
        },
      )
        .then(() => this.$modal.show("climateOnlineModal"))
        .finally(() => {
          this.climateOnlineLabelDisabled = false;
        });
    },
  },
};
