
import { mapActions, mapGetters } from "vuex";
import modalEsc from "@/mixins/modalEsc";
import AppButton from "@/components/Buttons/AppButton.vue";
import TimerInput from "@/components/Inputs/TimerInput.vue";
import { secondsToHHMM, getTextStyle } from "@/utils/helpers";
import { errorHandler } from "@/utils/api";

export default {
  name: "ConflictResolveModal",
  components: {
    AppButton,
    TimerInput,
  },
  mixins: [modalEsc],
  data: () => ({
    modalName: "ConflictResolveModal",
    timeValueInitial: "00:30",
    timeValue: "00:30",
    contentWithTime: null,
    loading: false,
  }),
  computed: {
    ...mapGetters({
      mainData: "modalConflictResolve/mainData",
      config: "modalConflictResolve/config",
      forceLoadingCardId: "modalConflictResolve/forceLoadingCardId",
      panelDeviceId: "system/getPanelDeviceId",
      getPaymentSessionConflictState: "payment/getPaymentSessionConflictState",
      getPaymentOrderConflictState: "payment/getPaymentOrderConflictState",
      getPaymentDeviceConflictState: "payment/getPaymentDeviceConflictState",
    }),
    disabledRequest() {
      return !!(this.contentWithTime && this.timeValue.length < 5);
    },
  },
  methods: {
    ...mapActions({
      toggleModal: "system/setShowModal",
      findCurrentDevice: "deviceFunctions/findCurrentDevice",
      setModalConflictResolveData: "modalConflictResolve/setModalData",
      fetchDeviceInfo: "deviceFunctions/fetchDeviceInfo",
    }),
    async modalOpened() {
      document.addEventListener("keydown", this.clickHandler);
      this.toggleModal(true);

      this.contentWithTime = this.mainData.contents.find(
        (content) => content.timeContent !== null
      );
      if (this.contentWithTime) {
        const timeContentMinutes = this.contentWithTime.timeContent;
        if (this.contentWithTime.timeContent !== null) {
          this.timeValue = secondsToHHMM(timeContentMinutes * 60);
        }
      }
    },
    async modalClosed() {
      document.removeEventListener("keydown", this.clickHandler);
      this.setModalConflictResolveData({
        mainData: null,
        config: null,
        forceLoadingCardId: null,
      });
      this.timeValue = "00:30";
      this.contentWithTime = null;
      this.toggleModal(false);
      this.$store.commit("payment/SET_PAYMENT_SESSION_CONFLICT_STATE", false);
      this.$store.commit("payment/SET_PAYMENT_ORDER_CONFLICT_STATE", false);
      this.$store.commit("payment/SET_PAYMENT_DEVICE_CONFLICT_STATE", false);
    },

    getTextStyleBus(attributedText) {
      return getTextStyle(attributedText);
    },

    onTimeChange(value) {
      this.timeValue = value;
    },

    actionClick(button) {
      if (!button.behaviour) {
        this.$modal.hide(this.modalName);
        return;
      }

      if (this.getPaymentDeviceConflictState && button.type === "PRIMARY") {
        window.location.href = button.urlData.url;
        return;
      }

      if (button.behaviour && button.behaviour === "URL") {
        window.location.href = button.urlData.url;
        return;
      }

      if (button.conflictResolveData) {
        this.submitConflictResolve(button);
      } else {
        this.$modal.hide(this.modalName);
        this.fetchDeviceInfo(this.panelDeviceId);
      }
    },

    onModalClose() {
      this.$modal.hide(this.modalName);

      if (this.getPaymentSessionConflictState) {
        this.$store.commit("payment/SET_PAYMENT_SESSION_CONFLICT_STATE", false);
        this.$router.push("/payments/finish");
        return;
      }

      if (this.getPaymentOrderConflictState) {
        this.$store.commit("payment/SET_PAYMENT_ORDER_CONFLICT_STATE", false);
        this.$router.push("/payments/finish");
        return;
      }

      if (this.getPaymentDeviceConflictState) {
        this.$store.commit("payment/SET_PAYMENT_DEVICE_CONFLICT_STATE", false);
      }

      this.fetchDeviceInfo(this.panelDeviceId);
    },

    submitConflictResolve(button) {
      let requestBody = {};
      if (this.config.data) {
        requestBody = JSON.parse(this.config.data);
      }

      requestBody.conflictResolveData = button.conflictResolveData;

      if (this.contentWithTime) {
        const timeArr = this.timeValue.split(":");
        requestBody.conflictResolveData.time =
          Number(timeArr[0]) * 60 + Number(timeArr[1]);
      }

      const thenHandler = (response) => {
        if (this.forceLoadingCardId) {
          this.$store.commit(
            "system/FORCE_LOCAL_LOADING_CARD_PLACE_ID",
            this.forceLoadingCardId
          );
        }

        const systemStateUpdate = response.data.data;
        this.findCurrentDevice(systemStateUpdate);

        const updatedDevices = systemStateUpdate.devices;
        for (const updated in updatedDevices) {
          this.$store.dispatch(
            "buildingDashboard/setPlaceData",
            updatedDevices[updated]
          );
        }

        const updatedPresets = systemStateUpdate.presets;
        const updatedGroupPresets = systemStateUpdate.groupPresets;
        this.$store.dispatch("buildingDashboard/updatePresets", updatedPresets);
        this.$store.dispatch(
          "buildingDashboard/updateGroupPresets",
          updatedGroupPresets
        );
        this.$store.dispatch(
          "buildingDashboard/updateSchedules",
          systemStateUpdate.schedules
        );
        for (const updated in updatedPresets) {
          this.$store.commit(
            "fastCommandsPage/EDIT_PLACE_PRESET",
            updatedPresets[updated]
          );
        }
        for (const updated in updatedGroupPresets) {
          this.$store.commit(
            "fastCommandsPage/EDIT_GROUP_PRESET",
            updatedGroupPresets[updated]
          );
        }

        this.$modal.hide(this.modalName);
      };

      const thenPaymentHandler = (response) => {
        this.$store.commit("payment/SET_SESSION", response.data.data);
        this.$modal.show("paymentModal");
      };

      const thenOrderHandler = (response) => {
        window.location.href = response.data.data.purchaseUrl;
      };

      this.loading = true;
      if (this.config.method === "delete" || this.config.method === "get") {
        this.$axios[this.config.method](this.config.url, {
          data: requestBody,
          headers: this.config.headers,
        })
          .then((response) => {
            if (this.getPaymentSessionConflictState) {
              thenPaymentHandler(response);
              return;
            }

            if (this.getPaymentOrderConflictState) {
              thenOrderHandler(response);
              return;
            }

            thenHandler(response);
          })
          .catch((error) => {
            if (
              this.getPaymentSessionConflictState ||
              this.getPaymentOrderConflictState
            )
              return;

            errorHandler.call(this.$store, error);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$axios[this.config.method](
          this.config.url,
          requestBody,
          this.config.headers
        )
          .then((response) => {
            if (this.getPaymentSessionConflictState) {
              thenPaymentHandler(response);
              return;
            }

            if (this.getPaymentOrderConflictState) {
              thenOrderHandler(response);
              return;
            }

            thenHandler(response);
          })
          .catch((error) => {
            if (
              this.getPaymentSessionConflictState ||
              this.getPaymentOrderConflictState
            )
              return;

            errorHandler.call(this.$store, error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
