
import { mapActions, mapGetters } from 'vuex';
import { errorServiceHandler } from '@/utils/api';
import CheckboxObject from '../checks/checkbox-object.vue';
import AppButton from '../Buttons/AppButton.vue';
import modalEsc from '../../mixins/modalEsc';
import SearchInput from '../Inputs/SearchInput.vue';

export default {
  name: 'EditGroupModal',
  components: { SearchInput, AppButton, CheckboxObject },
  mixins: [modalEsc],
  data: () => ({
    modalName: 'editGroupModal',
    listOfPlaces: [],
    preloading: false,
    searchValue: '',
    countChecked: 0,
    SHOW_BY: 21,
    countShowPlaces: 21,
  }),
  computed: {
    ...mapGetters({
      dashboardData: 'buildingDashboard/getBuildingSelectDashboard',
      currentGroupId: 'editGroupModal/getCurrentGroupId',
      modalOpened: 'system/getShowModal',
      buildingSelectId: 'buildings/getBuildingSelectId',
      objectPanelData: 'system/getPanelGroup',
      getTranslations: 'localization/getTranslations',
    }),
    searchPlacesResult() {
      return this.listOfPlaces.filter((place) => place.title.toLowerCase()
        .includes(this.searchValue.toLowerCase()));
    },
    searchPlacesToShow() {
      return this.searchPlacesResult.slice(0, this.countShowPlaces);
    },
    showMoreAmount() {
      let amount = 0;
      const leftToShow = this.searchPlacesResult.length - this.countShowPlaces;
      if (leftToShow >= this.SHOW_BY) {
        amount = this.SHOW_BY;
      } else if (leftToShow > 0) {
        amount = leftToShow;
      }
      return amount;
    },
    allGroups() {
      return this.dashboardData ? this.dashboardData.groups : [];
    },
    allPlaces() {
      return this.dashboardData ? this.dashboardData.places : [];
    },
  },
  methods: {
    ...mapActions({
      setShowModal: 'system/setShowModal',
      fetchObjectsPageData: 'objectsPage/fetchPageData',
    }),
    searchInput() {
      this.countShowPlaces = this.SHOW_BY;
    },
    async modalOpen() {
      const places = [];
      if (this.allGroups.find((item) => item.id === this.currentGroupId)) {
        places.push(...this.allGroups.find((item) => item.id === this.currentGroupId).places);
        places.push(...this.allPlaces);
        for (let i = 0; i < places.length; i++) {
          places[i].checked = places[i].groupId === this.currentGroupId;
          if (places[i].checked === true) {
            this.countChecked++;
          }
        }
        this.listOfPlaces = places;
      }
      this.setShowModal(true);
      document.addEventListener('keydown', this.clickHandler);
    },

    modalClosed() {
      this.setShowModal(false);
      document.removeEventListener('keydown', this.clickHandler);
      this.countChecked = 0;
      this.listOfPlaces = [];
      this.countShowPlaces = this.SHOW_BY;
    },
    checkItem(item) {
      for (let i = 0; i < this.listOfPlaces.length; i++) {
        if (this.listOfPlaces[i].id === item.id) {
          this.listOfPlaces[i].checked = !this.listOfPlaces[i].checked;
          this.listOfPlaces[i].checked ? this.countChecked++ : this.countChecked--;
        }
      }
    },
    async editGroup() {
      const placesInGroup = this.listOfPlaces.filter((item) => item.checked === true);
      const ids = placesInGroup.map((item) => item.id);
      if (placesInGroup.length >= 2) {
        try {
          const group = this.allGroups.find((item) => item.id === this.currentGroupId);
          const { data } = await this.$axios.put(`groups/${this.currentGroupId}`, {
            title: group.title,
            places: ids,
          });
          this.$store.commit('buildingDashboard/SET_NEW_PLACES_GROUPS', data.data);
          this.$store.commit('objectsPage/SET_PAGE_DATA', data.data);
          if (this.objectPanelData && this.objectPanelData.id === this.currentGroupId) {
            this.$store.commit('system/CONTROL_PANEL_GROUP', data.data.groups.find((item) => item.id === this.currentGroupId));
          }
        } catch (error) {
          errorServiceHandler.call(this, error);
        } finally {
          this.$modal.hide(this.modalName);
        }
      }
    },
  },
};
