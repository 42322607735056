import {
  SET_MODAL_CONFLICT_RESOLVE_DATA,
} from '@/utils/types';

export const state = () => ({
  mainData: null,
  config: null,
  forceLoadingCardId: null,
});

export const getters = {
  mainData: ({ mainData }) => mainData,
  config: ({ config }) => config,
  forceLoadingCardId: ({ forceLoadingCardId }) => forceLoadingCardId,
};

export const mutations = {
  [SET_MODAL_CONFLICT_RESOLVE_DATA](state, data) {
    state.mainData = data.mainData;
    state.config = data.config;
    state.forceLoadingCardId = data.forceLoadingCardId;
  },
};

export const actions = {
  setModalData({ commit }, data) {
    commit(SET_MODAL_CONFLICT_RESOLVE_DATA, data);
  },
};
