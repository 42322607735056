
export default {
  name: 'AppCheckbox',
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String, Number],
      default: 'cbx',
    },
    blocked: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
};
