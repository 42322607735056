
import { mixin as clickaway } from 'vue-clickaway';
import { mapActions, mapGetters } from 'vuex';
import LoadingSpinner from '@/components/loaders/LoadingSpinner.vue';
import FixCard from '../../assets/icons/fixcard.svg';
import TimeIcon from '../../assets/icons/time.svg';
import PresetStatus from '../class/PresetStatus';

export default {
  components: { TimeIcon, FixCard, LoadingSpinner },
  mixins: [clickaway],
  props: {
    preset: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: 'standard',
    },
    hidePin: {
      type: Boolean,
      default: false,
    },
    animation: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['contextMenuShow'],
  data: () => ({
    showAnimation: false,
    blocked: false,
  }),
  computed: {
    ...mapGetters({
      forceLocalLoadingCardPlaceId: 'system/getForceLocalLoadingCardPlaceId',
      groupPresetInfoId: 'fastCommandsPage/getPresetInPanelInfoId',
      getTranslations: 'localization/getTranslations',
    }),
    presetStatus() {
      return new PresetStatus(this.preset);
    },
    presetTitles() {
      const titlesList = this.preset.contents.map((item) => item.placeTitle || '');
      return titlesList.filter((item) => item !== '').join(', ');
    },
    opened() {
      return this.groupPresetInfoId === this.preset?.id;
    },
  },
  watch: {
    forceLocalLoadingCardPlaceId: {
      handler(newValue) {
        if (newValue && newValue === this.preset.id) {
          this.showAnimation = true;
          this.blocked = true;
          setTimeout(() => {
            this.showAnimation = false;
            this.blocked = false;
            this.$store.commit('system/FORCE_LOCAL_LOADING_CARD_PLACE_ID', null);
          }, 2000);
        }
      },
    },
  },
  beforeDestroy() {
    this.$refs.preset.removeEventListener('long-press', (e) => {
      this.$emit('contextMenuShow', [e, this.preset]);
    });
  },
  mounted() {
    if (this.animation && this.$refs.preset) {
      this.$refs.preset.style.opacity = 0;
      setTimeout(() => {
        if (this.$refs.preset) {
          this.$refs.preset.style.opacity = 1;
        }
      }, 300);
    }
    this.$refs.preset.addEventListener('long-press', (e) => {
      this.$emit('context-menu-show', [e, this.preset]);
    });
  },
  methods: {
    ...mapActions({
      setGroupPresetStatus: 'buildingDashboard/setGroupPresetStatus',
    }),
    async clickHandler() {
      if (!this.blocked && !this.showAnimation) {
        this.blocked = true;
        await this.setGroupPresetStatus({
          id: this.preset.id,
          isOn: this.preset.isOn,
          table: 'groupPresets',
        });
        this.blocked = false;
      }
    },
  },
};
