
import InputRange from '@/components/Inputs/InputRange';
import ButtonCount from '@/components/Buttons/ButtonCount';
import ToggleButton from '@/components/Buttons/ToggleButton';
import { debounce } from '@/utils/helpers';

export default {
  name: 'ControlPanelCircular',
  components: { ButtonCount, InputRange, ToggleButton },
  props: {
    deviceConnected: {
      type: Boolean,
      required: true,
    },
    functionBlockId: {
      type: Number,
      required: true,
    },
    functionItem: {
      type: Object,
      required: true,
    },
    needForUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    minTemp: 0,
    maxTemp: 40,
    temperature: 0,
  }),
  computed: {
    tempUnitSymbol() {
      if (this.functionItem && this.functionItem.uiInfo && this.functionItem.uiInfo.units) {
        return this.functionItem.uiInfo.units.replace('℃', '°С');
      }
      return '';
    },
    blocked() {
      return this.functionItem.state.blocked || !this.deviceConnected;
    },
    controllable() {
      return this.functionItem.state.controllable && this.deviceConnected;
    },
    active() {
      return this.functionItem.state.isOn;
    },
    controllableToggle() {
      return this.functionItem.linkedFunction?.state.controllable && this.deviceConnected;
    },
    onOffState() {
      return this.functionItem.linkedFunction?.state.isOn;
    },
  },
  watch: {
    functionItem: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.prepareData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  beforeMount() {
    this.prepareData();
  },
  methods: {
    changeTemperature(value) {
      this.temperature = value;
    },

    changeTemperatureApply: debounce(function () {
      this.functionTemperatureState();
    }, 500),

    changeOnOffState(value) {
      this.functionOnOffState(this.functionItem.linkedFunction.metaData.applyable ? true : value);
    },

    prepareData() {
      const { valueRange } = this.functionItem.state;
      this.minTemp = valueRange[0];
      this.maxTemp = valueRange[valueRange.length - 1];
      console.log(this.functionItem.state.value);
      this.temperature = this.functionItem.state.value;
    },

    functionTemperatureState() {
      const requestValue = {
        functionId: this.functionItem.id,
        value: this.temperature,
        parameters: null,
      };

      this.$emit('controlStateChanged', requestValue);
    },

    functionOnOffState(value) {
      const requestValue = {
        functionId: this.functionItem.linkedFunction.id,
        isOn: value,
        parameters: null,
      };
      this.$emit('controlStateChanged', requestValue);
    },
  },
};
