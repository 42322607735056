
export default {
  name: 'DayButton',
  props: {
    day: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    isFree: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
};
