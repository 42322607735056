
import SwiperCore, {
  Swiper, Navigation,
} from 'swiper';
import CardPayment from "../Cards/CardPayment.vue";

SwiperCore.use([Navigation]);

export default {
  name: "PaymentCardsSlider",
  components: {
    CardPayment,
  },
  props: {
    cards: {
      type: Array,
      default: () => [],
    },
    onCardButtonClick: {
      type: Function,
      default: () => 1,
    },
    onCardClick: {
      type: Function,
      default: () => 1,
    },
  },
  data: () => ({
    slider: null,
    sliderOptions: {
      slidesPerView: 'auto',
      watchOverflow: true,
      // allowTouchMove: false,
      // breakpointsBase: 'container',
      // observeParents: true,
      // observer: true,
      // spaceBetween: 16,
      // breakpoints: {
      //   768: {
      //     slidesPerView: 4,
      //     spaceBetween: 16,
      //   },
      //   900: {
      //     slidesPerView: 5,
      //     spaceBetween: 16,
      //   },
      // },
    },
  }),
  mounted() {
    this.initSlider();
  },
  methods: {
    initSlider() {
      const sliderItem = this.$refs.slider;
      if (!sliderItem) {
        return;
      }
      this.slider = new Swiper(sliderItem, this.sliderOptions);
    },
  },
};
