
import ServerConnectionError from '@/assets/images/errors/server-connection-error.svg';
import modalEsc from '@/mixins/modalEsc';
import { mapGetters } from 'vuex';

export default {
  name: 'Modal500',
  components: { ServerConnectionError },
  mixins: [modalEsc],
  data() {
    return {
      modalName: 'serverError',
    };
  },
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
      isSelectPage: 'payment/getSelectPageState',
    }),
  },
  methods: {
    onClose() {
      if (this.isSelectPage) {
        this.$router.push('/dashboard');
      }
    },
  },
};
