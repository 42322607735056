
import GoogleAppIcon from '../assets/icons/apps/app-google.svg';
import IosAppIcon from '../assets/icons/apps/app-ios.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'SmallScreen',
  components: { GoogleAppIcon, IosAppIcon },
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
  },
};
